import React from "react";
import { Container, Nav } from "react-bootstrap";
import {
  ExclamationSquare,
  PeopleFill,
  WrenchAdjustableCircle,
} from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { Link, Outlet, useLocation } from "react-router-dom";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";

const SuperAdminDashboard = () => {
  const location = useLocation();
  const siteName = SubDomainRouter("title");

  return (
    <>
      <Helmet>
        <title>
          Super Admin Dashboard | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <div className="mt-4 pt-5 dashboardSec">
        <div className="d-flex flex-md-row flex-column">
          <div className="dashboard-nav px-0 py-md-5">
            <div className="d-flex flex-md-column pb-md-5 gap-md-0 gap-4 justify-content-center">
              <Nav.Item>
                <Link
                  to="/super-admin-dashboard/config-management"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center lh-sm ${
                    location.pathname ===
                      "/super-admin-dashboard/config-management" && "active"
                  }`}
                >
                  <WrenchAdjustableCircle /> Configs Management
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to="/super-admin-dashboard/users"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center lh-sm ${
                    location.pathname === "/super-admin-dashboard/users" &&
                    "active"
                  }`}
                >
                  <PeopleFill /> Users
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to="/super-admin-dashboard/extractor"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center lh-sm ${
                    location.pathname === "/super-admin-dashboard/extractor" &&
                    "active"
                  }`}
                >
                  <ExclamationSquare /> Extractor
                </Link>
              </Nav.Item>
            </div>
          </div>
          <Container fluid="lg" className="px-lg-4 pb-5 pt-5 flex-grow-1">
            <Outlet />
          </Container>
        </div>
      </div>
    </>
  );
};

export default SuperAdminDashboard;
