import React, { useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import {
  FileEarmarkPlus,
  ImageAlt,
  Trash,
  Upload,
} from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";
import "./fileUpload.css";

const DynamicFileUploadEdit = ({
  field,
  fileCategory,
  inputIdPrefix,
  onFieldChange,
  acceptedTypes,
  noteLabel,
  onRemove,
  hasDelete = true,
}) => {
  const [name, setName] = useState("");
  const handleFileChange = (event) => {
    const file = event.target?.files[0];
    if (file) {
      const fileExtension = getFileExtension(file.name);
      setName(file.name);
      getBase64(file).then((base64) => {
        const attachment = {
          id: uuidv4(),
          documentType: fileCategory,
          field_name: field?.field_name ? field?.field_name : "",
          file_content: base64,
          file_extension: fileExtension,
        };

        onFieldChange(attachment);
      });
    }
  };

  const getFileExtension = (fileName) => {
    return fileName.split(".").pop().toLowerCase();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64 = reader.result.split(",")[1];
        resolve(base64);
      };
    });
  };

  const onFieldNameChange = (value) => {
    const updatedField = { ...field, field_name: value };
    onFieldChange(updatedField);
  };

  const isImageFile = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "tiff",
    "webp",
    "svg",
    "ico",
  ].includes(field?.file_extension);
  const isDocumentFile = [
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "odt",
    "odp",
    "ods",
    "txt",
    "rtf",
    "csv",
    "html",
    "css",
    "js",
  ].includes(field?.file_extension);
  console.log(field);
  return (
    <>
      <div className="d-flex gap-3 mb-3 flex-sm-row flex-column-reverse align-items-sm-start align-items-end">
        <div className="d-flex w-100 gap-3 align-items-sm-start align-items-center flex-sm-nowrap flex-wrap">
          {/* ?if File */}
          {fileCategory === "document" || fileCategory === "file" ? (
            <>
              {isDocumentFile ? (
                <div className="uploaded-thumb img-thumbnail rounded-2 d-flex flex-column align-items-center justify-content-center text-secondary">
                  <FileEarmarkPlus className="display-4" />
                  <span className="text-field-name mt-1">
                    {name ?? field.field_name ?? "Doc"}
                  </span>
                </div>
              ) : (
                <Form.Label
                  className="uploaded-thumb img-thumbnail rounded-2 d-flex flex-column align-items-center justify-content-center text-secondary cursor-pointer"
                  htmlFor={`${inputIdPrefix}-file`}
                >
                  <Upload className="display-4" />
                </Form.Label>
              )}
            </>
          ) : null}
          {/* If image */}
          <>
            {fileCategory === "image" &&
              (field?.file_extension && field?.file_content ? (
                <Image
                  src={`data:image/${field?.file_extension};base64,${field?.file_content}`}
                  alt=""
                  className="uploaded-thumb img-thumbnail rounded-2 object-fit-cover w-100 h-100"
                  width="100%"
                  height="100%"
                />
              ) : field?.url ? (
                <img
                  src={field?.url}
                  loading="lazy"
                  alt=""
                  className="uploaded-thumb img-thumbnail rounded-2 object-fit-cover w-100"
                  width="100%"
                  height="100%"
                />
              ) : field?.file_content ? (
                <Form.Label
                  className="uploaded-thumb img-thumbnail rounded-2 d-flex flex-column align-items-center justify-content-center text-secondary cursor-pointer"
                  htmlFor={`${inputIdPrefix}-file`}
                >
                  <ImageAlt className="display-1" />
                </Form.Label>
              ) : (
                <Form.Label
                  className="uploaded-thumb img-thumbnail rounded-2 d-flex flex-column align-items-center justify-content-center text-secondary cursor-pointer"
                  htmlFor={`${inputIdPrefix}-file`}
                >
                  <Upload className="display-4" />
                </Form.Label>
              ))}
          </>

          <div className="d-flex flex-column flex-wrap gap-2 w-100 mb-3 flex-grow-1">
            <Form.Group className="form-floating position-relative">
              <Form.Control
                type="text"
                className="uploadlabel docUploader w-100"
                name="document"
                placeholder="title"
                id={`${inputIdPrefix}-label`}
                value={field?.field_name}
                onChange={(e) => onFieldNameChange(e.target.value)}
              />
              <Form.Label
                className="form-label file-label"
                htmlFor={`${inputIdPrefix}-label`}
              >
                Add your {fileCategory} title
              </Form.Label>
            </Form.Group>
            <Form.Group className="flex-grow-1">
              <Form.Label
                className="form-label file-upload-label w-100 mb-0"
                htmlFor={`${inputIdPrefix}-file`}
              >
                <Form.Control
                  type="file"
                  className="uploadFile docUploader w-100 mt-1 d-none"
                  name="document"
                  id={`${inputIdPrefix}-file`}
                  accept={acceptedTypes}
                  onChange={handleFileChange}
                />
                <span className="btn add-button">Upload {fileCategory} </span>
                <div className="docPreview">
                  <span className="theme-text-2 d-block">
                    *Upload Your {noteLabel} File
                  </span>
                </div>
              </Form.Label>
            </Form.Group>
          </div>
        </div>

        {hasDelete && (
          <Button
            className="clickable actionDltBtn btn border-danger"
            onClick={() => onRemove(field.id)}
          >
            <span className="addBttn d-block">
              <Trash />
            </span>
          </Button>
        )}
      </div>
      <hr />
    </>
  );
};

export default DynamicFileUploadEdit;
