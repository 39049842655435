import React from "react";
import "./AboutPage.css";
import aboutImg from "../../img/SWIPERIMG3-min.jpg";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

function MovieShop() {
  return (
    <>
      <Helmet>
        <title>About | Ameerpet IT - World's Biggest Live Instructors Marketplace</title>
      </Helmet>
      <section className="about-sec">
        <Container fluid="lg" className="my-5 pt-5">
          <Row className="flex-lg-row flex-column-reverse justify-content-lg-between justify-content-center py-5">
            <Col lg={7} className="my-3">
              <h2 className="about-headings">
                About Us<span className="text-muted"></span>
              </h2>
              <p className="lead">
                AmeerPet IT (a product of <strong>Intellide, Inc</strong>) is
                your one-stop destination for online courses, trainers,
                institute demos, and AI chat services. We offer a wide range of
                high-quality courses and training programs in various IT fields,
                delivered by experienced trainers. Explore our institute demos
                to get a glimpse of the learning environment and teaching
                methodologies. Our AI chat service provides instant assistance
                and answers to your queries. Whether you're a beginner or an
                experienced professional, AmeerPet IT is here to help you
                enhance your skills and excel in the ever-evolving IT industry.
              </p>
              <Button className="about-solid-btn mt-lg-4 mt-3">
                Learn More
              </Button>
            </Col>
            <Col lg={5}>
              <Image
                fluid
                width={500}
                height={500}
                src={aboutImg}
                alt="aboutImg"
                className="about-img my-3"
              />
            </Col>
          </Row>
        </Container>
        <div className="semi-bg">
          <Container fluid="lg">
            <Row className="flex-lg-row flex-column-reverse justify-content-lg-between justify-content-center py-5">
              <Col lg={5}>
                <Image
                  fluid
                  width={500}
                  height={500}
                  src="https://images.theconversation.com/files/339942/original/file-20200604-67393-1dej576.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop"
                  alt="aboutImg"
                  className="about-img my-3"
                />
              </Col>
              <Col lg={7} className="my-3">
                <h2 className="about-headings">Courses/Trainers</h2>
                <p className="lead">
                  At AmeerPet IT, we offer a comprehensive learning platform
                  that brings together expert trainers and a diverse range of
                  courses. Our highly qualified trainers, with extensive
                  industry experience, deliver engaging and practical training
                  sessions across various IT disciplines. Explore our curated
                  selection of online courses, designed for learners of all
                  levels. From programming to web development, data science to
                  cybersecurity, our courses provide valuable skills and
                  knowledge. Benefit from personalized guidance, mentorship, and
                  industry insights as our trainers help you unlock your full
                  potential. Start your learning journey with us today.
                </p>
                <div className="btns d-flex gap-3 mt-4">
                  <Button className="about-solid-btn h4">Our Trainers</Button>
                  <Button className="about-outlined-btn h4">
                    Courses Details
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid="lg">
          <Row className="flex-lg-row flex-column-reverse justify-content-lg-between justify-content-center py-5">
            <Col lg={7} className="my-3">
              <h2 className="about-headings">AI Chat Bot</h2>
              <p className="lead my-2">
                Experience the power of AI with our interactive chat bot at
                AmeerPet IT. Our AI chat bot is designed to provide instant
                assistance and support, answering your questions and guiding you
                through your learning journey. Whether you need clarification on
                a course topic, recommendations for further study, or general
                inquiries about our platform, our AI chat bot is available 24/7
                to provide prompt and helpful responses. Interact with our
                intelligent chat bot and enjoy a seamless learning experience
                anytime, anywhere.
              </p>
              <Button className="about-solid-btn mt-lg-4 mt-3">
                Learn More
              </Button>
            </Col>
            <Col lg={5}>
              <Image
                fluid
                width={500}
                height={500}
                src="https://assets.www.happyfox.com/v2/images/hero-fold/CB_Image.png"
                alt="aboutImg"
                className="about-img my-3"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default MovieShop;
