import React from "react";
import { Accordion, Button, Col, Container, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import trainerImg from "../../img/how-it-works-for-trainer.png";
import studentImg from "../../img/how-it-works-for-students.png";
import conImg from "../../img/how-it-works-for-cons.png";
import orgImg from "../../img/how-it-works-for-org.png";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import "./howItWorks.css";
import { HowItWorksData } from "./howItWorksData";
import { Link } from "react-router-dom";

function HowItWorks() {
  const siteName = SubDomainRouter("title");
  const homePageRoute = SubDomainRouter("homePageRoute");
  const homeNavItem = SubDomainRouter("homeNavItem");

  return (
    <>
      <Helmet>
        <title>
          How It Works | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>
      <section className="about-sec my-5 pt-lg-5 pt-3">
        <Container fluid="lg">
          <Row className="justify-content-lg-between justify-content-center align-items-center py-5">
            <Col lg={7} className="my-3">
              <h4 className="about-headings text-wrap-balance">
                How It Works For Direct Students?
                <span className="text-muted"></span>
              </h4>
              <ul className="lead text-wrap-balance list-unstyled">
                <li className="mb-1 d-flex pb-1 gap-1">
                  <span className="fw-bold text-nowrap">Step 1:</span> Students
                  should enroll into
                  <Link
                    to={`${
                      homeNavItem === "/home" ? "/" : homePageRoute || "/"
                    }`}
                    className="theme-text-2"
                  >
                    AmeerpetIT.com
                  </Link>
                </li>
                <li className="mb-1 d-flex pb-1 gap-1">
                  <span className="fw-bold text-nowrap">Step 2:</span> Can
                  register for the courses in the platform and join for the demo
                  session of that course.
                </li>
                <li className="mb-1 d-flex pb-1 gap-1">
                  <span className="fw-bold text-nowrap">Step 3:</span> Register
                  for the course and start learning from our platform.
                </li>
                <li className="mb-1 d-flex pb-1 gap-1">
                  <span className="fw-bold text-nowrap">Step 4:</span> For
                  finance, please contact our team
                </li>
              </ul>
            </Col>
            <Col
              lg={5}
              sm={8}
              className="d-flex justify-content-lg-start justify-content-center"
            >
              <Image
                fluid
                width={500}
                height={500}
                src={studentImg}
                alt="how-it-works-for-students"
                className="about-img my-3"
              />
            </Col>
          </Row>
        </Container>
        <div className="semi-bg">
          <Container fluid="lg">
            <Row className="flex-lg-row flex-column-reverse justify-content-lg-between justify-content-center py-5 align-items-center">
              <Col
                lg={5}
                sm={8}
                className="d-flex justify-content-lg-start justify-content-center"
              >
                <Image
                  fluid
                  width={500}
                  height={500}
                  src={trainerImg}
                  alt="aboutImg"
                  className="about-img my-3"
                />
              </Col>
              <Col lg={7} className="my-3">
                <h4 className="about-headings text-wrap-balance">
                  How It Works For Direct Trainers?
                </h4>
                <ul className="lead text-wrap-balance list-unstyled">
                  <li className="mb-1 d-flex pb-1 gap-1">
                    <span className="fw-bold text-nowrap">Step 1:</span> Should
                    enroll into{" "}
                    <Link
                      to={`${
                        homeNavItem === "/home" ? "/" : homePageRoute || "/"
                      }`}
                      className="theme-text-2"
                    >
                      AmeerpetIT.com
                    </Link>
                  </li>
                  <li className="mb-1 d-flex pb-1 gap-1">
                    <span className="fw-bold text-nowrap">Step 2:</span> Can
                    register as trainer in the platform and give a 1 hour free
                    demo in the platform.
                  </li>
                  <li className="mb-1 d-flex pb-1 gap-1">
                    <span className="fw-bold text-nowrap">Step 3:</span> If you
                    are starting a batch, AmeerpetIT will market for your batch
                    in the platform and emails and various ways .
                  </li>
                  <li className="mb-1 d-flex pb-1 gap-1">
                    <span className="fw-bold text-nowrap">Step 4:</span> We will
                    take the commission for the marketing of that batch
                  </li>
                  <li className="mb-1 d-flex pb-1 gap-1">
                    <span className="fw-bold text-nowrap">Step 5:</span> Or
                    Trainer will give the free training to the students. After
                    student got placed by the platform. Commission we will be
                    given to the trainer.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <Container fluid="lg">
          <Row className="justify-content-lg-between justify-content-center align-items-center py-5">
            <Col lg={7} className="my-3">
              <h4 className="about-headings text-wrap-balance">
                What's in It Work for Consultancies?
              </h4>
              <ul className="lead text-wrap-balance list-unstyled">
                <li className="mb-1 d-flex pb-1 gap-1">
                  <span className="fw-bold text-nowrap">Step 1:</span> Should
                  enroll into our platform.
                </li>
                <li className="mb-1 d-flex pb-1 gap-1">
                  <span className="fw-bold text-nowrap">Step 2:</span> We will
                  train your students with our trainers, by starting a batch.
                </li>
                <li className="mb-1 d-flex pb-1 gap-1">
                  <span className="fw-bold text-nowrap">Step 3:</span> After
                  your students got placed, platform will get commission
                </li>
                <li className="mb-1 d-flex pb-1 gap-1">
                  <span className="fw-bold text-nowrap">Step 4:</span> For more
                  information, contact our team.
                </li>
              </ul>

              <div className="btns d-flex gap-3 mt-lg-4 mt-3">
                <Button className="about-outlined-btn h4">
                  Join As Coordinator
                </Button>
              </div>
            </Col>
            <Col
              lg={5}
              sm={8}
              className="d-flex justify-content-lg-start justify-content-center"
            >
              <Image
                fluid
                width={500}
                height={500}
                src={conImg}
                alt="aboutImg"
                className="about-img my-3"
              />
            </Col>
          </Row>
        </Container>

        <div className="semi-bg">
          <Container fluid="lg">
            <Row className="flex-lg-row flex-column-reverse justify-content-lg-between justify-content-center py-5 align-items-center">
              <Col
                lg={5}
                sm={8}
                className="d-flex justify-content-lg-start justify-content-center"
              >
                <Image
                  fluid
                  width={500}
                  height={500}
                  src={orgImg}
                  alt="aboutImg"
                  className="about-img my-3"
                />
              </Col>
              <Col lg={7} className="my-3">
                <h4 className="about-headings text-wrap-balance">
                  How It Works For Direct Organizations?
                </h4>
                <ul className="lead text-wrap-balance list-unstyled">
                  <li className="mb-1 d-flex pb-1 gap-1">
                    <span className="fw-bold text-nowrap">Step 1:</span> Should
                    enroll into our platform
                  </li>
                  <li className="mb-1 d-flex pb-1 gap-1">
                    <span className="fw-bold text-nowrap">Step 2:</span> We will
                    give a free demo to your employees.
                  </li>
                  <li className="mb-1 d-flex pb-1 gap-1">
                    <span className="fw-bold text-nowrap">Step 3:</span> We will
                    train your employees with our trainers
                  </li>
                  <li className="mb-1 d-flex pb-1 gap-1">
                    <span className="fw-bold text-nowrap">Step 4:</span> After
                    completion of course, a commission will be given to the
                    trainer.
                  </li>
                  <li className="mb-1 d-flex pb-1 gap-1">
                    <span className="fw-bold text-nowrap">Step 5:</span> For
                    more information,{" "}
                    <Link to="/contact" className="theme-text-2">
                      Contact
                    </Link>{" "}
                    our team.
                  </li>
                </ul>
                <div className="btns d-flex gap-3 mt-lg-4 mt-3">
                  <Button className="about-outlined-btn h4">
                    Join As Coordinator
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container fluid="lg">
          <div className="FAQs my-5">
            <h2 className="about-headings text-wrap-balance w-fit mx-auto theme-text-2">
              Frequently Asked Questions (FAQs)
            </h2>
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              {HowItWorksData?.map((item, index) => (
                <Accordion.Item
                  eventKey={`${index}`}
                  key={index}
                  className="border-0 mb-3"
                >
                  <Accordion.Header>
                    {item?.question} <span></span>
                  </Accordion.Header>
                  <Accordion.Body>{item?.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </Container>
      </section>
    </>
  );
}

export default HowItWorks;
