import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { X } from "react-bootstrap-icons";

const AddTag = ({ addedTags, setAddedTags, label }) => {
  const [inputValue, setInputValue] = useState("");

  const addTag = () => {
    if (inputValue.trim() !== "") {
      const newTag = inputValue.trim();

      // Check if the tag already exists before adding it
      if (!addedTags?.includes(newTag)) {
        addedTags
          ? setAddedTags([...addedTags, newTag])
          : setAddedTags([newTag]);
      }

      // Clear the input field
      setInputValue("");
    }
  };

  // Function to remove a tag by its ID
  const removeTag = (tagToRemove) => {
    const newTags = addedTags?.filter((tag) => tag !== tagToRemove);
    setAddedTags(newTags);
  };

  // Event handler for input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Event handler for input key press
  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addTag();
    } else if (
      e.key === "Backspace" &&
      inputValue === "" &&
      addedTags?.length > 0
    ) {
      const lastTag = addedTags && addedTags[addedTags?.length - 1];
      removeTag(lastTag);
    }
  };

  return (
    <Form.Group className="form-floating mb-3 flex-grow-1">
      <Form.Control
        className="tag-input"
        as={"textarea"}
        rows="1"
        placeholder="Add Tags"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
      />
      <Form.Label className="form-label" htmlFor="tags">
        {label}:
      </Form.Label>
      <div className="tag-list d-flex flex-wrap gap-2 mt-2">
        {addedTags?.map((tag, index) => (
          <span
            key={tag + index}
            className="tag-chip d-flex align-items-center"
          >
            <span className="remove-tag" onClick={() => removeTag(tag)}>
              <X />
            </span>
            {tag}
          </span>
        ))}
      </div>
    </Form.Group>
  );
};

export default AddTag;
