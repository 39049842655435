import { Mail } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Facebook, Globe, Instagram, Linkedin } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import USER from "../../img/user.png";
import "./trainerdetails.css";
import StarRating from "../../components/starRating/starRating";
import TimePeriod from "../../components/timestampConverters/timePeriodConverter";
import CustomLoader from "../../components/loader/loader";

function Get_Trainer(props) {
  const entities = "trainers";

  const [loading, setLoading] = useState(true);
  const [trainerData, setTrainerData] = useState({
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    phone: "",
    whatsapp: "",
    website: "",
    country: "",
    state: "",
    city: "",
    linkedin: "",
    trainer_bio: "",
    yearsOfExperience: "",
    skillset_speciality: "",
    specialization: "",
    modeOfTraining: "",
    institutes_associated: "",
    corporate_experience: "",
    achievements: "",
    past_training_sessions: "",
    past_projects: "",
    proposed_plan_for_training: "",
    courses_offered: "",
    video_links: "",
    demo_className_slot: "",
    photo: "",
    bio: "",
    // ... other fields
  });

  const [reviewData, setReviewData] = useState([]);
  const [displayedCards, setDisplayedCards] = useState(3);

  console.log(trainerData);

  useEffect(() => {
    GetReviews(props.params.id);
    populateList(props.params.id);
  }, [props.params.id]);

  const populateList = async (id) => {
    try {
      const data = await fetch(`${Actions.BASE_URL}${entities}/${id}`).then(
        (response) => {
          console.log(response);
          return response.json();
        }
      );

      setTrainerData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // TypeError: Failed to fetch
      console.log("There was an error", error);
    }
  };

  const GetReviews = async (id) => {
    try {
      const response = await axios.get(
        `${Actions.BASE_URL}reviews/trainer/${id}`
      );
      setReviewData(response.data);
      ScrollToReviews();
    } catch (error) {
      console.error("There was an error", error);
    }
  };

  // Scroll to reviews
  const location = useLocation();
  const scrollTo = new URLSearchParams(location.search).get("trainer");

  // Create a ref for the target element
  const targetRef = useRef(null);

  const ScrollToReviews = () => {
    if (scrollTo) {
      // Scroll to the target element after a delay (e.g., 1000 milliseconds)
      setTimeout(() => {
        if (targetRef.current) {
          targetRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 800);
    }
  };

  const handleShowMore = () => {
    setDisplayedCards((prevDisplayedCards) =>
      prevDisplayedCards + 3 <= reviewData.length
        ? prevDisplayedCards + 3
        : reviewData.length
    );
  };

  return (
    <Container fluid="lg" className="my-5 pt-5">
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="trainerProfile overflow-hidden mx-auto">
          <div className="p-2">
            <Image
              className="coverImg"
              src={trainerData.hero_image_url}
              width="100%"
              alt=""
            />
          </div>

          <Row className="profileHeader align-items-center position-relative py-2 mx-4">
            <Col lg={2} className="mr-3">
              <Image
                src={
                  trainerData.listing_image_url != null &&
                  trainerData.listing_image_url != undefined &&
                  trainerData.listing_image_url != ""
                    ? trainerData.listing_image_url
                    : USER
                }
                alt="..."
                className="profileImg"
              />
            </Col>
            <Col sm={6} className="mt-lg-0 mt-5 pt-lg-0 pt-2 ps-4">
              <h4 className="trainerName mb-2">
                {trainerData.first_name} {trainerData.last_name}
                <br />
                <span className="industry">
                  {trainerData.category != null && trainerData.category != ""
                    ? "(" + trainerData.category + ")"
                    : "(Trainer)"}
                </span>
              </h4>
              <StarRating rating={trainerData.star_rating} />
            </Col>
            <Col
              lg={4}
              sm={6}
              className="mt-lg-0 pt-lg-0 pt-2 social d-flex gap-md-3 gap-2 flex-wrap justify-content-center"
            >
              {trainerData.linkedin && (
                <a
                  target="_blank"
                  href={`https://${trainerData.linkedin}`}
                  rel="noreferrer"
                >
                  <Linkedin />
                </a>
              )}
              {trainerData.facebook && (
                <a
                  target="_blank"
                  href={`https://${trainerData.facebook}`}
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
              )}
              {trainerData.instagram && (
                <a
                  target="_blank"
                  href={`https://${trainerData.instagram}`}
                  rel="noreferrer"
                >
                  <Instagram />
                </a>
              )}
              {trainerData.website && (
                <a
                  target="_blank"
                  href={`https://${trainerData.website}`}
                  rel="noreferrer"
                >
                  <Globe />
                </a>
              )}
            </Col>
          </Row>
          <Card className="m-4 mt-2">
            <Card.Body>
              <Row>
                <Col md={3}>
                  <Card.Text className="mb-0 fw-bold">Speciality</Card.Text>
                </Col>
                <Col md={9}>
                  <Card.Text className="text-muted mb-0">
                    {trainerData.specialization != null
                      ? trainerData.specialization
                      : "Not Specified"}
                  </Card.Text>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={3}>
                  <Card.Text className="mb-0 fw-bold">
                    Year Of Experience
                  </Card.Text>
                </Col>
                <Col md={9}>
                  <Card.Text className="text-muted mb-0">
                    {trainerData.yearsOfExperience != null
                      ? `${trainerData.yearsOfExperience}`
                      : "Not Specified"}
                  </Card.Text>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={3}>
                  <Card.Text className="mb-0 fw-bold">Trainer Bio</Card.Text>
                </Col>
                <Col md={9}>
                  <Card.Text className="text-muted mb-0">
                    {trainerData.bio != null
                      ? trainerData.bio
                      : "An Expert in his field"}
                  </Card.Text>
                </Col>
              </Row>
              {trainerData.email && (
                <>
                  <hr />
                  <Row>
                    <Col md={12}>
                      <Card.Text className="mb-0">
                        <Mail /> {trainerData.email}
                      </Card.Text>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
            <Card.Body
              className="reviews-cont pt-0"
              id="reviews"
              ref={targetRef}
            >
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2">
                  Reviews And Ratings
                </h5>
                <Link
                  to={`/reviews/trainer/${trainerData.id}/add`}
                  className="actionBtn btn add-button cursor-pointer"
                >
                  Add Review
                </Link>
              </div>
              {reviewData != "" ? (
                <div className="d-flex gap-3 flex-column">
                  {reviewData.slice(0, displayedCards)?.map((review, index) => (
                    <Card className="detailReviewCard" key={index}>
                      <Card.Body>
                        <div className="review-top d-flex align-items-center justify-content-between">
                          <h4 className="mb-0">
                            {review.reviewer_first_name}{" "}
                            {review.reviewer_last_name}
                          </h4>
                          <Card.Text className="mb-0">
                            <small>
                              <TimePeriod createdDate={review.createdDate} />
                            </small>
                          </Card.Text>
                        </div>
                        <StarRating rating={review.star_rating} />
                        <div className="review-content mt-3">
                          <p className="mb-0 h6">{review.review_title}</p>
                          <Card.Text>{review.review_comments}</Card.Text>
                        </div>
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              ) : (
                <p>No Reviews Yet!</p>
              )}
            </Card.Body>
            {reviewData != "" && (
              <>
                {displayedCards < reviewData.length ? (
                  <div className="text-center mt-2 mb-3">
                    <Button
                      onClick={handleShowMore}
                      className="btn actionBtnMore action cursor-pointer"
                    >
                      Show More
                    </Button>
                  </div>
                ) : null}
              </>
            )}
          </Card>
        </div>
      )}
    </Container>
  );
}

export default withRouter(Get_Trainer);
