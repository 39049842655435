import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Tab,
  Tabs,
  Toast,
} from "react-bootstrap";
import { Clock, Send, Trash, XCircleFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../components/API/API";
import FAQsFieldset from "../../components/FAQsFieldset/FAQsFieldset";
import SponsorFieldset from "../../components/SponsorFieldset/SponsorFieldset";
import VideoTriggerUploadFieldSet from "../../components/VideoUploader/videoTriggerUploadFieldSet";
import BannerUploaderEdit from "../../components/bannerUploaderEdit/bannerUploaderEdit";
import ConvertMinutesToTime from "../../components/convertFromMinutes/convertFromMinutes  ";
import FileTriggerUploadFieldSet from "../../components/fileUploadFieldSet/fileTriggerUploadFieldSet";
import ThemeInpGroup from "../../components/homeSegments/ThemeInpGroup";
import CustomLoader from "../../components/loader/loader";
import ScheduleDateModalCourse from "../../components/scheduleDateModal/scheduleDateModalCourse";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import UploadFile from "../../helper/UploadFile";

function ClonePropertyEvent(props) {
  const item_id = props.params.id;

  const navigate = useNavigate();
  const entityType = "propertyevent";
  const entities = "propertyevents";

  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const attachmentFields = [
    {
      content: "",
      extension: "",
      field_name: "",
      url: "",
    },
  ];
  const sponsorFields = [
    {
      sponsor_category: "",
      sponsor_grade: "",
      sponsor_logo_title: "",
      sponsor_logo_url: "",
      sponsor_website_url: "",
    },
  ];

  const [loadingAI, setLoadingAI] = useState(false);
  const [reqLoading, setReqLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [entityTypesData, setEntityTypesData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [propertyEventNamesData, setPropertyEventNamesData] = useState([]);
  const [formData, setFormData] = useState({
    userId: myUserId,
    type: entityType,
    entity_type: entityType,
    listing_image_url: "",
    listing_image_content: "",
    listing_image_extension: "",
    hero_image_url: "",
    hero_image_content: "",
    hero_image_extension: "",
    start_date_time: "",
    meeting_id: "",
    register_url: "",
  });
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);

  const [documentData, setDocumentData] = useState(attachmentFields);
  const [imageData, setImageData] = useState(attachmentFields);
  const [videoData, setVideoData] = useState(attachmentFields);

  const [FAQs, setFAQs] = useState([{ question: "", answer: "" }]);
  const [sponsors, setSponsors] = useState(sponsorFields);
  // Schedule modal
  const [scheduleModalShow, setScheduleModalShow] = useState(false);
  const [needDetailedImage, setNeedDetailedImage] = useState(false);
  // check values
  const [hasValue, setHasValue] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const populateList = async (id) => {
    try {
      if (!id) {
        setLoading(false);
        return;
      }

      const response = await fetch(
        `${Actions.BASE_URL}items/${entityType}/${id}`,
        {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const resData = await response.json();

      setFAQs(resData?.data?.FAQs || [{ question: "", answer: "" }]);
      setSponsors(resData?.data?.sponsors || sponsorFields);

      setImageData(resData?.data?.attachments?.images || attachmentFields);
      setDocumentData(
        resData?.data?.attachments?.documents || attachmentFields
      );
      setVideoData(resData?.data?.attachments?.videos || attachmentFields);

      setFormData({
        ...resData?.data,
      });
      setLoading(false);
    } catch (error) {
      console.error("There was an error", error);
    }
  };

  const handleFieldChange = (fieldName, fieldValue) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const step1Fields = [
    "title",
    "description",
    "venue",
    "category",
    "subcategorylevel1",
    "subcategorylevel2",
    "start_date",
    "start_time",
    "duration",
  ];

  const areFieldsFilled = (fieldNames, state) => {
    return fieldNames.every((fieldName) => state[fieldName]);
  };

  const isStep1FieldsFilled = areFieldsFilled(step1Fields, formData);

  const handleNext = () => {
    const isSubcategoryPaid =
      formData?.subcategorylevel1 && formData?.subcategorylevel1 === "paid";
    const isStep1FieldsFilled = step1Fields?.every(
      (field) => field && formData[field]
    );

    if (
      isStep1FieldsFilled &&
      (!isSubcategoryPaid ||
        (isSubcategoryPaid && formData?.currency && formData?.amount))
    ) {
      setStep((prevStep) => prevStep + 1);
      setHasValue(false);
      setIsValid(true);
    } else {
      setHasValue(true);
      setIsValid(true);
      window.scrollTo(0, 0);
    }
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    populateList(item_id);
    fetchFilterTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item_id]);

  // Category type fetched from ENUM
  const fetchFilterTypes = async () => {
    try {
      const data = await API("get", "configs");
      const categorySettings = data?.categorysettings || {};
      const propertyEventsObj = categorySettings.courseNames || {};
      const teachingModeObj = categorySettings.teachingModes || {};
      const paymentTypesObj = categorySettings.paymentTypes || {};

      const mapToObject = (sourceObj) =>
        Object.entries(sourceObj).reduce((acc, [key, value]) => {
          acc[key] = {
            key: key,
            value: value,
          };
          return acc;
        }, {});

      setPropertyEventNamesData(mapToObject(propertyEventsObj));
      setEntityTypesData(mapToObject(teachingModeObj));
      setCategoriesData(mapToObject(paymentTypesObj));
    } catch (error) {
      console.error("Error fetching filter types:", error);
      // Handle error, e.g., show an error message to the user
    }
  };

  const addField = (setField, field) => {
    setField([...field, {}]);
  };
  const removeItemById = (fields, setFields, itemId) => {
    const updatedItems = fields.filter((item) => item.id !== itemId);
    setFields(updatedItems);
  };

  // Timezone user
  useEffect(() => {
    // Set the default timezone to the user's current timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone({ label: userTimezone, value: userTimezone });
  }, []);

  let localTime = "";
  if (formData?.start_time && selectedTimeZone.value) {
    const [utcHours, utcMinutes] = formData?.start_time
      ?.split(":")
      ?.map(Number);

    const localDate = new Date();
    localDate?.setUTCHours(utcHours, utcMinutes);

    const localHours = localDate?.getHours();
    const localMinutes = localDate?.getMinutes();

    const period = localHours >= 12 ? "PM" : "AM";
    localTime = `${localHours % 12 || 12}:${localMinutes
      ?.toString()
      .padStart(2, "0")} ${period}`;
  }

  // * ==========================================================================
  const handleAIGenerate = (e, input) => {
    e.preventDefault();
    setLoadingAI(true);
    const data = {
      searchText: input,
    };
    setFormData({
      ...formData,
      hero_image_content: "",
      listing_image_content: "",
      hero_image_url: "https://placehold.co/280x180?text=loading+Image",
      listing_image_url: "https://placehold.co/280x180?text=loading+Image",
    });
    if (input.trim() !== "") {
      axios
        .post(`https://api.ameerpetit.com/ai/create/event`, data, {
          withCredentials: true,
        })

        .then((generated_content) => {
          axios
            .post(`https://api.ameerpetit.com/ai/create/image`, data, {
              withCredentials: true,
            })

            .then((generated_image) => {
              setFormData({
                ...formData,
                title: input,
                description: generated_content.data,
              });
              UploadFile(
                formData.userId,
                "hero_image",
                generated_image.data,
                ".png",
                setLoadingAI
              ).then((url) => {
                setFormData({
                  ...formData,
                  hero_image_url: url,
                  hero_image_content: "",
                  listing_image_url: url,
                  listing_image_content: "",
                });
              });
              // Update the textarea height after setting the content
              updateTextareaHeight();
            })
            .catch((error) => {
              console.error("Error generating code:", error);
              setFormData({
                ...formData,
                title: input,
                description: generated_content.data,
                hero_image_content: "",
                listing_image_extension: "",
                listing_image_content: "",
                hero_image_extension: "",
                hero_image_url:
                  "https://placehold.co/280x180?text=loading+Image",
                listing_image_url:
                  "https://placehold.co/280x180?text=loading+Image",
              });
              toast.error("Error generating code. Please try again.", {
                autoClose: 3000,
                hideProgressBar: true,
                draggable: true,
              });
            })
            .finally(() => {
              setLoadingAI(false);
            });
        })
        .catch((error) => {
          console.error("Error generating code:", error);
          setFormData({
            ...formData,
            title: input,
            description: "",
            hero_image_content: "",
            listing_image_extension: "",
            hero_image_url: "",
            listing_image_content: "",
            hero_image_extension: "",
            listing_image_url: "",
          });
          setLoadingAI(false);
          toast.error("Error generating code. Please try again.", {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        });
    }
  };
  // img generate

  const handleImageGenerate = (e, input) => {
    e.preventDefault();
    const data = {
      searchText: input,
    };
    setFormData({
      ...formData,
      hero_image_content: "",
      listing_image_content: "",
      hero_image_url: "https://placehold.co/280x180?text=loading+Image",
      listing_image_url: "https://placehold.co/280x180?text=loading+Image",
    });
    if (input.trim() !== "") {
      axios
        .post(`https://api.ameerpetit.com/ai/create/image`, data, {
          withCredentials: true,
        })

        .then((generated_image) => {
          UploadFile(
            formData.userId,
            "hero_image",
            generated_image.data,
            ".png",
            setLoadingAI
          ).then((url) => {
            setFormData({
              ...formData,
              hero_image_url: url,
              hero_image_content: "",
              listing_image_url: url,
              listing_image_content: "",
            });
          });
          // Update the textarea height after setting the content
          updateTextareaHeight();
        })
        .catch((error) => {
          console.error("Error generating code:", error);
          setFormData({
            ...formData,
            hero_image_content: "",
            listing_image_extension: "",
            hero_image_url: "",
            listing_image_content: "",
            hero_image_extension: "",
            listing_image_url: "",
          });
          toast.error("Error generating code. Please try again.", {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        });
    }
  };

  // Function to update the textarea height based on content
  const updateTextareaHeight = () => {
    const textarea = document.getElementById("description");
    if (textarea) {
      // Reset the height to auto to ensure it shrinks if content is removed
      textarea.style.height = "auto";
      // Set the height to the scroll height of the content
      textarea.style.height = `${textarea.scrollHeight + 40}px`;
    }
  };
  // * ==========================================================================

  const handleSubmit = (event) => {
    event.preventDefault();
    setReqLoading(true);
    const addedAttachments = {
      documents: documentData,
      images: imageData,
      videos: videoData,
    };
    const data = {
      ...formData,
      FAQs: FAQs,
      sponsors: sponsors,
      attachments: addedAttachments,
      amount: formData?.amount,
    };

    const reqData = {
      userId: myUserId,
      type: entityType,
      host: window.location.host,
      header: {
        host: window.location.host,
        userId: myUserId,
      },
      data: {
        userId: myUserId,
        ...data,
      },
    };

    axios
      .post(`${Actions.BASE_URL}items`, reqData, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        navigate(`/${entityType}/items`);
      })
      .catch((error) => {
        console.error("API request failed:", error);
      })
      .finally(() => {
        setReqLoading(false);
      });
  };

  const propertyEventAlias = SubDomainRouter("propertyEventAlias");

  return (
    <Container fluid="lg" className="demosEditSection">
      {reqLoading || loadingAI ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <Row>
          <h1 className="text-center text-capitalize">
            Clone&nbsp;
            {propertyEventAlias ? propertyEventAlias : "propertyEvent"}
          </h1>
          <div className="demosEditHeader text-center">
            <p>Add details below</p>
            <ul className="demosEditList">
              <li>
                <button
                  type="button"
                  className={
                    step === 1 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(1)}
                >
                  <span>1</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={
                    step === 2 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(2)}
                  disabled={!isStep1FieldsFilled}
                >
                  <span>2</span>
                </button>
              </li>
              <li>
                <button
                  disabled={!isStep1FieldsFilled}
                  type="button"
                  className={
                    step === 3 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(3)}
                >
                  <span>3</span>
                </button>
              </li>
              <li>
                <button
                  disabled={!isStep1FieldsFilled}
                  type="button"
                  className={
                    step === 4 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(4)}
                >
                  <span>4</span>
                </button>
              </li>
              <li>
                <button
                  disabled={!isStep1FieldsFilled}
                  type="button"
                  className={
                    step === 5 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(5)}
                >
                  <span>5</span>
                </button>
              </li>
              <li>
                <button
                  disabled={!isStep1FieldsFilled}
                  type="button"
                  className={
                    step === 6 ? "active stepstriggers" : "stepstriggers"
                  }
                  onClick={() => setStep(6)}
                >
                  <span>6</span>
                </button>
              </li>
            </ul>
          </div>
          {/*  */}
          {/*  */}
          {step === 1 && (
            <div className="chatAI h-fit p-0 demosAddForm min-h-fit w-100 mb-0">
              <p className="opacity-75 text-center mb-1">
                Enter command below to generate content and Image
              </p>
              <ThemeInpGroup
                handleSubmit={handleAIGenerate}
                icon={<Send />}
                loading={loadingAI}
                inputPlaceholder={
                  "To generate Content & Image: Type the title or keywords."
                }
              />
              <Form.Group className="d-flex align-items-start pt-3">
                <Form.Check
                  className="ps-0 custom-checkbox"
                  type="checkbox"
                  name="needDetailedImage"
                  id={`needDetailedImage`}
                  label={" "}
                  checked={needDetailedImage}
                  onChange={(e) => setNeedDetailedImage(!needDetailedImage)}
                />
                <Form.Label htmlFor="needDetailedImage" className="mt-3">
                  Generate Detailed Image With Custom Prompts
                </Form.Label>
              </Form.Group>

              {needDetailedImage && (
                <>
                  <p className="opacity-75 text-center my-1">
                    Enter Detailed Prompt below to generate Image
                  </p>
                  <ThemeInpGroup
                    handleSubmit={handleImageGenerate}
                    icon={<Send />}
                    inputPlaceholder={
                      "To generate Detailed Image: Type description or keywords."
                    }
                  />
                </>
              )}
            </div>
          )}
          <Form action="" role="form" onSubmit={handleSubmit}>
            {step === 1 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col md={12}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        autoFocus
                        type="text"
                        className={`italicInput ${isValid && hasValue && !formData.title
                          ? "border-danger"
                          : ""
                          }`}
                        id="title"
                        required
                        value={formData?.title}
                        placeholder="Enter Event Title"
                        name="title"
                        onChange={(e) =>
                          handleFieldChange("title", e.target.value)
                        }
                      />
                      <Form.Label className="formLabel" htmlFor="title">
                        Title
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* Data Append from schedule popup */}
                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3 position-relative">
                      <Form.Control
                        type="button"
                        value={formData?.start_date || "YYYY/MM/DD"}
                        className={`${isValid && hasValue && !formData.start_date
                          ? "border-danger"
                          : ""
                          }`}
                        required
                        name="start_date"
                        id="start_date"
                        onClick={() => setScheduleModalShow(true)}
                      />
                      <Form.Label className="form-label" htmlFor="start_date">
                        Start Date
                      </Form.Label>
                      <Toast
                        show={showToast}
                        onClose={() => setShowToast(false)}
                        autohide
                        delay={3000}
                        style={{
                          position: "absolute",
                          top: "100%",
                          right: 0,
                          zIndex: "99",
                        }}
                      >
                        <Toast.Header closeButton={false}>
                          <strong className="mr-auto text-danger">
                            <Clock />
                            &nbsp;Error
                          </strong>
                        </Toast.Header>
                        <Toast.Body>
                          Invalid date. Please select a date not earlier than
                          today.
                        </Toast.Body>
                      </Toast>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="button"
                        id="duration"
                        className={`${isValid && hasValue && !formData.duration
                          ? "border-danger"
                          : ""
                          }`}
                        required
                        value={
                          formData?.duration
                            ? ConvertMinutesToTime({
                              minutes: formData?.duration,
                            })
                            : "Select Your Event Duration"
                        }
                        name="duration"
                        onClick={() => setScheduleModalShow(true)}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="button"
                        required
                        className={`${isValid && hasValue && !formData.start_time
                          ? "border-danger"
                          : ""
                          }`}
                        onClick={() => setScheduleModalShow(true)}
                        name="start_time"
                        value={localTime ? localTime : "Select Your Event Time"}
                        id="start_time"
                      />
                      <Form.Label className="form-label" htmlFor="start_time">
                        Event Time
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <div className="form-floating mb-3">
                      <Form.Control
                        type="button"
                        className={`timezone px-2 z-3 ${isValid && hasValue && !formData.start_time
                          ? "border-danger"
                          : ""
                          }`}
                        required
                        onClick={() => setScheduleModalShow(true)}
                        id="timezone"
                        value={
                          selectedTimeZone?.value || "Select Your Timezone"
                        }
                        placeholder="Select your time zone"
                      />
                    </div>
                  </Col>

                  <Col md={12}>
                    <span className="d-block text-end text-dark">
                      required<span className="text-danger">*</span>
                    </span>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className={`${isValid && hasValue && !formData.venue
                          ? "border-danger"
                          : ""
                          }`}
                        name="venue"
                        value={formData?.venue}
                        required
                        id="venue"
                        placeholder=" Enter your event venue"
                        onChange={(e) => {
                          handleFieldChange("venue", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="venue">
                        Venue
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <span className="d-block text-end text-dark">
                      required<span className="text-danger">*</span>
                    </span>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className={`${isValid && hasValue && !formData.description
                          ? "border-danger"
                          : ""
                          }`}
                        rows="4"
                        name="description"
                        value={formData?.description}
                        style={{ height: "auto" }}
                        required
                        id="description"
                        placeholder=" Enter your event description"
                        onChange={(e) => {
                          handleFieldChange("description", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="description">
                        Description
                      </Form.Label>
                    </Form.Group>
                    <small className="text-muted d-block mb-3">
                      <span className="text-danger">*</span>
                      {formData?.description?.length}/1900 characters
                    </small>
                  </Col>

                  <Col md={6} className="position-relative">
                    {formData?.hero_image_url || formData.hero_image_content ? (
                      <Button
                        className="deleteBtn p-1 btn-danger text-white bg-danger h-auto w-auto position-absolute top-0 end-0 mt-2 me-3 z-3"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            hero_image_url: "",
                            hero_image_content: "",
                          });
                        }}
                      >
                        <Trash />
                      </Button>
                    ) : null}
                    <BannerUploaderEdit
                      key={formData?.hero_image_url}
                      label="Big Banner"
                      accept="image/png, image/jpg, image/jpeg"
                      imageType="hero_image"
                      placehold="https://placehold.co/1024x200"
                      state={formData}
                      setState={(data) => {
                        UploadFile(
                          formData.userId,
                          "hero_image",
                          data?.hero_image_content,
                          data?.hero_image_extension,
                          setReqLoading
                        ).then((url) => {
                          setFormData({
                            ...formData,
                            hero_image_url: url,
                            hero_image_content: "",
                          });
                        });
                      }}
                      imageURL={formData?.hero_image_url}
                      imageContent={formData?.hero_image_content}
                      imageExtension={formData?.hero_image_extension}
                    />
                  </Col>

                  <Col md={6} className="position-relative">
                    {formData?.listing_image_url ||
                      formData.listing_image_content ? (
                      <Button
                        className="deleteBtn p-1 btn-danger text-white bg-danger h-auto w-auto position-absolute top-0 end-0 mt-2 me-3 z-3"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            listing_image_url: "",
                            listing_image_content: "",
                          });
                        }}
                      >
                        <Trash />
                      </Button>
                    ) : null}
                    <BannerUploaderEdit
                      key={formData?.listing_image_url}
                      label="Small Banner"
                      accept="image/png, image/jpg, image/jpeg"
                      imageType="listing_image"
                      placehold="https://placehold.co/280x180"
                      state={formData}
                      setState={(data) => {
                        UploadFile(
                          formData.userId,
                          "listing_image",
                          data?.listing_image_content,
                          data?.listing_image_extension,
                          setReqLoading
                        ).then((url) => {
                          setFormData({
                            ...formData,
                            listing_image_url: url,
                            listing_image_content: "",
                          });
                        });
                      }}
                      imageURL={formData?.listing_image_url}
                      imageContent={formData?.listing_image_content}
                      imageExtension={formData?.listing_image_extension}
                    />
                  </Col>

                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        id="video_url"
                        name="video_url"
                        value={formData?.video_url}
                        type="text"
                        placeholder="Video Url"
                        className="form-control input-md italicInput"
                        onChange={(e) =>
                          handleFieldChange("video_url", e.target.value)
                        }
                      />
                      <Form.Label className="form-label" htmlFor="video_url">
                        Video Url
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        id="category"
                        className={`${isValid && hasValue && !formData.category
                          ? "border-danger"
                          : ""
                          }`}
                        required
                        value={formData?.category}
                        name="category"
                        onChange={(e) =>
                          handleFieldChange("category", e.target.value)
                        }
                      >
                        <option value="">Category</option>
                        {Object.keys(propertyEventNamesData)?.map(
                          (paramType) => (
                            <option
                              key={propertyEventNamesData[paramType]?.key}
                              value={propertyEventNamesData[paramType]?.value}
                            >
                              {propertyEventNamesData[paramType]?.value}
                            </option>
                          )
                        )}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating  mb-3">
                      <Form.Select
                        id="subcategorylevel1"
                        required
                        className={`${isValid && hasValue && !formData.subcategorylevel1
                          ? "border-danger"
                          : ""
                          }`}
                        value={formData?.subcategorylevel1}
                        name="subcategorylevel1"
                        onChange={(e) =>
                          handleFieldChange("subcategorylevel1", e.target.value)
                        }
                      >
                        <option value="">Select Payment Option</option>
                        {Object.keys(categoriesData)?.map((paramType) => (
                          <option
                            key={categoriesData[paramType]?.key}
                            value={categoriesData[paramType]?.value}
                          >
                            {categoriesData[paramType]?.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        className={`${isValid && hasValue && !formData.subcategorylevel2
                          ? "border-danger"
                          : ""
                          }`}
                        aria-label="event type"
                        required
                        value={formData?.subcategorylevel2}
                        id="subcategorylevel2"
                        name="subcategorylevel2"
                        onChange={(e) =>
                          handleFieldChange("subcategorylevel2", e.target.value)
                        }
                      >
                        <option value="">Mode</option>
                        {Object.keys(entityTypesData)?.map((paramType) => (
                          <option
                            key={entityTypesData[paramType]?.key}
                            value={entityTypesData[paramType]?.value}
                          >
                            {entityTypesData[paramType]?.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {formData?.subcategorylevel1?.toLocaleLowerCase() ===
                    "paid" && (
                      <Col md={12}>
                        <small className="text-end d-block text-dark">
                          required<span className="text-danger">*</span>
                        </small>
                        <div className="d-flex gap-3">
                          <Form.Group className="form-floating w-25 mb-3">
                            <Form.Select
                              id="currency"
                              required
                              className={`text-uppercase ${isValid && hasValue && !formData.currency
                                ? "border-danger"
                                : ""
                                }`}
                              name="currency"
                              value={formData?.currency}
                              onChange={(e) =>
                                handleFieldChange("currency", e.target.value)
                              }
                            >
                              <option value="">Currency</option>
                              <option value="usd">usd</option>
                              <option value="cad">cad</option>
                              <option value="inr">inr</option>
                              <option value="eur">eur</option>
                              <option value="gbp">gbp</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="form-floating mb-3 flex-grow-1">
                            <Form.Control
                              id="amount"
                              name="amount"
                              type="number"
                              className={`text-italic ${isValid && hasValue && !formData.amount
                                ? "border-danger"
                                : ""
                                }`}
                              required
                              value={formData?.amount}
                              onChange={(e) =>
                                handleFieldChange("amount", e.target.value)
                              }
                              placeholder="Amount"
                            />
                            <Form.Label className="form-label" htmlFor="amount">
                              Enter Amount
                            </Form.Label>
                            <span className="position-absolute end-0 h-100 top-0 d-flex align-items-center pe-3">
                              .00
                            </span>
                          </Form.Group>
                        </div>
                      </Col>
                    )}

                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="next demosEditBtn "
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}

            {step === 2 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <div className="form-section-header mb-3">
                    <h4 className="theme-text-2">Organizer Details</h4>
                    <hr />
                  </div>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="organizer_first_name"
                        placeholder="Organizer Name"
                        className="form-control italicInput"
                        name="organizer_first_name"
                        value={formData?.organizer_first_name}
                        id="organizer_first_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_first_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_first_name"
                      >
                        Organizer First Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="organizer_last_name"
                        placeholder="Organizer Name"
                        className="form-control italicInput"
                        name="organizer_last_name"
                        value={formData?.organizer_last_name}
                        id="organizer_last_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_last_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_last_name"
                      >
                        Organizer Last Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="organizer_company_name"
                        placeholder="Organizer Company"
                        className="form-control italicInput"
                        name="organizer_company_name"
                        value={formData?.organizer_company_name}
                        id="organizer_company_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_company_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_company_name"
                      >
                        Organizer Company Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Organizer Email"
                        className="form-control italicInput"
                        name="organizer_email"
                        value={formData?.organizer_email}
                        id="organizer_email"
                        onChange={(e) =>
                          handleFieldChange("organizer_email", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_email"
                      >
                        Organizer Email:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="number"
                        className="form-control"
                        name="whatsapp"
                        id="organizer_whatsapp"
                        placeholder="organizer_whatsapp"
                        value={formData?.organizer_whatsapp}
                        maxLength="15"
                        minLength="10"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_whatsapp",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_whatsapp"
                      >
                        Organizer Whatsapp
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="number"
                        placeholder="Phone"
                        className="form-control"
                        name="organizer_phone"
                        value={formData?.organizer_phone}
                        id="organizer_phone"
                        maxLength="15"
                        minLength="10"
                        onChange={(e) =>
                          handleFieldChange("organizer_phone", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_phone"
                      >
                        Organizer Phone
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="url"
                        placeholder="Website"
                        value={formData?.organizer_website}
                        className="form-control"
                        name="organizer_website"
                        id="organizer_website"
                        onChange={(e) =>
                          handleFieldChange("organizer_website", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_website"
                      >
                        Organizer Website
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        style={{ height: "auto" }}
                        rows={4}
                        placeholder="bio"
                        value={formData?.organizer_bio}
                        className="form-control"
                        name="organizer_bio"
                        id="organizer_bio"
                        onChange={(e) =>
                          handleFieldChange("organizer_bio", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_bio"
                      >
                        Organizer Brief BIO
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mx-0">
                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="button"
                      onClick={handleNext}
                      className="next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}

            {step === 3 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col md={12}>
                    <h5 className="theme-text-2">Property Details</h5>
                    <hr />
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="overview"
                        value={formData?.overview}
                        style={{ height: "auto" }}
                        id="overview"
                        placeholder=" Enter your project overview"
                        onChange={(e) => {
                          handleFieldChange("overview", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="overview">
                        Project Overview:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="highlight"
                        value={formData?.highlight}
                        style={{ height: "auto" }}
                        id="highlight"
                        placeholder=" Enter your property highlight"
                        onChange={(e) => {
                          handleFieldChange("highlight", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="highlight">
                        Property Highlights:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* --------------------- */}
                  <Col md={12} className="mt-3">
                    <h5 className="theme-text-2">Price Structure</h5>
                    <hr />
                  </Col>
                  <Col md={6} lg={4}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="base_price"
                        value={formData?.base_price}
                        id="base_price"
                        placeholder=" Enter your property base_price"
                        onChange={(e) => {
                          handleFieldChange("base_price", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="base_price">
                        Base Price:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6} lg={4}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="car_parking"
                        value={formData?.car_parking}
                        id="car_parking"
                        placeholder=" Enter your property car_parking"
                        onChange={(e) => {
                          handleFieldChange("car_parking", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="car_parking">
                        Car Parking:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6} lg={4}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="price_range"
                        value={formData?.price_range}
                        id="price_range"
                        placeholder=" Enter your property price_range"
                        onChange={(e) => {
                          handleFieldChange("price_range", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="price_range">
                        Price Range:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* --------------------- */}
                  <Col md={12} className="mt-3">
                    <h5 className="theme-text-2">Location Details</h5>
                    <hr />
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="property_location"
                        value={formData?.property_location}
                        id="property_location"
                        placeholder=" Enter your property property_location"
                        onChange={(e) => {
                          handleFieldChange(
                            "property_location",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="property_location"
                      >
                        Property Location:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="url"
                        className="w-100 form-control max-h-auto"
                        name="property_video_url"
                        value={formData?.property_video_url}
                        id="property_video_url"
                        placeholder=" Enter your property property_video_url"
                        onChange={(e) => {
                          handleFieldChange(
                            "property_video_url",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="property_video_url"
                      >
                        Property Video Url:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="property_latitude"
                        value={formData?.property_latitude}
                        id="property_latitude"
                        placeholder=" Enter your property property_latitude"
                        onChange={(e) => {
                          handleFieldChange(
                            "property_latitude",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="property_latitude"
                      >
                        Latitude (Google Map):
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="property_longitude"
                        value={formData?.property_longitude}
                        id="property_longitude"
                        placeholder=" Enter your property property_longitude"
                        onChange={(e) => {
                          handleFieldChange(
                            "property_longitude",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="property_longitude"
                      >
                        Longitude (Google map)
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="url"
                        className="w-100 form-control max-h-auto"
                        name="property_360_video"
                        value={formData?.property_360_video}
                        id="property_360_video"
                        placeholder=" Enter your property property_360_video"
                        onChange={(e) => {
                          handleFieldChange(
                            "property_360_video",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="property_360_video"
                      >
                        360 Google Video Url:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="url"
                        className="w-100 form-control max-h-auto"
                        name="google_map_link"
                        value={formData?.google_map_link}
                        id="google_map_link"
                        placeholder=" Enter your property google_map_link"
                        onChange={(e) => {
                          handleFieldChange("google_map_link", e.target.value);
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="google_map_link"
                      >
                        Google maps Link:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>{" "}
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="commutation"
                        value={formData?.commutation}
                        style={{ height: "auto" }}
                        id="commutation"
                        placeholder=" Enter your property commutation"
                        onChange={(e) => {
                          handleFieldChange("commutation", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="commutation">
                        Commutation:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* --------------------- */}
                  <Col md={12} className="mt-3">
                    <h5 className="theme-text-2">Amenities</h5>
                    <hr />
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="property_amenities"
                        value={formData?.property_amenities}
                        style={{ height: "auto" }}
                        id="property_amenities"
                        placeholder=" Enter "
                        onChange={(e) => {
                          handleFieldChange(
                            "property_amenities",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="property_amenities"
                      >
                        Property Amenities: :
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="bedrooms"
                        value={formData?.bedrooms}
                        id="bedrooms"
                        placeholder=" Enter your property bedrooms"
                        onChange={(e) => {
                          handleFieldChange("bedrooms", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="bedrooms">
                        No. of Bedrooms:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="bathrooms"
                        value={formData?.bathrooms}
                        id="bathrooms"
                        placeholder=" Enter your property bathrooms"
                        onChange={(e) => {
                          handleFieldChange("bathrooms", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="bathrooms">
                        No. of Bathrooms:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="parking"
                        value={formData?.parking}
                        id="parking"
                        placeholder=" Enter your property parking"
                        onChange={(e) => {
                          handleFieldChange("parking", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="parking">
                        Parking:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="club_house"
                        value={formData?.club_house}
                        id="club_house"
                        placeholder=" Enter your property club_house"
                        onChange={(e) => {
                          handleFieldChange("club_house", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="club_house">
                        Club House:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="fitness_club"
                        value={formData?.fitness_club}
                        id="fitness_club"
                        placeholder=" Enter your property fitness_club"
                        onChange={(e) => {
                          handleFieldChange("fitness_club", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="fitness_club">
                        Fitness Club:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="swimming_pool"
                        value={formData?.swimming_pool}
                        id="swimming_pool"
                        placeholder=" Enter your property swimming_pool"
                        onChange={(e) => {
                          handleFieldChange("swimming_pool", e.target.value);
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="swimming_pool"
                      >
                        Swimming Pool:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* --------------------- */}
                  <Col md={12} className="mt-3">
                    <h5 className="theme-text-2">Details</h5>
                    <hr />
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="project_title"
                        value={formData?.project_title}
                        id="project_title"
                        placeholder=" Enter your property project_title"
                        onChange={(e) => {
                          handleFieldChange("project_title", e.target.value);
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="project_title"
                      >
                        Your Project Title
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="project_unit"
                        value={formData?.project_unit}
                        id="project_unit"
                        placeholder=" Enter your property project_unit"
                        onChange={(e) => {
                          handleFieldChange("project_unit", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="project_unit">
                        Project Unit
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="project_landmark"
                        value={formData?.project_landmark}
                        id="project_landmark"
                        placeholder=" Enter your property project_landmark"
                        onChange={(e) => {
                          handleFieldChange("project_landmark", e.target.value);
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="project_landmark"
                      >
                        Project Landmark
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="url"
                        className="w-100 form-control max-h-auto"
                        name="project_website"
                        value={formData?.project_website}
                        id="project_website"
                        placeholder=" Enter your property project_website"
                        onChange={(e) => {
                          handleFieldChange("project_website", e.target.value);
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="project_website"
                      >
                        Project Website
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="project_details"
                        value={formData?.project_details}
                        style={{ height: "auto" }}
                        id="project_details"
                        placeholder=" Enter your property project_details"
                        onChange={(e) => {
                          handleFieldChange("project_details", e.target.value);
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="project_details"
                      >
                        Project Details:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="project_address"
                        value={formData?.project_address}
                        style={{ height: "auto" }}
                        id="project_address"
                        placeholder=" Enter your property project_address"
                        onChange={(e) => {
                          handleFieldChange("project_address", e.target.value);
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="project_address"
                      >
                        Project Address:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="project_budget"
                        value={formData?.project_budget}
                        id="project_budget"
                        placeholder=" Enter your property project_budget"
                        onChange={(e) => {
                          handleFieldChange("project_budget", e.target.value);
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="project_budget"
                      >
                        Project Budget:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="project_unit_type"
                        value={formData?.project_unit_type}
                        id="project_unit_type"
                        placeholder=" Enter your property project_unit_type"
                        onChange={(e) => {
                          handleFieldChange(
                            "project_unit_type",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="project_unit_type"
                      >
                        Project Unit Type:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="project_unit_area"
                        value={formData?.project_unit_area}
                        id="project_unit_area"
                        placeholder=" Enter your property project_unit_area"
                        onChange={(e) => {
                          handleFieldChange(
                            "project_unit_area",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="project_unit_area"
                      >
                        Project Unit Area:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="project_floor_plan"
                        value={formData?.project_floor_plan}
                        id="project_floor_plan"
                        placeholder=" Enter your property project_floor_plan"
                        onChange={(e) => {
                          handleFieldChange(
                            "project_floor_plan",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="project_floor_plan"
                      >
                        Project Floor Plan:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="project_3D_floor_map"
                        value={formData?.project_3D_floor_map}
                        id="project_3D_floor_map"
                        placeholder=" Enter your property project_3D_floor_map"
                        onChange={(e) => {
                          handleFieldChange(
                            "project_3D_floor_map",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="project_3D_floor_map"
                      >
                        3D Floor Map:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* --------------------- */}
                  <Col md={12} className="mt-3">
                    <h5 className="theme-text-2">Contact Details</h5>
                    <hr />
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="agent_name"
                        value={formData?.agent_name}
                        id="agent_name"
                        placeholder=" Enter your property agent_name"
                        onChange={(e) => {
                          handleFieldChange("agent_name", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="agent_name">
                        Agent Name:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="agent_phone"
                        value={formData?.agent_phone}
                        id="agent_phone"
                        placeholder=" Enter your property agent_phone"
                        onChange={(e) => {
                          handleFieldChange("agent_phone", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="agent_phone">
                        Agent Phone:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="agent_whatsapp"
                        value={formData?.agent_whatsapp}
                        id="agent_whatsapp"
                        placeholder=" Enter your property agent_whatsapp"
                        onChange={(e) => {
                          handleFieldChange("agent_whatsapp", e.target.value);
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="agent_whatsapp"
                      >
                        Agent Whatsapp:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="agent_email"
                        value={formData?.agent_email}
                        id="agent_email"
                        placeholder=" Enter your property agent_email"
                        onChange={(e) => {
                          handleFieldChange("agent_email", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="agent_email">
                        Agent Email:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="agent_website"
                        value={formData?.agent_website}
                        id="agent_website"
                        placeholder=" Enter your property agent_website"
                        onChange={(e) => {
                          handleFieldChange("agent_website", e.target.value);
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="agent_website"
                      >
                        Agent Website:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="agent_appointment"
                        value={formData?.agent_appointment}
                        id="agent_appointment"
                        placeholder=" https://zoom.us"
                        onChange={(e) => {
                          handleFieldChange(
                            "agent_appointment",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="agent_appointment"
                      >
                        Add Appointment Link:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* --------------------- */}
                  <Col md={12} className="mt-3">
                    <h5 className="theme-text-2">Media Images</h5>
                    <hr />
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="floor_map_link"
                        value={formData?.floor_map_link}
                        id="floor_map_link"
                        placeholder=" Enter your property floor_map_link"
                        onChange={(e) => {
                          handleFieldChange("floor_map_link", e.target.value);
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="floor_map_link"
                      >
                        Floor Map Link:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="location_map_link"
                        value={formData?.location_map_link}
                        id="location_map_link"
                        placeholder=" Enter your property location_map_link"
                        onChange={(e) => {
                          handleFieldChange(
                            "location_map_link",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="location_map_link"
                      >
                        Location Map Link:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="property_map_link"
                        value={formData?.property_map_link}
                        id="property_map_link"
                        placeholder=" Enter your property property_map_link"
                        onChange={(e) => {
                          handleFieldChange(
                            "property_map_link",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="property_map_link"
                      >
                        Property Map Link:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* --------------------- */}
                  <Col md={12} className="mt-3">
                    <h5 className="theme-text-2">Offers</h5>
                    <hr />
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="discounts"
                        value={formData?.discounts}
                        id="discounts"
                        placeholder=" Enter your property discounts"
                        onChange={(e) => {
                          handleFieldChange("discounts", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="discounts">
                        Discounts:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* --------------------- */}
                  <Col md={12} className="mt-3">
                    <h5 className="theme-text-2">Certifications</h5>
                    <hr />
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="government_registration"
                        value={formData?.government_registration}
                        id="government_registration"
                        placeholder=" Enter your property government_registration"
                        onChange={(e) => {
                          handleFieldChange(
                            "government_registration",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="government_registration"
                      >
                        Government registration:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="municipality_approval"
                        value={formData?.municipality_approval}
                        id="municipality_approval"
                        placeholder=" Enter your property municipality_approval"
                        onChange={(e) => {
                          handleFieldChange(
                            "municipality_approval",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="municipality_approval"
                      >
                        Municipally Approval:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="association_approval"
                        value={formData?.association_approval}
                        id="association_approval"
                        placeholder=" Enter your property association_approval"
                        onChange={(e) => {
                          handleFieldChange(
                            "association_approval",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="association_approval"
                      >
                        Association Approval:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* --------------------- */}
                  <Col md={12} className="mt-3">
                    <h5 className="theme-text-2">Loan</h5>
                    <hr />
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        className="w-100 form-control max-h-auto"
                        name="project_loan_available"
                        value={formData?.project_loan_available}
                        id="project_loan_available"
                        placeholder=" Enter your property project_loan_available"
                        onChange={(e) => {
                          handleFieldChange(
                            "project_loan_available",
                            e.target.value
                          );
                        }}
                      >
                        <option value="">Project Loan Available:</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {/* --------------------- */}
                  <Col md={12} className="mt-3">
                    <h5 className="theme-text-2">More Details</h5>
                    <hr />
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="promoter"
                        value={formData?.promoter}
                        id="promoter"
                        placeholder=" Enter your property promoter"
                        onChange={(e) => {
                          handleFieldChange("promoter", e.target.value);
                        }}
                      />
                      <Form.Label className="form-label" htmlFor="promoter">
                        Promoter:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="w-100 form-control max-h-auto"
                        name="promoter_name"
                        value={formData?.promoter_name}
                        id="promoter_name"
                        placeholder=" Enter your property promoter_name"
                        onChange={(e) => {
                          handleFieldChange("promoter_name", e.target.value);
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="promoter_name"
                      >
                        Promoter Name:{" "}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="additional_description"
                        value={formData?.additional_description}
                        style={{ height: "auto" }}
                        id="additional_description"
                        placeholder=" Enter your property additional_description"
                        onChange={(e) => {
                          handleFieldChange(
                            "additional_description",
                            e.target.value
                          );
                        }}
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="additional_description"
                      >
                        Additional Description:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* --------------------- */}
                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="button"
                      onClick={handleNext}
                      className="next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}

            {step === 4 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  {" "}
                  <Col md={12}>
                    <h5 className="theme-text-2">Sponsors</h5>
                    <hr />
                  </Col>
                  <Col md={12}>
                    {sponsors?.map((field, index) => (
                      <React.Fragment key={index}>
                        <SponsorFieldset
                          key={index}
                          field={field}
                          onFieldChange={(newField) => {
                            const updatedFields = [...sponsors];
                            updatedFields[index] = newField;
                            setSponsors(updatedFields);
                          }}
                          indexField={index}
                          onRemove={() =>
                            removeItemById(sponsors, setSponsors, field.id)
                          }
                          inputIdPrefix={index}
                        />
                        <hr />
                      </React.Fragment>
                    ))}
                    <Button
                      className="clickable add-button mb-3 ms-auto d-flex"
                      onClick={() => addField(setSponsors, sponsors)}
                    >
                      Add More Sponsors
                    </Button>
                  </Col>
                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="button"
                      onClick={handleNext}
                      className="next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}

            {step === 5 && (
              <fieldset className="wizard-fieldset show demosAddForm demosViewMoreSection">
                <Row className="mx-0">
                  <Col md={12}>
                    <h5 className="theme-text-2 mb-3">
                      Add any documents, brochures, fliers, images, videos and
                      links related to the {entityType} below
                    </h5>
                  </Col>
                  <Col md={12}>
                    <Tabs
                      defaultActiveKey="documents"
                      id="demo-details"
                      className="mx-0 mt-2"
                      fill
                    >
                      <Tab
                        eventKey="documents"
                        title="Docs"
                        className="m-0 mt-0"
                      >
                        <Card className="border-top-0 rounded-top-0 p-4">
                          <Card.Body>
                            {documentData?.map((field, index) => (
                              <React.Fragment key={index}>
                                <FileTriggerUploadFieldSet
                                  key={
                                    field?.file_name ||
                                    field?.file_content ||
                                    field?.file_extension
                                  }
                                  field={field}
                                  onFieldChange={(newField) => {
                                    const updatedFields = [...documentData];
                                    UploadFile(
                                      formData.userId,
                                      newField?.file_name || "document",
                                      newField?.file_content,
                                      newField?.file_extension,
                                      setReqLoading
                                    ).then((url) => {
                                      updatedFields[index] = {
                                        ...newField,
                                        url: url,
                                      };
                                      setDocumentData(updatedFields);
                                    });
                                  }}
                                  onRemove={() =>
                                    removeItemById(
                                      documentData,
                                      setDocumentData,
                                      field.id
                                    )
                                  }
                                  fileCategory="document"
                                  acceptedTypes="application/pdf,application/msword,text/plain"
                                  noteLabel="PDF/DOC/TXT"
                                  inputIdPrefix={`documentUpload-${index}`}
                                />
                              </React.Fragment>
                            ))}
                            <Button
                              className="clickable add-button mb-3 ms-auto d-flex"
                              onClick={() =>
                                addField(setDocumentData, documentData)
                              }
                            >
                              Add More Documents
                            </Button>
                          </Card.Body>
                        </Card>
                      </Tab>
                      <Tab
                        eventKey="Images"
                        title="Images"
                        className="m-0 mt-0"
                      >
                        <Card className="border-top-0 rounded-top-0 p-4">
                          <Card.Body>
                            {imageData?.map((field, index) => (
                              <React.Fragment key={index}>
                                <FileTriggerUploadFieldSet
                                  field={field}
                                  onFieldChange={(newField) => {
                                    const updatedFields = [...imageData];
                                    UploadFile(
                                      formData.userId,
                                      newField?.file_name || "image",
                                      newField?.file_content,
                                      newField?.file_extension,
                                      setReqLoading
                                    ).then((url) => {
                                      updatedFields[index] = {
                                        ...newField,
                                        url: url,
                                      };
                                      setImageData(updatedFields);
                                    });
                                  }}
                                  onRemove={() =>
                                    removeItemById(
                                      imageData,
                                      setImageData,
                                      field.id
                                    )
                                  }
                                  fileCategory="image"
                                  acceptedTypes="image/jpg,image/png,image/jpeg"
                                  noteLabel="PNG/JPG/JPEG "
                                  inputIdPrefix={`imageUpload-${index}`}
                                />
                              </React.Fragment>
                            ))}
                            <Button
                              className="clickable add-button mb-3 ms-auto d-flex"
                              onClick={() => addField(setImageData, imageData)}
                            >
                              Add More Images
                            </Button>
                          </Card.Body>
                        </Card>
                      </Tab>
                      <Tab
                        eventKey="Videos"
                        title="Videos"
                        className="m-0 mt-0"
                      >
                        <Card className="border-top-0 rounded-top-0 p-4">
                          <Card.Body className="d-flex flex-column gap-3">
                            {videoData?.map((field, index) => (
                              <VideoTriggerUploadFieldSet
                                key={index}
                                field={field}
                                onFieldChange={(newField) => {
                                  const updatedFields = [...videoData];
                                  if (newField.url) {
                                    updatedFields[index] = {
                                      ...newField,
                                      url: newField.url,
                                    };
                                    setVideoData(updatedFields);
                                  } else {
                                    UploadFile(
                                      formData.userId,
                                      newField?.file_name || "video",
                                      newField?.file_content,
                                      newField?.file_extension,
                                      setReqLoading
                                    ).then((url) => {
                                      updatedFields[index] = {
                                        ...newField,
                                        url: url,
                                      };
                                      setVideoData(updatedFields);
                                    });
                                  }
                                }}
                                indexField={index}
                                onRemove={() =>
                                  removeItemById(
                                    videoData,
                                    setVideoData,
                                    field.id
                                  )
                                }
                                fileCategory="video"
                                inputIdPrefix={`video-${index}`}
                              />
                            ))}
                            <Button
                              className="clickable add-button mb-3 ms-auto d-flex"
                              onClick={() => addField(setVideoData, videoData)}
                            >
                              Add More Videos
                            </Button>
                          </Card.Body>
                        </Card>
                      </Tab>
                    </Tabs>
                  </Col>
                  <Col md={12} className="position-relative mt-4">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={handleNext}
                      type="button"
                      className="next-button next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}

            {step === 6 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col md={12}>
                    <h5 className="theme-text-2">
                      Frequently Asked Questions (FAQs)
                    </h5>
                    <hr />
                  </Col>
                  <Col md={12}>
                    {FAQs?.map((field, index) => (
                      <React.Fragment key={index}>
                        <FAQsFieldset
                          isCourse={true}
                          key={index}
                          field={field}
                          onFieldChange={(newField) => {
                            const updatedFields = [...FAQs];
                            updatedFields[index] = newField;
                            setFAQs(updatedFields);
                          }}
                          indexField={index}
                          onRemove={() =>
                            removeItemById(FAQs, setFAQs, field.id)
                          }
                          inputIdPrefix={index}
                        />
                        <hr />
                      </React.Fragment>
                    ))}
                    <Button
                      className="clickable add-button mb-3 ms-auto d-flex"
                      onClick={() => addField(setFAQs, FAQs)}
                    >
                      Add More FAQs
                    </Button>
                  </Col>

                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button type="submit" className="next demosEditBtn">
                      Submit
                    </Button>

                    <Toast
                      show={showErrorToast}
                      onClose={() => setShowErrorToast(false)}
                      autohide
                      delay={3200}
                      className="bg-white opacity-1 position-absolute"
                      style={{
                        bottom: "calc(100% + 10px)",
                        right: "10px",
                        zIndex: "999",
                      }}
                    >
                      <Toast.Header closeButton={false}>
                        <strong className="mr-auto text-danger">
                          <XCircleFill />
                          &nbsp;Error
                        </strong>
                      </Toast.Header>
                      <Toast.Body className="text-dark">
                        Fill all the (
                        <span className="text-danger">required*</span>) fields
                        to submit
                      </Toast.Body>
                    </Toast>
                  </Col>
                </Row>
              </fieldset>
            )}
          </Form>
        </Row>
      )}

      <ScheduleDateModalCourse
        show={scheduleModalShow}
        setshow={setScheduleModalShow}
        entity="propertyevent"
        entityType={entities}
        state={formData}
        setstate={setFormData}
        onHide={() => {
          setScheduleModalShow(false);
        }}
      />
    </Container>
  );
}

export default withRouter(ClonePropertyEvent);
