import React, { useEffect, useState } from "react";
import { Accordion, Button, Form } from "react-bootstrap";
import { Search, X } from "react-bootstrap-icons";
import API from "../API/API";
import StarRating from "../starRating/starRating";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import AddTag from "../tagInput/tagInput";
import "./sidebar.css";

const FilterSidebar = ({
  filteringFrom,
  smFilterMenu,
  setSmFilterMenu,
  setEntityObj,
  selectedFilters,
  setSelectedFilters,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [addedTags, setAddedTags] = useState([]);
  const [countryTypeKeys, setCountryTypeKeys] = useState([]);
  const [categoryKeys, setCategoryKeys] = useState([]);
  const [subCategoryKeys_2, setSubCategoryKeys_2] = useState([]);
  const [subCategoryKeys_1, setSubCategoryKeys_1] = useState([]);
  const [subCategoryKeys, setSubCategoryKeys] = useState([]);
  const [starRatingKeys, setStarRatingKeys] = useState([]);

  const coursesAlias = SubDomainRouter("coursesAlias");
  const countryFilter = SubDomainRouter("countryFilter");
  const tagsFilter = SubDomainRouter("tagsFilter");
  const coursesFilter = SubDomainRouter("coursesFilter");
  const learningLevelFilter = SubDomainRouter("learningLevelFilter");
  const paymentFilter = SubDomainRouter("paymentFilter");
  const teachingModeFilter = SubDomainRouter("teachingModeFilter");
  const ratingsFilter = SubDomainRouter("ratingsFilter");

  const countryTypeFilterAlias = SubDomainRouter("countryTypeFilterAlias");
  const tagsFilterAlias = SubDomainRouter("tagsFilterAlias");
  const coursesFilterAlias = SubDomainRouter("coursesFilterAlias");
  const learningLevelFilterAlias = SubDomainRouter("learningLevelFilterAlias");
  const paymentFilterAlias = SubDomainRouter("paymentFilterAlias");
  const teachingModesFilterAlias = SubDomainRouter("teachingModesFilterAlias");
  const ratingsFilterAlias = SubDomainRouter("ratingsFilterAlias");

  const handleCheckboxChange = (event, filterType) => {
    const { name, checked } = event.target;

    if (filterType === "country_type") {
      if (name === "global") {
        // If "Global" is selected, check or uncheck all other country filters
        const otherCountries = Object.keys(countryTypeKeys)
          .filter((key) => key !== "global")
          ?.map((key) => countryTypeKeys[key].value.toLowerCase());

        setSelectedFilters((prevFilters) => ({
          ...prevFilters,
          country_type: checked ? ["global", ...otherCountries] : [],
        }));

        setEntityObj((prevEntityObj) => ({
          ...prevEntityObj,
          country_type: checked ? ["global", ...otherCountries] : [],
        }));
      } else {
        // If any other country is selected, check or uncheck the specific country
        const isGlobalChecked =
          selectedFilters?.country_type?.includes("global");

        setSelectedFilters((prevFilters) => ({
          ...prevFilters,
          country_type: isGlobalChecked
            ? [name]
            : checked
            ? [...new Set([...prevFilters.country_type, name].filter(Boolean))]
            : prevFilters.country_type.filter((country) => country !== name),
        }));

        setEntityObj((prevEntityObj) => ({
          ...prevEntityObj,
          country_type: isGlobalChecked
            ? [name]
            : checked
            ? [
                ...new Set(
                  [...prevEntityObj.country_type, name].filter(Boolean)
                ),
              ]
            : prevEntityObj.country_type.filter((country) => country !== name),
        }));
      }
    } else {
      // General handling for other filters (e.g., category, subcategory, etc.)
      const isGlobalChecked = selectedFilters?.country_type?.includes("global");

      // Uncheck the "Global" filter if it was checked
      if (isGlobalChecked) {
        setSelectedFilters((prevFilters) => ({
          ...prevFilters,
          country_type: [],
        }));

        setEntityObj((prevEntityObj) => ({
          ...prevEntityObj,
          country_type: [],
        }));
      }

      // Update the selected filters
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: checked
          ? [...new Set([...prevFilters[filterType], name].filter(Boolean))]
          : prevFilters[filterType].filter((filter) => filter !== name),
      }));

      // Update the entity object
      setEntityObj((prevEntityObj) => ({
        ...prevEntityObj,
        [filterType]: checked
          ? [...new Set([...prevEntityObj[filterType], name].filter(Boolean))]
          : prevEntityObj[filterType].filter((filter) => filter !== name),
      }));
    }
  };

  const handleSearchInputChange = (event) => {
    const inpVal = event.target.value;
    setInputValue(inpVal);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      searchTerm: inputValue,
    }));

    // Update the entityObj state
    setEntityObj((prevEntityObj) => ({
      ...prevEntityObj,
      searchTerm: inputValue,
    }));

    setSmFilterMenu(false);
  };

  const handleTagSearchSubmit = () => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      subcategorylevel3: addedTags,
    }));

    setEntityObj((prevEntityObj) => ({
      ...prevEntityObj,
      subcategorylevel3: addedTags,
    }));
  };

  const handleClearFilters = () => {
    if (
      selectedFilters?.subcategorylevel1?.length > 0 ||
      selectedFilters?.country_type?.length > 0 ||
      selectedFilters?.category?.length > 0 ||
      selectedFilters?.subcategory?.length > 0 ||
      selectedFilters?.star_rating_type?.length > 0 ||
      selectedFilters?.subcategorylevel2?.length > 0 ||
      selectedFilters?.subcategorylevel3?.length > 0 ||
      selectedFilters?.searchTerm?.trim() !== ""
    ) {
      // Clear all selected filters and search term
      setSelectedFilters({
        type: filteringFrom,
        searchTerm: "",
        country_type: [],
        category: [],
        subcategorylevel1: [],
        subcategory: [],
        star_rating_type: [],
        subcategorylevel2: [],
        subcategorylevel3: [],
      });

      // Clear the addedTags
      setAddedTags([]);

      // Restore the entityObj state to its initial state
      setEntityObj({
        type: filteringFrom,
        searchTerm: "",
        country_type: [],
        category: [],
        subcategorylevel1: [],
        subcategory: [],
        star_rating_type: [],
        subcategorylevel2: [],
        subcategorylevel3: [],
      });
    }
  };

  // Category type fetched from ENUM
  const fetchFilterTypes = async () => {
    try {
      const data = await API("get", "configs");
      const categorySettings = data?.categorysettings || {};

      const countryTypeObj = categorySettings.countryTypes || {};
      const coursesObj = categorySettings.courseNames || {};
      const teachingModeObj = categorySettings.teachingModes || {};
      const paymentTypesObj = categorySettings.paymentTypes || {};
      const subcategoryTypesObj = categorySettings.subcategoryTypes || {};
      const starRatingsObj = categorySettings.starRatings || {};

      const mapToObject = (sourceObj) =>
        Object.entries(sourceObj).reduce((acc, [key, value]) => {
          acc[key] = {
            key: key,
            value: value,
          };
          return acc;
        }, {});

      setCountryTypeKeys(mapToObject(countryTypeObj));
      setCategoryKeys(mapToObject(coursesObj));
      setSubCategoryKeys_2(mapToObject(teachingModeObj));
      setSubCategoryKeys_1(mapToObject(paymentTypesObj));
      setSubCategoryKeys(mapToObject(subcategoryTypesObj));
      setStarRatingKeys(mapToObject(starRatingsObj));
    } catch (error) {
      console.error("Error fetching filter types:", error);
      // Handle error, e.g., show an error message to the user
    }
  };

  useEffect(() => {
    fetchFilterTypes();
  }, []);

  // Effect to update entityObj when addedTags change
  useEffect(() => {
    handleTagSearchSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTags]);

  return (
    <div
      className={smFilterMenu ? "sidebar view pt-4 pb-5" : "sidebar pt-4 pb-5"}
    >
      <div className="side-bar-close-btn">
        <Button
          className="d-lg-none d-flex bg-transparent p-0 mb-3 align-items-center justify-content-center"
          onClick={() => setSmFilterMenu(false)}
        >
          <X />
        </Button>
      </div>
      <Form
        onSubmit={(event) => handleSearchSubmit(event)}
        className="mb-3 inputGroup w-100 rounded-pill d-flex align-items-center"
      >
        <Form.Control
          name="searchTerm"
          placeholder="Search..."
          value={inputValue}
          onChange={handleSearchInputChange}
        />
        <Button
          className="input-group-text"
          type="submit"
          id="basic-addon2 p-0"
        >
          <Search />
        </Button>
      </Form>

      <div className="clear-filters-button text-end">
        <Button
          className="btn bg-transparent border-0 p-0 theme-text-2"
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </div>
      <Accordion alwaysOpen defaultActiveKey="1">
        {countryFilter === "show"
          ? countryTypeKeys &&
            countryTypeKeys !== "" && (
              <Accordion.Item eventKey="7">
                <Accordion.Header>{countryTypeFilterAlias}</Accordion.Header>
                <Accordion.Body>
                  {Object.keys(countryTypeKeys)?.map((paramCountryType) => (
                    <Form.Check
                      key={`country${countryTypeKeys[paramCountryType]?.key}`}
                      label={countryTypeKeys[
                        paramCountryType
                      ]?.value.toLowerCase()}
                      name={countryTypeKeys[
                        paramCountryType
                      ]?.value.toLowerCase()}
                      type="checkbox"
                      id={`country${countryTypeKeys[
                        paramCountryType
                      ]?.value.toLowerCase()}`}
                      className="ps-0 mb-2 custom-checkbox"
                      onChange={(e) => handleCheckboxChange(e, "country_type")}
                      checked={selectedFilters?.country_type?.includes(
                        countryTypeKeys[paramCountryType].value.toLowerCase()
                      )}
                    />
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            )
          : null}

        {tagsFilter === "show" ? (
          <Accordion.Item eventKey="0">
            <Accordion.Header>{tagsFilterAlias}</Accordion.Header>
            <Accordion.Body className="px-1">
              <Form>
                <AddTag
                  label={"Tags"}
                  addedTags={addedTags}
                  setAddedTags={setAddedTags}
                />
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        ) : null}

        {coursesFilter === "show"
          ? categoryKeys &&
            categoryKeys != "" && (
              <Accordion.Item eventKey="1">
                <Accordion.Header>{coursesFilterAlias}</Accordion.Header>
                <Accordion.Body>
                  {Object.keys(categoryKeys)
                    .sort((a, b) =>
                      categoryKeys[a].value.localeCompare(categoryKeys[b].value)
                    ) // Sort alphabetically
                    ?.map((paramcourseNames) => (
                      <Form.Check
                        key={`coursename${categoryKeys[paramcourseNames]?.key}`}
                        label={categoryKeys[
                          paramcourseNames
                        ]?.value.toLowerCase()}
                        name={categoryKeys[
                          paramcourseNames
                        ]?.value.toLowerCase()}
                        type="checkbox"
                        id={`coursename${categoryKeys[
                          paramcourseNames
                        ]?.value.toLowerCase()}`}
                        className="ps-0 mb-2 custom-checkbox"
                        onChange={(e) => handleCheckboxChange(e, "category")}
                        checked={selectedFilters?.category?.includes(
                          categoryKeys[paramcourseNames].value.toLowerCase()
                        )}
                      />
                    ))}
                </Accordion.Body>
              </Accordion.Item>
            )
          : null}

        {learningLevelFilter === "show"
          ? subCategoryKeys &&
            subCategoryKeys !== "" && (
              <Accordion.Item eventKey="2">
                <Accordion.Header>{learningLevelFilterAlias}</Accordion.Header>
                <Accordion.Body>
                  {Object.keys(subCategoryKeys)?.map((paramSubCat) => (
                    <Form.Check
                      key={`subcategory${subCategoryKeys[paramSubCat]?.key}`}
                      label={subCategoryKeys[paramSubCat]?.value.toLowerCase()}
                      name={subCategoryKeys[paramSubCat]?.value.toLowerCase()}
                      type="checkbox"
                      id={`subcategory${subCategoryKeys[
                        paramSubCat
                      ]?.value.toLowerCase()}`}
                      className="ps-0 mb-2 custom-checkbox"
                      onChange={(e) => handleCheckboxChange(e, "subcategory")}
                      checked={selectedFilters?.subcategory?.includes(
                        subCategoryKeys[paramSubCat].value.toLowerCase()
                      )}
                    />
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            )
          : null}

        {paymentFilter === "show"
          ? subCategoryKeys_1 &&
            subCategoryKeys_1 !== "" && (
              <Accordion.Item eventKey="3">
                <Accordion.Header>{paymentFilterAlias}</Accordion.Header>
                <Accordion.Body>
                  {Object.keys(subCategoryKeys_1)?.map(
                    (paramSubcategorylevel1) => (
                      <Form.Check
                        key={`v${subCategoryKeys_1[
                          paramSubcategorylevel1
                        ]?.value.toLowerCase()}`}
                        label={subCategoryKeys_1[
                          paramSubcategorylevel1
                        ]?.value.toLowerCase()}
                        name={subCategoryKeys_1[
                          paramSubcategorylevel1
                        ]?.value.toLowerCase()}
                        type="checkbox"
                        id={`category${subCategoryKeys_1[
                          paramSubcategorylevel1
                        ]?.value.toLowerCase()}`}
                        className="ps-0 mb-2 custom-checkbox"
                        onChange={(e) =>
                          handleCheckboxChange(e, "subcategorylevel1")
                        }
                        checked={selectedFilters?.subcategorylevel1?.includes(
                          subCategoryKeys_1[
                            paramSubcategorylevel1
                          ].value.toLowerCase()
                        )}
                      />
                    )
                  )}
                </Accordion.Body>
              </Accordion.Item>
            )
          : null}

        {teachingModeFilter === "show"
          ? subCategoryKeys_2 &&
            subCategoryKeys_2 !== "" && (
              <Accordion.Item eventKey="4">
                <Accordion.Header>{teachingModesFilterAlias}</Accordion.Header>
                <Accordion.Body>
                  {Object.keys(subCategoryKeys_2)?.map((paramMode) => (
                    <Form.Check
                      key={`type${subCategoryKeys_2[paramMode]?.key}`}
                      label={subCategoryKeys_2[paramMode]?.value.toLowerCase()}
                      name={subCategoryKeys_2[paramMode]?.value.toLowerCase()}
                      type="checkbox"
                      id={`type${subCategoryKeys_2[
                        paramMode
                      ]?.value.toLowerCase()}`}
                      className="ps-0 mb-2 custom-checkbox"
                      onChange={(e) =>
                        handleCheckboxChange(e, "subcategorylevel2")
                      }
                      checked={selectedFilters?.subcategorylevel2?.includes(
                        subCategoryKeys_2[paramMode].value.toLowerCase()
                      )}
                    />
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            )
          : null}

        {ratingsFilter === "show"
          ? starRatingKeys &&
            starRatingKeys !== "" && (
              <Accordion.Item eventKey="5">
                <Accordion.Header>{ratingsFilterAlias}</Accordion.Header>
                <Accordion.Body>
                  {Object.keys(starRatingKeys)?.map((paramRating) => (
                    <Form.Check
                      key={`star_rating_type${starRatingKeys[paramRating]?.key}`}
                      label={
                        <span>
                          <StarRating
                            rating={starRatingKeys[
                              paramRating
                            ]?.value.toLowerCase()}
                          />
                        </span>
                      }
                      name={starRatingKeys[paramRating]?.value.toLowerCase()}
                      type="checkbox"
                      id={`star_rating_type${starRatingKeys[
                        paramRating
                      ]?.value.toLowerCase()}`}
                      className="ps-0 mb-2 custom-checkbox"
                      onChange={(e) =>
                        handleCheckboxChange(e, "star_rating_type")
                      }
                      checked={selectedFilters?.star_rating_type?.includes(
                        starRatingKeys[paramRating].value.toLowerCase()
                      )}
                    />
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            )
          : null}
      </Accordion>
    </div>
  );
};

export default FilterSidebar;
