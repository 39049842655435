import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";

const FeedbackFieldset = ({ field, onFieldChange, onRemove }) => {
  const handleFieldChange = (fieldName, fieldValue) => {
    const updatedField = {
      ...field,
      id: uuidv4(),
      [fieldName]: fieldValue,
    };

    onFieldChange(updatedField);
  };

  return (
    <div className="d-flex gap-3 mt-3 batch">
      <Row className="mx-0 flex-grow-1">
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="text"
              className="w-100"
              id="user_name"
              name="user_name"
              placeholder="Course Name"
              value={field?.user_name}
              onChange={(e) => handleFieldChange("user_name", e.target.value)}
            />
            <Form.Label className="form-label" htmlFor="user_name">
              Feedback User Name
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="text"
              className="w-100"
              id="user_post"
              name="user_post"
              placeholder="Course Name"
              value={field?.user_post}
              onChange={(e) => handleFieldChange("user_post", e.target.value)}
            />
            <Form.Label className="form-label" htmlFor="user_post">
              Feedback User Post
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="url"
              className="w-100"
              id="profile"
              name="profile"
              placeholder="Course Name"
              value={field?.profile}
              onChange={(e) => handleFieldChange("profile", e.target.value)}
            />
            <Form.Label className="form-label" htmlFor="profile">
              Feedback User Photo URL
            </Form.Label>
          </Form.Group>
        </Col>

        <Col md={12}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              as={"textarea"}
              rows={4}
              style={{ height: "auto" }}
              name="feedback"
              placeholder="Course Name"
              value={field?.feedback}
              onChange={(e) => handleFieldChange("feedback", e.target.value)}
            />
            <Form.Label className="form-label file-label" htmlFor="feedback">
              Feedback details:
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Button
        className="clickable actionDltBtn btn border-danger"
        onClick={() => onRemove(field.id)}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default FeedbackFieldset;
