import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FileEarmarkPlus, Upload } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";
import "./fileUpload.css";
import TokenDetails from "../useUserDetails/useTokenDetails";

const ContactUpload = ({
  field,
  fileCategory,
  inputIdPrefix,
  onFieldChange,
  acceptedTypes,
  noteLabel,
  onRemove,
  uploadListID,
  listType,
}) => {
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [name, setName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target?.files[0];
    if (file) {
      setName(file.name);
      getBase64(file).then((base64) => {
        const attachment = {
          id: uuidv4(),
          userId: myUserId,
          type: "contact",
          filecontent: base64,
          entityrelation: [{ [listType]: uploadListID }],
        };

        onFieldChange(attachment);
      });
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64 = reader.result.split(",")[1];
        resolve(base64);
      };
    });
  };

  return (
    <>
      <div className="d-flex gap-3">
        <div className="d-flex flex-column w-100 gap-3 align-items-center">
          {fileCategory === "document" && field?.filecontent ? (
            <div className="upload-file img-thumbnail rounded-circle d-flex flex-column align-items-center justify-content-center text-secondary">
              <FileEarmarkPlus className="display-3" />
              <span className="text-field-name mt-1">
                {name || "File Uploaded"}
              </span>
            </div>
          ) : (
            <Form.Label
              className="form-label file-upload-label cursor-pointer"
              htmlFor={`${inputIdPrefix}-file`}
            >
              <div className="upload-file img-thumbnail initial rounded-circle d-flex flex-column align-items-center justify-content-center text-secondary">
                <Upload className="display-3 mb-2" />
                <span className="text-field-name d-block">
                  *Upload Your {noteLabel} File
                </span>
              </div>
            </Form.Label>
          )}
          <Form.Group className="flex-grow-1">
            <Form.Label
              className="form-label file-upload-label w-100 mb-0"
              htmlFor={`${inputIdPrefix}-file`}
            >
              <Form.Control
                type="file"
                className="uploadFile docUploader w-100 mt-1 d-none"
                name="document"
                id={`${inputIdPrefix}-file`}
                accept={acceptedTypes}
                onChange={handleFileChange}
              />
              <span className="btn add-button text-capitalize">
                Upload {field?.filecontent && "new"}&nbsp;{fileCategory}
              </span>
            </Form.Label>
          </Form.Group>
        </div>
        {/* <Button
          className="clickable add-button d-block bg-danger border-danger"
          onClick={() => onRemove(field.id)}
        >
          <span className="addBttn d-block">
            <Trash />
          </span>
        </Button> */}
      </div>
    </>
  );
};

export default ContactUpload;
