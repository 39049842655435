import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { Check2Circle, XCircle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import CustomLoader from "../../components/loader/loader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import useUserData from "../../components/useUserDetails/useUserDetails"; 

const Register = (props) => {
  const navigate = useNavigate();

  const myToken = localStorage.getItem("token");

  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  const userData = useUserData();

  const title = SubDomainRouter("title");

  const [loading, setLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [selectedItemDetails, setSelectedItemDetails] = useState({});
  const [checkedCourse, setCheckedCourse] = useState(true);
  const [formData, setFormData] = useState({
    meeting_id: "",
    first_name: "",
    last_name: "",
    email: myUserId,
    phone: "",
    whatsapp: "",
    website: "",
    city: "",
    job_title: "",
    industry: "",
    interestInFullCourse: true,
    title: "",
  });
  const [emailChanged, setEmailChanged] = useState(false);

  const handleFieldChange = (fieldName, fieldValue) => {
    if (fieldName === "email" && fieldValue !== myUserId) {
      setEmailChanged(true);
    }
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const populateList = async (id, entityType) => {
    try {
      await axios
        .get(`${Actions.BASE_URL}${entityType}s/${id}`, {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => setSelectedItemDetails(response.data));
      setLoading(false);
    } catch (error) {
      console.log("There was an error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList(props.params.id, props.params.entityType);
  }, [props.params.id, props.params.entityType]);

  const handleRegister = () => {
    const data = {
      ...formData,
      meeting_id: selectedItemDetails?.meeting_id,
      entityId: selectedItemDetails?.id,
      entityType: selectedItemDetails?.type,
    };

    axios
      .post(`${Actions.BASE_URL}demos/register`, data, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        successAlert(true);
        setLoadingProgress(false);
      })
      .catch((error) => {
        if (error.response.data.httpstatuscode === "BadRequest") {
          setLoadingProgress(false);
          errorAlert(error.response.data);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingProgress(true);
    handleRegister();
  };
  const handleDone = () => {
    selectedItemDetails?.register_url
      ? navigate(selectedItemDetails?.register_url)
      : selectedItemDetails?.subcategorylevel1 === "paid"
      ? navigate(
          `/pay?email=${selectedItemDetails?.userId}&currency=${selectedItemDetails?.currency}&amount=${selectedItemDetails?.amount}`
        )
      : navigate(
          `/share/${selectedItemDetails?.type}/${selectedItemDetails?.id}`
        );
  };

  useEffect(() => {
    myUserId === formData.email
      ? setEmailChanged(false)
      : setEmailChanged(true);
  }, [formData.email, myUserId]);

  useEffect(() => {
    setFormData({
      ...formData,
      city: userData?.city,
      phone: userData?.phone,
      email: userData?.email,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      website: userData?.website,
      whatsapp: userData?.whatsapp,
      industry: userData?.industry,
      job_title: userData?.job_title,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const successAlert = (param) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center mt-2 text-capitalize">
              Your Registration Info is submitted.
            </h5>
            <button
              className="btn add-button"
              onClick={() => {
                navigate(
                  `/${selectedItemDetails?.type}s/details/${props.params.id}/`
                );
                onClose();
                handleDone();
              }}
            >
              Done
            </button>
          </div>
        );
      },
      onClose: handleDone,
      afterClose: handleDone,
    });
  };

  const errorAlert = (param) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <XCircle className="display-1 text-danger" />
            <h5 className="theme-text-2 text-center mt-2 text-capitalize mb-1">
              {param?.Error}
            </h5>
            <p className="text-danger text-center message-sm">
              {param?.Message}
            </p>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                navigate(
                  `/${selectedItemDetails?.type}s/details/${selectedItemDetails?.id}/`
                );
                onClose();
              }}
            >
              Discard
            </button>
          </div>
        );
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>
          Register{" "}
          {selectedItemDetails?.title ? `| ${selectedItemDetails?.title}` : ""}{" "}
          | &nbsp;{title}
        </title>
      </Helmet>

      {loadingProgress ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}
      <Container fluid="lg" className="my-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <Form onSubmit={handleSubmit}>
            <h4 className="theme-text-2 my-3 text-capitalize text-center">
              Register for {selectedItemDetails?.title}
            </h4>
            <fieldset className="demosAddForm show p-4">
              <Row>
                <Col md={3}>
                  <Card.Text className="mb-0 fw-bold">
                    {selectedItemDetails?.title ? "Title" : ""}
                  </Card.Text>
                </Col>
                <Col md={9}>
                  <Card.Text className="text-muted mb-0">
                    {selectedItemDetails?.title}
                    {selectedItemDetails?.description === "" ? (
                      <>
                        {" "}
                        &nbsp;
                        <Button
                          className="theme-text-2 btn-link p-0 bg-transparent border-0 btn-sm"
                          onClick={() => {
                            navigate(
                              `/${selectedItemDetails?.type}s/details/${selectedItemDetails?.id}`
                            );
                          }}
                        >
                          Learn More
                        </Button>
                      </>
                    ) : null}
                  </Card.Text>
                </Col>
              </Row>
              <hr />

              {selectedItemDetails?.description ? (
                <>
                  <Row>
                    <Col md={3}>
                      <Card.Text className="mb-0 fw-bold">
                        {selectedItemDetails?.description ? "Description" : ""}
                      </Card.Text>
                    </Col>
                    <Col md={9}>
                      <Card.Text className="text-muted mb-0">
                        <span className="text-overflow-desc mb-0 d-inline">
                          {selectedItemDetails?.description}
                        </span>
                        &nbsp;
                        <Button
                          className="theme-text-2 btn-link p-0 bg-transparent border-0 d-inline"
                          onClick={() => {
                            navigate(
                              `/${selectedItemDetails?.type}s/details/${selectedItemDetails?.id}`
                            );
                          }}
                        >
                          Read More
                        </Button>
                      </Card.Text>
                    </Col>
                    <hr />
                  </Row>
                </>
              ) : null}

              <Row>
                <Col md={6} className="d-none">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="meeting_id"
                      name="meeting_id"
                      placeholder="meeting_id"
                      onChange={(e) => {
                        handleFieldChange("meeting_id", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="meeting_id">meeting_id</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="first_name"
                      name="first_name"
                      required
                      value={formData?.first_name}
                      placeholder="First Name"
                      onChange={(e) => {
                        handleFieldChange("first_name", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="first_name">First Name</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="last_name"
                      value={formData?.last_name}
                      required
                      name="last_name"
                      placeholder="Last Name"
                      onChange={(e) => {
                        handleFieldChange("last_name", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="last_name">Last Name</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="email"
                      name="email"
                      type="email"
                      required
                      value={formData?.email}
                      placeholder="email"
                      onChange={(e) => {
                        handleFieldChange("email", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="email">Email</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="phone"
                      name="phone"
                      type="tel"
                      value={formData?.phone}
                      placeholder="Phone"
                      onChange={(e) => {
                        handleFieldChange("phone", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="phone">Phone</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="whatsapp"
                      name="whatsapp"
                      type="tel"
                      value={formData?.whatsapp}
                      placeholder="Whatsapp"
                      onChange={(e) => {
                        handleFieldChange("whatsapp", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="whatsapp">Whatsapp</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="city"
                      name="city"
                      value={formData?.city}
                      placeholder="City"
                      onChange={(e) => {
                        handleFieldChange("city", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="city">City</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="job_title"
                      name="job_title"
                      required
                      value={formData?.job_title}
                      placeholder="Title"
                      onChange={(e) => {
                        handleFieldChange("job_title", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="job_title">Job Title</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="industry"
                      name="industry"
                      value={formData?.industry}
                      placeholder="Industry"
                      onChange={(e) => {
                        handleFieldChange("industry", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="industry">Industry</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={12} className="px-1 pb-3">
                  <Form.Group>
                    <Form.Check
                      label="Interested in Full Course"
                      name="interestInFullCourse"
                      type="checkbox"
                      id="interestInFullCourse"
                      className="ps-2 mb-2 custom-checkbox"
                      checked={checkedCourse}
                      onChange={() => {
                        handleFieldChange(
                          "interestInFullCourse",
                          !formData.interestInFullCourse
                        );
                      }}
                      onClick={() => setCheckedCourse(!checkedCourse)}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  {emailChanged && (
                    <Alert className="p-2 alert-theme-2">
                      <small>
                        Changing your email will exclude this&nbsp;
                        {selectedItemDetails?.type} from dashboard.
                      </small>
                    </Alert>
                  )}
                </Col>
                <Col xs={12}>
                  <Button type="submit" className="btn add-button float-end">
                    Submit
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </Form>
        )}
      </Container>
    </>
  );
};

export default withRouter(Register);
