import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import {
  ArrowRightCircleFill,
  Facebook,
  Globe,
  Instagram,
  Linkedin,
  PencilSquare,
  Whatsapp,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import COVERIMG from "../../img/Digital-Skills-2.jpeg";
import USER from "../../img/user.png";
import CustomLoader from "../../components/loader/loader";
import StarRating from "../../components/starRating/starRating";
import useUserData from "../../components/useUserDetails/useUserDetails";
import AddRoleModal from "./addRoleModal";
import "./profile.css";

function Profile() {
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const userData = useUserData({ setLoading });
  const myRole = userData.role;
  useEffect(() => {
    if (myRole === "norole") {
      setModalShow(true);
    } else {
      setModalShow(false);
    }
  }, [userData]);

  return (
    <Container fluid="lg" className="my-5 pt-5">
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="demosViewMoreSection demoProfile overflow-hidden mx-auto mt-3 position-relative profile">
          <Row className="mx-md-4 mx-2 my-2 position-relative">
            <Link
              to={`/profile/edit`}
              className="btn-link text-white text-center px-1 rounded-start-1 mt-2 text-decoration-none bg-dark bg-opacity-50 position-absolute end-0 top-0 z-1 w-fit"
              title="Edit Profile"
            >
              Edit <PencilSquare />
            </Link>
            <Col md={12} className="coverImg px-0 pb-0 position-relative w-100">
              <Image
                src={userData?.hero_image_url || COVERIMG}
                height="100%"
                width="100%"
                alt=""
              />
            </Col>
          </Row>
          <Row className="profileHeader align-items-center position-relative py-2 mx-4">
            <Col lg={2} className="mr-3">
              <Image
                src={
                  userData?.photo_image_url &&
                  userData?.photo_image_url !== undefined &&
                  userData?.photo_image_url
                    ? userData?.photo_image_url
                    : USER
                }
                alt="..."
                className="profileImg"
              />
            </Col>
            <Col
              sm={6}
              className="mt-lg-0 mt-5 pt-lg-0 pt-2 ps-4 d-flex flex-column align-items-sm-start align-items-center"
            >
              <h4 className="trainerName mb-2">
                {userData?.first_name} {userData?.last_name}
                <br />
                <small className="industry">
                  {userData?.role || userData?.role !== null
                    ? "(" + userData?.role + ")"
                    : userData?.type || userData?.type
                    ? "(" + userData?.type + ")"
                    : null}
                </small>
              </h4>
              <StarRating rating={userData?.star_rating || 0} />
            </Col>
            <Col
              lg={4}
              sm={6}
              className="mt-lg-0 pt-lg-0 pt-2 social d-flex gap-md-3 gap-2 flex-wrap justify-content-center"
            >
              {userData?.whatsapp && (
                <a
                  target="_blank"
                  href={`https://wa.me/${userData?.whatsapp}`}
                  rel="noreferrer"
                >
                  <Whatsapp />
                </a>
              )}
              {userData?.linkedin && (
                <a
                  target="_blank"
                  href={`https://${userData?.linkedin}`}
                  rel="noreferrer"
                >
                  <Linkedin />
                </a>
              )}
              {userData?.facebook && (
                <a
                  target="_blank"
                  href={`https://${userData?.facebook}`}
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
              )}
              {userData?.instagram && (
                <a
                  target="_blank"
                  href={`https://${userData?.instagram}`}
                  rel="noreferrer"
                >
                  <Instagram />
                </a>
              )}
              {userData?.website && (
                <a
                  target="_blank"
                  href={`https://${userData?.website}`}
                  rel="noreferrer"
                >
                  <Globe />
                </a>
              )}
            </Col>
          </Row>
          <Card className="m-4 mt-2">
            <Card.Body>
              <Row>
                <Col md={3}>
                  <Card.Text className="mb-0 fw-bold">Job Title</Card.Text>
                </Col>
                <Col md={9}>
                  <Card.Text className="text-muted mb-0">
                    {userData?.job_title
                      ? userData?.job_title
                      : "Not Specified"}
                  </Card.Text>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={3}>
                  <Card.Text className="mb-0 fw-bold">Email Address</Card.Text>
                </Col>
                <Col md={9}>
                  <Card.Text className="text-muted mb-0">
                    {userData?.email ? userData?.email : "Private"}
                  </Card.Text>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={3}>
                  <Card.Text className="mb-0 fw-bold">Phone</Card.Text>
                </Col>
                <Col md={9}>
                  <Card.Text className="text-muted mb-0">
                    {userData?.phone ? `${userData?.phone}` : "Not Available"}
                  </Card.Text>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={3}>
                  <p className="mb-0 fw-bold">Address</p>
                </Col>
                <Col md={9}>
                  <Card.Text className="text-muted mb-0">
                    {userData?.state || userData?.city || userData?.country
                      ? `${userData?.state ?? ""} ${userData?.city ?? ""}, ${
                          userData?.country ?? ""
                        }`
                      : "Not Available"}
                  </Card.Text>
                </Col>
              </Row>
              <hr />
              <Link
                to={`/coordinator/join`}
                className="btn-link theme-text text-decoration-none d-flex align-items-center"
              >
                <ArrowRightCircleFill />
                &nbsp;
                <span className="text-decoration-underline">
                  Join as Training Coordinator
                </span>
              </Link>
            </Card.Body>
          </Card>
        </div>
      )}

      <AddRoleModal show={modalShow} setshow={setModalShow} />
    </Container>
  );
}

export default Profile;
