import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Check2Circle, QuestionCircle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import EmailFooter from "../../components/emailTemplate/emailFooter";
import EmailHeader from "../../components/emailTemplate/emailHeader";
import EmailCorporateBody from "../../components/emailTemplate/getCorporateBody";
import CustomLoader from "../../components/loader/loader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import useUserData from "../../components/useUserDetails/useUserDetails";

const Coordinator = () => {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const siteName = SubDomainRouter("title");
  const fromEmail = SubDomainRouter("fromEmail");

  const [loading, setLoading] = useState(true);
  const userData = useUserData({ setLoading });

  const [reqLoading, setReqLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company_name: "",
    company_address: "",
    message: "",
    message_for_team: "A user has enrolled for Training Coordination",
  });

  const campaignFields = {
    userId: myUserId,
    type: "email",
    subject: "Request to join as Training Coordinator",
    from: fromEmail,
    to: [],
    cc: [],
    bcc: [],
    Attachments: null,
    Template: null,
    IsHTML: true,
  };

  const handleFieldChange = (fieldName, fieldValue) => {
    const newFormData = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newFormData);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      email: userData?.email,
      phone: userData?.phone,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const logo = SubDomainRouter("logo");
  const title = SubDomainRouter("title");
  const courseAlias = SubDomainRouter("courseAlias");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");
  const adminEmail = SubDomainRouter("adminEmail");
  const supportEmail = SubDomainRouter("supportEmail");

  const handleSubmit = (e) => {
    e.preventDefault();
    setReqLoading(true);

    const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
    const mappedBodyData = EmailCorporateBody(formData);
    const footer_email_content = EmailFooter(logo, title);

    const data = {
      ...campaignFields,
      to: [adminEmail, supportEmail],
      message: `${header_email_content} ${mappedBodyData} ${footer_email_content}`,
    };

    axios
      .post(`${Actions.APIGATEWAY_URL}email`, data, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setReqLoading(false);
        successCampaignAlert();
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          company_name: "",
          company_address: "",
          message: "",
          message_for_team: "A user has enrolled for Training Coordination",
        });
      })
      .catch((error) => {
        setReqLoading(false);
        console.log(error.response);
      });
  };

  const successCampaignAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white py-4 px-2 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center mt-2 text-capitalize">
              Your Request was received.
            </h5>
            <p className="text-center lh-1">
              <small>
                Thank You for showing Interest&nbsp;
                <b>
                  {formData?.first_name} {formData?.last_name}
                </b>
                <br />
                We will contact you soon!
              </small>
            </p>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                onClose();
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>
          Join As Coordinator |&nbsp;
          {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="py-5 mt-5">
          <h4 className="theme-text-2 text-center text-capitalize pt-3">
            Join us as a {courseAlias ? courseAlias : "course"} Coordinator
          </h4>
          <Form
            className="ContactUsForm mt-4 py-lg-5 px-md-4 px-md-3 py-3"
            onSubmit={handleSubmit}
          >
            <p className="text-center text-wrap-balance">
              Join as a training manager / Training Coordinator / Liaison
              Officer to coordinate between the trainers / mentors and students
            </p>
            <Link
              to={`/how-it-works`}
              className="btn-link theme-text-2 text-decoration-none d-flex align-items-center justify-content-center mb-3 text-center"
            >
              <QuestionCircle />
              &nbsp;
              <span className="text-decoration-underline">
                See How It Works
              </span>
            </Link>
            <Row className="mx-0 pt-4">
              <Col lg={6}>
                <div className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    required
                    className="form-control"
                    id="FirstName"
                    name="FirstName"
                    value={formData?.first_name}
                    placeholder="First Name"
                    onChange={(e) =>
                      handleFieldChange("first_name", e.target.value)
                    }
                  ></Form.Control>
                  <Form.Label htmlFor="FirstName">First Name</Form.Label>
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-floating mb-3">
                  <Form.Control
                    className="form-control"
                    type="text"
                    value={formData?.last_name}
                    id="LastName"
                    name="LastName"
                    placeholder="Last Name"
                    onChange={(e) =>
                      handleFieldChange("last_name", e.target.value)
                    }
                  ></Form.Control>
                  <Form.Label htmlFor="LastName">Last Name</Form.Label>
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-floating mb-3">
                  <Form.Control
                    className="form-control"
                    required
                    value={formData?.email}
                    type="email"
                    id="Email"
                    name="Email"
                    placeholder="Email"
                    onChange={(e) => handleFieldChange("email", e.target.value)}
                  ></Form.Control>
                  <Form.Label htmlFor="Email">Email</Form.Label>
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-floating mb-3">
                  <Form.Control
                    className="form-control"
                    required
                    value={formData?.phone}
                    type="phone"
                    id="Phone"
                    name="Phone"
                    placeholder="Phone"
                    onChange={(e) => handleFieldChange("phone", e.target.value)}
                  ></Form.Control>
                  <Form.Label htmlFor="Phone">Phone</Form.Label>
                </div>
              </Col>
              <Col md={12}>
                <div className="form-floating mb-3">
                  <Form.Control
                    as={"textarea"}
                    className="form-control textArea max-h-auto"
                    value={formData?.message}
                    id="message"
                    rows={4}
                    name="message"
                    placeholder="Message"
                    onChange={(e) =>
                      handleFieldChange("message", e.target.value)
                    }
                  ></Form.Control>
                  <Form.Label htmlFor="message">Message</Form.Label>
                </div>
              </Col>

              <Col md={12} className="ContactBottom pt-3">
                <Button className="ContactSubmitBtn add-button" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
    </>
  );
};

export default Coordinator;
