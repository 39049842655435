import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import {
  Calendar2Date,
  Facebook,
  Instagram,
  Linkedin,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import BlogPlaceholdImg from "../../img/blog.jpg";
import CustomLoader from "../loader/loader";
import FormattedDate from "../timestampConverters/createdDateFormatter";
import { toast } from "react-toastify";

const PostsSidebar = () => {
  const myToken = localStorage.getItem("token");

  const entities = "blogs";
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const populateList = async () => {
    try {
      const response = await axios.get(`${Actions.BASE_URL}${entities}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      setItems(response.data);
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Something went wrong. Please Try again later.", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card-cont featured-blog-posts p-3 d-flex flex-column gap-3 text-wrap-balance">
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="latest-posts">
            <h6 className="theme-text-2 mb-3">Latest Posts</h6>
            <Row className="mx-0 d-flex flex-lg-column flex-wrap">
              {items
                ?.slice(0, 5)
                ?.sort((a, b) =>
                  moment(b.createdDate).diff(moment(a.createdDate))
                )
                ?.map((item, index) => (
                  <Col lg={12} md={6} className="ps-0 pe-lg-0 pe-md-2 pe-0" key={index}>
                    <Card className="featured-blog-card flex-row border-0 row mx-0 align-items-stretch mb-3">
                      <Col sm={4} className="featured-card-image p-0">
                        <Image
                          loading="lazy"
                          src={item?.listing_image_url || BlogPlaceholdImg}
                          alt=""
                          width="100%"
                          height="100%"
                        />
                      </Col>
                      <Col
                        sm={8}
                        className="featured-card-content pe-0 d-flex justify-content-between flex-column ps-sm-2 ps-0"
                      >
                        <div>
                          <Link
                            to={`/${entities}/details/${item?.id}`}
                            className="text-dark text-decoration-none text-overflow-desc-xs"
                          >
                            <p className="text-overflow-desc mb-0 fw-semibold">
                              {item?.title}
                            </p>
                          </Link>
                          <p className="text-dark text-decoration-none text-overflow-desc-sm lh-sm">
                            <small>
                              {item?.twoLinerIntro || item.blogContent}
                            </small>
                          </p>
                        </div>
                        <span className="time d-flex align-items-center">
                          <Calendar2Date />
                          &nbsp;
                          <FormattedDate createdDate={item?.createdDate} />
                        </span>
                      </Col>
                    </Card>
                  </Col>
                ))}
            </Row>
          </div>
          <div className="social-section">
            <h6 className="theme-text-2">Follow Us</h6>
            <ul className="social-list list m-0 list-unstyled d-flex align-items-center gap-sm-3 gap-2">
              <li>
                <a
                  href="http://facebook.com/ameerpetit"
                  target="_blank"
                  title="facebook"
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
              </li>
              <li>
                <a href="/" target="_blank" title="twitter">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    width="1em"
                    height="1em"
                  >
                    <path d="M18.2048 2.25H21.5128L14.2858 10.51L22.7878 21.75H16.1308L10.9168 14.933L4.95084 21.75H1.64084L9.37084 12.915L1.21484 2.25H8.04084L12.7538 8.481L18.2048 2.25ZM17.0438 19.77H18.8768L7.04484 4.126H5.07784L17.0438 19.77Z"></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/ameerpetit"
                  target="_blank"
                  title="linkedin"
                  rel="noreferrer"
                >
                  <Linkedin />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/ameerpettraining/"
                  target="_blank"
                  title="instagram"
                  rel="noreferrer"
                >
                  <Instagram />
                </a>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default PostsSidebar;
