const EmailCorporateBody = (details) => {
  const htmlContent = `
        <table width="600px" style="max-width: 600px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
        align="center" style="text-wrap: balance;">
        <tr>
            <td height="24"></td>
        </tr>
        <!-- Main Message -->
        <tr>
            <td>
                <span style="font-weight: bold; font-size: 16px; font-family: 'Unbounded', sans-serif;">
                    Hello,
                </span>
            </td>
        </tr>
        <tr>
            <td height="6"></td>
        </tr>
        <tr>
            <td width="100%" style="line-height: 1.3;">
                <span style="font-size: 12px; font-family: 'Poppins', sans-serif;">
                  ${details?.message_for_team}
                </span>
            </td>
        </tr>
        <tr>
            <td height="30"></td>
        </tr>
    </table>
    <!-- User Details -->
    <table width="600px" style="max-width: 600px; width: 100%; border-radius: 4px;" border="0"
        cellspacing="0" cellpadding="0" align="center" bgcolor="#e7eeee">
        <tr>
            <td height="12"></td>
        </tr>
        <tr>
            <td width="12"></td>
            <td>
                <table width="574px"
                    style="max-width: 574px; width: 100%; font-size: 12px; font-family: 'Poppins', sans-serif;"
                    border="0" cellspacing="0" cellpadding="0" align="center">
                    <tr>
                        <td>
                            <span>
                                <strong>Name:</strong>&nbsp; ${
                                  details?.first_name
                                } ${details?.last_name}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td height="6"></td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <strong>Email Address:</strong>&nbsp; ${
                                  details?.email
                                } 
                            </span>
                        </td>
                    </tr> 
                    ${
                      details?.phone
                        ? `
                        <tr>
                             <td height="6"></td>
                         </tr> 
                         <tr>
                              <td>
                                 <span>
                                      <strong>Phone No:</strong>&nbsp; ${details?.phone}
                                </span>
                              </td>
                          </tr>
                           `
                        : ""
                    }
                    ${
                      details?.whatsapp
                        ? `
                        <tr>
                             <td height="6"></td>
                         </tr> 
                         <tr>
                              <td>
                                 <span>
                                      <strong>WhatsApp:</strong>&nbsp; ${details?.whatsapp}
                                </span>
                              </td>
                          </tr>
                           `
                        : ""
                    }
                    ${
                      details?.specialization
                        ? `
                        <tr>
                             <td height="6"></td>
                         </tr> 
                         <tr>
                              <td>
                                 <span>
                                      <strong>Specialization:</strong>&nbsp; ${details?.specialization}
                                </span>
                              </td>
                          </tr>
                           `
                        : ""
                    }
                    ${
                      details?.address
                        ? `
                        <tr>
                             <td height="6"></td>
                         </tr> 
                         <tr>
                              <td>
                                 <span>
                                      <strong>City:</strong>&nbsp; ${details?.address}
                                </span>
                              </td>
                          </tr>
                           `
                        : ""
                    }
                    ${
                      details?.company_name
                        ? `
                        <tr>
                             <td height="6"></td>
                         </tr> 
                         <tr>
                              <td>
                                 <span>
                                      <strong>Company Name:</strong>&nbsp; ${details?.company_name}
                                </span>
                              </td>
                          </tr>
                           `
                        : ""
                    }
                    ${
                      details?.company_website
                        ? `
                    <tr>
                        <td height="6"></td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <strong>Company Website:</strong>&nbsp; ${details?.company_website}
                            </span>
                        </td>
                    </tr>
                    `
                        : ""
                    }
                    ${
                      details?.industry
                        ? `
                    <tr>
                        <td height="6"></td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <strong>Industry:</strong>&nbsp; ${details?.industry}
                            </span>
                        </td>
                    </tr>
                    `
                        : ""
                    }
                    <tr>
                        <td height="6"></td>
                    </tr>
                    ${
                      details?.message
                        ? `<tr>
                            <td>
                            <span>
                                <strong>Message:</strong>&nbsp; ${details?.message}
                            </span>
                            </td>
                        </tr>
                        <tr>
                            <td height="6"></td>
                        </tr>
                        `
                        : ""
                    }
                </table>
            </td>
            <td width="12"></td>
        </tr>
        <tr>
            <td height="12"></td>
        </tr>
    </table>
  `;

  return htmlContent;
};

export default EmailCorporateBody;
