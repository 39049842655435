import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import * as Actions from "../../constants/baseUrl";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import TokenDetails from "../useUserDetails/useTokenDetails";
import moment from "moment-timezone";
import useUserData from "../useUserDetails/useUserDetails";

const CampaignRelations = ({
  fields,
  field,
  index,
  onFieldChange,
  onEntityDetailsChange,
  selectOptions,
}) => {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  const userData = useUserData();
  const role = userData.role?.toLocaleLowerCase();

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(Object.keys(field)[0]);
  const [value, setValue] = useState(field[type]);
  const [coursesData, setCoursesData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [blogsData, setBlogsData] = useState([]);
  const [propertyEventsData, setPropertyEventsData] = useState([]);
  const [demosData, setDemosData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleTypeChange = (newType) => {
    setType(newType);
    const updatedFields = [...fields];
    if (newType === "custom_campaigns") {
      updatedFields[index] = { type: "custom_campaigns" };
    } else {
      updatedFields[index] = { [newType]: value };
    }
    onFieldChange(updatedFields);
  };

  useEffect(() => {
    // Extract selected items from the fields array
    const updatedSelectedItems = fields
      ?.map((field) => {
        const type = Object.keys(field)[0];
        const value = field[type];
        return type && value ? getSelectedItem(type, value) : null;
      })
      .filter((item) => item !== null);

    // Update the selected items state
    setSelectedItems(updatedSelectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const getSelectedItem = (type, value) => {
    if (type.toLowerCase() === "demo") {
      return demosData.find((demo) => demo?.id === value);
    } else if (type.toLowerCase() === "course") {
      return coursesData.find((course) => course?.id === value);
    }
    else if (type.toLowerCase() === "event") {
      return eventsData.find((event) => event?.id === value);
    }
    else if (type.toLowerCase() === "blog") {
      return blogsData.find((blog) => blog?.id === value);
    }
    else if (type.toLowerCase() === "items/propertyevent") {
      return propertyEventsData.find((propertyEvent) => propertyEvent?.id === value);
    }
    else {
      return null;
    }
  };

  const handleValueChange = async (newValue) => {
    setLoading(true);

    if (type === "custom_campaigns") {
      setValue("custom_campaigns");
      const updatedFields = [...fields];
      updatedFields[index] = { type: "custom_campaigns" };
      onFieldChange(updatedFields);

      setSelectedItems("custom_campaigns");
      setLoading(false);
      return;
    }

    setValue(newValue);
    const updatedFields = [...fields];
    updatedFields[index] = { [type]: newValue };
    onFieldChange(updatedFields);



    const isItemEntity = type?.includes('items/')
    try {
      await axios.get(`${Actions.BASE_URL}${isItemEntity ? type : type + "s"}/${newValue}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      setLoading(false);
      // Filter the selected item based on the ID
      const filteredItem =
        type.toLowerCase() === "demo"
          ? demosData.find((demo) => demo?.id === newValue)
          : type.toLowerCase() === "course"
            ? coursesData.find((course) => course?.id === newValue)
            : type.toLowerCase() === "event"
              ? eventsData.find((event) => event?.id === newValue)
              : type.toLowerCase() === "blog"
                ? blogsData.find((blog) => blog?.id === newValue)
                : type.toLowerCase() === "items/propertyevent"
                  ? propertyEventsData.find((propertyevent) => propertyevent?.id === newValue)
                  : null;

      setSelectedItems((prevSelectedItems) => {
        // Check if the item is already in the array

        if (isItemEntity && !prevSelectedItems.some((item) => item?.id === filteredItem?.id)) {
          return [...prevSelectedItems, filteredItem.data];
        }
        else if (!prevSelectedItems.some((item) => item?.id === filteredItem?.id)) {
          return [...prevSelectedItems, filteredItem];
        }
        else {
          return prevSelectedItems;
        }
      });
    } catch (error) {
      setLoading(false);
      console.error(error.response);
    }
  };

  const onFieldRemove = () => {
    const updatedFields = fields.filter((_, i) => i !== index);
    onFieldChange(updatedFields);
  };

  useEffect(() => {
    onFieldChange(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  // Get Demos
  const getDemos = () => {
    const data = {
      userid: myUserId,
    };

    if (role === "admin" || role === "siteadmin" || role === "superadmin") {
      axios
        .get(`${Actions.BASE_URL}demos`, {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // Sort data  in ascending order
          const sortedData = res.data?.sort((a, b) => {
            const dateDiff = moment(a.start_date).diff(moment(b.start_date));

            // If the dates are equal, compare based on time
            if (dateDiff === 0) {
              return moment(a.start_time, "HH:mm:ss").diff(
                moment(b.start_time, "HH:mm:ss")
              );
            }

            return dateDiff;
          });
          setDemosData(sortedData);
        })
        .catch((error) => {
          // setLoading(false);
          console.error(error.response);
        });
    } else {
      axios
        .post(`${Actions.BASE_URL}dashboard/MyDemos?userid=${myUserId}`, data, {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // Sort data  in ascending order
          const sortedData = res.data?.sort((a, b) => {
            const dateDiff = moment(a.start_date).diff(moment(b.start_date));

            // If the dates are equal, compare based on time
            if (dateDiff === 0) {
              return moment(a.start_time, "HH:mm:ss").diff(
                moment(b.start_time, "HH:mm:ss")
              );
            }

            return dateDiff;
          });
          setDemosData(sortedData);
        })
        .catch((error) => {
          // setLoading(false);
          console.error(error.response);
        });
    }
  };

  // Get Courses
  const getCourses = () => {
    const data = {
      userid: myUserId,
    };

    if (role === "admin" || role === "siteadmin" || role === "superadmin") {
      axios
        .get(`${Actions.BASE_URL}courses`, {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // Sort data  in ascending order
          const sortedData = res.data?.sort((a, b) => {
            const dateDiff = moment(a.start_date).diff(moment(b.start_date));

            // If the dates are equal, compare based on time
            if (dateDiff === 0) {
              return moment(a.start_time, "HH:mm:ss").diff(
                moment(b.start_time, "HH:mm:ss")
              );
            }

            return dateDiff;
          });
          setCoursesData(sortedData);
        })
        .catch((error) => {
          // setLoading(false);
          console.error(error.response);
        });
    } else {
      axios
        .post(
          `${Actions.BASE_URL}dashboard/MyCourses?userid=${myUserId}`,
          data,
          {
            headers: {
              Authorization: "Bearer " + myToken,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // Sort data  in ascending order
          const sortedData = res.data?.sort((a, b) => {
            const dateDiff = moment(a.start_date).diff(moment(b.start_date));

            // If the dates are equal, compare based on time
            if (dateDiff === 0) {
              return moment(a.start_time, "HH:mm:ss").diff(
                moment(b.start_time, "HH:mm:ss")
              );
            }

            return dateDiff;
          });
          setCoursesData(sortedData);
        })
        .catch((error) => {
          // setLoading(false);
          console.error(error.response);
        });
    }
  };

  // Get Events 
  const getEvents = () => {
    const data = {
      userid: myUserId,
    };

    if (role === "admin" || role === "siteadmin" || role === "superadmin") {
      axios
        .get(`${Actions.BASE_URL}events`, {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // Sort data  in ascending order
          const sortedData = res.data?.sort((a, b) => {
            const dateDiff = moment(a.start_date).diff(moment(b.start_date));

            // If the dates are equal, compare based on time
            if (dateDiff === 0) {
              return moment(a.start_time, "HH:mm:ss").diff(
                moment(b.start_time, "HH:mm:ss")
              );
            }

            return dateDiff;
          });
          setEventsData(sortedData);
        })
        .catch((error) => {
          // setLoading(false);
          console.error(error.response);
        });
    } else {
      axios
        .post(`${Actions.BASE_URL}dashboard/MyEvents?userid=${myUserId}`, data, {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // Sort data  in ascending order
          const sortedData = res.data?.sort((a, b) => {
            const dateDiff = moment(a.start_date).diff(moment(b.start_date));

            // If the dates are equal, compare based on time
            if (dateDiff === 0) {
              return moment(a.start_time, "HH:mm:ss").diff(
                moment(b.start_time, "HH:mm:ss")
              );
            }

            return dateDiff;
          });
          setEventsData(sortedData);
        })
        .catch((error) => {
          // setLoading(false);
          console.error(error.response);
        });
    }
  };

  // Get Blogs 
  const getBlogs = () => {
    const data = {
      userid: myUserId,
    };

    if (role === "admin" || role === "siteadmin" || role === "superadmin") {
      axios
        .get(`${Actions.BASE_URL}blogs`, {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // Sort data  in ascending order
          const sortedData = res.data?.sort((a, b) => {
            const dateDiff = moment(a.start_date).diff(moment(b.start_date));

            // If the dates are equal, compare based on time
            if (dateDiff === 0) {
              return moment(a.start_time, "HH:mm:ss").diff(
                moment(b.start_time, "HH:mm:ss")
              );
            }

            return dateDiff;
          });
          setBlogsData(sortedData);
        })
        .catch((error) => {
          // setLoading(false);
          console.error(error.response);
        });
    } else {
      axios
        .post(`${Actions.BASE_URL}dashboard/MyBlogs?userid=${myUserId}`, data, {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // Sort data  in ascending order
          const sortedData = res.data?.sort((a, b) => {
            const dateDiff = moment(a.start_date).diff(moment(b.start_date));

            // If the dates are equal, compare based on time
            if (dateDiff === 0) {
              return moment(a.start_time, "HH:mm:ss").diff(
                moment(b.start_time, "HH:mm:ss")
              );
            }

            return dateDiff;
          });
          setBlogsData(sortedData);
        })
        .catch((error) => {
          // setLoading(false);
          console.error(error.response);
        });
    }
  };

  // Get Events
  const getPropertyEvents = () => {
    axios
      .get(`${Actions.BASE_URL}items/propertyevent`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // Sort data  in ascending order
        const sortedData = res.data?.sort((a, b) => {
          const dateDiff = moment(a.start_date).diff(moment(b.start_date));

          // If the dates are equal, compare based on time
          if (dateDiff === 0) {
            return moment(a.start_time, "HH:mm:ss").diff(
              moment(b.start_time, "HH:mm:ss")
            );
          }

          return dateDiff;
        });
        setPropertyEventsData(sortedData);
      })
      .catch((error) => {
        // setLoading(false);
        console.error(error.response);
      });
  };

  // Call Req fun initially
  useEffect(() => {
    if (role === "admin" || role === "siteadmin" || role === "superadmin") {
      getPropertyEvents();
    }

    getEvents();
    getCourses();
    getDemos();
    getBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const hasUndefinedData = selectedItems?.map((item) => item === undefined);

  useEffect(() => {
    if (hasUndefinedData && type && value) {
      handleValueChange(value);
      handleTypeChange(type);
      onFieldChange(fields);
      getSelectedItem(type, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onEntityDetailsChange(selectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");
  const eventsAlias = SubDomainRouter("eventsAlias");
  const blogsAlias = SubDomainRouter("blogsAlias");
  const propertyEventsAlias = SubDomainRouter("propertyEventsAlias");


  return (
    <div className="d-flex gap-3 w-100 align-items-sm-center mb-3">
      {loading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}

      <div className="flex-grow-1 row mx-0">
        <div className="col-sm-6 ps-0">
          <Form.Group className="form-floating formfloating">
            <Form.Select
              value={type}
              onChange={(e) => handleTypeChange(e.target.value)}
              className="form-control form-select text-capitalize"
            >
              <option value="">Select Entity Type</option>
              {selectOptions?.map((option) => (
                <option
                  className="text-capitalize"
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>

        <div className="col-sm-6 ps-sm-2 ps-0">
          <Form.Group className="form-floating">
            <Form.Select
              value={value}
              onChange={(e) => handleValueChange(e.target.value)}
              className="form-control form-select text-capitalize"
              disabled={!type || type === "custom_campaigns"}
            >
              <option value="">
                {type.toLocaleLowerCase() === "demo" ? (
                  <>
                    {demosData?.length > 0
                      ? `Select ${demosAlias ? demosAlias : "demos"}`
                      : `No ${demosAlias ? demosAlias : "demos"} Yet`}
                  </>
                ) :
                  type.toLocaleLowerCase() === "course" ? (
                    <>
                      {coursesData?.length > 0
                        ? `Select ${coursesAlias ? coursesAlias : "courses"}`
                        : `No ${coursesAlias ? coursesAlias : "courses"} Yet`}
                    </>
                  ) :
                    type.toLocaleLowerCase() === "event" ? (
                      <>
                        {eventsData?.length > 0
                          ? `Select ${eventsAlias ? eventsAlias : "events"}`
                          : `No ${eventsAlias ? eventsAlias : "events"} Yet`}
                      </>
                    ) :
                      type.toLocaleLowerCase() === "blog" ? (
                        <>
                          {blogsData?.length > 0
                            ? `Select ${blogsAlias ? blogsAlias : "blogs"}`
                            : `No ${blogsAlias ? blogsAlias : "blogs"} Yet`}
                        </>
                      ) :
                        type.toLocaleLowerCase() === "items/propertyevent" ? (
                          <>
                            {propertyEventsData?.length > 0
                              ? `Select ${propertyEventsAlias ? propertyEventsAlias : "propertyevents"}`
                              : `No ${propertyEventsAlias ? propertyEventsAlias : "propertyevents"} Yet`}
                          </>
                        ) :
                          type.toLocaleLowerCase() === "custom_campaigns" ? (
                            "Not available for Custom Campaigns"
                          ) : (
                            "Select an entity type first"
                          )}
              </option>
              {type.toLocaleLowerCase() === "demo" &&
                demosData?.map((demo) => (
                  <option
                    className="text-capitalize"
                    key={demo?.id}
                    value={demo?.id}
                  >
                    {demo.title}
                  </option>
                ))}
              {type.toLocaleLowerCase() === "course" &&
                coursesData?.map((course) => (
                  <option
                    className="text-capitalize"
                    key={course?.id}
                    value={course?.id}
                  >
                    {course.title}
                  </option>
                ))}

              {type.toLocaleLowerCase() === "event" &&
                eventsData?.map((event) => (
                  <option
                    className="text-capitalize"
                    key={event?.id}
                    value={event?.id}
                  >
                    {event.title}
                  </option>
                ))}
              {type.toLocaleLowerCase() === "blog" &&
                blogsData?.map((blog) => (
                  <option
                    className="text-capitalize"
                    key={blog?.id}
                    value={blog?.id}
                  >
                    {blog.title}
                  </option>
                ))}
              {type.toLocaleLowerCase() === "items/propertyevent" &&
                propertyEventsData?.map((propertyEvent) => (
                  <option
                    className="text-capitalize"
                    key={propertyEvent?.id}
                    value={propertyEvent?.id}
                  >
                    {propertyEvent?.data?.title}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </div>
      </div>

      <Button
        className="clickable actionDltBtn btn border-danger"
        onClick={onFieldRemove}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default CampaignRelations;
