import { ErrorRounded } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import { Toast } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const BannerUploaderEdit = ({
  className = "",
  label,
  accept,
  imageType,
  state,
  setState,
  imageURL,
  imageContent,
  imageExtension,
  placehold,
  isBlog,
  id,
}) => {
  const [showToast, setShowToast] = useState(false);
  const minWidth =
    imageType === "hero_image" && isBlog
      ? 720
      : imageType === "listing_image" && isBlog
      ? 280
      : imageType === "hero_image"
      ? 1024
      : 280;
  const minHeight =
    imageType === "hero_image" && isBlog
      ? 720
      : imageType === "listing_image" && isBlog
      ? 280
      : imageType === "hero_image"
      ? 200
      : 180;
  const inputRef = useRef(null);

  const validateImageDimensions = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const base64Data = event.target.result.split(",")[1]; // Get the base64 content without the prefix

      const img = new Image();
      img.src = `data:image/${file.name.split(".").pop()};base64,${base64Data}`;

      img.onload = () => {
        if (img.width >= minWidth && img.height >= minHeight) {
          const fileExtension = file.name.split(".").pop();
          const newState = { ...state };

          if (imageType === "listing_image") {
            newState.listing_image_content = base64Data;
            newState.listing_image_extension = fileExtension;
          } else if (imageType === "hero_image") {
            newState.hero_image_content = base64Data;
            newState.hero_image_extension = fileExtension;
          }

          setState(newState);
          // console.log(state);
        } else {
          inputRef.current.value = "";
          setShowToast(true);
        }
      };
    };

    reader.readAsDataURL(file);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      validateImageDimensions(selectedFile);
    }
  };

  return (
    <Form.Group
      className={`imgBanner mt-2 mb-3 w-100 position-relative ${className}`}
    >
      {imageExtension && imageContent ? (
        <img
          src={`data:image/${imageExtension};base64,${imageContent}`}
          loading="lazy"
          alt=""
          className="uploaded-thumb-edit img-thumbnail rounded-2 object-fit-cover w-100"
          width="100%"
          height="100%"
        />
      ) : imageURL ? (
        <img
          src={imageURL}
          loading="lazy"
          alt=""
          className="uploaded-thumb-edit img-thumbnail rounded-2 object-fit-cover w-100"
          width="100%"
          height="100%"
        />
      ) : (
        <img
          src={placehold}
          loading="lazy"
          alt=""
          className="uploaded-thumb-edit img-thumbnail rounded-2 object-fit-contain w-100"
          width="100%"
          height="100%"
        />
      )}

      <Form.Label
        className="form-label file-upload-label mt-3"
        htmlFor={id ?? imageType}
      >
        <span className="bannerBTn add-button">Upload New {label}</span>
        <Form.Control
          ref={inputRef}
          type="file"
          accept={accept}
          className={`upload${
            id ?? imageType
          }File imgUploader w-100 mt-3 d-none`}
          name={id ?? imageType}
          id={id ?? imageType}
          onChange={handleFileChange}
        />
      </Form.Label>
      <div className="imagePreview ms-1">
        <span className="theme-text-2 d-block d-block">
          *Upload Your PNG/JPG/JPEG Image
        </span>
      </div>
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        autohide
        delay={3500}
        style={{
          position: "absolute",
          top: "100%",
          right: 0,
          zIndex: "99",
        }}
      >
        <Toast.Header closeButton={false}>
          <strong className="mr-auto text-danger">
            <ErrorRounded />
            &nbsp;Error
          </strong>
        </Toast.Header>
        <Toast.Body>
          The {label} dimensions should be at least{" "}
          <b>
            {minWidth} x {minHeight} Pixels
          </b>
          .
        </Toast.Body>
      </Toast>
    </Form.Group>
  );
};

export default BannerUploaderEdit;
