import React from "react";
import { Form } from "react-bootstrap";

const DurationSelect = ({ field, handleFieldChange, isCourse }) => {
  return (
    <Form.Select
      className="form-control form-select"
      id="duration"
      required
      name="duration"
      value={field?.duration}
      onChange={(e) => handleFieldChange("duration", e.target.value)}
    >
      <option value="">Duration</option>
      <option value="60">1 Hour</option>
      <option value="120">2 Hour</option>
      <option value="180">3 Hour</option>
      <option value="240">4 Hour</option>
      <option value="300">5 Hour</option>
      <option value="360">6 Hour</option>
      <option value="420">7 Hour</option>
      <option value="480">8 Hour</option>
      <option value="1440">1 Day</option>
      <option value="2880">2 Days</option>
      <option value="4320">3 Days</option>
      <option value="5760">4 Days</option>
      <option value="7200">5 Days</option>
      <option value="8640">6 Days</option>
      <option value="10080">1 Week</option>
      <option value="20160">2 Weeks</option>
      <option value="30240">3 Weeks</option>
      <option value="40320">1 Month</option>
      <option value="80640">2 Months</option>
      {isCourse === true && (
        <>
          <option value="131400">3 Months</option>
          <option value="175316">4 Months</option>
          <option value="219145">5 Months</option>
          <option value="262974">6 Months</option>
          <option value="306803">7 Months</option>
          <option value="350632">8 Months</option>
          <option value="394461">9 Months</option>
          <option value="438290">10 Months</option>
          <option value="482119">11 Months</option>
          <option value="525948">12 Months</option>
          <option value="788922">18 Months</option>
          <option value="1051887">24 Months</option>
        </>
      )}
    </Form.Select>
  );
};

export default DurationSelect;
