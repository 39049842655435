import React from "react";
import "./loader.css";

const CustomLoader = ({ height, className }) => {
  return (
    <div
      className={`parentLoader my-5 py-5 ${className}`}
      style={{ height: height || "45vh" }}
    >
      <span className="loader"></span>
    </div>
  );
};
export default CustomLoader;
