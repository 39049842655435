import React from "react";

const CoordinatorGuide = () => {
  const documentId = "1Dto8pMGVV5wl6IPkVzQLryDkzYQ79Ghd1WkYWLBMduM";
  const embedUrl = `https://docs.google.com/document/d/${documentId}/preview`;
  return (
    <div className="pt-5 overflow-hidden" style={{ height: "100dvh" }}>
      <iframe
        title="How it Works"
        src={embedUrl}
        width="100%"
        height="100%"
        frameBorder="0"
        scrolling="yes"
        seamless
      ></iframe>
    </div>
  );
};

export default CoordinatorGuide;
