class HttpHelper {
    async fetchJson(url, httpMethod, payload, cookies) {
        if (cookies) {
            var jwtVal = cookies
                .split("; ")
                .find((row) => row.startsWith("IdentiTeamToken="))
                ?.split("=")[1];
        }

        let result;
        console.log(url);
        try {
            result = await fetch(url,
                {
                    method: httpMethod,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + jwtVal,
                    },
                    body: httpMethod == "POST" ? JSON.stringify(payload) : null,
                    credentials: "include",
                });
            console.log("helper result OK");
            return result;
        } catch (error) {
            console.log(error);
            // return new {
            //   error: error,
            // }();
        }
    }
}

export default HttpHelper;
