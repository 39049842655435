import React, { useEffect, useState } from "react";
import * as Actions from "../../constants/baseUrl";
import axios from "axios";
import "./add2.css";
import "./trainersAdd.css";
import { Container, Row, Form, Button, Col } from "react-bootstrap";

function TrainerAdd() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [courseNamesData, setCourseNamesData] = useState([]);
  const [step, setStep] = useState(1);
  const [trainCategory, setTrainCategory] = useState([]);
  const [trainSubCategory, setTrainSubCategory] = useState([]);
  const [trainerData, setTrainerData] = useState({
    id: "",
    type: "",
    first_name: " ",
    last_name: "",
    name: "",
    website: "",
    photo_link: "",
    bio: "",
    yearsOfExperience: "",
    specialization: "",
    listing_image_url: "",
    listing_image_content: "",
    listing_image_extension: "",
    hero_image_url: "",
    hero_image_content: "",
    hero_image_extension: "",
    expertise: {},
    image_urls: {},
    audio_urls: {},
    training_demo_urls: {},
    video_urls: {},
    blog_urls: [{}],
    article_urls: {},
    achievements: [{}],
    awards: {},
    linkedin: "",
    facebook: "",
    instagram: "",
    twitter: "",
    featured: "",
    star_rating: "",
    course_type: "",
    category: "",
    subcategory: "",
    approvalStatus: "",
    comments: "",
    tags: {},
  });
  // Field Change
  const handleFieldChange = (fieldName, fieldValue) => {
    const newFromData = {
      ...trainerData,
      [fieldName]: fieldValue,
    };
    setTrainerData(newFromData);
    console.log(trainerData);
  };

  // Get course names
  const getCourseNames = () => {
    axios
      .get(`${Actions.BASE_URL}enums/courseNames`)
      .then((response) => {
        setCourseNamesData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Get category Type
  const getCategories = () => {
    axios
      .get(`${Actions.BASE_URL}enums/categoryTypes`)
      .then((response) => {
        setTrainCategory(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // Get sub category Type
  const getsubCategories = () => {
    axios
      .get(`${Actions.BASE_URL}enums/subcategoryTypes`)
      .then((response) => {
        setTrainSubCategory(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getCourseNames();
    getCategories();
    getsubCategories();
  }, []);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleFileSelect = (event, fileType) => {
    let file = event.target.files[0];
    getBase64(file).then((result) => {
      file["base64"] = result;
      console.log("File Is", file);

      const fileExtension = file.name.split(".").pop();

      const newState = {
        file,
      };

      if (fileType === "listing_image") {
        newState.listing_image_content = result;
        newState.listing_image_extension = fileExtension;
      } else if (fileType === "hero_image") {
        newState.hero_image_content = result;
        newState.hero_image_extension = fileExtension;
      }

      setTrainerData((prevState) => ({
        ...prevState,
        ...newState,
      }));
    });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result
          .replace("data:image/jpg;base64,", "")
          .replace("data:image/png;base64,", "")
          .replace("data:image/jpeg;base64,", "");
        resolve(baseURL);
      };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitLoading(true);
    const { first_name, last_name } = trainerData;
    const name = `${first_name} ${last_name}`;
    const data = {
      ...trainerData,
      name,
    };

    axios
      .post(`${Actions.BASE_URL}trainers`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSubmitLoading(false);
        window.location.href = `/trainers/`;
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.error(error.response.data);
        if (error.response.data.errors.newItem) {
          setErrorMessage(true);
        }
      });
  };

  return (
    <section className="trainersAddSection mt-5 pt-5">
      {submitLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}
      <Container fluid="lg">
        <Row>
          <Form action="" method="post" role="form">
            <h1 className="text-center">Add Trainer</h1>
            <div className="trainersAddHeader text-center mb-3 mb-lg-5">
              <p>Fill all form fields to go to the next step</p>
              <ul className="trainersList">
                <li
                  className={step === 1 ? "active" : ""}
                  onClick={() => setStep(1)}
                >
                  <span>1</span>
                </li>
                <li
                  className={step === 2 ? "active" : ""}
                  onClick={() => setStep(2)}
                >
                  <span>2</span>
                </li>
                <li
                  className={step === 3 ? "active" : ""}
                  onClick={() => setStep(3)}
                >
                  <span>3</span>
                </li>
                <li
                  className={step === 4 ? "active" : ""}
                  onClick={() => setStep(4)}
                >
                  <span>4</span>
                </li>
              </ul>
            </div>

            {step === 1 && (
              <fieldset className="wizard-fieldset show trainersAddForm">
                <div className="row m-0">
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="first_name"
                        name="first_name"
                        placeholder="First Name"
                        defaultValue={trainerData.first_name}
                        onChange={(e) =>
                          handleFieldChange("first_name", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="first_name">First Name</Form.Label>
                      <Form.Control
                        type="hidden"
                        className="form-control"
                        name="id"
                        defaultValue={trainerData.id}
                      ></Form.Control>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="last_name"
                        name="last_name"
                        placeholder="Last Name"
                        defaultValue={trainerData.last_name}
                        onChange={(e) =>
                          handleFieldChange("last_name", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="last_name">Last Name</Form.Label>
                    </div>
                  </Col>

                  <Col lg={6} className="form-group mb-3 imgBanner my-3">
                    <Form.Label
                      className="form-label file-upload-label"
                      htmlFor="profile"
                    >
                      <span className=" bannerBTn add-button">
                        Upload Profile Picture
                      </span>
                      <Form.Control
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        className="uploadbannerFile imgUploader it-placeholder mt-3"
                        name="profile"
                        id="profile"
                        required=""
                        onChange={(e) => handleFileSelect(e, "listing_image")}
                      />
                    </Form.Label>
                    <div className="imagePreview">
                      <span className="theme-text-2 d-block">
                        *Upload Your PNG/JPG/JPEG Image
                      </span>
                    </div>
                  </Col>
                  <Col lg={6} className="form-group mb-3 imgBanner my-3">
                    <Form.Label
                      className="form-label file-upload-label"
                      htmlFor="cover"
                    >
                      <span className="add-button bannerBTn">
                        Upload Cover Picture
                      </span>
                      <Form.Control
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        className="uploadMiniBannerFile imgUploader it-placeholder mt-3"
                        name="cover"
                        id="cover"
                        onChange={(e) => handleFileSelect(e, "hero_image")}
                      />
                    </Form.Label>
                    <div className="imagePreview banner2">
                      <span className="theme-text-2 d-block">
                        *Upload Your PNG/JPG/JPEG Image
                      </span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="email"
                        name="email"
                        placeholder="Email"
                        defaultValue={trainerData.email}
                        onChange={(e) =>
                          handleFieldChange("email", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="email">Email</Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                        defaultValue={trainerData.phone}
                        onChange={(e) =>
                          handleFieldChange("phone", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="phone">Phone</Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="whatsapp"
                        name="whatsapp"
                        placeholder="Whatsapp"
                        defaultValue={trainerData.whatsapp}
                        onChange={(e) =>
                          handleFieldChange("whatsapp", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="name">Whatsapp</Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Select
                        className="form-control fromcontrol"
                        id="approvalStatus"
                        name="approvalStatus"
                        placeholder="Website"
                        defaultValue={trainerData.approvalStatus}
                        onChange={(e) =>
                          handleFieldChange("approvalStatus", e.target.value)
                        }
                      >
                        <option value="">Approval Status</option>
                        <option value="Available">Available</option>
                        <option value="Not Available">Not Available</option>
                      </Form.Select>
                    </div>
                  </Col>
                  <div className="col mt-2">
                    <Button
                      type="button"
                      className=" next add-button"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </fieldset>
            )}
            {step === 2 && (
              <fieldset className="wizard-fieldset show trainersAddForm">
                <Row className="mx-0">
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="linkedin"
                        name="linkedin"
                        placeholder="LinkedIn"
                        defaultValue={trainerData.linkedin}
                        onChange={(e) =>
                          handleFieldChange("linkedin", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="linkedin">LinkedIn</Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="facebook"
                        name="facebook"
                        placeholder="facebook"
                        defaultValue={trainerData.facebook}
                        onChange={(e) =>
                          handleFieldChange("facebook", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="facebook">Facebook</Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="instagram"
                        name="instagram"
                        placeholder="Instagram"
                        defaultValue={trainerData.instagram}
                        onChange={(e) =>
                          handleFieldChange("instagram", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="instagram">Instagram</Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="twitter"
                        name="twitter"
                        placeholder="Twitter"
                        defaultValue={trainerData.twitter}
                        onChange={(e) =>
                          handleFieldChange("twitter", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="twitter">Twitter</Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="country"
                        name="country"
                        placeholder="Country"
                        defaultValue={trainerData.country}
                        onChange={(e) =>
                          handleFieldChange("country", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="country">Country</Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="state"
                        name="state"
                        placeholder="State"
                        defaultValue={trainerData.state}
                        onChange={(e) =>
                          handleFieldChange("state", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="state">State</Form.Label>
                    </div>
                  </Col>
                  <div className="col-lg-12 col-md-6 col-sm-12">
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="city"
                        name="city"
                        placeholder="City"
                        defaultValue={trainerData.city}
                        onChange={(e) =>
                          handleFieldChange("city", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="city">City</Form.Label>
                    </div>
                  </div>

                  <div className="col mt-2">
                    <Button
                      type="button"
                      className=" previous-button add-button"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="button"
                      className=" next add-button"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </div>
                </Row>
              </fieldset>
            )}
            {step === 3 && (
              <fieldset className="wizard-fieldset show trainersAddForm">
                <Row className="mx-0">
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <select
                        className="form-control fromcontrol form-select"
                        id="subcategory"
                        name="subcategory"
                        defaultValue={trainerData.subcategory}
                        onChange={(e) =>
                          handleFieldChange("subcategory", e.target.value)
                        }
                      >
                        <option value="">Training Level</option>
                        {trainSubCategory?.map((subcategory) => (
                          <option
                            key={subcategory.key}
                            value={subcategory.value}
                          >
                            {subcategory.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <select
                        className="form-control form-select fromcontrol"
                        id="category"
                        name="category"
                        onChange={(e) =>
                          handleFieldChange("category", e.target.value)
                        }
                      >
                        <option value="">Training Category</option>
                        {trainCategory?.map((category) => (
                          <option key={category.key} value={category.value}>
                            {category.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <select
                        id="course_type"
                        className="form-control form-select"
                        name="course_type"
                        defaultValue={trainerData.course_type}
                        onChange={(e) =>
                          handleFieldChange("course_type", e.target.value)
                        }
                      >
                        <option value="">Course</option>
                        {courseNamesData?.map((course) => (
                          <option key={course.key} value={course.value}>
                            {course.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="corporate_experience"
                        name="corporate_experience"
                        placeholder="Corporate Experience"
                        defaultValue={trainerData.corporate_experience}
                        onChange={(e) =>
                          handleFieldChange(
                            "corporate_experience",
                            e.target.value
                          )
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="corporate_experience">
                        Corporate Experience
                      </Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="yearsOfExperience"
                        name="yearsOfExperience"
                        placeholder="Years of Experience"
                        defaultValue={trainerData.yearsOfExperience}
                        onChange={(e) =>
                          handleFieldChange("yearsOfExperience", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="yearsOfExperience">
                        Years of Experiencce
                      </Form.Label>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="specialization"
                        name="specialization"
                        placeholder="Specialization"
                        defaultValue={trainerData.specialization}
                        onChange={(e) =>
                          handleFieldChange("specialization", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="specialization">
                        Specialization
                      </Form.Label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        as="textarea"
                        id="bio"
                        name="bio"
                        placeholder="Trainer Bio"
                        defaultValue={trainerData.bio}
                        onChange={(e) =>
                          handleFieldChange("bio", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="bio">Trainer Bio</Form.Label>
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <Button
                      type="button"
                      className=" previous-button add-button"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="button"
                      className=" next add-button"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}
            {step === 4 && (
              <fieldset className="wizard-fieldset show trainersAddForm">
                <Row className="mx-0">
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="institutes_associated"
                        name="institutes_associated"
                        placeholder="Institutes Associated"
                        defaultValue={trainerData.institutes_associated}
                        onChange={(e) =>
                          handleFieldChange(
                            "institutes_associated",
                            e.target.value
                          )
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="institutes_associates">
                        Institutes Associated
                      </Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id={`achievements${0}`}
                        name={`achievements${0}`}
                        placeholder="Achievements"
                        defaultValue={
                          trainerData.achievements["Achievements0"] || ""
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          setTrainerData((prevData) => ({
                            ...prevData,
                            achievements: { achievements: value },
                          }));
                        }}
                      ></Form.Control>
                      <Form.Label htmlFor="achievements">
                        Achievements
                      </Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="past_training_sessions"
                        name="past_training_sessions"
                        placeholder="Past Training Sessions"
                        defaultValue={trainerData.past_training_sessions}
                        onChange={(e) =>
                          handleFieldChange(
                            "past_training_sessions",
                            e.target.value
                          )
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="past_training_sessions">
                        Past Training Sessions
                      </Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="proposed_plan_for_training"
                        name="proposed_plan_for_training"
                        placeholder="Proposed Plan for Training"
                        defaultValue={trainerData.proposed_plan_for_training}
                        onChange={(e) =>
                          handleFieldChange(
                            "proposed_plan_for_training",
                            e.target.value
                          )
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="proposed_plan_for_training">
                        Proposed Plan for Training
                      </Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id="video_links"
                        name="video_links"
                        placeholder="Video Links"
                        defaultValue={trainerData.video_links}
                        onChange={(e) =>
                          handleFieldChange("video_links", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="proposed_plan_for_training">
                        Video Links
                      </Form.Label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        className="form-control fromcontrol"
                        id={`blog_urls${0}`}
                        name={`blog_urls${0}`}
                        placeholder="Achievements"
                        defaultValue={trainerData.blog_urls["url0"] || ""}
                        onChange={(e) => {
                          const { value } = e.target;
                          setTrainerData((prevData) => ({
                            ...prevData,
                            blog_urls: { blog_urls: value },
                          }));
                        }}
                      ></Form.Control>
                      <Form.Label htmlFor="blog_urls">
                        Blog/Article URL
                      </Form.Label>
                    </div>
                  </Col>
                  <div className="col-sm-12">
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        as="textarea"
                        className="form-control fromcontrol"
                        id="skillset_speciality"
                        name="skillset_speciality"
                        placeholder="Skillset/Speciality"
                        defaultValue={trainerData.skillset_speciality}
                        onChange={(e) =>
                          handleFieldChange(
                            "skillset_speciality",
                            e.target.value
                          )
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="skillset_speciality">
                        Skillset/Speciality
                      </Form.Label>
                    </div>
                  </div>
                  <Col lg={12}>
                    {errorMessage ? (
                      <p className="alert text-dark py-2 alert-danger">
                        *Please fill all the fields to continue.
                      </p>
                    ) : (
                      <p className="py-0 m-0 h-0 alert-danger opacity-0">
                        *Please fill all the fields to continue.
                      </p>
                    )}
                  </Col>
                  <Col lg={12} className="mt-2">
                    <Button
                      type="button"
                      className="previous-button add-button"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      className=" next add-button"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}
          </Form>
        </Row>
      </Container>
    </section>
  );
}

export default TrainerAdd;
