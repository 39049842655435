import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as Actions from "../../constants/baseUrl";

const myToken = sessionStorage.getItem("token");

// Create an async thunk for fetching data
export const fetchData = createAsyncThunk("config/fetchData", async () => {
  const response = await axios.get(`${Actions.BASE_URL}configs`, {
    headers: {
      Authorization: "Bearer " + myToken,
    },
  });

  return response && response?.data && response?.data
    
});

const initialState = {
  value: null,
  status: "idle", // or "loading", "succeeded", "failed"
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.value = action.payload;
      })
      .addCase(fetchData.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectConfig = (state) => state.config.value;

export default configSlice.reducer;
