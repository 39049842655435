export const mainTimeZones = [
  "Asia/Kolkata",
  "Asia/Karachi",
  "Europe/London",
  "America/New_York",
  "America/Los_Angeles",
  "America/Chicago",
  "Australia/Sydney",
  "Europe/Berlin",
  "Asia/Tokyo",
  "Africa/Johannesburg",
  "Europe/Paris",
  "America/Toronto",
  "Asia/Dubai",
  "America/Denver",
  "Asia/Hong_Kong",
  "America/Mexico_City",
  "Europe/Madrid",
  "Asia/Singapore",
  "America/Phoenix",
  "Australia/Perth",
  "Africa/Cairo",
  "Asia/Kuala_Lumpur",
];

export const years = [
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
  "2007",
  "2006",
  "2005",
  "2004",
  "2003",
  "2002",
  "2001",
  "2000",
  "1999",
  "1998",
  "1997",
  "1996",
  "1995",
  "1994",
  "1993",
  "1992",
  "1991",
  "1990",
  "1989",
  "1988",
  "1987",
  "1986",
  "1985",
  "1984",
  "1983",
  "1982",
  "1981",
  "1980",
  "1979",
  "1978",
  "1977",
  "1976",
  "1975",
  "1974",
  "1973",
  "1972",
  "1971",
  "1970",
  "1969",
  "1968",
  "1967",
  "1966",
  "1965",
  "1964",
  "1963",
  "1962",
  "1961",
  "1960",
  "1959",
  "1958",
  "1957",
  "1956",
  "1955",
  "1954",
  "1953",
  "1952",
  "1951",
];

export const countries = [
  "Afghanistan",
  "Aland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "British Virgin Islands",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo, (Kinshasa)",
  "Congo (Brazzaville)",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Federated States of Micronesia",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong, SAR China",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (North)",
  "Korea (South)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao PDR",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao, SAR China",
  "Macedonia, Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Réunion",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and Grenadines",
  "Saint-Barthélemy",
  "Saint-Martin (French part)",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen Islands",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic (Syria)",
  "Taiwan, Republic of China",
  "Tajikistan",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "US Minor Outlying Islands",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United Republic of Tanzania",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic)",
  "Vietnam",
  "Virgin Islands, US",
  "Wallis and Futuna Islands",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const industries = [
  "Agriculture",
  "Consumer Goods",
  "Defense",
  "Education",
  "Energy Utilities",
  "Environment Action",
  "Finance",
  "Gaming",
  "Healthcare",
  "Hospitality",
  "Industrial",
  "Insurance",
  "Legal",
  "Media Entertainment",
  "Non-profit",
  "Private Equity Venture Capital",
  "Professional Services",
  "Real Estate",
  "Sustainability",
  "Tax",
  "Technology",
  "Transportation and Logistics",
  "RE Tech",
  "Retail",
  "Others",
];

export const companyStage = [
  "Idea ",
  "Prototype",
  "Minimum Viable Product",
  "Pre-Revenue",
  "Pre-product",
  "Product Live - Pre-Revenue",
  "Early Revenue ",
  "Revenue Generating",
  "Growth ",
];

export const currencies = [
  "Australian Dollar",
  "Bermuda Dollar",
  "Brazilian Real",
  "British Pound",
  "Canadian Dollar",
  "Chinese Yuan Renminbi",
  "Colombian Peso",
  "Euro",
  "Hong Kong Dollar",
  "Indian Rupee",
  "United Arab Emirates Dirham",
  "Indonesian Rupiah",
  "Israeli New Shekel",
  "Japanese Yen",
  "Korean Won",
  "Malaysian Ringgit",
  "Mexican Peso",
  "New Zealand Dollar",
  "Qatari riyal",
  "Russian Ruble",
  "Singapore Dollar",
  "South African Rand",
  "Swiss Franc",
  "Thai Baht",
  "Turkish Lira",
  "US Dollar",
];

export const country_codes = [
  { value: "+93", label: "Afgha ... (+93)" },
  { value: "+358", label: "Aland ... (+358)" },
  { value: "+355", label: "Alban ... (+355)" },
  { value: "+213", label: "Alger ... (+213)" },
  { value: "+1684", label: "Ameri ... (+1684)" },
  { value: "+376", label: "Andor ... (+376)" },
  { value: "+244", label: "Angol ... (+244)" },
  { value: "+1264", label: "Angui ... (+1264)" },
  { value: "+672", label: "Antar ... (+672)" },
  { value: "+1268", label: "Antig ... (+1268)" },
  { value: "+54", label: "Argen ... (+54)" },
  { value: "+374", label: "Armen ... (+374)" },
  { value: "+297", label: "Aruba ... (+297)" },
  { value: "+61", label: "Austr ... (+61)" },
  { value: "+43", label: "Austr ... (+43)" },
  { value: "+994", label: "Azerb ... (+994)" },
  { value: "+1242", label: "Baham ... (+1242)" },
  { value: "+973", label: "Bahra ... (+973)" },
  { value: "+880", label: "Bangl ... (+880)" },
  { value: "+1246", label: "Barba ... (+1246)" },
  { value: "+375", label: "Belar ... (+375)" },
  { value: "+32", label: "Belgi ... (+32)" },
  { value: "+501", label: "Beliz ... (+501)" },
  { value: "+229", label: "Benin ... (+229)" },
  { value: "+1441", label: "Bermu ... (+1441)" },
  { value: "+975", label: "Bhuta ... (+975)" },
  { value: "+591", label: "Boliv ... (+591)" },
  { value: "+387", label: "Bosni ... (+387)" },
  { value: "+267", label: "Botsw ... (+267)" },
  { value: "+47", label: "Bouve ... (+47)" },
  { value: "+55", label: "Brazi ... (+55)" },
  { value: "+246", label: "Briti ... (+246)" },
  { value: "+1284", label: "Briti ... (+1284)" },
  { value: "+673", label: "Brune ... (+673)" },
  { value: "+359", label: "Bulga ... (+359)" },
  { value: "+226", label: "Burki ... (+226)" },
  { value: "+257", label: "Burun ... (+257)" },
  { value: "+855", label: "Cambo ... (+855)" },
  { value: "+237", label: "Camer ... (+237)" },
  { value: "+1", label: "Canad ... (+1)" },
  { value: "+238", label: "Cape  ... (+238)" },
  { value: "+ 345", label: "Cayma ... (+ 345)" },
  { value: "+236", label: "Centr ... (+236)" },
  { value: "+235", label: "Chad ... (+235)" },
  { value: "+56", label: "Chile ... (+56)" },
  { value: "+86", label: "China ... (+86)" },
  { value: "+61", label: "Chris ... (+61)" },
  { value: "+61", label: "Cocos ... (+61)" },
  { value: "+57", label: "Colom ... (+57)" },
  { value: "+269", label: "Comor ... (+269)" },
  { value: "+243", label: "Congo ... (+243)" },
  { value: "+242", label: "Congo ... (+242)" },
  { value: "+682", label: "Cook  ... (+682)" },
  { value: "+506", label: "Costa ... (+506)" },
  { value: "+385", label: "Croat ... (+385)" },
  { value: "+53", label: "Cuba ... (+53)" },
  { value: "+357", label: "Cypru ... (+357)" },
  { value: "+420", label: "Czech ... (+420)" },
  { value: "+225", label: "Côte  ... (+225)" },
  { value: "+45", label: "Denma ... (+45)" },
  { value: "+253", label: "Djibo ... (+253)" },
  { value: "+1767", label: "Domin ... (+1767)" },
  { value: "+1849", label: "Domin ... (+1849)" },
  { value: "+593", label: "Ecuad ... (+593)" },
  { value: "+20", label: "Egypt ... (+20)" },
  { value: "+503", label: "El Sa ... (+503)" },
  { value: "+240", label: "Equat ... (+240)" },
  { value: "+291", label: "Eritr ... (+291)" },
  { value: "+372", label: "Eston ... (+372)" },
  { value: "+251", label: "Ethio ... (+251)" },
  { value: "+500", label: "Falkl ... (+500)" },
  { value: "+298", label: "Faroe ... (+298)" },
  { value: "+691", label: "Feder ... (+691)" },
  { value: "+679", label: "Fiji ... (+679)" },
  { value: "+358", label: "Finla ... (+358)" },
  { value: "+33", label: "Franc ... (+33)" },
  { value: "+594", label: "Frenc ... (+594)" },
  { value: "+689", label: "Frenc ... (+689)" },
  { value: "+262", label: "Frenc ... (+262)" },
  { value: "+241", label: "Gabon ... (+241)" },
  { value: "+220", label: "Gambi ... (+220)" },
  { value: "+995", label: "Georg ... (+995)" },
  { value: "+49", label: "Germa ... (+49)" },
  { value: "+233", label: "Ghana ... (+233)" },
  { value: "+350", label: "Gibra ... (+350)" },
  { value: "+30", label: "Greec ... (+30)" },
  { value: "+299", label: "Green ... (+299)" },
  { value: "+1473", label: "Grena ... (+1473)" },
  { value: "+590", label: "Guade ... (+590)" },
  { value: "+1671", label: "Guam ... (+1671)" },
  { value: "+502", label: "Guate ... (+502)" },
  { value: "+44", label: "Guern ... (+44)" },
  { value: "+224", label: "Guine ... (+224)" },
  { value: "+245", label: "Guine ... (+245)" },
  { value: "+592", label: "Guyan ... (+592)" },
  { value: "+509", label: "Haiti ... (+509)" },
  { value: "+0", label: "Heard ... (+0)" },
  { value: "+379", label: "Holy  ... (+379)" },
  { value: "+504", label: "Hondu ... (+504)" },
  { value: "+852", label: "Hong  ... (+852)" },
  { value: "+36", label: "Hunga ... (+36)" },
  { value: "+354", label: "Icela ... (+354)" },
  { value: "+91", label: "India ... (+91)" },
  { value: "+62", label: "Indon ... (+62)" },
  { value: "+98", label: "Iran, ... (+98)" },
  { value: "+964", label: "Iraq ... (+964)" },
  { value: "+353", label: "Irela ... (+353)" },
  { value: "+44", label: "Isle  ... (+44)" },
  { value: "+972", label: "Israe ... (+972)" },
  { value: "+39", label: "Italy ... (+39)" },
  { value: "+1876", label: "Jamai ... (+1876)" },
  { value: "+81", label: "Japan ... (+81)" },
  { value: "+44", label: "Jerse ... (+44)" },
  { value: "+962", label: "Jorda ... (+962)" },
  { value: "+7", label: "Kazak ... (+7)" },
  { value: "+254", label: "Kenya ... (+254)" },
  { value: "+686", label: "Kirib ... (+686)" },
  { value: "+850", label: "Korea ... (+850)" },
  { value: "+82", label: "Korea ... (+82)" },
  { value: "+965", label: "Kuwai ... (+965)" },
  { value: "+996", label: "Kyrgy ... (+996)" },
  { value: "+856", label: "Lao P ... (+856)" },
  { value: "+371", label: "Latvi ... (+371)" },
  { value: "+961", label: "Leban ... (+961)" },
  { value: "+266", label: "Lesot ... (+266)" },
  { value: "+231", label: "Liber ... (+231)" },
  { value: "+218", label: "Libya ... (+218)" },
  { value: "+423", label: "Liech ... (+423)" },
  { value: "+370", label: "Lithu ... (+370)" },
  { value: "+352", label: "Luxem ... (+352)" },
  { value: "+853", label: "Macao ... (+853)" },
  { value: "+389", label: "Maced ... (+389)" },
  { value: "+261", label: "Madag ... (+261)" },
  { value: "+265", label: "Malaw ... (+265)" },
  { value: "+60", label: "Malay ... (+60)" },
  { value: "+960", label: "Maldi ... (+960)" },
  { value: "+223", label: "Mali ... (+223)" },
  { value: "+356", label: "Malta ... (+356)" },
  { value: "+692", label: "Marsh ... (+692)" },
  { value: "+596", label: "Marti ... (+596)" },
  { value: "+222", label: "Mauri ... (+222)" },
  { value: "+230", label: "Mauri ... (+230)" },
  { value: "+262", label: "Mayot ... (+262)" },
  { value: "+52", label: "Mexic ... (+52)" },
  { value: "+373", label: "Moldo ... (+373)" },
  { value: "+377", label: "Monac ... (+377)" },
  { value: "+976", label: "Mongo ... (+976)" },
  { value: "+382", label: "Monte ... (+382)" },
  { value: "+1664", label: "Monts ... (+1664)" },
  { value: "+212", label: "Moroc ... (+212)" },
  { value: "+258", label: "Mozam ... (+258)" },
  { value: "+95", label: "Myanm ... (+95)" },
  { value: "+264", label: "Namib ... (+264)" },
  { value: "+674", label: "Nauru ... (+674)" },
  { value: "+977", label: "Nepal ... (+977)" },
  { value: "+31", label: "Nethe ... (+31)" },
  { value: "+599", label: "Nethe ... (+599)" },
  { value: "+687", label: "New C ... (+687)" },
  { value: "+64", label: "New Z ... (+64)" },
  { value: "+505", label: "Nicar ... (+505)" },
  { value: "+227", label: "Niger ... (+227)" },
  { value: "+234", label: "Niger ... (+234)" },
  { value: "+683", label: "Niue ... (+683)" },
  { value: "+672", label: "Norfo ... (+672)" },
  { value: "+1670", label: "North ... (+1670)" },
  { value: "+47", label: "Norwa ... (+47)" },
  { value: "+968", label: "Oman ... (+968)" },
  { value: "+92", label: "Pakis ... (+92)" },
  { value: "+680", label: "Palau ... (+680)" },
  { value: "+970", label: "Pales ... (+970)" },
  { value: "+507", label: "Panam ... (+507)" },
  { value: "+675", label: "Papua ... (+675)" },
  { value: "+595", label: "Parag ... (+595)" },
  { value: "+51", label: "Peru ... (+51)" },
  { value: "+63", label: "Phili ... (+63)" },
  { value: "+64", label: "Pitca ... (+64)" },
  { value: "+48", label: "Polan ... (+48)" },
  { value: "+1939", label: "Puert ... (+1939)" },
  { value: "+974", label: "Qatar ... (+974)" },
  { value: "+40", label: "Roman ... (+40)" },
  { value: "+7", label: "Russi ... (+7)" },
  { value: "+250", label: "Rwand ... (+250)" },
  { value: "+262", label: "Réuni ... (+262)" },
  { value: "+290", label: "Saint ... (+290)" },
  { value: "+1869", label: "Saint ... (+1869)" },
  { value: "+1758", label: "Saint ... (+1758)" },
  { value: "+508", label: "Saint ... (+508)" },
  { value: "+1784", label: "Saint ... (+1784)" },
  { value: "+590", label: "Saint ... (+590)" },
  { value: "+590", label: "Saint ... (+590)" },
  { value: "+685", label: "Samoa ... (+685)" },
  { value: "+378", label: "San M ... (+378)" },
  { value: "+239", label: "Sao T ... (+239)" },
  { value: "+966", label: "Saudi ... (+966)" },
  { value: "+221", label: "Seneg ... (+221)" },
  { value: "+381", label: "Serbi ... (+381)" },
  { value: "+248", label: "Seych ... (+248)" },
  { value: "+232", label: "Sierr ... (+232)" },
  { value: "+65", label: "Singa ... (+65)" },
  { value: "+421", label: "Slova ... (+421)" },
  { value: "+386", label: "Slove ... (+386)" },
  { value: "+677", label: "Solom ... (+677)" },
  { value: "+252", label: "Somal ... (+252)" },
  { value: "+27", label: "South ... (+27)" },
  { value: "+500", label: "South ... (+500)" },
  { value: "+211", label: "South ... (+211)" },
  { value: "+34", label: "Spain ... (+34)" },
  { value: "+94", label: "Sri L ... (+94)" },
  { value: "+249", label: "Sudan ... (+249)" },
  { value: "+597", label: "Surin ... (+597)" },
  { value: "+47", label: "Svalb ... (+47)" },
  { value: "+268", label: "Swazi ... (+268)" },
  { value: "+46", label: "Swede ... (+46)" },
  { value: "+41", label: "Switz ... (+41)" },
  { value: "+963", label: "Syria ... (+963)" },
  { value: "+886", label: "Taiwa ... (+886)" },
  { value: "+992", label: "Tajik ... (+992)" },
  { value: "+66", label: "Thail ... (+66)" },
  { value: "+670", label: "Timor ... (+670)" },
  { value: "+228", label: "Togo ... (+228)" },
  { value: "+690", label: "Tokel ... (+690)" },
  { value: "+676", label: "Tonga ... (+676)" },
  { value: "+1868", label: "Trini ... (+1868)" },
  { value: "+216", label: "Tunis ... (+216)" },
  { value: "+90", label: "Turke ... (+90)" },
  { value: "+993", label: "Turkm ... (+993)" },
  { value: "+1649", label: "Turks ... (+1649)" },
  { value: "+688", label: "Tuval ... (+688)" },
  { value: "581", label: "US Mi ... (581)" },
  { value: "+256", label: "Ugand ... (+256)" },
  { value: "+380", label: "Ukrai ... (+380)" },
  { value: "+971", label: "Unite ... (+971)" },
  { value: "+44", label: "Unite ... (+44)" },
  { value: "+255", label: "Unite ... (+255)" },
  { value: "+1", label: "Unite ... (+1)" },
  { value: "+598", label: "Urugu ... (+598)" },
  { value: "+998", label: "Uzbek ... (+998)" },
  { value: "+678", label: "Vanua ... (+678)" },
  { value: "+58", label: "Venez ... (+58)" },
  { value: "+84", label: "Vietn ... (+84)" },
  { value: "+1340", label: "Virgi ... (+1340)" },
  { value: "+681", label: "Walli ... (+681)" },
  { value: "732", label: "Weste ... (732)" },
  { value: "+967", label: "Yemen ... (+967)" },
  { value: "+260", label: "Zambi ... (+260)" },
  { value: "+263", label: "Zimba ... (+263)" },
];

// ---- speakers

export const chosenCountries = [
  "India",
  "USA",
  "Canada",
  "UK",
  "Australia",
  "New Zealand",
  "Singapore",
  "Malaysia",
  "Gulf Countries",
  "European Country",
  "Other",
];

export const chosenCities = [
  "Delhi",
  "Hyderabad",
  "Bangalore",
  "Chennai",
  "Chandigarh",
  "Kochi",
  "Kolkata",
  "Pune",
  "Gurgaon",
  "Noida",
  "Mysore",
  "Jaipur",
  "Lucknow",
  "Bhopal",
  "Varanasi",
  "Guwahati",
  "Ahmedabad",
  "Surat",
  "Shimla",
  "Bhubaneswar",
  "Indore",
  "Others - Add in comments",
];

export const interest = [
  "Not interested in Equity based compensation",
  "Looking for upto 0.5% Equity based compensation for (250hrs in 6months) contribution",
  "Looking for upto 1% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 2% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 3% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 4% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 5% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 6% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 7% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 8% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 9% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 8% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 10% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 10-15% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 15-20% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 25% Equity based compensation for (1000hrs or 6months) contribution",
  "Looking for upto 25-50% Equity based compensation for (1000hrs or 6months) contribution",
  "Interested in Equity - Let us talk ",
  "Others",
];
