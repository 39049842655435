// DynamicOptionsFieldset.js
import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import "./fieldset.css";

const DynamicOptionsFieldset = ({
  fields,
  setFields,
  index,
  label,
  ColNum = 6,
}) => {
  const handleFieldRemove = () => {
    setFields((prevFields) => {
      const updatedFields = {};
      Object.keys(prevFields)
        .sort((a, b) => Number(a) - Number(b))
        .filter((key) => key !== index)
        .forEach((key, idx) => {
          updatedFields[idx] = prevFields[key];
        });
      return updatedFields;
    });
  };

  const handleInputChange = (value) => {
    setFields((prevFields) => ({
      ...prevFields,
      [index]: value,
    }));
  };

  return (
    <Col md={ColNum} className="d-flex align-items-center mb-3 fieldset">
      <Form.Group className="form-floating flex-grow-1 pe-2">
        <Form.Control
          type="text"
          id={`Option${index + 1}`}
          placeholder="Enter something"
          value={fields[index] || ""}
          onChange={(e) => handleInputChange(e.target.value)}
          className="form-control italicInput"
        />
        <Form.Label
          className="form-label text-capitalize"
          htmlFor={`Option${index}`}
        >
          {label
            ? `${label} ${Number(index) + 1}`
            : `Option ${Number(index) + 1}`}
        </Form.Label>
      </Form.Group>
      <Button
        className="clickable actionDltBtn btn border-danger"
        onClick={handleFieldRemove}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </Col>
  );
};

export default DynamicOptionsFieldset;
