import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { countries, country_codes } from "../../constants/extras";
import UploadFile from "../../helper/UploadFile";
import BannerUploaderEdit from "../bannerUploaderEdit/bannerUploaderEdit";
import DynamicRelationFieldEdit from "../dynamicRelationFieldSet/dynamicRelationFieldEdit";

const MemberAddPopup = (props) => {
  const [memberData, setMemberData] = useState({});
  const [socialLinks, setSocialLinks] = useState([]);
  // check values
  const [hasValue, setHasValue] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...memberData,
      [fieldName]: fieldValue,
    };
    setMemberData(newState);
  };

  const modalFields = [
    "name",
    "title",
    "gender",
    "country",
    "email",
    "country_code",
    "phone",
    "description",
  ];

  const areFieldsFilled = (fieldNames, memberData) => {
    return fieldNames.every((fieldName) => memberData[fieldName]);
  };

  const isModalFieldsFilled = areFieldsFilled(modalFields, memberData);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isModalFieldsFilled) {
      setHasValue(false);
      setIsValid(true);

      if (props.memberData && props.memberData.id) {
        const members = props.state.members.map((member) => {
          if (member.id === props.memberData.id) {
            return { ...memberData, id: member.id };
          }
          return member;
        });

        props.setState({
          ...props.state,
          members,
        });
      } else {
        props.setState({
          ...props.state,
          members: props.state.members
            ? props.state.members.concat([
                { ...memberData, id: uuidv4(), social_links: socialLinks },
              ])
            : [{ ...memberData, id: uuidv4(), social_links: socialLinks }],
        });
      }
      onHide();
      props.onHide();
    } else {
      setHasValue(true);
      setIsValid(true);
    }
  };

  const onHide = () => {
    props.setMemberData({});
    setMemberData({});
    props.onHide();
  };

  useEffect(() => {
    setMemberData({ ...props.memberData });
    setSocialLinks(props.memberData.social_links || [{ type: "", value: "" }]);
  }, [props.memberData]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-startup-popup"
      onHide={onHide}
      show={props.show}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-capitalize h5"
        >
          Add new member
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={6} className="list-img-wrap position-relative">
              <BannerUploaderEdit
                className="item-list-img position-static"
                key={memberData.profile_image_url}
                label="Member Profile"
                accept="image/png, image/jpg, image/jpeg"
                imageType="listing_image"
                placehold={
                  memberData.profile_image_url ||
                  "https://placehold.co/280x180/FFFFFF/DDDDDD.png"
                }
                state={memberData}
                setState={(data) => {
                  UploadFile(
                    memberData.userId,
                    "profile_image",
                    data?.listing_image_content,
                    data?.listing_image_extension,
                    props.setReqLoading
                  ).then((url) => {
                    setMemberData({
                      ...memberData,
                      profile_image_url: url,
                      listing_image_content: "",
                    });
                  });
                }}
                imageURL={memberData.profile_image_url}
                imageContent={memberData.listing_image_content}
                imageExtension={memberData.listing_image_extension}
              />
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={12} md={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      placeholder="NAME"
                      onChange={(e) => {
                        handleFieldChange("name", e.target.value);
                      }}
                      value={memberData.name || ""}
                      className={
                        isValid && hasValue && !memberData.name
                          ? "border-danger"
                          : ""
                      }
                    />
                    <Form.Label>Full Name</Form.Label>
                  </Form.Group>
                </Col>

                <Col lg={12} md={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      placeholder="TITLE"
                      onChange={(e) => {
                        handleFieldChange("title", e.target.value);
                      }}
                      required
                      value={memberData.title || ""}
                      className={
                        isValid && hasValue && !memberData.title
                          ? "border-danger"
                          : ""
                      }
                    />
                    <Form.Label>Enter Title</Form.Label>
                  </Form.Group>
                </Col>
                <Col lg={12} md={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Select
                      className={`form-control ${
                        isValid && hasValue && !memberData.gender
                          ? "border-danger"
                          : ""
                      }`}
                      onChange={(e) => {
                        handleFieldChange("gender", e.target.value);
                      }}
                      value={memberData.gender}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                      <option value="not specified">Prefer not to say</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Select
                  id="country"
                  className={`form-control text-capitalize ${
                    isValid && hasValue && !memberData.country
                      ? "border-danger"
                      : ""
                  }`}
                  required
                  value={memberData.country || ""}
                  name="country"
                  onChange={(e) => handleFieldChange("country", e.target.value)}
                >
                  <option value="">country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  onChange={(e) => {
                    handleFieldChange("email", e.target.value);
                  }}
                  required
                  value={memberData.email || ""}
                  className={
                    isValid && hasValue && !memberData.email
                      ? "border-danger"
                      : ""
                  }
                />
                <Form.Label>Enter Email</Form.Label>
              </Form.Group>
            </Col>

            <Col lg={12}>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Row>
                <Col lg={3} md={4} sm={5}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Select
                      id="country_code"
                      className={`form-control text-capitalize ${
                        isValid && hasValue && !memberData.country_code
                          ? "border-danger"
                          : ""
                      }`}
                      required
                      value={memberData.country_code || ""}
                      name="country_code"
                      onChange={(e) =>
                        handleFieldChange("country_code", e.target.value)
                      }
                    >
                      <option value="">Country Code</option>
                      {country_codes.map((country_code, index) => (
                        <option key={index} value={country_code.value}>
                          {country_code.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col lg={9} md={8} sm={7}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="phone"
                      placeholder="Phone"
                      onChange={(e) => {
                        handleFieldChange("phone", e.target.value);
                      }}
                      value={memberData.phone || ""}
                      className={
                        isValid && hasValue && !memberData.phone
                          ? "border-danger"
                          : ""
                      }
                      disabled={!memberData.country_code}
                    />
                    <Form.Label>Enter Phone</Form.Label>
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col md={12}>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  as={"textarea"}
                  rows={4}
                  className={
                    isValid && hasValue && !memberData.description
                      ? "border-danger"
                      : ""
                  }
                  id="description"
                  required
                  value={memberData.description || ""}
                  placeholder="description"
                  name="description"
                  onChange={(e) =>
                    handleFieldChange("description", e.target.value)
                  }
                />
                <Form.Label className="formLabel" htmlFor="description">
                  Tell us more about this team member
                </Form.Label>
              </Form.Group>
            </Col>

            <hr />
            <Col md={12} className="d-flex justify-content-between gap-3">
              <h6 className="theme-text-2 flex-grow-1">
                Connect social accounts
              </h6>
              <Button
                className="clickable add-button mb-3 ms-auto d-flex"
                onClick={() => {
                  const newField = { type: "", value: "" };
                  setSocialLinks([...socialLinks, newField]);
                }}
              >
                <span className="addBttn d-block">+</span>
              </Button>
            </Col>
            <Col lg={12}>
              {socialLinks?.map((field, index) => (
                <DynamicRelationFieldEdit
                  key={index}
                  fields={socialLinks}
                  field={field}
                  index={index}
                  onFieldChange={(newFields) => {
                    setSocialLinks(newFields);
                    handleFieldChange("social_links", newFields);
                  }}
                  fieldLabels={{
                    valueInput: "Social Link",
                  }}
                  selectOptions={[
                    { value: "facebook", label: "Facebook" },
                    { value: "instagram", label: "Instagram" },
                    { value: "twitter", label: "Twitter" },
                    { value: "linkedIn", label: "LinkedIn" },
                    { value: "website", label: "Website" },
                    { value: "others", label: "Others" },
                  ]}
                />
              ))}
            </Col>
          </Row>
          <Button className="btn add-button float-end mt-3 px-4" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MemberAddPopup;
