import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import UploadFile from "../../../helper/UploadFile";
import BannerUploaderEdit from "../../bannerUploaderEdit/bannerUploaderEdit";
import TokenDetails from "../../useUserDetails/useTokenDetails";

const DynamicEntityBox = ({ field, setFields, onRemove, setLoading }) => {
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [formData, setFormData] = useState(field);

  useEffect(() => {
    setFormData(field);
  }, [field]);

  const handleInputChange = (fieldName, value) => {
    const updatedData = { ...formData, [fieldName]: value };
    setFormData(updatedData);
    setFields((prevFields) => {
      return prevFields.map((item) =>
        item.id === field.id ? updatedData : item
      );
    });
  };

  const handleImageUpload = (data) => {
    UploadFile(
      myUserId,
      "listing_image",
      data?.listing_image_content,
      data?.listing_image_extension,
      setLoading
    ).then((url) => {
      handleInputChange("listing_image_content", "");
      handleInputChange("listing_image_url", url);
    });
  };

  return (
    <div className="d-flex align-items-start flex-row gap-2 w-100 mb-3">
      <Row className="mx-0 card flex-row flex-grow-1">
        <Col md={12} className="d-flex justify-content-end">
          <Form.Group className="d-flex align-items-start">
            <Form.Check
              className="ps-0 custom-checkbox"
              type="checkbox"
              name="show"
              id={`show-${field.id}`}
              label={" "}
              checked={formData?.show || false}
              onChange={(e) => handleInputChange("show", e.target.checked)}
            />
            <Form.Label htmlFor={`show-${field.id}`} className="mt-3">
              Show in details page
            </Form.Label>
          </Form.Group>
        </Col>

        <Col md={6} className="position-relative mx-0 pt-1">
          {formData?.listing_image_url || formData?.listing_image_content ? (
            <Button
              className="deleteBtn p-1 btn-danger text-white bg-danger h-auto w-auto position-absolute top-0 end-0 mt-2 me-3 z-3"
              onClick={() => {
                handleInputChange("listing_image_url", "");
                handleInputChange("listing_image_content", "");
              }}
            >
              <Trash />
            </Button>
          ) : null}
          <BannerUploaderEdit
            key={`${field.id}-banner-uploader`}
            id={field?.id}
            label="Icon"
            accept="image/png, image/jpg, image/jpeg"
            imageType="listing_image"
            placehold="https://placehold.co/280x180"
            state={formData}
            setState={handleImageUpload}
            imageURL={formData?.listing_image_url}
            imageContent={formData?.listing_image_content}
            imageExtension={formData?.listing_image_extension}
          />
        </Col>
        <Col md={6} className="mx-0 pt-2 mt-1">
          <Form.Group className="form-floating flex-grow-1 pe-2 mb-3">
            <Form.Control
              type="text"
              id={`title-${field.id}`}
              placeholder="Enter title"
              value={formData?.title || ""}
              onChange={(e) => handleInputChange("title", e.target.value)}
              className="form-control italicInput"
            />
            <Form.Label
              className="form-label text-capitalize"
              htmlFor={`title-${field.id}`}
            >
              Title
            </Form.Label>
          </Form.Group>
          <Form.Group className="form-floating flex-grow-1 pe-2 mb-1">
            <Form.Control
              type="text"
              id={`path-${field.id}`}
              placeholder="Enter path"
              value={formData?.path || ""}
              onChange={(e) => handleInputChange("path", e.target.value)}
              className="form-control italicInput"
            />
            <Form.Label
              className="form-label text-capitalize"
              htmlFor={`path-${field.id}`}
            >
              Link
            </Form.Label>
          </Form.Group>

          <Form.Group className="d-flex align-items-start mb-2">
            <Form.Check
              className="ps-0 custom-checkbox"
              type="checkbox"
              name="collect_data"
              id={`collect_data-${field.id}`}
              label={" "}
              checked={formData?.collect_data || false}
              onChange={(e) => handleInputChange("collect_data", e.target.checked)}
            />
            <Form.Label htmlFor={`collect_data-${field.id}`} className="mt-3">
              Collect user data before redirecting to link through popup
            </Form.Label>
          </Form.Group>

          <Form.Group className="form-floating flex-grow-1 pe-2 mb-3">
            <Form.Control
              type="text"
              disabled={!formData?.collect_data}
              id={`buttonText-${field.id}`}
              placeholder="Enter buttonText"
              value={formData?.buttonText || "Join Now"}
              onChange={(e) => handleInputChange("buttonText", e.target.value)}
            />
            <Form.Label
              className="form-label text-capitalize"
              htmlFor={`buttonText-${field.id}`}
            >
              Popup Trigger Button Text
            </Form.Label>
          </Form.Group>

        </Col>
        <Col md={12}>
          <Form.Group className="form-floating flex-grow-1 pe-2 mb-3">
            <Form.Control
              as='textarea'
              style={{ height: 'auto' }}
              rows={1}
              id={`description-${field.id}`}
              placeholder="Enter description"
              value={formData?.description || ""}
              onChange={(e) => handleInputChange("description", e.target.value)}
              className="form-control italicInput"
            />
            <Form.Label
              className="form-label text-capitalize"
              htmlFor={`description-${field.id}`}
            >
              Description
            </Form.Label>
          </Form.Group></Col>
      </Row>
      <Button
        className="clickable actionDltBtn btn border-danger rounded-3 py-1 px-2"
        onClick={() => onRemove(field.id)}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default DynamicEntityBox;
