import React from "react";
import { Check2Circle, XCircle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";

export const SuccessAlert = (message, onSuccess) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
          <Check2Circle className="display-1 theme-text" />
          <h5 className="theme-text-2 text-center mt-2 text-capitalize">
            {message}
          </h5>
          <button
            className="btn add-button mt-2"
            onClick={() => {
              onClose();
            }}
          >
            Done
          </button>
        </div>
      );
    },
  });
};
export const ErrorAlert = (message, onSuccess) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
          <XCircle className="display-1 text-danger" />
          <h5 className="theme-text-2 text-center mt-2 text-capitalize">
            {message}
          </h5>
          <button
            className="btn add-button mt-2"
            onClick={() => {
              onClose();
            }}
          >
            Done
          </button>
        </div>
      );
    },
  });
};
