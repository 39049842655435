import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Modal, Row } from "react-bootstrap";
import userImg from "../../img/user.png";
import TextFormatter from "../textFormat/textFormat";
import { Globe } from "react-bootstrap-icons";

const CustomerDetailsPopup = (props) => {
  const [customerData, setCustomerData] = useState(props.customerData);

  const onHide = () => {
    props.setCustomerData({});
    setCustomerData({});
    props.onHide();
  };

  useEffect(() => {
    setCustomerData({ ...props.customerData });
  }, [props.customerData]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-startup-popup"
      onHide={onHide}
      show={props.show}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-capitalize h5"
        >
          {customerData.type} Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={3} className="list-img-wrap position-relative min-h-full">
            <Card>
              <Image
                className="modal-profile-img position-static rounded"
                src={customerData?.profile_image_url || userImg}
                alt="image"
                width={"100%"}
              />
            </Card>
            <Card className="mt-2 p-3">
              <div className="social d-flex gap-2 flex-wrap align-items-center justify-content-center pt-2">
                {customerData.website && (
                  <a
                    target="_blank"
                    href={`${customerData.website}`}
                    rel="noreferrer"
                  >
                    <Globe />
                  </a>
                )}
              </div>
            </Card>
          </Col>
          <Col lg={9} className="min-h-full">
            <Card className="row h-100">
              <Card.Body>
                <Col lg={12} md={6} className="row mb-3">
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold text-capitalize">
                      name
                    </Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                      {customerData?.name ?? "Not Available"}
                    </Card.Text>
                  </Col>
                </Col>
                <hr />
                <Col lg={12} md={6} className="row mb-3">
                  <Col md={3}>
                    <Card.Text className="mb-0 fw-bold text-capitalize">
                      Website
                    </Card.Text>
                  </Col>
                  <Col md={9}>
                    <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                      <a
                        target="_blank"
                        href={`${customerData.website}`}
                        rel="noreferrer"
                        className="text-muted text-truncate d-inline-block w-100 text-overflow-desc-xs"
                      >
                        {customerData.website}
                      </a>
                    </Card.Text>
                  </Col>
                </Col>
                <hr />
                <Col lg={12}>
                  <Card.Text className="mb-0 fw-bold text-capitalize">
                    description
                  </Card.Text>
                </Col>
                <Col md={12}>
                  <Card.Text className="text-muted mb-0 text-wrap">
                    {customerData?.description ? (
                      <TextFormatter text={customerData?.description} />
                    ) : (
                      "Not Available"
                    )}
                  </Card.Text>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Button className="btn add-button float-end mt-3 px-4" onClick={onHide}>
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerDetailsPopup;
