import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";

const DynamicRelationFieldEdit = ({
  fields,
  field,
  index,
  onFieldChange,
  fieldLabels,
  selectOptions,
}) => {
  const [type, setType] = useState(Object.keys(field)[0]);
  const [value, setValue] = useState(field[type]);

  const handleTypeChange = (newType) => {
    setType(newType);
    const updatedFields = [...fields];
    updatedFields[index] = { [type]: value };
    onFieldChange(updatedFields);
  };

  const handleValueChange = (newValue) => {
    setValue(newValue);
    const updatedFields = [...fields];
    updatedFields[index] = { [type]: value };
    onFieldChange(updatedFields);
  };

  const onFieldRemove = () => {
    const updatedFields = fields.filter((_, i) => i !== index);
    onFieldChange(updatedFields);
  };

  return (
    <div className="d-flex gap-3 w-100 align-items-center mb-3">
      <div className="flex-grow-1">
        <Form.Group className="form-floating formfloating">
          <Form.Select
            value={type}
            onChange={(e) => handleTypeChange(e.target.value)}
            className="form-control form-select fromcontrol text-capitalize"
          >
            <option value="">Select Type</option>
            {selectOptions?.map((option) => (
              <option
                className="text-capitalize"
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
      <div className="flex-grow-1">
        <Form.Group className="form-floating">
          <Form.Control
            type="text"
            placeholder={fieldLabels?.valueInput}
            value={value}
            onChange={(e) => handleValueChange(e.target.value)}
            className="form-control italicInput"
          />
          <label className="form-label">{fieldLabels?.valueInput}</label>
        </Form.Group>
      </div>

      <Button
        className="clickable actionDltBtn btn border-danger"
        onClick={onFieldRemove}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default DynamicRelationFieldEdit;
