import React from "react";
import { StarFill, StarHalf, Star } from "react-bootstrap-icons";

function StarRating({ rating, countTag }) {
  const maxRating = 5; // Maximum rating value
  const filledStars = Math.floor(rating); // Number of filled stars
  const hasHalfStar = rating - filledStars >= 0.5; // Check for half star

  const starElements = [];

  for (let i = 0; i < filledStars; i++) {
    starElements.push(
      <div key={i} className="star">
        <StarFill />
      </div>
    );
  }

  if (hasHalfStar) {
    starElements.push(
      <div key="half" className="star">
        <StarHalf />
      </div>
    );
  }

  for (let i = starElements.length; i < maxRating; i++) {
    starElements.push(
      <div key={`empty-${i}`} className="star">
        <Star />
      </div>
    );
  }

  return (
    <div className="d-flex gap-2 stars align-items-end">
      {starElements}
      {countTag && (
        <p className="mb-0">
          (
          {rating == null
            ? 0
            : rating == "*****"
            ? 0
            : rating == "****"
            ? 0
            : rating == "***"
            ? 0
            : rating == "**"
            ? 0
            : rating == "*"
            ? 0
            : rating}
          )
        </p>
      )}
    </div>
  );
}

export default StarRating;
