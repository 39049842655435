import { v4 as uuidv4 } from "uuid";
import API from "../components/API/API";
import { toast } from "react-toastify";

const UploadFile = async (
  userId,
  fileName,
  fileContent,
  fileExtension,
  setLoading
) => {
  if (!fileContent) return null;
  setLoading(true);
  try {
    const data = {
      userId,
      type: "upload",
      files: [
        {
          id: uuidv4(),
          file_content: fileContent,
          file_extension: fileExtension,
          file_name: fileName,
        },
      ],
    };

    const response = await API("post", "upload/files", data);

    if (response.files && response.files.length > 0) {
      const { url } = response.files[0];
      return url;
    } else {
      throw new Error("No file uploaded.");
    }
  } catch (error) {
    toast.error("Could not upload file. Please try again.", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      draggable: true,
    });
    console.log(error);
    return null;
  } finally {
    setLoading(false);
  }
};

export default UploadFile;
