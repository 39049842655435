const GeneratedEmail = () => {
  const email = localStorage.getItem("generatedEmail");

  return (
    <div className="py-5 mt-5">
      <div
        className="generated-temp"
        dangerouslySetInnerHTML={{ __html: email || "" }}
      ></div>
    </div>
  );
};

export default GeneratedEmail;
