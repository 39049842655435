const EmailAcceleratorRegisterBody = (details) => {
  const htmlContent = `
        <table width="600px" style="max-width: 600px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
        align="center" style="text-wrap: balance;">
        <tr>
            <td height="24"></td>
        </tr>
        <!-- Main Message -->
        <tr>
            <td>
                <span style="font-weight: bold; font-size: 16px; font-family: 'Unbounded', sans-serif;">
                    Hello,
                </span>
            </td>
        </tr>
        <tr>
            <td height="6"></td>
        </tr>
        <tr>
            <td width="100%" style="line-height: 1.3;">
                <span style="font-size: 12px; font-family: 'Poppins', sans-serif;">
                  ${details?.message_for_team}
                </span>
            </td>
        </tr>
        <tr>
            <td height="30"></td>
        </tr>
    </table>
    <!-- User Details -->
    <table width="600px" style="max-width: 600px; width: 100%; border-radius: 4px;" border="0"
        cellspacing="0" cellpadding="0" align="center" bgcolor="#e7eeee">
        <tr>
            <td height="12"></td>
        </tr>
        <tr>
            <td width="12"></td>
            <td>
                <table width="574px"
                    style="max-width: 574px; width: 100%; font-size: 12px; font-family: 'Poppins', sans-serif;"
                    border="0" cellspacing="0" cellpadding="0" align="center">
                    <tr>
                        <td>
                            <span>
                                <strong>Name:</strong>&nbsp; ${
                                  details?.first_name
                                } ${details?.last_name}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td height="6"></td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <strong>Email Address:</strong>&nbsp; ${
                                  details?.email
                                } 
                            </span>
                        </td>
                    </tr> 
                    ${
                      details?.phone
                        ? `
                        <tr>
                             <td height="6"></td>
                         </tr> 
                         <tr>
                              <td>
                                 <span>
                                      <strong>Phone No:</strong>&nbsp; ${details?.phone}
                                </span>
                              </td>
                          </tr>
                           `
                        : ""
                    }
                    ${
                      details?.linked_in
                        ? `
                        <tr>
                             <td height="6"></td>
                         </tr> 
                         <tr>
                              <td>
                                 <span>
                                      <strong>LinkedIn Profile:</strong>&nbsp; <a href="${details?.linked_in}" target="_blank">${details?.linked_in}</a>
                                </span>
                              </td>
                          </tr>
                           `
                        : ""
                    }
                    
                    ${
                      details?.interestedUniversities
                        ? `
                        <tr>
                             <td height="6"></td>
                         </tr> 
                         <tr>
                              <td>
                                 <span>
                                      <strong>I'm interested in attending Venture University because:</strong>&nbsp; <br/> ${details?.interestedUniversities.map(
                                        (item) => `● ${item} <br/>`
                                      )}
                                </span>
                              </td>
                          </tr>
                           `
                        : ""
                    }
                    ${
                      details?.programType
                        ? `
                        <tr>
                             <td height="6"></td>
                         </tr> 
                         <tr>
                              <td>
                                 <span>
                                      <strong>Which Venture University program are you applying for:</strong>&nbsp;  <br/> ● ${details?.programType}
                                </span>
                              </td>
                          </tr>
                           `
                        : ""
                    }
                    ${
                      details?.educationPrograms
                        ? `
                          <tr>
                               <td height="6"></td>
                           </tr> 
                           <tr>
                                <td>
                                   <span>
                                        <strong>Programs:</strong>&nbsp; <br/> ${details?.educationPrograms.map(
                                          (item) => `● ${item} <br/>`
                                        )}
                                  </span>
                                </td>
                            </tr>
                             `
                        : ""
                    }
                    ${
                      details?.acceleratorsDuration
                        ? `
                        <tr>
                             <td height="6"></td>
                         </tr> 
                         <tr>
                              <td>
                                 <span>
                                      <strong> How many quarters are you applying for:</strong>&nbsp; <br/> ● ${details?.acceleratorsDuration}
                                </span>
                              </td>
                          </tr>
                           `
                        : ""
                    }
                    ${
                      details?.programPeriod
                        ? `
                            <tr>
                                 <td height="6"></td>
                             </tr> 
                             <tr>
                                  <td>
                                     <span>
                                          <strong> Which program periods are you applying for:</strong>&nbsp; <br/> ${details?.programPeriod.map(
                                            (item) => `● ${item} <br/>`
                                          )}
                                    </span>
                                  </td>
                              </tr>
                               `
                        : ""
                    }
                    ${
                      details?.earlyAdmissions
                        ? `
                    <tr>
                        <td height="6"></td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <strong>Early Admissions:</strong>&nbsp; <br/> ●  ${details?.earlyAdmissions}
                            </span>
                        </td>
                    </tr>
                    `
                        : ""
                    }
                    ${
                      details?.location
                        ? `
                    <tr>
                        <td height="6"></td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <strong> Where are you located:</strong>&nbsp; ${details?.location}
                            </span>
                        </td>
                    </tr>
                    `
                        : ""
                    }
                    ${
                      details?.socialPlatform
                        ? `
                            <tr>
                                 <td height="6"></td>
                             </tr> 
                             <tr>
                                  <td>
                                     <span>
                                          <strong>How did you learn about VU:</strong>&nbsp; <br/> ${details?.socialPlatform.map(
                                            (item) => `● ${item} <br/>`
                                          )}
                                    </span>
                                  </td>
                              </tr>
                               `
                        : ""
                    }
                    ${
                      details?.referral
                        ? `
                      <tr>
                          <td height="6"></td>
                      </tr>
                      <tr>
                          <td>
                              <span>
                                  <strong>Referred By:</strong>&nbsp; ${details?.referral}
                              </span>
                          </td>
                      </tr>
                      `
                        : ""
                    }
                </table>
            </td>
            <td width="12"></td>
        </tr>
        <tr>
            <td height="12"></td>
        </tr>
    </table>
  `;

  return htmlContent;
};

export default EmailAcceleratorRegisterBody;
