import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";

const FAQsFieldset = ({ field, onFieldChange, onRemove }) => {
  const handleFieldChange = (fieldName, fieldValue) => {
    const updatedField = {
      ...field,
      id: uuidv4(),
      [fieldName]: fieldValue,
    };

    onFieldChange(updatedField);
  };

  return (
    <div className="d-flex gap-3 mt-3 batch align-items-center">
      <Row className="mx-0 flex-grow-1">
        <Col md={12}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="text"
              className="w-100"
              name="question"
              placeholder="Course Name"
              value={field?.question}
              onChange={(e) => handleFieldChange("question", e.target.value)}
            />
            <Form.Label
              className="form-label file-label"
              htmlFor="question-label"
            >
              FAQ Question
            </Form.Label>
          </Form.Group>
        </Col>

        <Col md={12}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              as={"textarea"}
              style={{ height: "auto" }}
              className="w-100"
              rows={4}
              name="answer"
              placeholder="Link"
              value={field?.answer}
              onChange={(e) => handleFieldChange("answer", e.target.value)}
            />
            <Form.Label
              className="form-label file-label"
              htmlFor="answer-label"
            >
              FAQ Answer
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Button
        className="clickable actionDltBtn btn border-danger"
        onClick={() => onRemove(field.id)}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default FAQsFieldset;
