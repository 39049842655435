import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import TextFormatter from "../../components/textFormat/textFormat";
import { Facebook, Globe, Linkedin } from "react-bootstrap-icons";

const ItemInfo = ({ itemData, item_type }) => {

  return (
    <Card className="border-top-0 rounded-top-0">
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          {item_type} Details:
        </h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Description</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.description ? (
                <TextFormatter text={itemData?.description} />
              ) : (
                "Not Available"
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Email</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.email ??
                "Not Available"
              }
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Phone</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.phone ??
                "Not Available"
              }
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Address</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.address ? itemData?.address + ',' :
                ""
              }{" "}
              {itemData?.state ? itemData?.state + ',' :
                ""
              }{" "}
              {itemData?.city ? itemData?.city + ',' :
                ""
              }{" "}
              {itemData?.country ??
                ""
              }
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <h6 className="theme-text-2 text-capitalize">Follow us:</h6>
          </Col>
          <Col
            md={9}
            className="social d-flex gap-md-3 gap-2 flex-wrap align-items-center"
          >
            {itemData.linkedIn && (
              <a
                target="_blank"
                href={`${itemData.linkedIn}`}
                rel="noreferrer"
              >
                <Linkedin />
              </a>
            )}
            {itemData.facebook && (
              <a
                target="_blank"
                href={`${itemData.facebook}`}
                rel="noreferrer"
              >
                <Facebook />
              </a>
            )}
            {itemData.website && (
              <a
                target="_blank"
                href={`${itemData.website}`}
                rel="noreferrer"
              >
                <Globe />
              </a>
            )}
          </Col>
        </Row>
      </Card.Body>
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          More Details:
        </h5>
        <Row>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.job_title
                ?? "Not Available"
              }
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Industry</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.industry
                ?? "Not Available"
              }
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Social</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.linkedin ?? "Not Available"
              }
            </Card.Text>
          </Col>
        </Row>
        <hr />
      </Card.Body>

    </Card>
  );
};

export default ItemInfo;
