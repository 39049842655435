const UTCLocalTimeConverterEmail = ({ utcTime, zone }) => {
  const [utcHours, utcMinutes] = utcTime?.split(":")?.map(Number);

  const localDate = new Date();
  localDate?.setUTCHours(utcHours, utcMinutes);

  const localHours = localDate?.getHours();
  const localMinutes = localDate?.getMinutes();

  const period = localHours >= 12 ? "PM" : "AM";
  const formattedLocalTime = `${localHours % 12 || 12}:${localMinutes
    .toString()
    .padStart(2, "0")} ${period}`;

  const userTimeZone = Intl.DateTimeFormat(undefined, {
    timeZoneName: "long",
  })
    ?.formatToParts(localDate)
    ?.find((part) => part.type === "timeZoneName")?.value;

  // Return the formatted time and timezone as a string
  return `${formattedLocalTime}\n${zone ? `(${userTimeZone})` : ""}`;
};

export default UTCLocalTimeConverterEmail;
