export const PropertyDetailKeys = [
  "highlight",
  "overview",
  "price_range",
  "base_price",
  "car_parking",
  "property_location",
  "property_video_url",
  "property_latitude",
  "property_360_video",
  "google_map_link",
  "property_longitude",
  "commutation",
  "property_amenities",
  "bedrooms",
  "bathrooms",
  "swimming_pool",
  "project_title",
  "project_unit",
  "project_website",
  "project_landmark",
  "project_details",
  "project_address",
  "project_budget",
  "project_3D_floor_map",
  "project_unit_type",
  "project_floor_plan",
  "project_unit_area",
  "agent_name",
  "agent_email",
  "agent_phone",
  "agent_appointment",
  "agent_website",
  "agent_whatsapp",
  "location_map_link",
  "floor_map_link",
  "property_map_link",
  "discounts",
  "municipality_approval",
  "association_approval",
  "government_registration",
  "project_loan_available",
  "promoter",
  "promoter_name",
  "additional_description",
];
