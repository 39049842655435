import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { PencilSquare, PlusCircle, Share, Trash } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import API from "../../components/API/API";
import CustomLoader from "../../components/loader/loader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import useUserData from "../../components/useUserDetails/useUserDetails";
import * as Actions from "../../constants/baseUrl";
import COURSEIMG from "../../img/course.png";
import SocialShare from "../../components/share/socialShare";

const Startups = () => {
  const navigate = useNavigate()


  const currentDomain = window.location.hostname;
  const entityType = 'startup'

  const startupsAlias = SubDomainRouter("startupsAlias");
  const myToken = localStorage.getItem("token");
  const isValidUser = localStorage.getItem("isValidUser")


  const role = useUserData().role?.toLocaleLowerCase();
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [loading, setLoading] = useState(true);
  const [startups, setStartups] = useState(null);
  const [displayedCards, setDisplayedCards] = useState(8);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await API("get", `items/${entityType}`);
      setStartups(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}items/${entityType}/${id}`, {
                headers: {
                  Authorization: "Bearer " + myToken,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                fetchData();
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  // Define regular expressions for filtering
  const itemList = (startups) => {
    const handleShowMore = () => {
      setDisplayedCards((prevDisplayedCards) =>
        prevDisplayedCards + 4 <= startups?.length
          ? prevDisplayedCards + 4
          : startups?.length
      );
    };

    return (
      <Container fluid="lg" className="formMain create my-5 py-5">
        <Row>
          <Col className="mb-3" lg={10} sm={8}>
            <h3
              id="Startups"
              className="pageSubHeading mb-0 text-sm-start text-center text-capitalize"
            >
              {startupsAlias ? startupsAlias : "Startups"}
            </h3>
          </Col>
          {role === "admin" ||
            role === "siteadmin" ||
            role === "superadmin" ||
            role === "trainer" ||
            role === "training-coordinator" ? (
            <Col
              lg={2}
              sm={4}
              className="d-flex mb-3 align-items-center justify-content-sm-end justify-content-center"
            >
              <Link
                to={`/${entityType}/items/add`}
                type="button"
                className="w-md-75 w-auto px-md-3 px-5 btn add-button d-flex align-items-center justify-content-center"
              >
                <PlusCircle /> &nbsp; Add
              </Link>
            </Col>
          ) : null}
        </Row>

        <Row className="mx-0">
          {startups ? (
            startups?.map((item) => (
              <Col md={4} key={item.id}>
                <Card className="demoCard">
                  <div className="itemImg">
                    <Link
                      to={`/${entityType}/items/details/${item.id}`}
                      className="linkImg"
                    >
                      <img
                        src={
                          item?.data?.listing_image_url ||
                          item?.data?.hero_image_url ||
                          COURSEIMG
                        }
                        className="img-fluid"
                        alt="Load Error"
                      />
                    </Link>
                  </div>
                  <Card.Body className="itemInfo px-2 pb-3 d-flex flex-column justify-content-between py-1">
                    <div className="card-details text-center">
                      <h3 className="itemName text-left mb-0">
                        <Link
                          to={`/${entityType}/items/details/${item.id}`}
                          className="detailLink text-left"
                        >
                          {item?.data?.company_name}
                        </Link>
                      </h3>

                      <Card.Text className="card-text mb-0 pb-0 text-left text-overflow-desc p-0">
                        {item?.data?.registered_company_name}
                      </Card.Text>
                      <div className="d-flex gap-2 justify-content-center my-3 align-items-center">
                        <SocialShare
                          url={`https://${currentDomain}/${entityType}/items/details/${item?.id}/`}
                          iconsSize={30}
                          title={item?.data?.company_name}
                          image={item?.data?.listing_image_url}
                          description={item?.data?.description}
                        />

                        <Link
                          to={`/share/items_${entityType}/${item.id}`}
                          className="entity-share-btn d-flex align-items-center cursor-pointer"
                          title="Share"
                        >
                          <Share />
                        </Link>
                      </div>
                      <hr className="mt-2 mb-1" />
                      <Card.Text className="card-text mb-0 pb-0 text-left text-overflow-desc p-0">
                        {item?.data?.description}
                      </Card.Text>
                    </div>
                    <div className="itemEdit d-flex flex-column flex-wrap justify-content-center mt-3 gap-2">
                      <div className="d-flex flex-wrap justify-content-center gap-2">
                        <Link
                          to={`/${entityType}/items/details/${item.id}`}
                          className="actionBtn btn action d-flex align-items-center justify-content-center text-center w-100 flex-grow-1 cursor-pointer"
                          title="startups"
                        >
                          View More
                        </Link>
                      </div>
                      <div className="d-flex flex-wrap justify-content-center gap-2">
                        {role === "admin" ||
                          role === "siteadmin" ||
                          role === "superadmin" ||
                          item?.userId?.toLocaleLowerCase() === myUserId ? (
                          <>
                            <Link
                              to={`/${entityType}/items/edit/${item.id}`}
                              className="actionBtn btn d-flex align-items-center justify-content-center text-center action cursor-pointer flex-grow-1"
                            >
                              <span className="d-sm-block d-none">Edit</span>
                              <PencilSquare className="d-sm-none d-block" />
                            </Link>
                            <Link
                              to="#"
                              className="actionDltBtn btn d-flex align-items-center justify-content-center text-center action cursor-pointer flex-grow-1"
                              onClick={() => deleteItem(item.id)}
                            >
                              <span className="d-sm-block d-none">Delete</span>
                              <Trash className="d-sm-none d-block" />
                            </Link>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <p>No {startupsAlias} found..</p>
          )}
        </Row>

        {!loading && displayedCards < startups?.length ? (
          <div className="text-center mb-5">
            <Button
              onClick={() => isValidUser && myToken ? handleShowMore() : navigate('/my-profile')}
              className="btn actionBtnMore action cursor-pointer w-auto add-button"
            >
              Show More
            </Button>
          </div>
        ) : null}
      </Container>
    );
  };

  const siteName = SubDomainRouter("title");
  let uppercaseTitle = startupsAlias.toLocaleUpperCase();
  return (
    <>
      <Helmet>
        <title>
          {uppercaseTitle} | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      {loading ? <CustomLoader /> : itemList(startups)}
    </>
  );
};

export default Startups;
