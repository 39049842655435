const EmailQuoteBody = (details, trainersListID) => {
  const htmlContent = `
        <table width="600px" style="max-width: 600px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
        align="center" style="text-wrap: balance;">
        <tr>
            <td height="24"></td>
        </tr>
        <!-- Main Message -->
        <tr>
            <td>
                <span style="font-weight: bold; font-size: 16px; font-family: 'Unbounded', sans-serif;">
                    Hi,
                </span>
            </td>
        </tr>
        <tr>
            <td height="6"></td>
        </tr>
        <tr>
            <td width="100%" style="line-height: 1.3;">
                <span style="font-size: 12px; font-family: 'Poppins', sans-serif;">
                    Please checkout the following enquiry and contact us
                </span>
            </td>
        </tr>
        <tr>
            <td height="30"></td>
        </tr>
    </table>
    <!-- User Details -->
    <table width="600px" style="max-width: 600px; width: 100%; border-radius: 4px;" border="0"
        cellspacing="0" cellpadding="0" align="center" bgcolor="#e7eeee">
        <tr>
            <td height="12"></td>
        </tr>
        <tr>
            <td width="12"></td>
            <td>
                <table width="574px"
                    style="max-width: 574px; width: 100%; font-size: 12px; font-family: 'Poppins', sans-serif;"
                    border="0" cellspacing="0" cellpadding="0" align="center">
                    <tr>
                        <td>
                            <span style="text-wrap: balance; white-space: pre-line;">
                                <strong>Course:</strong>&nbsp; ${
                                  details?.course
                                }
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td height="6"></td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <strong>Name:</strong>&nbsp; ${details?.name}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td height="6"></td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <strong>Teaching Mode:</strong>&nbsp; ${
                                  details?.teaching_mode
                                }
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td height="6"></td>
                    </tr>
                    ${
                      details?.teaching_mode.toLocaleLowerCase() ===
                      "in-class room"
                        ? `<tr>
                            <td>
                            <span>
                                <strong>City:</strong>&nbsp; ${details?.city}
                            </span>
                            </td>
                        </tr>
                        <tr>
                            <td height="6"></td>
                        </tr>
                        `
                        : ""
                    }
                    <tr>
                        <td>
                            <span>
                                <strong>Email Address:</strong>&nbsp; ${
                                  trainersListID ? "Available" : details?.email
                                }
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td height="6"></td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <strong>Phone No.:</strong>&nbsp; ${
                                  trainersListID ? "Available" : details?.phone
                                }
                            </span>
                        </td>
                    </tr>
                   ${
                     details?.message &&
                     ` <tr>
                        <td height="6"></td>
                    </tr>
                    <tr>
                        <td>
                            <span style="text-wrap: balance; white-space: pre-line;">
                                <strong>Message.:</strong>&nbsp; ${details?.message}
                            </span>
                        </td>
                    </tr>`
                   }
                </table>
            </td>
            <td width="12"></td>
        </tr>
        <tr>
            <td height="12"></td>
        </tr>
    </table>
  `;

  return htmlContent;
};

export default EmailQuoteBody;
