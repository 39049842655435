const ConvertCreatedDateFormat = ({ inputDate }) => {
  const formatDate = (date) => {
    const monthName = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(date);
    const dayWithSuffix = addDateSuffix(date.getUTCDate());
    let shortMonth = monthName?.slice(0, 3);

    return `${shortMonth} ${dayWithSuffix}, ${date.getUTCFullYear()}`;
  };

  const addDateSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  const convertISODate = (isoDate) => {
    const date = new Date(isoDate);
    if (isNaN(date)) {
      return null; // Invalid date
    }

    return formatDate(date);
  };

  const convertOtherDateFormats = (dateString) => {
    const dateParts = dateString?.split(/[-/]/);

    if (dateParts?.length === 3) {
      const [year, month, day] = dateParts?.map(Number);

      if (!isNaN(month) && !isNaN(day) && !isNaN(year)) {
        const date = new Date(year, month - 1, day);

        if (!isNaN(date)) {
          return formatDate(date);
        }
      }
    }

    return null;
  };

  // Check if the input date is in ISO format
  if (inputDate.includes("T")) {
    return convertISODate(inputDate);
  }

  // If not in ISO format, try to parse using the existing logic
  return convertOtherDateFormats(inputDate);
};

export default ConvertCreatedDateFormat;
