import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import ConvertDateFormat from "../convertDateFormat/convertDateFormat";
import ConvertMinutesToTime from "../convertFromMinutes/convertFromMinutes  ";
import ConvertTo12HourFormat from "../convertTo12HourFormat/convertTo12HourFormat";

const EntityItem = ({ id, isChecked, onCheckboxChange, item, entityType }) => {
  return (
    <Form.Check
      type="checkbox"
      className="ps-0 my-3 custom-checkbox newsletter-item"
      name={item?.id}
      id={item?.id}
      label={
        <Row className="demoCard flex-sm-row card p-2 ms-2">
          <Col
            md={2}
            sm={3}
            className="itemImg border-dark border-opacity-10 rounded-2 p-0 border"
          >
            <img
              src={
                item.listing_image_url ||
                `https://placehold.co/380x280/42a49f/white?text=${item?.title?.slice(
                  0,
                  5
                )}`
              }
              className="img-fluid h-100 w-100 object-fit-cover"
              alt="imageCourse"
            />
          </Col>
          <Col md={10} sm={9} className="itemInfo px-2">
            <div className="card-details">
              <h3 className="itemName text-left pt-2">
                <span className="detailLink">{item.title}</span>
              </h3>

              <Card.Text className="card-text my-1 pb-0 text-start d-flex flex-wrap align-items-center">
                {<ConvertDateFormat inputDate={item.start_date} />}
                &nbsp;-&nbsp;
                {<ConvertTo12HourFormat time24={item.start_time} />}
                &nbsp;
                <span>
                  (
                  <ConvertMinutesToTime minutes={item.duration} />)
                </span>
              </Card.Text>
              <Card.Text className="card-text mb-0 pb-0 text-start text-overflow-desc-sm p-0">
                {item.description}
              </Card.Text>
            </div>
          </Col>
        </Row>
      }
      onChange={() => onCheckboxChange(entityType, id)}
      checked={isChecked}
    />
  );
};

export default EntityItem;
