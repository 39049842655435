import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  PencilSquare,
  PlusCircle,
  Trash3Fill,
  Upload,
} from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import TokenDetails from "../useUserDetails/useTokenDetails";
import CustomLoader from "../loader/loader";
import UploadModal from "../fileUploadFieldSet/listUploadPopup";

const TelegramLists = () => {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [telegramLists, setTelegramLists] = useState([]);

  const [uploadListID, setUploadListID] = useState("");

  // Get data
  const getTelegramLists = () => {
    setLoading(true);
    axios
      .get(`${Actions.BASE_URL}telegramLists/users/${myUserId}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTelegramLists(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTelegramLists();
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}telegramLists/${id}`, {
                headers: {
                  Authorization: "Bearer " + myToken,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                getTelegramLists();
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="d-flex mb-3 align-items-center justify-content-between gap-2 fle-wrap">
            <h5 className="theme-text mb-sm-0">Telegram Lists</h5>
            <Link
              to="/my-contacts/telegram-list/add"
              className="btn add-button d-flex align-items-center"
            >
              <PlusCircle />
              <span className="d-md-block d-none">
                &nbsp; Create Contact List
              </span>
            </Link>
          </div>
          {telegramLists?.length > 0 ? (
            <div className="striped overflow-x-md-scroll">
              {telegramLists?.map((list, index) => (
                <div
                  className="d-flex align-items-center justify-content-between gap-3 p-2"
                  key={index}
                >
                  <Link
                    to={`/my-contacts/telegram-list/contact/${list.id}`}
                    className="theme-text-2 mb-0 h6 text-wrap list-name"
                  >
                    {list.list_name}
                  </Link>
                  <div className="d-flex gap-3 align-items-center">
                    <Link
                      to={`/my-contacts/telegram-list/contact/${list.id}`}
                      className="btn add-button d-flex align-items-center d-lg-block text-nowrap d-none"
                    >
                      See List
                    </Link>
                    <Button
                      className="btn add-button mb-0 d-flex align-items-center"
                      onClick={() => {
                        setModalShow(true);
                        setUploadListID(list.id);
                      }}
                    >
                      <Upload />
                      <span className="d-lg-block text-nowrap d-none">
                        &nbsp; Upload
                      </span>
                    </Button>
                    <Link
                      to={`/my-contacts/telegram-list/contact/${list.id}/add`}
                      className="btn add-button mb-0 d-flex align-items-center"
                    >
                      <PlusCircle />
                      <span className="d-lg-block text-nowrap d-none">
                        &nbsp; Add Contact
                      </span>
                    </Link>
                    <Link
                      to={`/my-contacts/telegram-list/${list.id}/edit`}
                      className="btn-link theme-text text-center"
                    >
                      <PencilSquare />
                    </Link>
                    <Link
                      to={"#"}
                      className="btn-link text-danger text-center p-0"
                      onClick={() => deleteItem(list.id)}
                    >
                      <Trash3Fill />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No Telegram Lists Founds...</p>
          )}
        </>
      )}
      <UploadModal
        listtype="telegramlist"
        uploadlistid={uploadListID}
        show={modalShow}
        setshow={setModalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default TelegramLists;
