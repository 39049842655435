import axios from "axios";
import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { ClipboardCheckFill, PlusCircle } from "react-bootstrap-icons";
import * as Actions from "../../../constants/baseUrl";
import TokenDetails from "../../../components/useUserDetails/useTokenDetails";
import DynamicFileUploadEdit from "../../../components/fileUploadFieldSet/fileUploadEdit";

const UploadFiles = () => {
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const attachmentFields = [
    {
      content: "",
      extension: "",
      field_name: "",
      url: "",
    },
  ];
  const [loading, setLoading] = useState(false);
  const [filesData, setFilesData] = useState(attachmentFields);
  const [responseURLs, setResponseURLs] = useState(null);
  const [copySuccess, setCopySuccess] = useState(
    new Array(filesData.length).fill(null)
  );

  const addField = (setField, field) => {
    setField([...field, {}]);
  };
  const removeItemById = (fields, setFields, itemId) => {
    const updatedItems = fields.filter((item) => item.id !== itemId);
    setFields(updatedItems);
  };

  const handleSubmit = () => {
    setLoading(true);

    const modifiedFilesData = filesData?.map(
      ({ field_name, documentType, ...rest }) => ({
        ...rest,
        file_name: field_name,
      })
    );

    const data = {
      userId: myUserId,
      type: "upload",
      files: modifiedFilesData,
    };

    axios
      .post(`${Actions.BASE_URL}upload/files`, data)
      .then((res) => {
        setLoading(false);
        setResponseURLs(res.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("API request failed:", error);
      });
  };

  const handleCopyClick = (url, index) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        const newCopySuccess = [...copySuccess];
        newCopySuccess[index] = true;
        setCopySuccess(newCopySuccess);
      })
      .catch(() => {
        const newCopySuccess = [...copySuccess];
        newCopySuccess[index] = false;
        setCopySuccess(newCopySuccess);
      });

    setTimeout(() => {
      const newCopySuccess = [...copySuccess];
      newCopySuccess[index] = null;
      setCopySuccess(newCopySuccess);
    }, 1400);
  };

  return (
    <Container fluid="lg" className="my-5 py-5">
      {loading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}
      <div className="header text-center my-3">
        <h3 className="theme-text-2">File Uploader</h3>
        <p className="opacity-75">Upload your file(s) to get the URL(s)</p>
      </div>
      <Row className="mx-0 demosAddForm mx-auto">
        <Col md={12} className="pt-3">
          <>
            {filesData?.map((field, index) => (
              <DynamicFileUploadEdit
                key={index}
                field={field}
                onFieldChange={(newField) => {
                  const updatedFields = [...filesData];
                  updatedFields[index] = newField;
                  setFilesData(updatedFields);
                }}
                onRemove={() =>
                  removeItemById(filesData, setFilesData, field.id)
                }
                fileCategory="file"
                acceptedTypes={"*"}
                inputIdPrefix={`fileUpload-${index}`}
              />
            ))}
          </>
        </Col>
        <Col
          md={12}
          className="d-flex align-items-center justify-content-between gap-3"
        >
          <Button
            className="clickable add-button mb-3 d-flex align-items-center"
            onClick={() => addField(setFilesData, filesData)}
          >
            <PlusCircle />
            &nbsp;Add More Files
          </Button>
          <Button
            className="clickable add-button mb-3 ms-auto d-flex"
            onClick={handleSubmit}
          >
            Upload Files
          </Button>
        </Col>
        {responseURLs != null && (
          <Col md={12} className="py-5">
            <h5 className="theme-text-2">Generated URLs:</h5>
            <Card>
              <Card.Body className="d-flex flex-column gap-2">
                {responseURLs?.files?.map((file, index) => {
                  return (
                    <div
                      className="d-flex align-items-start justify-content-between gap-3"
                      key={index}
                    >
                      <p className="mb-0 overflow-hidden text-overflow-desc-xs d-flex align-items-baseline">
                        <b className="text-capitalize">
                          {file?.file_name || `file${index + 1}`}
                        </b>
                        :&nbsp;
                        <a
                          href={`${file?.url}`}
                          target="_blank"
                          className="text-primary w-100 text-overflow-ellipsis d-block text-nowrap"
                          rel="noreferrer"
                        >
                          <small>{file?.url}</small>
                        </a>
                      </p>
                      <Button
                        variant="link"
                        className="p-0 theme-text p-0 text-decoration-none position-relative w-fit"
                        onClick={() => handleCopyClick(file?.url, index)}
                      >
                        {copySuccess[index] === true && (
                          <span className="text-success position-absolute z-3 end-100 bg-white">
                            Copied!&nbsp;
                          </span>
                        )}
                        {copySuccess[index] === false && (
                          <span className="text-danger position-absolute z-3 end-100 bg-white">
                            Copy failed&nbsp;
                          </span>
                        )}
                        <ClipboardCheckFill />
                      </Button>
                    </div>
                  );
                })}
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default UploadFiles;
