import axios from "axios";
import * as Actions from "../../constants/baseUrl"; 

const myToken = localStorage.getItem("token");

const createAxiosInstance = () => {
  return axios.create({
    baseURL: Actions.BASE_URL,
    headers: {
      Authorization: "Bearer " + myToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

const API = async (method, url, data = null) => {
  const axiosInstance = createAxiosInstance();

  try {
    const response = await axiosInstance({
      method,
      url,
      data,
    });

    return response.data;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    throw error;
  }
};

export default API;
