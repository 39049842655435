import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { Check2Circle, Search, ShareFill } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import UTCLocalTimeConverter from "../../components/UTCLocalTimeConverter/UTCLocalTimeConverter";
import ConvertDateFormat from "../../components/convertDateFormat/convertDateFormat";
import EmailBodyNewsletter from "../../components/emailTemplate/emailBodyNewsletter";
import EmailFooter from "../../components/emailTemplate/emailFooter";
import EmailHeader from "../../components/emailTemplate/emailHeader";
import CustomLoader from "../../components/loader/loader";
import RegistrantsCampaignModal from "../../components/registrantsCampaignModal/registrantsCampaignModal";
import RegistrantsEmailListModal from "../../components/registrantsEmailListModal/registrantsEmailListModal";
import SocialShare from "../../components/share/socialShare";
import StarRating from "../../components/starRating/starRating";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import IMG2 from "../../img/SWIPERIMG3-min.jpg";
import CourseImg from "../../img/course.png";

function Registrants(props) {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const logo = SubDomainRouter("logo");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");

  const fromEmail = SubDomainRouter("fromEmail");
  const title = SubDomainRouter("title");

  const entities = props.params.entity + "s";
  const [loading, setLoading] = useState(true);
  const [reqLoading, setReqLoading] = useState(false);
  const [isGettingRegistrants, setIsGettingRegistrants] = useState(true);
  const [entityData, setEntityData] = useState({});
  const [registrantData, setRegistrantData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderBy, setOrderBy] = useState("first_name");
  const [order, setOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");

  // send campaigns
  const [registeredEmails, setRegisteredEmails] = useState([]);
  const [campaignModal, setCampaignModal] = useState(false);
  const [emailListModal, setEmailListModal] = useState(false);

  const populateList = async (id) => {
    try {
      const data = await fetch(`${Actions.BASE_URL}${entities}/${id}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) => response.json());
      setLoading(false);
      setEntityData(data);
    } catch (error) {
      console.log("There was an error", error);
    } finally {
      setLoading(false);
    }
  };
  const populateRegistrants = async (id) => {
    try {
      const data = await fetch(`${Actions.BASE_URL}registrants/entity/${id}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) => response.json());
      setRegistrantData(data);
    } catch (error) {
      console.log("There was an error", error);
    } finally {
      setIsGettingRegistrants(false);
    }
  };

  useEffect(() => {
    populateList(props.params.id);
    populateRegistrants(props.params.id);
  }, [props.params.id]);

  const siteName = SubDomainRouter("title");
  const currentDomain = window.location.hostname;

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredContacts = registrantData.filter(
    (contact) =>
      contact?.first_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.last_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.email?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const sortedContacts = filteredContacts.sort((a, b) => {
    const isAsc = order === "asc";
    return isAsc
      ? a[orderBy]?.localeCompare(b[orderBy])
      : b[orderBy]?.localeCompare(a[orderBy]);
  });

  const handleSendCampaign = () => {
    const email = registrantData.map((registrant) => registrant?.email);
    setRegisteredEmails(email);
    setCampaignModal(true);
  };

  const handleCreateEmailList = () => {
    setRegisteredEmails(registrantData);
    setEmailListModal(true);
  };

  const handleUpdateEmailList = () => {
    setReqLoading(true);
    // structure data
    const emailListId = entityData?.emaillistid;
    const data = registrantData?.map((registrant) => ({
      userId: myUserId,
      type: "contact",
      approval_status: "",
      lists: [emailListId],
      entityRelation: [{ emaillist: emailListId }],
      first_name: registrant.first_name,
      last_name: registrant.last_name,
      email: registrant.email,
    }));

    // if no registrants
    if (!data || data?.length === 0) {
      toast.error("No registrants found to update email list", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
      setReqLoading(false);
      return;
    }
    axios
      .post(
        `${Actions.BASE_URL}contacts/${myUserId}/${emailListId}/multiple`,
        data,
        {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const currentDate = new Intl.DateTimeFormat("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        }).format(new Date());
        successAlert(
          `Registrants successfully added to Email List: ${entityData.title} -  ${currentDate}`
        );
      })
      .catch((error) => {
        toast.error(
          "something went wrong while adding contacts to email list",
          {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          }
        );
        console.log(error);
      })
      .finally(() => {
        setReqLoading(false);
      });
  };

  const handleSubmitEmail = (userEmail, userName) => {
    setReqLoading(true);

    const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
    const footer_email_content = EmailFooter(logo, title);

    const customMessage = `<p style="font-size:16px; line-height:1; margin:0;">
    <b> Hello ${userName || "Dear"},</b><br/>
    We warmly invite you to join ${entityData ? entityData?.title : null
      } session and seize the opportunity to learn with Us</p>`;

    const mappedBodyData = EmailBodyNewsletter(
      [entityData],
      customMessage,
      demosAlias,
      coursesAlias
    );
    const data = {
      Attachments: null,
      Template: null,
      IsHTML: true,
      userId: myUserId,
      type: "email",
      from: fromEmail,
      body: header_email_content + mappedBodyData + footer_email_content,
      message: header_email_content + mappedBodyData + footer_email_content,
      to: [userEmail],
      subject: `Invitation for ${entityData.title ?? ""}`,
    };

    axios
      .post(`${Actions.APIGATEWAY_URL}email`, data, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        successAlert(`Email sent successfully to ${userName}`);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong. Please Try again later.", {
          autoClose: 3000,
          hideProgressBar: true,
          draggable: true,
        });
      })
      .finally(() => {
        setReqLoading(false);
      });
  };

  const successAlert = (param) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h6 className="theme-text-2 text-center mt-2 text-capitalize">
              {param}
            </h6>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                onClose();
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>
          Registrant Details | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}

      <Container fluid="lg" className="my-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <div className="demosViewMoreSection demoProfile overflow-hidden mx-auto">
            <div className="coverImg p-2 position-relative w-100">
              <Image
                src={
                  entityData?.hero_image_url ||
                  entityData?.listing_image_url ||
                  IMG2
                }
                width="100%"
                alt=""
              />
            </div>

            <Row className="profileHeader align-items-center position-relative py-2 mx-4">
              <Col lg={2} className="mr-3">
                <Image
                  src={
                    entityData?.listing_image_url ||
                    entityData?.hero_image_url ||
                    CourseImg
                  }
                  alt="..."
                  className="profileImg"
                />
              </Col>
              <Col md={6} className="mt-lg-0 mt-5 pt-lg-0 pt-2 ps-4">
                <h4 className="trainerName mb-2">
                  {entityData?.title}
                  <br />
                  <span className="industry">
                    {entityData?.start_date !== "" ? (
                      <ConvertDateFormat inputDate={entityData?.start_date} />
                    ) : (
                      "Yet To Decide"
                    )}
                    &nbsp; - &nbsp;
                    {entityData?.start_time !== "" ? (
                      <b>
                        <UTCLocalTimeConverter
                          zone
                          utcTime={entityData?.start_time}
                        />
                      </b>
                    ) : (
                      ""
                    )}
                  </span>
                </h4>
                {entityData?.star_rating_type && (
                  <StarRating rating={entityData?.star_rating_type} />
                )}
              </Col>
              <Col lg={4} md={6}>
                <div className="d-flex align-items-center justify-content-center gap-2 mb-3">
                  <SocialShare
                    url={`https://${currentDomain}/${entityData?.type}s/details/${entityData?.id}/`}
                    iconsSize={30}
                    title={entityData?.title}
                    image={entityData?.listing_image_url}
                    description={entityData?.description}
                  />
                  <Link
                    to={`/share/items_${entityData.type}/${entityData.id}`}
                    className="entity-share-btn d-flex align-items-center cursor-pointer"
                    title="Share"
                  >
                    <ShareFill />
                  </Link>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-2 mb-3">
                  {entityData?.emaillistid ? (
                    <Button
                      className="add-button d-block w-fit"
                      onClick={handleUpdateEmailList}
                    >
                      Update Email List
                    </Button>
                  ) : (
                    <Button
                      className="add-button d-block w-fit"
                      onClick={handleCreateEmailList}
                    >
                      Create Email List
                    </Button>
                  )}
                  <Button
                    className="add-button d-block w-fit"
                    onClick={handleSendCampaign}
                  >
                    Send Campaign
                  </Button>
                </div>
              </Col>
            </Row>

            <div className="mx-4 mt-4 mb-2 all-contacts">
              <div className="d-flex justify-content-between px-3 pt-3 flex-wrap">
                <div className="mb-3">
                  <h4 className="theme-text mb-1">Registrants</h4>
                </div>

                <div className="mb-3 w-100 tableSearch ms-auto rounded-pill position-relative input-group">
                  <Form.Control
                    className="rounded-pill"
                    onChange={handleSearch}
                    placeholder="Search"
                  />
                  <span
                    id="basic-addon1"
                    className="border-0 bg-transparent position-absolute top-0 h-100 end-0 input-group-text"
                  >
                    <Search />
                  </span>
                </div>
              </div>

              {isGettingRegistrants ? (
                <CustomLoader />
              ) : sortedContacts.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 768 }}
                    aria-label="simple table"
                    className="striped rounded-0"
                  >
                    <TableHead className="bg-theme">
                      <TableRow>
                        <TableCell className="text-nowrap text-white fw-bold">
                          <TableSortLabel
                            active={orderBy === "first_name"}
                            direction={orderBy === "first_name" ? order : "asc"}
                            onClick={() => handleSortRequest("first_name")}
                          >
                            First Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className="text-nowrap text-white fw-bold">
                          <TableSortLabel
                            active={orderBy === "last_name"}
                            direction={orderBy === "last_name" ? order : "asc"}
                            onClick={() => handleSortRequest("last_name")}
                          >
                            Last Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className="text-nowrap text-white fw-bold">
                          <TableSortLabel
                            active={orderBy === "email"}
                            direction={orderBy === "email" ? order : "asc"}
                            onClick={() => handleSortRequest("email")}
                          >
                            Email
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className="text-nowrap text-white fw-bold">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedContacts
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((contact) => (
                          <TableRow key={contact?.id}>
                            <TableCell>{contact?.first_name}</TableCell>
                            <TableCell>{contact?.last_name}</TableCell>
                            <TableCell>{contact?.email}</TableCell>
                            <TableCell>
                              <span className="d-flex align-items-center justify-content-center">
                                {contact?.email && contact?.first_name ? (
                                  <Button
                                    className="btn add-button"
                                    onClick={() =>
                                      handleSubmitEmail(
                                        contact.email,
                                        contact.first_name ||
                                        "" + contact.last_name ||
                                        ""
                                      )
                                    }
                                  >
                                    Send Mail
                                  </Button>
                                ) : (
                                  "Action not available"
                                )}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[50, 75, 100]}
                    component="div"
                    className="d-flex align-items-center justify-content-end"
                    count={sortedContacts?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </TableContainer>
              ) : (
                <p className="text-center mb-4">No Data Found...</p>
              )}
            </div>
          </div>
        )}
      </Container>

      <RegistrantsCampaignModal
        show={campaignModal}
        onHide={() => setCampaignModal(false)}
        registeredEmails={registeredEmails}
        selectedItemDetails={entityData}
      />

      <RegistrantsEmailListModal
        show={emailListModal}
        onHide={() => setEmailListModal(false)}
        registeredEmails={registeredEmails}
        selectedItemDetails={entityData}
      />
    </>
  );
}
export default withRouter(Registrants);
