import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Globe, Linkedin, PlayCircle, Whatsapp } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";

const ItemInfo = ({ itemData }) => {
  const mentorAlias = SubDomainRouter("mentorAlias");

  return (
    <Card className="border-top-0 rounded-top-0">
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          {mentorAlias ? mentorAlias : "demo"} Details:
        </h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Full Name
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.first_name + " " + itemData?.last_name ||
                "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              job title
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.job_title || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              email
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.email || "Not available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              specialization
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.specialization || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              industry
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.industry || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              experience
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.experience || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              achievements
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.achievements || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              availability
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.availability || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />

        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Address
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.city || "Not Available"},&nbsp;
              {itemData?.country || "Not Available"}
            </Card.Text>
          </Col>
        </Row>

        {itemData?.video_url && (
          <>
            <hr />
            <Row>
              <Col md={3}>
                <Card.Text className="mb-0 fw-bold text-capitalize">
                  Intro Video
                </Card.Text>
              </Col>
              <Col md={9}>
                <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                  <Link
                    to={itemData?.video_url}
                    target="_blank"
                    className="btn-link theme-text-2 btn d-flex align-items-center gap-1 text-decoration-none p-0"
                  >
                    <PlayCircle /> Watch Video
                  </Link>
                </Card.Text>
              </Col>
            </Row>
          </>
        )}

        {itemData?.whatsapp || itemData?.linkedin || itemData?.website ? (
          <>
            <hr />
            <Row>
              <Col md={3}>
                <Card.Text className="mb-0 fw-bold text-capitalize">
                  Social Links
                </Card.Text>
              </Col>
              <Col
                md={9}
                className="social d-flex gap-md-3 gap-2 flex-wrap align-items-center"
              >
                {itemData?.linkedin ? (
                  <a
                    target="_blank"
                    href={`${itemData?.linkedin}`}
                    rel="noreferrer"
                  >
                    <Linkedin />
                  </a>
                ) : null}
                {itemData?.whatsapp ? (
                  <a href={`tel:${itemData?.whatsapp}`} rel="noreferrer">
                    <Whatsapp />
                  </a>
                ) : null}
                {itemData?.website ? (
                  <a
                    target="_blank"
                    href={`${itemData?.website}`}
                    rel="noreferrer"
                  >
                    <Globe />
                  </a>
                ) : null}
              </Col>
            </Row>
          </>
        ) : null}
      </Card.Body>

      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          Session details
        </h5>
        <hr />
        {itemData?.presentation && (
          <>
            <Row>
              <Col md={3}>
                <Card.Text className="mb-0 fw-bold text-capitalize">
                  Presentation Video
                </Card.Text>
              </Col>
              <Col md={9}>
                <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                  <Link
                    to={itemData?.presentation}
                    target="_blank"
                    className="btn-link theme-text-2 btn d-flex align-items-center gap-1 text-decoration-none p-0"
                  >
                    <PlayCircle /> Watch Video
                  </Link>
                </Card.Text>
              </Col>
            </Row>
            <hr />
          </>
        )}

        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Potential Date(s) & Time for Mentoring Session
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
              {itemData?.session_date_time || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ItemInfo;
