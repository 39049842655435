import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import API from "../../components/API/API";
import CustomLoader from "../../components/loader/loader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import withRouter from "../../constants/withRouter";
import IMG2 from "../../img/SWIPERIMG3-min.jpg";
import COURSEIMG from "../../img/course.png";
import userImg from "../../img/user.png";
import ItemInfo from "./itemInfo";
import SocialShare from "../../components/share/socialShare";
import { Link } from "react-router-dom";
import { ShareFill } from "react-bootstrap-icons";
import CustomerDetailsPopup from "../../components/startupPopup/customerDetailsPopup";
import MemberDetailsPopup from "../../components/startupPopup/memberDetailsPopup";
import TextFormatter from "../../components/textFormat/textFormat";

function StartupDetails(props) {
  const entities = "items";
  const currentDomain = window.location.hostname;
  const entityType = 'startup'

  const [loading, setLoading] = useState(true);
  const [itemData, setItemData] = useState({});
  // customer
  const [customerDetailModalShow, setCustomerDetailModalShow] = useState(false);
  const [selectedCustomerData, setSelectedCustomerData] = useState({});
  // member
  const [memberDetailModalShow, setMemberDetailModalShow] = useState(false);
  const [selectedMemberData, setSelectedMemberData] = useState({});

  const populateList = async (id) => {
    try {
      const response = await API("get", entities + `/${entityType}/` + id);
      setItemData(response);
    } catch (error) {
      console.log("There was an error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList(props.params.id);
  }, [props.params.id]);

  const siteName = SubDomainRouter("title");
  const startupAlias = SubDomainRouter("startupAlias");

  return (
    <>
      <Helmet>
        <title>
          {itemData?.data?.company_name
            ? `${itemData?.data?.company_name}`
            : `${startupAlias ? startupAlias : "Item"} Details`}{" "}
          | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <Container fluid="lg" className="my-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <div className="demosViewMoreSection demoProfile overflow-hidden mx-auto">
            <div className="coverImg p-2 position-relative w-100">
              <Image
                src={
                  itemData?.data?.hero_image_url ||
                  itemData?.data?.listing_image_url ||
                  IMG2
                }
                loading="lazy"
                width="100%"
                alt=""
              />
            </div>

            <Row className="profileHeader align-items-center position-relative py-2 mx-4">
              <Col lg={2} className="mr-3">
                <Image
                  src={
                    itemData?.data?.listing_image_url ||
                    itemData?.data?.hero_image_url ||
                    COURSEIMG
                  }
                  alt="..."
                  className="profileImg"
                />
              </Col>
              <Col md={6} className="mt-lg-0 mt-5 pt-2 ps-4">
                <h4 className="trainerName mb-2">
                  {itemData?.data?.company_name} <br />
                  <span className="industry">
                    {itemData?.data?.registered_company_name || ""}
                  </span>
                </h4>
              </Col>
              <Col lg={4} md={6}>
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <SocialShare
                    url={`https://${currentDomain}/${entityType}/items/details/${itemData?.id}/`}
                    iconsSize={30}
                    title={itemData?.data?.company_name}
                    image={itemData?.data?.listing_image_url}
                    description={itemData?.data?.description}
                  />
                  <Link
                    to={`/share/items_/${entityType}/${itemData.id}`}
                    className="entity-share-btn d-flex align-items-center cursor-pointer"
                    title="Share"
                  >
                    <ShareFill />
                  </Link>
                </div>
              </Col>
            </Row>

            <Tabs defaultActiveKey="details" className="mx-4 mt-2" fill>
              <Tab eventKey="details" title="Details" className="m-4 mt-0">
                <ItemInfo itemData={itemData?.data} />
              </Tab>
              <Tab eventKey="members" title="Members" className="m-4 mt-0">
                <div className="mt-3 d-flex gap-2 flex-wrap gap-2 mb-3">
                  {itemData?.data?.members?.length > 0 ? (
                    itemData?.data?.members?.map((member, index) => (
                      <div className="member-profile-chip" key={index}>
                        <img
                          src={member?.profile_image_url || userImg}
                          loading="lazy"
                          alt=""
                          className="member-thumb rounded-circle object-fit-cover"
                          width="120"
                          height="120"
                        />

                        <h6>{member?.name}</h6>

                        <Button
                          className="add-button py-1 small"
                          onClick={() => {
                            setSelectedMemberData(member);
                            setMemberDetailModalShow(true);
                          }}
                        >
                          View Profile
                        </Button>
                      </div>
                    ))
                  ) : (
                    <p>No Members Yet...</p>
                  )}
                </div>
              </Tab>

              <Tab eventKey="customers" title="Customers" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <div className="mt-3 d-flex gap-2 flex-wrap gap-2 mb-3">
                      {itemData?.data?.customers?.length > 0 ? (
                        itemData?.data?.customers?.map((customer, index) => (
                          <div className="member-profile-chip" key={index}>
                            <img
                              src={customer?.profile_image_url || userImg}
                              loading="lazy"
                              alt=""
                              className="customer-thumb rounded-circle object-fit-cover"
                              width="120"
                              height="120"
                            />

                            <h6>{customer?.name}</h6>

                            <Button
                              className="add-button py-1 small"
                              onClick={() => {
                                setSelectedCustomerData(customer);
                                setCustomerDetailModalShow(true);
                              }}
                            >
                              View Profile
                            </Button>
                          </div>
                        ))
                      ) : (
                        <p>No Customers Yet...</p>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Tab>

              <Tab eventKey="investors" title="Investors" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <div className="mt-3 d-flex gap-2 flex-wrap gap-2 mb-3">
                      {itemData?.data?.investors?.length > 0 ? (
                        itemData?.data?.investors?.map((investor, index) => (
                          <div className="member-profile-chip" key={index}>
                            <img
                              src={investor?.profile_image_url || userImg}
                              loading="lazy"
                              alt=""
                              className="customer-thumb rounded-circle object-fit-cover"
                              width="120"
                              height="120"
                            />

                            <h6>{investor?.name}</h6>

                            <Button
                              className="add-button py-1 small"
                              onClick={() => {
                                setSelectedCustomerData(investor);
                                setCustomerDetailModalShow(true);
                              }}
                            >
                              View Profile
                            </Button>
                          </div>
                        ))
                      ) : (
                        <p>No Customers Yet...</p>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="pitch" title="Pitch Deck" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <h4 className="theme-text-2 text-capitalize">Pitch Deck</h4>
                    <hr />
                    <Row className="my-lg-3 mt-3">
                      <Col lg={3} className="position-relative min-h-full">
                        <Card>
                          <Image
                            className="modal-profile-img position-static rounded"
                            src={itemData?.data?.profile_image_url || COURSEIMG}
                            alt="image"
                            width={"100%"}
                          />
                        </Card>
                      </Col>
                      <Col lg={9} className="min-h-full">
                        <Row>
                          <Col md={3}>
                            <Card.Text className="mb-0 fw-bold text-capitalize">
                              Company Name
                            </Card.Text>
                          </Col>
                          <Col md={9}>
                            <Card.Text className="text-muted mb-0 text-capitalize">
                              {itemData?.data?.company_name ?? "Not Available"}
                            </Card.Text>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={3}>
                            <Card.Text className="mb-0 fw-bold text-capitalize">
                              Website
                            </Card.Text>
                          </Col>
                          <Col md={9}>
                            <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                              <a
                                target="_blank"
                                href={`${itemData?.data?.website}`}
                                rel="noreferrer"
                                className="text-muted text-truncate d-inline-block w-100 text-overflow-desc-xs"
                              >
                                {itemData?.data?.website}
                              </a>
                            </Card.Text>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={3}>
                            <Card.Text className="mb-0 fw-bold text-capitalize">
                              industry
                            </Card.Text>
                          </Col>
                          <Col md={9}>
                            <Card.Text className="text-muted mb-0">
                              {itemData?.data?.industry ?? "Not Available"}
                            </Card.Text>
                          </Col>
                        </Row>
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <Card.Text className="mb-0 fw-bold text-capitalize">
                          Description
                        </Card.Text>
                      </Col>
                      <Col md={9}>
                        <Card.Text className="text-muted mb-0">
                          {itemData?.data?.description ? (
                            <TextFormatter text={itemData?.data?.description} />
                          ) : (
                            "Not Available"
                          )}
                        </Card.Text>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={3}>
                        <Card.Text className="mb-0 fw-bold text-capitalize">
                          company stage
                        </Card.Text>
                      </Col>
                      <Col md={9}>
                        <Card.Text className="text-muted mb-0">
                          {itemData?.data?.company_stage ?? "Not Available"}
                        </Card.Text>
                      </Col>
                    </Row>
                    <hr />

                    <Row>
                      <Col md={3}>
                        <Card.Text className="mb-0 fw-bold text-capitalize">
                          sector
                        </Card.Text>
                      </Col>
                      <Col md={9}>
                        <Card.Text className="text-muted mb-0">
                          {itemData?.data?.sector ?? "Not Available"}
                        </Card.Text>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      {itemData?.data?.members?.length > 0 && (
                        <Col lg={6}>
                          <Card.Text className="mb-0 fw-bold text-capitalize">
                            members:
                          </Card.Text>
                          <ul>
                            {itemData?.data?.members?.map((member) => (
                              <li>{member?.name}</li>
                            ))}
                          </ul>
                        </Col>
                      )}
                      {itemData?.data?.customers?.length > 0 && (
                        <Col lg={6}>
                          <Card.Text className="mb-0 fw-bold text-capitalize">
                            Customers:
                          </Card.Text>
                          <ul>
                            {itemData?.data?.customers?.map((customer) => (
                              <li>{customer?.name}</li>
                            ))}
                          </ul>
                        </Col>
                      )}
                      {itemData?.data?.investors?.length > 0 && (
                        <Col lg={6}>
                          <Card.Text className="mb-0 fw-bold text-capitalize">
                            Investors:
                          </Card.Text>
                          <ul>
                            {itemData?.data?.investors?.map((investor) => (
                              <li>{investor?.name}</li>
                            ))}
                          </ul>
                        </Col>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </div>
        )}
      </Container>

      <CustomerDetailsPopup
        customerData={selectedCustomerData}
        setCustomerData={setSelectedCustomerData}
        show={customerDetailModalShow}
        setshow={setCustomerDetailModalShow}
        state={itemData}
        onHide={() => {
          setCustomerDetailModalShow(false);
        }}
      />

      <MemberDetailsPopup
        memberData={selectedMemberData}
        setMemberData={setSelectedMemberData}
        show={memberDetailModalShow}
        setshow={setMemberDetailModalShow}
        state={itemData}
        onHide={() => {
          setMemberDetailModalShow(false);
        }}
      />
    </>
  );
}
export default withRouter(StartupDetails);
