import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { configDefaultValues } from "../../constants/configDefaultValues";

const SubDomainRouter = (contentType) => {
  const configStatus = useSelector((state) => state.config.status);
  const config = useSelector((state) => state.config.value);

  const [content, setContent] = useState("");

  useEffect(() => {
    try {
      // Check if config is not null and contains the specified contentType
      if (config?.appsettings != null && configStatus !== 'failed' && configStatus !== 'loading' && config?.appsettings?.hasOwnProperty(contentType)) {
        setContent(config?.appsettings[contentType]);
      } else {
        // Use provided default values for missing fields

        setContent(configDefaultValues[contentType] || `${contentType}`);
      }
    } catch (error) {
      // Handle any other errors that might occur during content retrieval
      console.error("Error retrieving content:", error);
      // Set a default value or handle the error as needed
      setContent(`${contentType}`);
    }
  }, [config, configStatus, contentType]);

  return content;
};

export default SubDomainRouter;
