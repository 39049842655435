import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";

const DynamicFieldEdit = ({ field, onFieldChange, onRemove, key }) => {
  const handleFieldsChange = (updatedAttachment) => {
    const newAttachment = {
      id: uuidv4(),
      documentType: "link",
      field_name: updatedAttachment?.field_name || "",
      file_content: updatedAttachment?.file_content || "",
      file_extension: "",
    };
    onFieldChange(newAttachment);
  };

  const onFieldNameChange = (value) => {
    const updatedAttachment = {
      ...field,
      field_name: value,
    };
    handleFieldsChange(updatedAttachment);
  };

  const onFileContentChange = (value) => {
    const updatedAttachment = {
      ...field,
      file_content: value,
    };
    handleFieldsChange(updatedAttachment);
  };

  return (
    <Col md={12} className="dynamicField pt-3">
      <div className="d-flex gap-3 w-100 flex-sm-row flex-column-reverse align-items-sm-start align-items-end mb-3">
        <div className="d-flex align-items-center gap-3 flex-sm-row flex-column flex-grow-1 w-100">
          <Form.Group className="mb-md-3 flex-grow-1 w-100 form-floating position-relative">
            <Form.Control
              type="text"
              className="w-100"
              name="document"
              id={`type-${key}`}
              placeholder="Type"
              value={field.field_name}
              onChange={(e) => onFieldNameChange(e.target.value)}
            />
            <Form.Label className="form-label" htmlFor={`type-${key}`}>
              Link Type
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-md-3 flex-grow-1 w-100 form-floating position-relative">
            <Form.Control
              type="url"
              className="w-100"
              name="document"
              id={`link-${key}`}
              placeholder="Link"
              value={field.file_content}
              onChange={(e) => onFileContentChange(e.target.value)}
            />
            <Form.Label className="form-label" htmlFor={`link-${key}`}>
              URL
            </Form.Label>
          </Form.Group>
        </div>
        <Button
          className="clickable actionDltBtn btn border-danger"
          onClick={() => onRemove(field.id)}
        >
          <span className="addBttn d-block">
            <Trash />
          </span>
        </Button>
      </div>
    </Col>
  );
};

export default DynamicFieldEdit;
