import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import COURSEIMG from "../../img/Web-Development-Course-Thumbnail.jpg";
import ConvertDateFormat from "../convertDateFormat/convertDateFormat";
import CustomLoader from "../loader/loader";
import "./courseCard.css";
import UTCLocalTimeConverter from "../UTCLocalTimeConverter/UTCLocalTimeConverter";
import moment from "moment-timezone";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";

function Courses() {
  const entities = "courses";

  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const populateList = async () => {
    try {
      await axios.get(`${Actions.BASE_URL}${entities}`).then((response) => {
        const sortedData = response.data.sort((a, b) =>
          moment(a.start_date).diff(moment(b.start_date))
        );
        setItems(sortedData);
        setLoading(false);
      });
    } catch (error) {
      console.log("There was an error", error);
    }
  };

  useEffect(() => {
    populateList();
  }, []);

  const homeCourseData = items.slice(0, 3);
  const coursesAlias = SubDomainRouter("coursesAlias");

  return (
    <section className="demosSection section semi-bg">
      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg">
          <h1 className="main-heading text-center text-capitalize">
            Upcoming {coursesAlias ? coursesAlias : "courses"}
          </h1>
          <Row className="justify-content-center mx-0">
            {homeCourseData?.length > 0 ? (
              homeCourseData?.map((item) => (
                <Col
                  xl={4}
                  md={5}
                  sm={10}
                  xs={11}
                  key={item.id}
                  className="pt-2 pb-3"
                >
                  <Card className="DemoCard p-3">
                    <div className="img-bg h-100">
                      <img
                        onClick={() => {
                          navigate("/courses/details/" + item.id);
                        }}
                        src={item.listing_image_url || COURSEIMG}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = COURSEIMG;
                        }}
                        width="100%"
                        alt=""
                      />
                    </div>
                    <Card.Body className="p-0 d-flex align-items-center justify-content-between flex-column">
                      <div>
                        <Card.Title>
                          <Link
                            to={`/courses/details/${item.id}`}
                            className="detailLink"
                          >
                            {item.title}
                          </Link>
                        </Card.Title>
                        <Card.Text className="text-overflow-desc mb-2">
                          {item.description}
                        </Card.Text>
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          <p className="card-text mb-0 d-flex align-items-center">
                            <ConvertDateFormat inputDate={item.start_date} />
                          </p>
                          -
                          <p className="card-text mb-0 d-flex align-items-center">
                            <ConvertDateFormat inputDate={item.end_date} />
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-center gap-3">
                          <p className="card-text mb-0 d-flex align-items-center">
                            <UTCLocalTimeConverter utcTime={item.start_time} />
                          </p>
                        </div>
                      </div>
                      <Link
                        to={`/courses/details/${item.id}`}
                        className="btn-more btn mt-2"
                      >
                        View More
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <p className="text-center">
                No {coursesAlias ? coursesAlias : "courses"} Found.
              </p>
            )}
          </Row>
        </Container>
      )}
    </section>
  );
}

export default Courses;
