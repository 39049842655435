import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { Check2Circle, Search } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import ConvertDateFormat from "../../../components/convertDateFormat/convertDateFormat";
import EmailBodyNewsletter from "../../../components/emailTemplate/emailBodyNewsletter";
import EmailFooter from "../../../components/emailTemplate/emailFooter";
import EmailHeader from "../../../components/emailTemplate/emailHeader";
import CustomLoader from "../../../components/loader/loader";
import SubDomainRouter from "../../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../../components/useUserDetails/useTokenDetails";
import * as Actions from "../../../constants/baseUrl";
import ZoomIcon from "../../../img/zoomIcon.png";
import ConvertCreatedDateFormat from "../../../components/convertDateFormat/convertCreatedDateFormat";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

const AllEventsRegistrants = () => {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const logo = SubDomainRouter("logo");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");

  const fromEmail = SubDomainRouter("fromEmail");
  const title = SubDomainRouter("title");

  const [loading, setLoading] = useState(true);
  const [loadingReq, setLoadingReq] = useState(false);
  const [registrants, setRegistrants] = useState([]);
  const [formData, setFormData] = useState(null);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderBy, setOrderBy] = useState("first_name");
  const [order, setOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");

  const fetchDataForEntity = async (entityType, entityId) => {
    try {
      const response = await axios.get(
        `${Actions.BASE_URL}${entityType}s/${entityId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data for entity:", error);
      return null;
    }
  };

  const handleSelectedItemDetails = async (
    entityType,
    entityId,
    userEmail,
    userName
  ) => {
    const itemData = await fetchDataForEntity(entityType, entityId);
    setSelectedItemData({
      ...itemData,
      userEmail: userEmail,
      userName: userName,
    });
  };

  useEffect(() => {
    const fetchAdditionalDataForRegistrants = async (registrants) => {
      try {
        const additionalDataPromises = registrants?.map(async (registrant) => {
          const { entityType, entityId } = registrant;
          const additionalData = await fetchDataForEntity(entityType, entityId);
          const title = additionalData?.title ? additionalData?.title : null;
          const start_date = additionalData?.start_date
            ? additionalData?.start_date
            : null;
          const modifiedDate = additionalData?.modifiedDate
            ? additionalData?.modifiedDate
            : null;
          const online_join_link = additionalData?.online_join_link
            ? additionalData?.online_join_link
            : null;

          return {
            ...registrant,
            title,
            online_join_link,
            start_date,
            modifiedDate,
          };
        });

        const additionalDataWithTitles = await Promise.all(
          additionalDataPromises
        );

        setRegistrants(additionalDataWithTitles);
        return additionalDataWithTitles;
      } catch (error) {
        console.error("Error fetching additional data for registrants:", error);
        return null;
      }
    };
    const getAllRegistrants = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Actions.BASE_URL}registrants`);
        const sortedData = response?.data?.sort((a, b) =>
          a.first_name.localeCompare(b.first_name)
        );
        await fetchAdditionalDataForRegistrants(sortedData);
      } catch (error) {
        console.error("Error calling API:", error);
      } finally {
        setLoading(false);
      }
    };

    getAllRegistrants();
  }, []);
  useEffect(() => {
    const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
    const footer_email_content = EmailFooter(logo, title);

    const selectedItemDetails = [selectedItemData];

    const customMessage = `<p style="font-size:16px; line-height:1; margin:0;">
    <b> Hi ${selectedItemData ? selectedItemData?.userName : null},</b><br/>
    Come and learn ${selectedItemData ? selectedItemData?.title : null
      } with Us</p>`;

    const mappedBodyData = EmailBodyNewsletter(
      selectedItemDetails,
      customMessage,
      demosAlias,
      coursesAlias
    );
    const newState = {
      ...formData,
      Attachments: null,
      Template: null,
      IsHTML: true,
      userId: myUserId,
      type: "email",
      from: fromEmail,
      body: header_email_content + mappedBodyData + footer_email_content,
    };

    setFormData(newState);
  }, [
    coursesAlias,
    demosAlias,
    fromEmail,
    logo,
    myUserId,
    selectedItemData,
    title,
  ]);

  useEffect(() => {
    // handle submit function if required
    const handleSubmit = () => {
      setLoadingReq(true);

      const data = {
        ...formData,
        message: formData.body,
        to: [selectedItemData.userEmail],
        subject: `${selectedItemData.title ?? ""} Newsletter for ${selectedItemData.userName ?? ""
          }`,
      };

      axios
        .post(`${Actions.APIGATEWAY_URL}email`, data, {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setLoadingReq(false);
          successCampaignAlert("Email");
          setFormData(null);
        })
        .catch((error) => {
          setLoadingReq(false);
          console.log(error.response);
        });
    };

    if (
      formData &&
      selectedItemData?.title &&
      selectedItemData?.userEmail &&
      selectedItemData?.userName
    ) {
      handleSubmit();
    }
  }, [formData]);

  const successCampaignAlert = (param) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center mt-2 text-capitalize">
              {param} sent successfully
            </h5>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                onClose();
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };

  // ---------
  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredContacts = registrants.filter(
    (contact) =>
      contact?.first_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.last_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.phone?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.whatsapp?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.listName?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const sortedContacts = filteredContacts.sort((a, b) => {
    const isAsc = order === "asc";
    return isAsc
      ? a[orderBy]?.localeCompare(b[orderBy])
      : b[orderBy]?.localeCompare(a[orderBy]);
  });

  return (
    <div className="my-demos mb-4 all-contacts">
      {loadingReq ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="my-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <h4 className="theme-text mb-1">Registrants</h4>

              <InputGroup className="mb-3 w-100 tableSearch ms-auto rounded-pill position-relative">
                <Form.Control
                  className="rounded-pill"
                  onChange={handleSearch}
                  placeholder="Search"
                />
                <InputGroup.Text
                  id="basic-addon1"
                  className="border-0 bg-transparent position-absolute top-0 h-100 end-0"
                >
                  <Search />
                </InputGroup.Text>
              </InputGroup>
            </div>

            {registrants.length > 0 ? (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 768 }}
                  aria-label="simple table"
                  className="striped rounded-0"
                >
                  <TableHead className="bg-theme">
                    <TableRow>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme">
                        <TableSortLabel
                          active={orderBy === "first_name"}
                          direction={orderBy === "first_name" ? order : "asc"}
                          onClick={() => handleSortRequest("first_name")}
                        >
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme">
                        <TableSortLabel
                          active={orderBy === "email"}
                          direction={orderBy === "email" ? order : "asc"}
                          onClick={() => handleSortRequest("email")}
                        >
                          Email
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme">
                        <TableSortLabel
                          active={orderBy === "title"}
                          direction={orderBy === "title" ? order : "asc"}
                          onClick={() => handleSortRequest("title")}
                        >
                          Event Title
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme">
                        <TableSortLabel
                          active={orderBy === "start_date"}
                          direction={orderBy === "start_date" ? order : "asc"}
                          onClick={() => handleSortRequest("start_date")}
                        >
                          Event Date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme">
                        <TableSortLabel
                          active={orderBy === "modifiedDate"}
                          direction={orderBy === "modifiedDate" ? order : "asc"}
                          onClick={() => handleSortRequest("modifiedDate")}
                        >
                          Registered Date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white text-center fw-bold bg-theme">
                        Event Join Link
                      </TableCell>
                      <TableCell className="text-nowrap text-white text-center fw-bold bg-theme">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedContacts
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((registrant) => (
                        <TableRow key={registrant?.id}>
                          <TableCell>
                            {registrant?.first_name}&nbsp;
                            {registrant?.last_name}
                          </TableCell>
                          <TableCell>{registrant?.email}</TableCell>
                          <TableCell>
                            {" "}
                            <span className="text-overflow-desc-xs">
                              <span className="text-capitalize">
                                {registrant?.entityType}
                              </span>
                              , {registrant?.title ?? "Title not available"}
                            </span>
                          </TableCell>
                          <TableCell>
                            {registrant?.start_date ? (
                              <ConvertDateFormat
                                inputDate={registrant?.start_date}
                              />
                            ) : (
                              "Not available"
                            )}
                          </TableCell>
                          <TableCell>
                            {registrant?.modifiedDate ? (
                              <ConvertCreatedDateFormat
                                inputDate={registrant?.modifiedDate}
                              />
                            ) : (
                              "Not available"
                            )}
                          </TableCell>
                          <TableCell>
                            {registrant?.online_join_link ? (
                              <Link
                                to={registrant?.online_join_link}
                                target="_blank"
                                className="zoom-icon-btn d-block m-auto"
                              >
                                <img
                                  src={ZoomIcon}
                                  alt="link"
                                  className="h-100 w-100"
                                />
                              </Link>
                            ) : (
                              "Not Available"
                            )}
                          </TableCell>
                          <TableCell>
                            <span className="d-flex align-items-center justify-content-center">
                              {registrant?.entityType &&
                                registrant.entityId &&
                                registrant.title &&
                                registrant.first_name ? (
                                <Button
                                  className="btn add-button"
                                  onClick={() =>
                                    handleSelectedItemDetails(
                                      registrant.entityType,
                                      registrant.entityId,
                                      registrant.email,
                                      registrant.first_name
                                    )
                                  }
                                >
                                  Send Mail
                                </Button>
                              ) : (
                                "Action not available"
                              )}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[50, 75, 100]}
                  component="div"
                  className="d-flex align-items-center justify-content-end"
                  count={sortedContacts?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </TableContainer>
            ) : (
              <p>No Users Founds...</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AllEventsRegistrants;
