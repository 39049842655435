import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import API from "../../../components/API/API";
import CustomLoader from "../../../components/loader/loader";
import SubDomainRouter from "../../../components/subDomainRouter/subDomainRouter";
import withRouter from "../../../constants/withRouter";
import TextFormatter from "../../../components/textFormat/textFormat";
import JoinRequestModal from "../../../components/joinRequestModal/joinRequestModal";

function DynamicHomepageDetails(props) {
  const entities = "items";
  const entityType = "homepage";

  const siteName = SubDomainRouter("title");

  const [loading, setLoading] = useState(true);
  const [entityData, setEntityData] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [selectedBoxData, setSelectedBoxData] = useState('');

  const populateList = async (id) => {
    try {
      const response = await API("get", entities + "/" + entityType + "/" + id);
      setEntityData(response);
    } catch (error) {
      console.log("There was an error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList(props.params.id);
  }, [props.params.id]);

  return (
    <>
      <Helmet>
        <title>
          {entityData?.data?.page_title
            ? `${entityData?.data?.page_title}`
            : "Page"}{" "}
          |{siteName ? siteName : window.location.host}
        </title>
        {/* styles */}
        {entityData?.data?.styleLinks?.length > 0 &&
          entityData?.data?.styleLinks[0]?.style_link !== ""
          ? entityData?.data?.styleLinks.map((item, index) =>
            item?.style_link.startsWith("<link") ? (
              item.style_link
            ) : (
              <link
                rel="stylesheet"
                href={item?.style_link}
                data-priority="high"
                key={index}
              />
            )
          )
          : ""}

        {entityData?.data?.custom_css &&
          entityData?.data?.custom_css?.startsWith("<style") ? (
          entityData?.data?.custom_css
        ) : entityData?.data?.custom_css ? (
          <style data-priority="high">{entityData?.data?.custom_css}</style>
        ) : (
          ""
        )}

        {/* script */}
        {entityData?.data?.scriptLinks?.length > 0 &&
          entityData?.data?.scriptLinks[0]?.script_link !== ""
          ? entityData?.data?.scriptLinks.map((item, index) =>
            item?.script_link?.startsWith("<script") ? (
              item.script_link
            ) : (
              <script
                src={item?.script_link}
                type="text/javascript"
                key={index}
              ></script>
            )
          )
          : ""}

        {entityData?.data?.custom_js &&
          entityData?.data?.custom_js?.startsWith("<script") ? (
          entityData?.data?.custom_js
        ) : entityData?.data?.custom_js ? (
          <script type="text/javascript">{entityData?.data?.custom_js}</script>
        ) : (
          ""
        )}
      </Helmet>

      {loading ? (
        <CustomLoader />
      ) : (
        <>
          {(entityData?.data?.sliderImages &&
            (Object?.keys(entityData?.data?.sliderImages)?.length > 0) && Object?.values(entityData?.data?.sliderImages)[0] !== "") ||
            entityData?.data?.slider_heading ? (
            <section
              className="heroSection position-relative"
              style={{ height: "80vh" }}
            >
              <Swiper
                style={{ height: "80vh" }}
                spaceBetween={30}
                effect={"fade"}
                navigation={false}
                speed={900}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[EffectFade, Autoplay, Navigation, Pagination]}
                className="mySwiper relative"
              >
                {loading ? (
                  <>
                    <span className="register-loader position-absolute bg-opacity-50 h-50 w-50"></span>
                    <span className="spinnerOverlay position-absolute"></span>
                  </>
                ) : (
                  <>
                    {entityData?.data?.sliderImages &&
                      Object?.keys(entityData?.data?.sliderImages)?.length > 0 ? (
                      Object.keys(entityData?.data?.sliderImages)?.map(
                        (imageKey) => (
                          <SwiperSlide
                            key={imageKey}
                            className="d-flex align-items-center"
                            style={{
                              backgroundImage: `radial-gradient(#00000099, #0712128c, #00000074), url(${entityData?.data?.sliderImages[imageKey]})`,
                            }}
                          ></SwiperSlide>
                        )
                      )
                    ) : (
                      <SwiperSlide
                        className="d-flex align-items-center"
                        style={{
                          backgroundImage: `radial-gradient(#00000099, #0712128c, #00000074), url(https://images.unsplash.com/photo-1469719847081-4757697d117a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
                        }}
                      ></SwiperSlide>
                    )}
                  </>
                )}
              </Swiper>

              <Container fluid="lg" className="heroContentWrap h-100">
                <div className="heroContent d-flex flex-column align-items-center justify-content-between h-100 py-5">
                  <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center w-100">
                    <h1 className="theme-text">
                      {entityData?.data?.slider_heading}
                    </h1>
                    <h4 className="theme-text-2">
                      {entityData?.data?.slider_sub_heading}
                    </h4>
                    <p>{entityData?.data?.slider_description}</p>
                  </div>
                </div>
              </Container>
            </section>
          ) : null}

          <Container fluid="lg" className="startupsHomepage py-5">
            {entityData?.data?.dynamicSections?.length > 0 && entityData?.data?.dynamicSections?.map((data, index) => (
              <Row className="align-items-stretch my-5" key={index}>
                <Col lg={12}>
                  <h1>{data?.section_heading1}</h1>
                </Col>
                {data?.entityItems?.map((item, index) =>
                  item?.show ? (
                    <Col
                      key={index}
                      xl={3}
                      lg={4}
                      md={6}
                      className="py-2 min-h-full"
                    >
                      {item?.collect_data ?
                        <Card className="demoCard text-decoration-none d-flex flex-column justify-content-center align-items-center w-100 h-100 gap-0 itemImg" style={{ minHeight: '18vh' }}>
                          {item?.listing_image_url &&
                            <div
                              onClick={() => {
                                setModalShow(true);
                                setSelectedBoxData(item);
                              }}
                              className="image-wrapper text-decoration-none d-flex align-items-center justify-content-center position-relative linkImg">
                              <Image
                                src={item?.listing_image_url}
                                width={"100%"}
                                alt="img"
                              />
                            </div>
                          }
                          <h5
                            onClick={() => {
                              setModalShow(true);
                              setSelectedBoxData(item);
                            }} className="theme-text-2 h5 text-decoration-none text-capitalize mb-0 mt-3">
                            {item?.title}
                          </h5>
                          {item?.description && <Card.Text className="text-dark text-left text-overflow-desc mt-1">
                            {item?.description}
                          </Card.Text>}
                          <Button
                            onClick={() => {
                              setModalShow(true);
                              setSelectedBoxData(item);
                            }}
                            className="add-button px-4">
                            {item.buttonText ?? "Join Now"}
                          </Button>
                        </Card> :
                        <Link
                          to={item?.path}
                          className="demoCard card text-decoration-none d-flex flex-column justify-content-center align-items-center w-100 h-100 gap-0 itemImg" style={{ minHeight: '18vh' }}
                        >
                          {item?.listing_image_url &&
                            <div className="image-wrapper d-flex align-items-center justify-content-center position-relative linkImg">
                              <Image
                                src={item?.listing_image_url}
                                width={"100%"}
                                alt="img"
                              />
                            </div>
                          }
                          <h5 className="theme-text-2 text-capitalize mb-0 mt-3">
                            {item?.title}
                          </h5>
                          <Card.Text className="text-dark text-left text-overflow-desc mt-1">
                            {item?.description}
                          </Card.Text>
                        </Link>
                      }
                    </Col>
                  ) : null
                )}
              </Row>
            ))}

            <div className="pt-5 mt-4">
              {entityData?.data?.section_heading2 && (
                <>
                  {entityData?.data?.sub_heading && (
                    <h5 className="text-center mb-1 theme-text">
                      {entityData?.data?.sub_heading}
                    </h5>
                  )}
                  <h1 className="text-center mt-1 mb-4">
                    {entityData?.data?.section_heading2}
                  </h1>
                </>
              )}
              {entityData?.data?.description && (
                <Card.Text className="text-muted mb-0">
                  <TextFormatter
                    text={entityData?.data?.description}
                    isTruncated={false}
                  />
                </Card.Text>
              )}
            </div>
          </Container>

          <div
            className="mt-5 pt-4"
            dangerouslySetInnerHTML={{
              __html: entityData?.data?.html_content || "",
            }}
          ></div>

          {entityData?.data?.keywords && (
            <div className="d-none visually-hidden">
              {entityData?.data?.keywords}
            </div>
          )}
        </>
      )}


      <JoinRequestModal
        show={modalShow}
        setshow={setModalShow}
        modalData={selectedBoxData}
      />
    </>
  );
}
export default withRouter(DynamicHomepageDetails);
