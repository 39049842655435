import React, { useEffect } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";

const SocialShare = ({ url, iconsSize, image, description, title }) => {
  const siteName = SubDomainRouter("title");

  useEffect(() => {
    document.title = siteName;
    return () => {
      document.title = siteName;
    };
  }, [siteName]);

  const shareQuote = " 🚀 Check out the link below to learn!";

  return (
    <>
      {/* Facebook share: Use Open Graph meta tags in HTML head for image */}
      <FacebookShareButton className="w-auto" url={url} quote={shareQuote}>
        <FacebookIcon size={iconsSize} round={true} />
      </FacebookShareButton>

      {/* WhatsApp share: Use Open Graph meta tags in HTML head for image */}
      <WhatsappShareButton
        className="w-auto"
        url={url}
        title={`_*${title}*_ \n${description}\n \n*${shareQuote}*`}
        separator={`\n`}
        image={image}
      >
        <WhatsappIcon size={iconsSize} round={true} />
      </WhatsappShareButton>
      <TelegramShareButton
        className="w-auto"
        url={url}
        title={`<strong>${title}</strong> \n${description}\n \n<strong>${shareQuote}</strong>`}
        separator={`\n`}
        image={image}
      >
        <TelegramIcon size={iconsSize} round={true} />
      </TelegramShareButton>

      {/* LinkedIn share: Supports image directly */}
      <LinkedinShareButton
        className="w-auto"
        url={url}
        title={`${title} \n${description}\n \n${shareQuote}`}
        summary={description}
        source={url}
        image={image}
      >
        <LinkedinIcon size={iconsSize} round={true} />
      </LinkedinShareButton>

      {/* Twitter share: Supports image directly */}
      <TwitterShareButton
        className="w-auto"
        url={url}
        title={`${title}* \n${description}\n \n${shareQuote}`}
        image={image}
      >
        <TwitterIcon size={iconsSize} round={true} />
      </TwitterShareButton>
    </>
  );
};

export default SocialShare;
