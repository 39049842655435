import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Check2Circle, XCircle } from 'react-bootstrap-icons';
import { useSearchParams } from 'react-router-dom';
import EmailBodyContent from '../../components/emailTemplate/emailBody';
import EmailFooter from '../../components/emailTemplate/emailFooter';
import EmailHeader from '../../components/emailTemplate/emailHeader';
import SubDomainRouter from '../../components/subDomainRouter/subDomainRouter';
import TokenDetails from '../../components/useUserDetails/useTokenDetails';
import * as Actions from "../../constants/baseUrl";
import withRouter from '../../constants/withRouter';

const AutomateCampaign = (props) => {
    const entityType = props?.params?.entityType;
    const [searchParams] = useSearchParams();
    const entityId = searchParams.get('id');
    const subject = searchParams.get('subject');
    const listId = searchParams.get('listId') ?? searchParams.get('listId');

    const logo = SubDomainRouter("logo");
    const title = SubDomainRouter("title");
    const coursesAlias = SubDomainRouter("coursesAlias");
    const demosAlias = SubDomainRouter("demosAlias");
    const eventsAlias = SubDomainRouter("eventsAlias");
    const fromEmail = SubDomainRouter("fromEmail");
    const adminEmail = SubDomainRouter("adminEmail");
    const supportEmail = SubDomainRouter("supportEmail");
    const subscriberLstId = SubDomainRouter("subscriptionListID");
    const myToken = localStorage.getItem("token");
    const tokenDetails = TokenDetails();
    const myUserId = tokenDetails?.Email;

    const [loadingItems, setLoadingItems] = useState(true);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState();
    const [entityDetails, setEntityDetails] = useState([]);
    const [formData, setFormData] = useState({
        campaign_name: `My Campaign ${new Date()}`,
        description: "My Campaign Description",
        userId: myUserId,
        type: "campaign",
        emaillists: [],
        entityRelation: [],
        subject: subject ?? `${title} Campaign`,
        body_header: "",
        body: "",
        body_footer: "",
        body_plain_text: "",
        from: fromEmail,
        to: [],
        cc: [],
        bcc: [],
        scheduled_date: "",
        scheduled_time: "",
        campaign_sent_status: "",
        tags: [],
        comments: "",
    });
    const [selectedItemDetails, setSelectedItemDetails] = useState([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const [emailHeaderContent, setEmailHeaderContent] = useState(undefined);
    const [emailBodyContent, setEmailBodyContent] = useState(undefined);
    const [emailFooterContent, setEmailFooterContent] = useState(undefined);

    const handleFieldChange = (fieldName, fieldValue) => {
        const newValue =
            fieldName === "cc" || fieldName === "bcc" || fieldName === "to"
                ? fieldValue.split(/[, \n]+/).map(id => id.trim()).filter(id => id.length > 0)
                : fieldValue;

        setFormData(prevState => ({
            ...prevState,
            [fieldName]: newValue,
        }));
    };

    useEffect(() => {
        const GetItemDetails = async (entityId) => {
            try {
                const response = await axios.get(`${Actions.BASE_URL}${entityType}/${entityId}`, {
                    headers: {
                        Authorization: `Bearer ${myToken}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                });

                setEntityDetails(prevState => {
                    if (!prevState.some(item => item.id === response.data.id)) {
                        return [...prevState, response.data];
                    }
                    return prevState;
                });
            } catch (error) {
                console.error(`Error fetching details for entity ID: ${entityId}`, error.response);
            }
        };

        if (entityId) {
            const entityIds = entityId.includes(',')
                ? entityId.split(',').map(id => id.trim()).filter(Boolean)
                : [entityId];

            Promise.all(entityIds.map(GetItemDetails)).then(() => setLoadingItems(false));
        }
    }, [entityId, entityType, myToken]);

    useEffect(() => {


        const generateEntityDetails = (details) => {
            setSelectedItemDetails(details);
            const mappedBodyData = EmailBodyContent(
                details,
                null,
                demosAlias,
                coursesAlias,
                eventsAlias,
            );
            setEmailBodyContent(mappedBodyData);
            handleFieldChange("body", mappedBodyData);
        };

        if (entityDetails.length > 0) {
            generateEntityDetails(entityDetails);
        }

    }, [coursesAlias, demosAlias, entityDetails, eventsAlias]);

    useEffect(() => {
        const mappedBodyData = EmailBodyContent(
            selectedItemDetails,
            null,
            demosAlias,
            coursesAlias
        );

        setEmailBodyContent(mappedBodyData);
    }, [coursesAlias, demosAlias, selectedItemDetails]);


    useEffect(() => {
        const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
        const footer_email_content = EmailFooter(logo, title);
        setEmailHeaderContent(header_email_content);
        setEmailFooterContent(footer_email_content);

        setFormData(prevState => ({
            ...prevState,
            body_header: header_email_content,
            body_footer: footer_email_content,
        }));
    }, [coursesAlias, demosAlias, logo, title]);

    useEffect(() => {

        const handleSubmit = () => {
            setLoading(true);
            const defaultTemplateBody = `${emailHeaderContent} ${emailBodyContent} ${emailFooterContent}`;

            const data = {
                ...formData,
                subject: subject ?? `${title} Campaign`,
                entityRelation: entityDetails.map((detail) => ({ [entityType]: detail.id })),
                emaillists: [listId ?? subscriberLstId],
                body: defaultTemplateBody,
                to: [adminEmail, supportEmail],
                cc: [],
                from: fromEmail,
            };

            axios
                .post(`${Actions.BASE_URL}campaigns`, data, {
                    headers: {
                        Authorization: `Bearer ${myToken}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                .then(() => {
                    setLoading(false);
                    setSuccess(true);
                })
                .catch((error) => {
                    setLoading(false);
                    setSuccess(false);
                    console.error("Error sending campaign", error.response);
                });
        };

        if (
            selectedItemDetails.length > 0 &&
            emailBodyContent &&
            formData &&
            subscriberLstId &&
            !hasSubmitted
        ) {
            const timer = setTimeout(() => {
                handleSubmit();
                setHasSubmitted(true);
            }, 1000);

            return () => clearTimeout(timer);
        }

    }, [emailBodyContent, selectedItemDetails, formData, subscriberLstId, hasSubmitted, emailHeaderContent, emailFooterContent, subject, title, entityDetails, listId, adminEmail, supportEmail, fromEmail, myToken, entityType]);



    return (
        <Container fluid="lg" className="py-5 my-5 demosEditSection">

            <div className="my-4 d-flex align-items-center justify-content-center" style={{ minHeight: '60vh' }}>
                {loadingItems ?
                    (
                        <div className="d-flex flex-column gap-4">
                            <span className="register-loader position-relative"> </span>
                            <h4 className='text-center mt-5'>
                                Fetching Data'...
                            </h4>
                        </div>
                    )
                    :
                    loading ? (
                        <div className="d-flex flex-column gap-4">
                            <span className="register-loader position-relative"> </span>
                            <h4 className='text-center mt-5'>
                                Sending campaign for {selectedItemDetails.map((item) => item?.title).join(', ')}
                            </h4>
                        </div>
                    ) : null}

                {!loading && success && (
                    <div className='d-flex flex-column gap-3 justify-content-around align-items-center'>
                        <Check2Circle className="display-1 theme-text" />
                        <h4>Campaign sent successfully</h4>
                    </div>
                )}

                {!loading && success === false && (
                    <div className='d-flex flex-column gap-3 justify-content-around align-items-center'>
                        <XCircle className="display-1 theme-danger" />
                        <h4>Failed to send campaign. Please try again later</h4>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default withRouter(AutomateCampaign);
