import { ErrorRounded } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import { Button, Col, Form, Toast } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";

const VideoUploader = ({ onVideoChange, inputIdPrefix, fileCategory }) => {
  const [videos, setVideos] = useState([
    { content: "", extension: "", field_name: "", url: "" },
  ]);
  const [uploadOption, setUploadOption] = useState("file");
  const [showToast, setShowToast] = useState(false);
  const inputRef = useRef(null);

  const handleVideoChange = (event, index) => {
    const file = event.target?.files[0];
    if (file) {
      const fileExtension = getFileExtension(file.name);
      if (file.size <= 5 * 1024 * 1024) {
        getBase64(file).then((base64) => {
          const newVideo = {
            documentType: fileCategory,
            field_name: videos[index].field_name,
            other_fields: videos[index].other_fields,
            file_content: base64,
            file_extension: fileExtension,
          };

          const updatedVideos = [...videos];
          updatedVideos[index] = newVideo;
          setVideos(updatedVideos);
          onVideoChange(updatedVideos);
        });
      } else {
        inputRef.current.value = "";
        setShowToast(true);
      }
    }
  };

  const getFileExtension = (fileName) => {
    return fileName.split(".").pop().toLowerCase();
  };

  const onFieldNameChange = (index, value) => {
    const updatedVideos = [...videos];
    updatedVideos[index].field_name = value;
    setVideos(updatedVideos);
    onVideoChange(updatedVideos);
  };
  const onURLChange = (index, value) => {
    const updatedVideos = [...videos];
    updatedVideos[index].url = value;
    setVideos(updatedVideos);
    onVideoChange(updatedVideos);
  };

  const addVideoField = () => {
    const newVideo = { content: "", extension: "", field_name: "" };
    setVideos([...videos, newVideo]);
  };

  const removeVideoField = (index) => {
    const updatedVideos = videos.filter((_, i) => index !== i);
    setVideos(updatedVideos);
    onVideoChange(updatedVideos);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64 = reader.result.split(",")[1];
        resolve(base64);
      };
    });
  };

  return (
    <Col md={12} className="dynamicField">
      <Button
        className="clickable add-button mb-3 ms-auto d-flex"
        onClick={addVideoField}
      >
        <span className="addBttn d-block">+</span>
      </Button>
      <Form.Group className="form-floating select mb-3 w-md-fit">
        <select
          id="subcategory"
          className="form-control form-select pe-md-4"
          required
          name="subcategory"
          multiple=""
          onChange={(e) => setUploadOption(e.target.value)}
        >
          <option value="file">Upload Video File</option>
          <option value="url">Add Video URL</option>
        </select>
      </Form.Group>

      {videos?.map((video, index) => (
        <div className="d-flex gap-3 align-items-start" key={index}>
          <div className="d-flex flex-column flex-wrap gap-2 w-100 mb-3">
            <Form.Group className="form-floating position-relative">
              <Form.Control
                type="text"
                className="uploadlabel docUploader w-100"
                name="document"
                placeholder="title"
                id={`${inputIdPrefix}-label-${index}`}
                onChange={(e) => onFieldNameChange(index, e.target.value)}
              />
              <Form.Label
                className="form-label file-label"
                htmlFor={`${inputIdPrefix}-label-${index}`}
              >
                Add your {fileCategory} title
              </Form.Label>
            </Form.Group>
            {uploadOption !== "file" ? null : (
              <Form.Group className="flex-grow-1">
                <Form.Label
                  className="form-label file-upload-label w-100 mb-0"
                  htmlFor={`${inputIdPrefix}-file-${index}`}
                >
                  <Form.Control
                    ref={inputRef}
                    type="file"
                    className="uploadFile docUploader w-100 mt-1"
                    name="document"
                    id={`${inputIdPrefix}-file-${index}`}
                    accept="video/*"
                    onChange={(e) => handleVideoChange(e, index)}
                  />
                  <div className="docPreview">
                    <span className="theme-text-2 d-block text-capitalize">
                      *Upload Your {fileCategory} (Up to 5MB)
                    </span>
                  </div>
                </Form.Label>
              </Form.Group>
            )}
            {uploadOption !== "url" ? null : (
              <Form.Group className="form-floating position-relative">
                <Form.Control
                  type="text"
                  className="uploadlabel docUploader w-100"
                  name="document"
                  placeholder="Link"
                  id={`${inputIdPrefix}-label-link-${index}`}
                  onChange={(e) => onURLChange(index, e.target.value)}
                />
                <Form.Label
                  className="form-label file-label text-capitalize"
                  htmlFor={`${inputIdPrefix}-label-link-${index}`}
                >
                  Add A {fileCategory} Url
                </Form.Label>
              </Form.Group>
            )}
          </div>
          <Button
            className="clickable actionDltBtn btn border-danger"
            onClick={() => removeVideoField(index)}
          >
            <span className="addBttn d-block">
              <Trash />
            </span>
          </Button>
        </div>
      ))}
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        autohide
        delay={3500}
        style={{
          position: "absolute",
          top: "100%",
          right: 0,
          zIndex: "99",
        }}
      >
        <Toast.Header closeButton={false}>
          <strong className="mr-auto text-danger">
            <ErrorRounded />
            &nbsp;Error
          </strong>
        </Toast.Header>
        <Toast.Body>
          File size exceeds 5MB limit. Please choose a smaller video.
        </Toast.Body>
      </Toast>
    </Col>
  );
};

export default VideoUploader;
