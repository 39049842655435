import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";

const SingleFieldset = ({
  label,
  fieldKey,
  type = "input",
  field,
  onFieldChange,
  onRemove,
}) => {
  const handleFieldChange = (fieldName, fieldValue) => {
    const updatedField = {
      ...field,
      id: uuidv4(),
      [fieldName]: fieldValue,
    };

    onFieldChange(updatedField);
  };

  return (
    <div className="d-flex align-items-center gap-3 mt-3 batch">
      <Row className="mx-0 flex-grow-1">
        <Col md={12}>
          {type && type === "textarea" ? (
            <Form.Group className="form-floating position-relative">
              <Form.Control
                as={"textarea"}
                rows={4}
                style={{ height: "auto" }}
                name={fieldKey}
                placeholder="Course Name"
                value={field[fieldKey] || ""}
                onChange={(e) => handleFieldChange(fieldKey, e.target.value)}
              />
              <Form.Label className="form-label file-label" htmlFor={fieldKey}>
                {label}
              </Form.Label>
            </Form.Group>
          ) : (
            <Form.Group className="form-floating position-relative">
              <Form.Control
                type="text"
                className="w-100"
                id={fieldKey}
                name={fieldKey}
                placeholder="placeholder"
                value={field[fieldKey] || ""}
                onChange={(e) => handleFieldChange(fieldKey, e.target.value)}
              />
              <Form.Label className="form-label" htmlFor={fieldKey}>
                {label}
              </Form.Label>
            </Form.Group>
          )}
        </Col>
      </Row>
      <Button
        className="clickable actionDltBtn btn border-danger"
        onClick={() => onRemove(field.id)}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default SingleFieldset;
