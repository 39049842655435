import React, { useState } from "react";

function TextFormatter({ text, isTruncated = true }) {
  const [showMore, setShowMore] = useState(false);

  function isValidURL(string) {
    // A more comprehensive regex pattern for URL detection
    const pattern = /((https?:\/\/|www\.)[^\s]+)/g;
    return pattern.test(string);
  }

  function addSpaces(elements) {
    const result = [];
    elements.forEach((element, index) => {
      result.push(element);
      if (index < elements.length - 1) {
        result.push(" "); // Add a space between elements
      }
    });
    return result;
  }

  const truncatedText = showMore ? text : text.slice(0, 200) + "...";

  const lines = (isTruncated ? truncatedText : text)
    .split("\n")
    ?.map((line, index) => {
      const words = line.split(/\s+/); // Split by spaces while preserving spaces
      const textWithLinks = words?.map((word, wordIndex) => {
        if (isValidURL(word)) {
          return (
            <a
              key={wordIndex}
              href={word}
              target="_blank"
              rel="noopener noreferrer"
            >
              {word}
            </a>
          );
        }
        return word;
      });

      // Add spaces between words
      const textWithSpaces = addSpaces(textWithLinks);

      return (
        <React.Fragment key={index}>
          {textWithSpaces}
          <br />
        </React.Fragment>
      );
    });

  const handleShowMoreClick = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      {lines}{" "}
      {isTruncated && text.length > 200 ? (
        <button
          className="btn-link btn p-0 border-0 bg-transparent theme-text-2 d-inline fw-light"
          onClick={handleShowMoreClick}
          type="button"
        >
          <small>{showMore ? "Show Less" : "Show More"}</small>
        </button>
      ) : null}
    </>
  );
}

export default TextFormatter;
