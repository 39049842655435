// Import necessary dependencies
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import * as Actions from "../../../constants/baseUrl";
import { SuccessAlert } from "../../../components/generalMsgModal/Alerts";
import TokenDetails from "../../../components/useUserDetails/useTokenDetails";

const APIIntegrations = () => {
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const myToken = localStorage.getItem("token");

  const [formData, setFormData] = useState({})
  const [reqLoading, setReqLoading] = useState(false);

  const handleFieldChange = (fieldValue, fieldName) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };



  const populateList = () => {
    try {

      axios.get(`${Actions.BASE_URL}thirdpartyapis`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          setFormData({
            zoom_email: Object.values(res?.data[0]?.zoom)[0]?.email,
            zoom_account_id: Object.values(res?.data[0]?.zoom)[0]?.accountId,
            client_id: Object.values(res?.data[0]?.zoom)[0]?.clientId,
            client_secret: Object.values(res?.data[0]?.zoom)[0]?.clientSecret,
            paypal_email: res?.data[0]?.paypal
          })
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    populateList()
  }, [])


  const handleSubmit = (event) => {
    event.preventDefault();
    setReqLoading(true);

    const reqData = {
      "userId": myUserId,
      "zoom": {
        [formData?.zoom_email]: {
          "name": "zoom",
          "email": formData?.zoom_email,
          "accountId": formData?.zoom_account_id,
          "clientId": formData?.client_id,
          "clientSecret": formData?.client_secret
        },
      },
      "paypal": formData?.paypal_email

    }


    axios.post(`${Actions.BASE_URL}thirdpartyapis`, reqData, {
      headers: {
        Authorization: "Bearer " + myToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        SuccessAlert('Keys Updated Successfully');
      })
      .catch((error) => {
        console.error("API request failed:", error);
      })
      .finally(() => {
        setReqLoading(false);
      });
  };


  return (
    <Container
      fluid="lg"
      className="my-5 py-5 d-flex align-items-center justify-content-center flex-column"
    >

      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}


      <div className="header text-center">
        <h3 className="theme-text-2 text-center mb-0">
          API Integrations
        </h3>
      </div>
      <Form className="mt-sm-4 demosViewMoreForm" onSubmit={handleSubmit}>
        <Row className="border-bottom-0 mx-0">
          <Col md={12}>
            <div className="d-flex justify-content-between align-items-baseline">
              <h5>Zoom</h5>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
            </div>
            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="email"
                id="zoom_email"
                required
                placeholder="placeholder text"
                value={formData?.zoom_email}
                name="zoom_email"
                onChange={(e) => handleFieldChange(e.target.value, "zoom_email"
                )} />
              <Form.Label className="formLabel" htmlFor="zoom_email">
                Email
              </Form.Label>
            </Form.Group>

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                id="zoom_account_id"
                required
                placeholder="placeholder text"
                value={formData?.zoom_account_id}
                name="zoom_account_id"
                onChange={(e) => handleFieldChange(e.target.value, "zoom_account_id"
                )} />
              <Form.Label className="formLabel" htmlFor="zoom_account_id">
                Zoom Account ID
              </Form.Label>
            </Form.Group>

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                id="client_id"
                required
                placeholder="placeholder text"
                value={formData?.client_id}
                name="client_id"
                onChange={(e) => handleFieldChange(e.target.value, "client_id"
                )} />
              <Form.Label className="formLabel" htmlFor="client_id">
                Client ID
              </Form.Label>
            </Form.Group>

            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                id="client_secret"
                required
                placeholder="placeholder text"
                value={formData?.client_secret}
                name="client_secret"
                onChange={(e) => handleFieldChange(e.target.value, "client_secret"
                )} />
              <Form.Label className="formLabel" htmlFor="client_secret">
                Client Secret
              </Form.Label>
            </Form.Group>
          </Col>

          <Col md={12}>
            <div className="d-flex justify-content-between align-items-baseline mt-3">
              <h5>
                Paypal Email
              </h5>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
            </div>
            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                id="paypal_email"
                required
                placeholder="placeholder text"
                name="paypal_email"
                value={formData?.paypal_email}
                onChange={(e) => handleFieldChange(e.target.value, "paypal_email"
                )} />
              <Form.Label className="formLabel" htmlFor="paypal_email">
                Paypal Email
              </Form.Label>
            </Form.Group>
          </Col>

          <Col md={12}>
            <div className="d-flex justify-content-between align-items-baseline mt-3">
              <h5>
                Stripe Api Key
              </h5>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
            </div>
            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                id="stripe_api_key"
                required
                placeholder="placeholder text"
                name="stripe_api_key"
                onChange={(e) => handleFieldChange(e.target.value, "stripe_api_key"
                )} />
              <Form.Label className="formLabel" htmlFor="stripe_api_key">
                Stripe Api Key
              </Form.Label>
            </Form.Group>
          </Col>

          <Col md={12}>
            <div className="d-flex justify-content-between align-items-baseline mt-3">
              <h5>
                Sendgrid Api Key
              </h5>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
            </div>
            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                id="sendgrid_api_key"
                required
                placeholder="placeholder text"
                name="sendgrid_api_key"
                onChange={(e) => handleFieldChange(e.target.value, "sendgrid_api_key"
                )} />
              <Form.Label className="formLabel" htmlFor="sendgrid_api_key">
                Sendgrid Api Key
              </Form.Label>
            </Form.Group>
          </Col>
          <Col md={12} className="d-flex justify-content-end">
            <Button type="submit" className="add-button">Submit</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default APIIntegrations;
