import React from "react";

const InstructorGuide = () => {
  const documentId = "19rrLk4O8-ICfCZ4Occ2GLfdWV6PieKrUk2SucLeadjM";
  const embedUrl = `https://docs.google.com/document/d/${documentId}/preview`;
  return (
    <div className="pt-5 overflow-hidden" style={{ height: "100dvh" }}>
      {" "}
      <iframe
        title="How it Works"
        src={embedUrl}
        width="100%"
        height="800"
        frameBorder="0"
        scrolling="yes"
        seamless
      ></iframe>
    </div>
  );
};

export default InstructorGuide;
