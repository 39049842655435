const currentDomain = window.location.hostname;
const DynamicUrl =
  currentDomain === "events.indianstartups.us" ||
  currentDomain === "events.indianstartups.com"
    ? "indianstartups.us"
    : "ameerpetit.com";

export const BASE_URL = `https://api.${DynamicUrl}/api/entities/`;
export const API_URL = "https://email-service-ameerpetit-com.onrender.com/api/";
export const APIGATEWAY_URL = "https://api.ameerpetit.com/api/";
export const AUTH_URL = "https://identity.ameerpetit.com/api/";
export const Redirect_Url = `https://${currentDomain}/loginsuccess`;
export const PAYMENT_URL = "https://payment.ameerpetit.com/api/";
//export const AUTH_URL = "https://localhost:7130/api/"
//export const Redirect_Url = "https://localhost:3000/loginsuccess"

export const OIDC_CONFIG = {
  authority: "https://identity.ameerpetit.com",
  redirect_uri: `https://${currentDomain}/loginsuccess`,
  response_type: "code",
  scope: "openid profile offline_access IdentityServerApi",
  post_logout_redirect_uri: "https://ameerpetit.com/",
  loadUserInfo: true,
  //redirect_uri: "https://localhost:3000/loginSuccess",
  //post_logout_redirect_uri: "https://localhost:3000/",
};
// export const OIDC_CONFIG = {
//     authority: "https://localhost:7130",
//     redirect_uri: "https://localhost:3000/loginSuccess",
//     response_type: "code",
//     scope: "openid profile offline_access sstechidentityapi IdentityServerApi",
//     post_logout_redirect_uri: "https://localhost:3000/",
//     loadUserInfo: true,
//   };
