import {
  faGoogle,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, FormGroup } from "react-bootstrap";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import * as Actions from "../../constants/baseUrl";
import ForgotModal from "./forgetModal";

const LoginForm = ({ mode, setLoading, onSubmit, formRef, signupErrorMsg }) => {

  const [show, setShow] = useState(false);
  const [isPassword, setIsPassword] = useState(true);
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isSamePassword, setIsSamePassword] = useState(true);

  const handleGoogleClick = (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      window.location.href =
        Actions.AUTH_URL +
        "auth/google-login?returnurl=" +
        Actions.Redirect_Url;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  // const handleGitHubClick = (event) => {
  //   try {
  //     event.preventDefault();
  //     setLoading(true);
  //     window.location.href =
  //       Actions.AUTH_URL +
  //       "auth/github-login?returnurl=" +
  //       Actions.Redirect_Url;
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error);
  //   }
  // };
  const handleLinkedInClick = (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      window.location.href =
        Actions.AUTH_URL +
        "auth/linkedin-login?returnurl=" +
        Actions.Redirect_Url;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const config = useSelector((state) => state.config.value);

  useEffect(() => {
    setIsSamePassword(passwordData.password === passwordData.confirmPassword);
  }, [passwordData.confirmPassword, passwordData.password]);

  return (
    <>
      <Form
        onSubmit={(e) => {
          onSubmit(e);
        }}
        ref={formRef}
        className="loginform"
      >
        <div
          className={`form-input-wrapper align-items-start overflow-hidden ${mode ? "login" : "signup"
            }`}
        >
          <input type="hidden" id="mode" defaultValue={mode} />
          {/* SIgn In */}
          <div className="form-login">
            <header className="form-block__header mb-4 theme-text-2">
              <h4 className="headertxt text-center"> Welcome back! </h4>
            </header>
            <FormGroup className="form-floating mb-3">
              <Form.Control
                autoFocus
                type="text"
                id="email"
                label="email"
                disabled={!mode}
                placeholder="Type Here"
                required
              />
              <Form.Label htmlFor="email">User Name or Email</Form.Label>
            </FormGroup>
            <FormGroup className="form-floating password-group mb-3">
              <Form.Control
                type={isPassword ? "password" : "text"}
                id="password"
                label="password"
                required
                disabled={!mode}
                placeholder="Type Here"
              />
              <Form.Label htmlFor="password">Password</Form.Label>
              <Button className="bg-transparent border-0 p-0 passwordToggler">
                {isPassword ? (
                  <Eye onClick={() => setIsPassword(!isPassword)} />
                ) : (
                  <EyeSlash onClick={() => setIsPassword(!isPassword)} />
                )}
              </Button>
            </FormGroup>

            <Button
              className="forgot_password btn p-0 bg-transparent text-danger border-0 ms-auto d-block"
              disabled={!mode}
              onClick={() => {
                setShow(true);
              }}
            >
              Forgot Password?
            </Button>

            <Button
              className={`add-button w-100 d-block my-3 btn-${mode ? "login" : "signup"
                }`}
              type="submit"
            >
              Log In
            </Button>

            {localStorage.getItem("isValidUser") === false ||
              localStorage.getItem("isValidUser") === "false" ? (
              <Alert className="py-2" key="danger" variant="danger">
                ❗ Invalid Credentials
              </Alert>
            ) : (
              ""
            )}
          </div>
          {/* Signup */}
          <div className={`form-signup ${mode ? "h-0" : ""}`}>
            <header className="form-block__header  mb-4 theme-text-2">
              <h3 className="headertxt text-center"> Create An Account </h3>
            </header>
            <FormGroup className="form-floating mb-3">
              <Form.Control
                autoFocus
                type="text"
                id="firstname"
                label="first name"
                required
                disabled={mode}
                placeholder="Type Here"
              />
              <Form.Label htmlFor="firstname">First Name</Form.Label>
            </FormGroup>
            <FormGroup className="form-floating mb-3">
              <Form.Control
                type="text"
                id="lastname"
                label="last name"
                required
                disabled={mode}
                placeholder="Type Here"
              />
              <Form.Label htmlFor="lastname">Last Name</Form.Label>
            </FormGroup>

            <FormGroup className="form-floating mb-3">
              <Form.Control
                type="newEmail"
                id="newEmail"
                label="newEmail"
                required
                disabled={mode}
                placeholder="Type Here"
              />
              <Form.Label htmlFor="newEmail">Email</Form.Label>
            </FormGroup>

            <FormGroup className="form-floating mb-3">
              <Form.Control
                type="phone"
                id="phone"
                required
                label="phone"
                disabled={mode}
                placeholder="Type Here"
              />
              <Form.Label htmlFor="phone">Phone</Form.Label>
            </FormGroup>

            <FormGroup className="form-floating password-group mb-3">
              <Form.Control
                type={isPassword ? "password" : "text"}
                id="createpassword"
                required
                label="password"
                disabled={mode}
                value={passwordData.password}
                onChange={(e) => {
                  setPasswordData({
                    ...passwordData,
                    password: e.target.value,
                  });
                }}
                placeholder="Type Here"
              />
              <Form.Label htmlFor="password">Password</Form.Label>
              <Button className="bg-transparent border-0 p-0 passwordToggler">
                {isPassword ? (
                  <Eye onClick={() => setIsPassword(!isPassword)} />
                ) : (
                  <EyeSlash onClick={() => setIsPassword(!isPassword)} />
                )}
              </Button>
            </FormGroup>

            {!isSamePassword && (
              <small className="text-end d-block text-dark">
                <small className="text-danger">Passwords does not match.</small>
              </small>
            )}
            <FormGroup className="form-floating password-group mb-3">
              <Form.Control
                type={isPassword ? "password" : "text"}
                id="repeatpassword"
                className={!isSamePassword ? "border-danger" : ""}
                required
                label="repeat password"
                disabled={mode}
                value={passwordData.confirmPassword}
                onChange={(e) => {
                  setPasswordData({
                    ...passwordData,
                    confirmPassword: e.target.value,
                  });
                }}
                placeholder="Type Here"
              />
              <Form.Label htmlFor="repeatpassword">Repeat Password</Form.Label>
            </FormGroup>

            <FormGroup className="form-floating mb-3">
              <Form.Select id="role" required disabled={mode}>
                <option value="">Select Account type</option>
                {config?.appsettingsarrayobjects?.userRole?.length > 0 ?
                  config?.appsettingsarrayobjects?.userRole?.map((role, index) => (
                    <option
                      value={role?.key}
                      key={index + role.key}
                      className="text-capitalize"
                    >
                      {role.value}
                    </option>
                  )) :
                  <option
                    value={'user'}
                    className="text-capitalize"
                  >
                    user
                  </option>
                }
              </Form.Select>
              <Form.Label htmlFor="select">Create Account As</Form.Label>
            </FormGroup>

            {signupErrorMsg !== "" && (
              <Alert className="p-2" variant="danger">
                <small>⚠ {signupErrorMsg}</small>
              </Alert>
            )}

            <Button
              className={`add-button w-100 d-block mt-3 btn-${mode ? "login" : "signup"
                }`}
              disabled={!isSamePassword}
              type="submit"
            >
              Sign Up
            </Button>
          </div>
        </div>

        <div className="form-bottom">
          <div className="d-flex align-items-center justify-content-center gap-2 my-3 text-center">
            <hr width="20%" />
            <span>Or Continue with</span>
            <hr width="20%" />
          </div>

          <div className="d-flex gap-3 justify-content-center">
            <Button
              className={`button-login-option btngoogle btn-${mode ? "login" : "signup"
                }`}
              type="button"
              title="Sign in with Google"
              onClick={handleGoogleClick}
            >
              <FontAwesomeIcon icon={faGoogle} />
            </Button>
            {/* <Button
              className={`button-login-option btngithub btn-${
                mode ? "login" : "signup"
              }`}
              type="button"
              title="Sign in with Github"
              onClick={handleGitHubClick}
            >
              <FontAwesomeIcon icon={faGithub} />
            </Button> */}
            <Button
              className={`button-login-option btnlinkedin btn-${mode ? "login" : "signup"
                }`}
              type="button"
              title="Sign in with LinkedIn"
              onClick={handleLinkedInClick}
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </Button>
          </div>
        </div>
      </Form>

      <ForgotModal show={show} onHide={() => setShow(false)} />
    </>
  );
};

export default LoginForm;
