import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { HeartPulse } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ConvertMinutesToTime from "../../components/convertFromMinutes/convertFromMinutes  ";
import TextFormatter from "../../components/textFormat/textFormat";

const ItemInfo = ({ entityData }) => {
  const entityType = "propertyevent";
  return (
    <Card className="border-top-0 rounded-top-0">
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          Details:
        </h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Description</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.description && entityData?.description ? (
                <TextFormatter text={entityData?.description} />
              ) : (
                "Not Available"
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Venue</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.venue ?? ""}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Duration</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.duration && entityData?.duration ? (
                <ConvertMinutesToTime minutes={entityData?.duration} />
              ) : (
                ""
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Category</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {entityData?.category && entityData?.category
                ? entityData?.category
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Type</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {entityData?.subcategorylevel1 && entityData?.subcategorylevel1
                ? entityData?.subcategorylevel1
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Mode</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.subcategorylevel2
                ? entityData?.subcategorylevel2
                : "Yet To Decide"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        {entityData?.subcategorylevel1?.toLowerCase() === "paid" && (
          <Link
            to={`/request-sponsor/items_${entityType}/${entityData?.id}`}
            className="btn-link theme-text-2 text-decoration-none d-flex align-items-center mb-3"
          >
            <HeartPulse />
            &nbsp;
            <span className="text-decoration-underline">
              Request your employer or some one to sponsor / pay for this event
            </span>
          </Link>
        )}

        <Row>
          <Col md={12}>
            <div className="card-text text-muted mb-0 d-flex align-items-center gap-2">
              {entityData?.tags &&
                entityData?.tags?.map((tag, index) => (
                  <h3 className="tag-chip" key={index}>
                    #{tag}
                  </h3>
                ))}
            </div>
          </Col>
        </Row>
      </Card.Body>
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2">Organizer Details:</h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Organizer Name</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.organizer_first_name ||
                entityData?.organizer_last_name
                ? `${entityData?.organizer_first_name} ${entityData?.organizer_last_name}`.trim()
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Company Name</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.organizer_company_name &&
                entityData?.organizer_company_name
                ? entityData?.organizer_company_name
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Website</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.organizer_website &&
                entityData?.organizer_website.trim() !== "" ? (
                <a
                  href={entityData?.organizer_website}
                  target="_blank"
                  rel="noreferrer"
                >
                  {entityData?.organizer_website}
                </a>
              ) : (
                "Not Available"
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
      </Card.Body>
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2">Sponsors:</h5>
        <Row className="justify-content-center">
          {entityData?.sponsors.length > 0 &&
            (entityData?.sponsors[0]?.sponsor_logo_title ||
              entityData?.sponsors[0]?.sponsor_logo_url) ? (
            entityData.sponsors.map((item, index) => (
              <Col
                lg={2}
                md={3}
                xs={4}
                title={item?.sponsor_logo_title}
                onClick={() =>
                  item?.sponsor_website_url
                    ? window.open(item?.sponsor_website_url, "_blank")
                    : null
                }
                className={`pb-1 cursor-pointer`}
                key={index}
              >
                {item.sponsor_logo_url && (
                  <Image
                    src={item.sponsor_logo_url}
                    width={"100%"}
                    alt="logo"
                  />
                )}
              </Col>
            ))
          ) : (
            <p>No Sponsors Yet</p>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ItemInfo;
