import React from "react";
import NavBar from "../components/headers/navbar";
import AdminNavBar from "../components/headers/adminnavbar";
import useUserData from "../components/useUserDetails/useUserDetails";
import Footer from "../components/footer/Footer";
import { Outlet } from "react-router-dom";
import UnAuthNavBar from "../components/headers/navbarUnAuth";
import SubscriptionModal from "../components/subscriptionModal/subscriptionModal";

const RootLayout = () => {
  const userData = useUserData();
  const role = userData.role;
  const isValidUser =
    localStorage.getItem("isValidUser") === true ||
    localStorage.getItem("isValidUser") === "true";
  return (
    <>
      <div className="mainmenu">
        {role === "admin" || role === "siteadmin" || role === "superadmin" ? (
          <AdminNavBar />
        ) : isValidUser ? (
          <NavBar />
        ) : (
          <UnAuthNavBar />
        )}
      </div>

      <Outlet />

      <Footer />

      {/* SubscriptionModal */}
      <SubscriptionModal />
    </>
  );
};

export default RootLayout;
