import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Check2Circle, XCircle } from "react-bootstrap-icons";

const GeneralMsgModal = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.modalmessage === "success" ? "Success" : "Failed"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {props.modalmessage === "success" ? (
            <Check2Circle className="display-1 theme-text" />
          ) : (
            <XCircle className="display-1 text-danger" />
          )}
          <p>
            {props.modalmessage === "success"
              ? " You have Subscribed to our Newsletter. Now you will receive our latest updates."
              : "Something went wrong. Please try again later!"}
          </p>
          <Button
            className="btn add-button float-end mt-3"
            onClick={props.onHide}
          >
            Done
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GeneralMsgModal;
