import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Share } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import DEMO from "../../img/Web-Development-Course-Thumbnail.jpg";
import UTCLocalTimeConverter from "../UTCLocalTimeConverter/UTCLocalTimeConverter";
import ConvertDateFormat from "../convertDateFormat/convertDateFormat";
import ConvertMinutesToTime from "../convertFromMinutes/convertFromMinutes  ";
import CustomLoader from "../loader/loader";
import RegisterModal from "../register/registerModal";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import "./courseCard.css";

function Demos() {
  const entities = "demos";
  const myToken = localStorage.getItem("token");

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const populateList = async () => {
    try {
      await axios.get(`${Actions.BASE_URL}${entities}`).then((response) => {
        const sortedData = response.data.sort((a, b) =>
          moment(a.start_date).diff(moment(b.start_date))
        );
        setItems(sortedData);
        setLoading(false);
      });
    } catch (error) {
      console.log("There was an error", error);
    }
  };

  useEffect(() => {
    populateList();
  }, []);

  const homeDemosData = items.slice(0, 3);
  const demosAlias = SubDomainRouter("demosAlias");
  const defaultRegisterForm = SubDomainRouter("defaultRegisterForm");

  return (
    <section className="demosSection section border-bottom">
      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg">
          <h1 className="main-heading text-center text-capitalize">
            Upcoming {demosAlias ? demosAlias : "demos"}
          </h1>
          <Row className="justify-content-center mx-0">
            {homeDemosData?.length > 0 ? (
              homeDemosData?.map((item, index) => (
                <Col xl={4} md={6} sm={10} className="mt-2 mb-3" key={item.id}>
                  <Card className="demoCard">
                    <div className="itemImg">
                      <Link
                        to={`/demos/details/${item.id}`}
                        className="linkImg"
                      >
                        <img
                          src={
                            item.listing_image_url ||
                            item.hero_image_url ||
                            DEMO
                          }
                          className="img-fluid"
                          alt="demo"
                        />
                      </Link>
                    </div>
                    <Card.Body className="itemInfo px-2 pb-3 d-flex flex-column justify-content-between py-1">
                      <div className="card-details text-center">
                        <h3 className="itemName text-left mb-0">
                          <Link
                            to={`/demos/details/${item.id}`}
                            className="detailLink text-left"
                          >
                            {item.title}
                          </Link>
                        </h3>

                        <Link
                          to={`/share/demo/${item.id}`}
                          className="entity-share-btn d-flex align-items-center cursor-pointer float-end"
                          title="review"
                        >
                          <Share />
                        </Link>
                        <Card.Text className="card-text my-1 pb-0 text-left d-flex flex-wrap align-items-center justify-content-center">
                          <ConvertDateFormat inputDate={item.start_date} />
                          &nbsp;-&nbsp;
                          <UTCLocalTimeConverter utcTime={item.start_time} />
                          &nbsp;
                          <span>
                            (
                            <ConvertMinutesToTime minutes={item.duration} />)
                          </span>
                        </Card.Text>
                        <hr className="mt-2 mb-1" />
                        <Card.Text className="card-text mb-0 pb-0 text-left text-overflow-desc p-0">
                          {item.description}
                        </Card.Text>
                      </div>
                      <div className="itemEdit d-flex flex-wrap justify-content-center mt-3 gap-2">
                        <Link
                          to={`/demos/details/${item.id}`}
                          className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                        >
                          View More
                        </Link>
                        {myToken && (
                          <>
                            {defaultRegisterForm ===
                              "registerAcceleratorForm" ? (
                              <Link
                                to={`/accelerator/register`}
                                className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                                title="register"
                              >
                                Register
                              </Link>
                            ) : (
                              <Button
                                onClick={() => {
                                  setModalShow(true);
                                  setModalData(item);
                                }}
                                className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                              >
                                Register
                              </Button>
                            )}
                          </>
                        )}
                        <Link
                          to={`/demos/details/${item.id}?demo=reviews`}
                          className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                          title="review"
                        >
                          Reviews
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <p className="text-center">
                No {demosAlias ? demosAlias : "demos"} Found
              </p>
            )}
          </Row>
        </Container>
      )}
      <RegisterModal
        show={modalShow}
        setshow={setModalShow}
        modaldata={modalData}
      />
    </section>
  );
}

export default Demos;
