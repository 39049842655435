import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import UTCLocalTimeConverter from "../../components/UTCLocalTimeConverter/UTCLocalTimeConverter";
import ConvertDateFormat from "../../components/convertDateFormat/convertDateFormat";
import UploadedDocuments from "../../components/documents/documents";
import ImageGallery from "../../components/gallery/gallery";
import CustomLoader from "../../components/loader/loader";
import RegisterModal from "../../components/register/registerModal";
import StarRating from "../../components/starRating/starRating";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import VideoGallery from "../../components/videoGallery/videoGallery";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import IMG2 from "../../img/SWIPERIMG3-min.jpg";
import COURSEIMG from "../../img/course.png";
import { PropertyDetailKeys } from "./extras";
import ItemInfo from "./itemInfo";
import SocialShare from "../../components/share/socialShare";
import { ShareFill } from "react-bootstrap-icons";
function PropertyEventDetails(props) {
  const currentDomain = window.location.hostname;

  const entities = "items";
  const entityType = "propertyevent";

  const siteName = SubDomainRouter("title");
  const defaultRegisterForm = SubDomainRouter("defaultRegisterForm");

  const [loading, setLoading] = useState(true);
  const [entityData, setEntityData] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});

  const populateList = async (id) => {
    try {
      axios
        .get(`${Actions.BASE_URL}${entities}/${entityType}/${id}`)
        .then((response) => {
          setLoading(false);
          setEntityData(response.data);
        });
    } catch (error) {
      console.log("There was an error", error);
    }
  };

  useEffect(() => {
    populateList(props.params.id);
  }, [props.params.id]);

  const propertyEventAlias = SubDomainRouter("propertyEventAlias");

  return (
    <>
      <Helmet>
        <title>
          {entityData?.data?.title
            ? `${entityData?.data?.title}`
            : "PropertyEvent Details"}{" "}
          |{siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <Container fluid="lg" className="my-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <div className="demosViewMoreSection demoProfile overflow-hidden mx-auto">
            <div className="coverImg p-2 position-relative w-100">
              <Image
                src={
                  entityData?.data?.hero_image_url ||
                  entityData?.data?.listing_image_url ||
                  IMG2
                }
                width="100%"
                alt=""
              />
            </div>

            <Row className="profileHeader align-items-center position-relative py-2 mx-4">
              <Col lg={2} className="mr-3">
                <Image
                  src={
                    entityData?.data?.listing_image_url ||
                    entityData?.data?.hero_image_url ||
                    COURSEIMG
                  }
                  alt="..."
                  className="profileImg"
                />
              </Col>
              <Col md={6} className="mt-lg-0 mt-5 pt-lg-0 pt-2 ps-4">
                <h4 className="trainerName mb-2">
                  {entityData?.data?.title}
                  <br />
                  <span className="industry">
                    {entityData?.data?.start_date ? (
                      <ConvertDateFormat
                        inputDate={entityData?.data?.start_date}
                      />
                    ) : (
                      "Yet To Decide"
                    )}
                    &nbsp; - &nbsp;
                    {entityData?.data?.start_time ? (
                      <b>
                        <UTCLocalTimeConverter
                          zone="true"
                          utcTime={entityData?.data?.start_time}
                        />
                      </b>
                    ) : (
                      ""
                    )}
                  </span>
                </h4>
                {entityData?.data?.star_rating_type && (
                  <StarRating rating={entityData?.data?.star_rating_type} />
                )}
              </Col>
              <Col lg={4} md={6}>
                <div className="d-flex align-items-center justify-content-center gap-2 mb-3">
                  <SocialShare
                    url={`https://${currentDomain}/items/${entityData?.type}/details/${entityData?.id}/`}
                    iconsSize={30}
                    title={entityData?.data?.title}
                    image={entityData?.data?.listing_image_url}
                    description={entityData?.data?.description}
                  />
                  <Link
                    to={`/share/items_${entityType}/${entityData.id}`}
                    className="entity-share-btn d-flex align-items-center cursor-pointer"
                    title="Share"
                  >
                    <ShareFill />
                  </Link>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-3">
                  {defaultRegisterForm === "registerAcceleratorForm" ? (
                    <Link
                      to={`/accelerator/register`}
                      className="add-button btn d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                      title="register"
                    >
                      Register
                    </Link>
                  ) : (
                    <Button
                      onClick={() => {
                        setModalShow(true);
                        setModalData(entityData);
                      }}
                      className="add-button btn w-fit px-4 d-flex align-items-center justify-content-center text-center cursor-pointer"
                    >
                      Register
                    </Button>
                  )}
                  <Link
                    to={`/enquiries/items_${entityType}/${entityData?.id}/add`}
                    className="add-button btn w-fit px-3 d-block"
                  >
                    Enquiry
                  </Link>
                  {entityData?.data?.subcategorylevel1?.toLowerCase() ===
                    "paid" && (
                      <Link
                        to={`/pay?currency=${entityData?.data?.currency}&amount=${entityData?.data?.amount}&email=${entityData?.data?.userId}&entityType=${entityData.type}&entityId=${entityData.id}`}
                        className="add-button btn w-fit px-3 d-block"
                      >
                        Pay
                      </Link>
                    )}
                </div>
              </Col>
            </Row>

            <Tabs
              defaultActiveKey="details"
              id="demo-details"
              className="mx-4 mt-2"
              fill
            >
              <Tab eventKey="details" title="Details" className="m-4 mt-0">
                <ItemInfo entityData={entityData?.data} />
              </Tab>

              <Tab
                eventKey="property-details"
                title={propertyEventAlias + " Details"}
                className="m-4 mt-0"
              >
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
                      Property Details:
                    </h5>

                    {PropertyDetailKeys.map((key) => (
                      <React.Fragment key={key}>
                        {entityData?.data?.[key] &&
                          entityData?.data?.[key] !== "" && (
                            <>
                              <Row>
                                <Col md={3}>
                                  <Card.Text className="mb-0 fw-bold text-capitalize">
                                    {key.replace(/_/g, " ")}
                                  </Card.Text>
                                </Col>
                                <Col md={9}>
                                  <Card.Text
                                    className="text-muted mb-0 text-wrap-balance"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {entityData?.data?.[key]}
                                  </Card.Text>
                                </Col>
                              </Row>
                              <hr />
                            </>
                          )}
                      </React.Fragment>
                    ))}
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="gallery" title="Gallery" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <ImageGallery
                      imageData={entityData?.data?.attachments?.images}
                    />
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="videos" title="Videos" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <VideoGallery
                      videoData={entityData?.data?.attachments?.videos}
                    />
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="docs" title="Docs" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <UploadedDocuments
                      docData={entityData?.data?.attachments?.documents}
                    />
                  </Card.Body>
                </Card>
              </Tab>
              {entityData?.data?.FAQs?.length > 0 &&
                entityData?.data?.FAQs[0].question !== "" ? (
                <Tab eventKey="faqs" title="FAQs" className="m-4 mt-0">
                  <Card className="border-top-0 rounded-top-0 FAQs">
                    <Card.Body>
                      <h5 className="about-headings text-wrap-balance theme-text-2 mb-3">
                        Frequently Asked Questions (FAQs)
                      </h5>
                      {
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          {entityData?.data?.FAQs?.map((item, index) => (
                            <Accordion.Item
                              eventKey={`${index}`}
                              key={index}
                              className="border-0 mb-3"
                            >
                              <Accordion.Header>
                                {item?.question} <span></span>
                              </Accordion.Header>
                              <Accordion.Body>{item?.answer}</Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      }
                    </Card.Body>
                  </Card>
                </Tab>
              ) : null}
            </Tabs>
          </div>
        )}

        <RegisterModal
          show={modalShow}
          setshow={setModalShow}
          modaldata={modalData}
        />
      </Container>
    </>
  );
}
export default withRouter(PropertyEventDetails);
