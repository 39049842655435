import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";

const ParticipantsFieldset = ({ field, onFieldChange, onRemove }) => {
  const handleFieldChange = (fieldName, fieldValue) => {
    const updatedField = {
      ...field,
      id: uuidv4(),
      [fieldName]: fieldValue,
    };

    onFieldChange(updatedField);
  };

  return (
    <div className="d-flex gap-3 mt-3 batch align-items-start">
      <Row className="mx-0 flex-grow-1 align-items-end">
        <Col md={6}>
          <small className="text-end d-block text-dark">
            required<span className="text-danger">*</span>
          </small>
          <Form.Group className="form-floating mb-3">
            <Form.Control
              type="text"
              id="first_name"
              required
              value={field?.first_name}
              placeholder="name"
              name="first_name"
              autoFocus
              onChange={(e) => handleFieldChange("first_name", e.target.value)}
            />
            <Form.Label className="formLabel" htmlFor="first_name">
              First Name
            </Form.Label>
          </Form.Group>
        </Col>

        <Col md={6}>
          <small className="text-end d-block text-dark">
            required<span className="text-danger">*</span>
          </small>
          <Form.Group className="form-floating mb-3">
            <Form.Control
              type="text"
              id="last_name"
              required
              value={field?.last_name}
              placeholder="name"
              name="last_name"
              onChange={(e) => handleFieldChange("last_name", e.target.value)}
            />
            <Form.Label className="formLabel" htmlFor="last_name">
              Last Name
            </Form.Label>
          </Form.Group>
        </Col>

        <Col md={6}>
          <small className="text-end d-block text-dark">
            required<span className="text-danger">*</span>
          </small>
          <Form.Group className="form-floating mb-3">
            <Form.Control
              type="email"
              id="email"
              required
              value={field?.email}
              placeholder="email"
              name="email"
              onChange={(e) => handleFieldChange("email", e.target.value)}
            />
            <Form.Label className="formLabel" htmlFor="email">
              Email
            </Form.Label>
          </Form.Group>
        </Col>

        <Col md={6}>
          <small className="text-end d-block text-dark">
            required<span className="text-danger">*</span>
          </small>
          <Form.Group className="form-floating mb-3">
            <Form.Control
              type="phone"
              id="phone"
              required
              value={field?.phone}
              placeholder="phone"
              name="phone"
              onChange={(e) => handleFieldChange("phone", e.target.value)}
            />
            <Form.Label className="formLabel" htmlFor="phone">
              Phone
            </Form.Label>
          </Form.Group>
        </Col>

        <Col md={6}>
          <small className="text-end d-block text-dark">
            required<span className="text-danger">*</span>
          </small>
          <Form.Group className="form-floating mb-3">
            <Form.Control
              id="whatsapp"
              required
              value={field?.whatsapp}
              placeholder="whatsapp"
              name="whatsapp"
              onChange={(e) => handleFieldChange("whatsapp", e.target.value)}
            />
            <Form.Label className="formLabel" htmlFor="whatsapp">
              Whatsapp
            </Form.Label>
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group className="form-floating mb-3">
            <Form.Control
              type="url"
              id="linkedin"
              value={field?.linkedin}
              placeholder="linkedin"
              name="linkedin"
              onChange={(e) => handleFieldChange("linkedin", e.target.value)}
            />
            <Form.Label className="formLabel" htmlFor="linkedin">
              LinkedIn
            </Form.Label>
          </Form.Group>
        </Col>

        <Col md={12}>
          <Form.Group className="form-floating mb-3">
            <Form.Control
              type="text"
              id="job_title"
              value={field?.job_title}
              placeholder="job_title"
              name="job_title"
              onChange={(e) => handleFieldChange("job_title", e.target.value)}
            />
            <Form.Label className="formLabel" htmlFor="job_title">
              Job Title
            </Form.Label>
          </Form.Group>
        </Col>

        <Col md={12}>
          <Form.Group className="form-floating mb-3">
            <Form.Control
              as={"textarea"}
              id="bio"
              rows={4}
              value={field?.bio}
              placeholder="bio"
              name="bio"
              onChange={(e) => handleFieldChange("bio", e.target.value)}
            />
            <Form.Label className="formLabel" htmlFor="bio">
              Brief Bio
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Button
        className="clickable actionDltBtn btn border-danger"
        onClick={() => onRemove(field.id)}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default ParticipantsFieldset;
