import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Subscriber from "../../components/homeSegments/Subscriber";

import newsletterImg from "../../img/newsletter.jpg";

const RegisterNewsletter = () => {
  return (
    <Container fluid="lg" className="my-5 py-5">
      <Row className="align-items-start justify-content-center mx-auto text-center">
        <Col md={10} lg={8}>
          <Image src={newsletterImg} className="w-75" alt="newsletter" />

          <h3 className="theme-text-2 mt-3">Newsletter</h3>
          <p className="opacity-75">
            Signup to our newsletter to get regular updates
          </p>
          <div className="subscribe">
            <Subscriber className="bg-transparent" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterNewsletter;
