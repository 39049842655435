import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";

const SponsorFieldset = ({ field, onFieldChange, onRemove }) => {
  const handleFieldChange = (fieldName, fieldValue) => {
    const updatedField = {
      ...field,
      id: uuidv4(),
      [fieldName]: fieldValue,
    };

    onFieldChange(updatedField);
  };

  return (
    <div className="d-flex align-items-start gap-3 mt-3 batch">
      <Row className="mx-0 flex-grow-1">
        <Col md={12}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="text"
              className="w-100"
              name="sponsor_category"
              placeholder="Course Name"
              value={field?.sponsor_category}
              onChange={(e) =>
                handleFieldChange("sponsor_category", e.target.value)
              }
            />
            <Form.Label
              className="form-label file-label"
              htmlFor="sponsor_category-label"
            >
              Sponsors Category
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Select
              className="w-100"
              name="sponsor_grade"
              placeholder="Course Name"
              value={field?.sponsor_grade}
              onChange={(e) =>
                handleFieldChange("sponsor_grade", e.target.value)
              }
            >
              <option value={""}>Sponsor Grade</option>
              <option value={"platinum"}>Platinum</option>
              <option value={"gold "}>Gold </option>
              <option value={"silver "}>Silver </option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="text"
              className="w-100"
              name="sponsor_logo_title"
              placeholder="Course Name"
              value={field?.sponsor_logo_title}
              onChange={(e) =>
                handleFieldChange("sponsor_logo_title", e.target.value)
              }
            />
            <Form.Label
              className="form-label file-label"
              htmlFor="sponsor_logo_title-label"
            >
              Sponsors Logo Title
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="url"
              className="w-100"
              name="sponsor_logo_url"
              placeholder="Course Name"
              value={field?.sponsor_logo_url}
              onChange={(e) =>
                handleFieldChange("sponsor_logo_url", e.target.value)
              }
            />
            <Form.Label
              className="form-label file-label"
              htmlFor="sponsor_logo_url-label"
            >
              Sponsors Logo URL
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="url"
              className="w-100"
              name="sponsor_website_url"
              placeholder="Course Name"
              value={field?.sponsor_website_url}
              onChange={(e) =>
                handleFieldChange("sponsor_website_url", e.target.value)
              }
            />
            <Form.Label
              className="form-label file-label"
              htmlFor="sponsor_website_url-label"
            >
              Sponsors Website URL
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Button
        className="clickable actionDltBtn btn border-danger"
        onClick={() => onRemove(field.id)}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default SponsorFieldset;
