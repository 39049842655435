import React, { useEffect, useState } from "react";
import "./Slider.css";
// Import Swiper styles
import { Container } from "react-bootstrap";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import swiper3 from "../../img/SWIPERIMG3-min.jpg";
import swiper2 from "../../img/swiperIMG2-min.jpg";
import swiper1 from "../../img/swiperImg1-min.jpg";
import API from "../API/API";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import SearchBar from "./SearchBar";
import Subscriber from "./Subscriber";

function Slider({ setSearchQuery, searchQuery }) {
  const [configData, setConfigData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await API("get", "configs");
      setConfigData(
        response && response?.categorysettings?.heroSliderImages
          ? response?.categorysettings?.heroSliderImages
          : { swiper1, swiper2, swiper3 }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const heroSliderHeading = SubDomainRouter("heroSliderHeading");
  const heroSliderText = SubDomainRouter("heroSliderText");

  return (
    <section className="heroSection position-relative">
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={false}
        speed={900}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Autoplay, Navigation, Pagination]}
        className="mySwiper relative"
      >
        {loading ? (
          <>
            <span className="register-loader position-absolute bg-opacity-50 h-50 w-50"></span>
            <span className="spinnerOverlay position-absolute"></span>
          </>
        ) : (
          <>
            {configData && Object.keys(configData).length > 0
              ? Object.keys(configData)?.map((imageKey) => (
                  <SwiperSlide
                    key={imageKey}
                    className="d-flex align-items-center"
                    style={{
                      backgroundImage: `radial-gradient(#00000099, #0712128c, #00000074), url(${configData[imageKey]})`,
                    }}
                  ></SwiperSlide>
                ))
              : null}
          </>
        )}
      </Swiper>
      <Container fluid="lg" className="heroContentWrap h-100">
        <div className="heroContent d-flex flex-column align-items-center justify-content-between h-100 py-5">
          <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center w-100">
            <h1 className="theme-text">{heroSliderHeading}</h1>
            <p>{heroSliderText}</p>
            <SearchBar className={"w-100"} setSearchQuery={setSearchQuery} />
            {searchQuery !== "" && (
              <p className="text-start mb-0 searchNote">
                Searching for&nbsp;
                <span className="text-decoration-underline">
                  {searchQuery}...
                </span>
              </p>
            )}
          </div>
          <Subscriber className="mb-2" />
        </div>
      </Container>
    </section>
  );
}

export default Slider;
