import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";

const DynamicLocationFieldSet = ({ onFieldChange }) => {
  const [fields, setFields] = useState([{ city: "", address: "" }]);

  const addField = () => {
    setFields([...fields, { city: "", address: "" }]);
  };

  const handleCityChange = (index, city) => {
    const updatedFields = [...fields];
    updatedFields[index].city = city;
    setFields(updatedFields);
    onFieldChange(updatedFields);
  };

  const handleAddressChange = (index, address) => {
    const updatedFields = [...fields];
    updatedFields[index].address = address;
    setFields(updatedFields);
    onFieldChange(updatedFields);
  };

  const onFieldRemove = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
    onFieldChange(updatedFields);
  };

  return (
    <Col md={12} className="dynamicField">
      <Button
        className="clickable add-button mb-3 ms-auto d-flex"
        onClick={addField}
      >
        <span className="addBttn d-block">+</span>
      </Button>
      {fields?.map((field, index) => (
        <div key={index} className="d-flex gap-4 w-100 align-items-center mb-3">
          <div className="flex-grow-1 d-flex gap-3 flex-wrap">
            <div className="form-floating flex-grow-1">
              <Form.Control
                type="text"
                placeholder="City"
                value={field.city}
                onChange={(e) => handleCityChange(index, e.target.value)}
                className="form-control italicInput"
                name={`city${index}`}
                id={`city${index}`}
              />
              <label className="form-label" htmlFor={`city${index}`}>
                City
              </label>
            </div>
            <div className="form-floating flex-grow-1">
              <Form.Control
                type="text"
                placeholder="Address"
                value={field.address}
                onChange={(e) => handleAddressChange(index, e.target.value)}
                className="form-control italicInput"
                name={`address${index}`}
                id={`address${index}`}
              />
              <label className="form-label" htmlFor={`address${index}`}>
                Address
              </label>
            </div>
          </div>
          <Button
            className="clickable add-button"
            onClick={() => onFieldRemove(index)}
          >
            <span className="addBttn d-block">-</span>
          </Button>
        </div>
      ))}
    </Col>
  );
};

export default DynamicLocationFieldSet;
