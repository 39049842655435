import React from "react";

const UTCLocalTimeConverter = ({ utcTime, showUTC, zone }) => {
  const [utcHours, utcMinutes] = utcTime?.split(":")?.map(Number);

  const localDate = new Date();
  localDate?.setUTCHours(utcHours, utcMinutes);

  const localHours = localDate?.getHours();
  const localMinutes = localDate?.getMinutes();

  const period = localHours >= 12 ? "PM" : "AM";
  const formattedLocalTime = `${localHours % 12 || 12}:${localMinutes
    .toString()
    .padStart(2, "0")} ${period}`;

  const userTimeZone = Intl.DateTimeFormat(undefined, {
    timeZoneName: "long",
  })
    ?.formatToParts(localDate)
    ?.find((part) => part.type === "timeZoneName")?.value;

  return (
    <>
      <span>{formattedLocalTime}</span>
      {zone && (
        <>
          <br />
          <span className="text-nowrap">
            (<b>{userTimeZone}</b>)
          </span>
        </>
      )}
      &nbsp;
      {showUTC && (
        <>
          &#10576;&nbsp;
          <span>
            UTC &#58; &nbsp;
            <b>{utcTime}</b>
          </span>
        </>
      )}
    </>
  );
};

export default UTCLocalTimeConverter;
