import React, { useState } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../components/API/API";
import CustomLoader from "../../components/loader/loader";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";

const Extractor = () => {
  const entities = "extractor";

  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    useSsl: "true",
  });
  const [resData, setResData] = useState({});

  // check values
  const [hasValue, setHasValue] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newState);
  };

  const reqFields = [
    "hostname",
    "port",
    "useSsl",
    "username",
    "password",
    "provider",
  ];

  const areFieldsFilled = (fieldNames, formData) => {
    return fieldNames.every((fieldName) => formData[fieldName]);
  };

  const isModalFieldsFilled = areFieldsFilled(reqFields, formData);

  const handleSubmit = async () => {
    if (isModalFieldsFilled) {
      setHasValue(false);
      setIsValid(true);
      setLoading(true);

      const reqData = {
        type: "extractor",
        header: {
          host: window.location.host,
          userId: myUserId,
        },
        data: {
          d: {
            ...formData,
          },
        },
      };

      try {
        const response = await API("post", entities, reqData);
        setResData(response);
      } catch (error) {
        console.log(error.response);
        toast.error("Error. Please try again later.", {
          autoClose: 3000,
          hideProgressBar: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      setHasValue(true);
      setIsValid(true);
    }
  };

  // Function to extract names and email addresses from the response
  const extractNamesAndContact = (data, key) => {
    const list = data[key] || [];
    const emailData = list
      .filter((item) => item.includes("@")) // Filter out items without email addresses
      .map((item) => {
        const match = item.match(/"([^"]+)"\s<([^>]+)>/);
        if (match) {
          return {
            name: match[1],
            email: match[2],
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    return emailData;
  };

  return (
    <>
      <div className="demosAddFormHeader text-center">
        <h2 className="addFormHeading text-capitalize theme-text-2">
          Extractor
        </h2>
      </div>
      <Container
        fluid="lg"
        className="formMain create my-3 mx-auto row align-items-center justify-content-center"
      >
        <Col lg={10} className="mx-auto d-flex justify-content-center">
          <Form
            className="w-100"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="form-section addFormSection event-detail">
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Select
                  id="provider"
                  className={`form-control text-capitalize ${
                    isValid && hasValue && !formData.provider
                      ? "border-danger"
                      : ""
                  }`}
                  required
                  value={formData.provider}
                  name="provider"
                  multiple=""
                  onChange={(e) =>
                    handleFieldChange("provider", e.target.value)
                  }
                >
                  <option value="">Select Provider</option>
                  <option value="gmail">Gmail</option>
                  <option value="yahoo">Yahoo</option>
                  <option value="hotmail">Hotmail</option>
                  <option value="custom">Custom</option>
                </Form.Select>
              </Form.Group>

              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="text"
                  className={`${
                    isValid && hasValue && !formData.hostname
                      ? "border-danger"
                      : ""
                  }`}
                  id="hostname"
                  required
                  value={formData.hostname}
                  placeholder="Enter Event Title"
                  name="hostname"
                  autoFocus
                  onChange={(e) =>
                    handleFieldChange("hostname", e.target.value)
                  }
                />
                <Form.Label className="formLabel" htmlFor="hostname">
                  Host Name
                </Form.Label>
              </Form.Group>

              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="number"
                  className={`${
                    isValid && hasValue && !formData.port ? "border-danger" : ""
                  }`}
                  id="port"
                  required
                  value={formData.port}
                  placeholder="Enter Event Title"
                  name="port"
                  autoFocus
                  onChange={(e) => handleFieldChange("port", e.target.value)}
                />
                <Form.Label className="formLabel" htmlFor="port">
                  Port
                </Form.Label>
              </Form.Group>

              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="email"
                  className={`${
                    isValid && hasValue && !formData.username
                      ? "border-danger"
                      : ""
                  }`}
                  id="username"
                  required
                  value={formData.username}
                  placeholder="Enter Event Title"
                  name="username"
                  autoFocus
                  onChange={(e) =>
                    handleFieldChange("username", e.target.value)
                  }
                />
                <Form.Label className="formLabel" htmlFor="username">
                  Username
                </Form.Label>
              </Form.Group>

              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="password"
                  className={`${
                    isValid && hasValue && !formData.password
                      ? "border-danger"
                      : ""
                  }`}
                  id="password"
                  required
                  value={formData.password}
                  placeholder="Enter Event Title"
                  name="password"
                  autoFocus
                  onChange={(e) =>
                    handleFieldChange("password", e.target.value)
                  }
                />
                <Form.Label className="formLabel" htmlFor="password">
                  Password
                </Form.Label>
              </Form.Group>

              <Form.Check
                key={`useSsl`}
                label={"I consent to the platform's use of SSL."}
                name={"useSsl"}
                type="checkbox"
                id={`useSsl`}
                className="ps-0 mb-2 custom-checkbox"
                checked={formData.useSsl === "true" ? true : false}
                onChange={(e) =>
                  handleFieldChange(
                    "useSsl",
                    e.target.value === "on" ? "true" : "false"
                  )
                }
              />

              <Button
                type="button"
                className="next demosEditBtn "
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Col>

        <Col lg={10}>
          {loading ? (
            <CustomLoader />
          ) : !resData ? (
            <p>No data found!</p>
          ) : resData ? (
            <>
              <h6>Email List</h6>
              <p className="pre border pt-2" style={{ whiteSpace: "pre-wrap" }}>
                <ul className="">
                  {extractNamesAndContact(resData, "emailList").map(
                    (item, index) => (
                      <li key={index} className="mb-1">
                        <span className="text-black">
                          {item.name.replace(/,/g, "")}
                        </span>
                        : &nbsp;
                        <Link
                          to={`mailto:${item.email}`}
                          className="theme-text-2"
                        >
                          {item.email}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </p>
              <hr />
              <h6>Phone List</h6>
              <p className="pre border pt-2" style={{ whiteSpace: "pre-wrap" }}>
                <ul className="">
                  {extractNamesAndContact(resData, "phonelist").map(
                    (item, index) => (
                      <li key={index} className="mb-1">
                        <span className="text-black">
                          {item.name.replace(/,/g, "")}
                        </span>
                        : &nbsp;
                        <Link to={`tel:${item.phone}`} className="theme-text-2">
                          {item.phone}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </p>
            </>
          ) : null}
        </Col>
      </Container>
    </>
  );
};

export default Extractor;
