// Import necessary dependencies
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Container, Image } from "react-bootstrap";
import { Clipboard, Send } from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ThemeInpGroup from "../../../components/homeSegments/ThemeInpGroup";
import useUserData from "../../../components/useUserDetails/useUserDetails";
import botImg from "../../../img/bot.png";

const ContentGenerator = () => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const secondThemeInpGroupRef = useRef(null);

  useEffect(() => {
    // Scroll to the 2nd ThemeInpGroup when messages change
    if (secondThemeInpGroupRef.current) {
      secondThemeInpGroupRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
        block: "end",
      });
    }
  }, [messages, loading]);

  const handleSubmit = (e, input) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      searchText: input,
    };
    if (input.trim() !== "") {
      axios
        .post(`https://api.ameerpetit.com/ai/create`, data, {
          withCredentials: true,
        })

        .then((res) => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { type: "user", text: input },
            { type: "bot", text: res.data },
          ]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error generating code:", error);
          toast.error("Error generating code. Please try again.", {
            autoClose: 3000,
            hideProgressBar: true,
            draggable: true,
          });
        });
    }
  };

  const handleCopyToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(textarea);

    const toastId = text;

    if (!toast.isActive(toastId)) {
      toast.success("Response copied!", {
        autoClose: 1000,
        hideProgressBar: true,
        draggable: true,
        toastId: toastId,
      });
    }
  };

  const userData = useUserData();

  return (
    <Container
      fluid="lg"
      className="my-5 py-5 chatAI d-flex align-items-center justify-content-center flex-column"
    >
      <div className="header text-center">
        <h3 className="theme-text-2 text-center mb-0 mt-sm-5 mt-3">
          Content Generator
        </h3>
        <p className="opacity-75">Enter command to generate content for you</p>
      </div>
      <div className="demosViewMoreForm mt-sm-4">
        <ThemeInpGroup
          handleSubmit={handleSubmit}
          icon={<Send />}
          inputPlaceholder={"To generate Content: Type the keyword."}
        />

        <div className="chat-container">
          {messages?.map((message, index) => (
            <div
              key={index}
              className={`message-container d-flex gap-2 mt-3 ${
                message?.type === "user"
                  ? "user align-items-start mb-1"
                  : "bot align-items-end flex-md-row flex-column mb-4"
              }`}
            >
              {message?.type === "user" && (
                <div className="semi-bg border border-dark-subtle rounded-circle">
                  <Image
                    className="rounded-circle object-fit-contain userImg"
                    src={
                      userData?.photo_image_url ||
                      `https://placehold.co/280x280/42a49f/white?text=${
                        userData?.first_name?.slice(0, 1) ||
                        userData?.type?.slice(0, 1) ||
                        "I"
                      }`
                    }
                    alt={"user"}
                    width={40}
                    height={40}
                  />
                </div>
              )}
              <Card
                className={`message flex-grow-1 w-100 ${
                  message?.type === "user"
                    ? "user border-0"
                    : "bot bg-light ms-md-5"
                }`}
              >
                {message?.type === "bot" && (
                  <Card.Header className="d-flex align-items-center bg-theme justify-content-end px-sm-1 px-0 py-1">
                    <Button
                      className="text-end fw-normal bg-transparent py-1 rounded-0 border-0"
                      onClick={() => handleCopyToClipboard(message?.text)}
                    >
                      <Clipboard /> Copy
                    </Button>
                  </Card.Header>
                )}
                <Card.Body
                  className={`p-sm-2 pt-0 p-1 text-wrap ${
                    message?.type === "user" && "bg-light"
                  }`}
                >
                  <Card.Text className="fw-normal">
                    {message?.type === "user" ? (
                      message?.text
                    ) : (
                      <pre className="text-dark text-wrap-balance">
                        {message?.text}
                      </pre>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
              {message?.type === "bot" && (
                <div className="semi-bg border border-dark-subtle rounded-circle p-1">
                  <Image
                    className="rounded-circle object-fit-contain botImg"
                    src={botImg}
                    alt={"bot"}
                    width={32}
                    height={32}
                  />
                </div>
              )}
            </div>
          ))}
          {loading ? (
            <div
              className="w-100 h-100 d-flex align-items-end pb-4 justify-content-end position-relative"
              style={{ minHeight: "14vh" }}
            >
              <div className="typing-loader"></div>
            </div>
          ) : null}
          {messages?.length > 0 && (
            <div
              ref={secondThemeInpGroupRef}
              className="d-flex gap-1 align-items-center"
            >
              <div
                className={`semi-bg border border-dark-subtle rounded-circle`}
              >
                <Image
                  className="rounded-circle object-fit-contain"
                  src={
                    userData?.photo_image_url ||
                    `https://placehold.co/280x280/42a49f/white?text=${
                      userData?.first_name?.slice(0, 1) ||
                      userData?.type?.slice(0, 1) ||
                      "I"
                    }`
                  }
                  alt={"user"}
                  width={40}
                  height={40}
                />
              </div>
              <ThemeInpGroup
                handleSubmit={handleSubmit}
                icon={<Send />}
                inputPlaceholder={"Enter command...'"}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ContentGenerator;
