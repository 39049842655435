const ConvertDateFormat = ({ inputDate }) => {
  const formatDate = (dateString) => {
    const dateParts = dateString?.split(/[-/]/);

    if (dateParts?.length !== 3) {
      return null;
    }

    const [year, month, day] = dateParts?.map(Number);

    if (isNaN(month) || isNaN(day) || isNaN(year)) {
      return null;
    }

    const date = new Date(year, month - 1, day);

    if (isNaN(date)) {
      return null; // Invalid date
    }

    const monthName = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(date);
    const dayWithSuffix = addDateSuffix(day);
    let shortMonth = monthName?.slice(0, 3);

    return `${shortMonth} ${dayWithSuffix}, ${year}`;
  };

  const addDateSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  return formatDate(inputDate);
};

export default ConvertDateFormat;
