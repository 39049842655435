import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomLoader from "../../components/loader/loader";
import useUserData from "../../components/useUserDetails/useUserDetails";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";

const AddReview = (props) => {
  const navigate = useNavigate();

  const [loadingProgress, setLoadingProgress] = useState(false);
  const [loading, setLoading] = useState(true);
  const userData = useUserData({ setLoading });
  const [state, setState] = useState({
    type: "review",
    email: "",
    phone: "",
    whatsapp: "",
    reviewer_first_name: "",
    reviewer_last_name: "",
    service_availed: "",
    reviewType: "",
    entityTypeAssociated: "",
    entityIdAssociated: "",
    review_title: "",
    review_comments: "",
    star_rating: "",
    no_of_reviews: "",
    tags: [],
    listing_image_url: "",
    hero_image_url: "",
    category: "",
    comments: "",
  });
  const [entityData, setEntityData] = useState([]);

  const handleFieldChange = (fieldName, fieldValue) => {
    setState((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const populateList = async (id, entityType) => {
    try {
      const response = await fetch(`${Actions.BASE_URL}${entityType}s/${id}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setEntityData(data);
      const tagsArray = Object.values(data.tags);
      setState({
        ...state,
        entityIdAssociated: data.id,
        entityTypeAssociated: entityType,
        reviewType: entityType,
        category: data.category,
        tags: tagsArray,
        listing_image_url: data.listing_image_url,
        hero_image_url: data.hero_image_url,
      });
      setLoading(false);
    } catch (error) {
      console.error("There was an error", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingProgress(true);
    const data = {
      ...state,
    };
    axios
      .post(`${Actions.BASE_URL}reviews`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        navigate(
          `/${state.entityTypeAssociated}s/details/${state.entityIdAssociated}/`
        );
        setLoadingProgress(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong. Please Try again later.", {
          autoClose: 3000,
          hideProgressBar: true,
          draggable: true,
        });
        setLoadingProgress(false);
      });
  };

  useEffect(() => {
    console.log(props.params);
    populateList(props.params.id, props.params.entityType);
  }, []);

  useEffect(() => {
    setState({
      ...state,
      reviewer_first_name: userData?.first_name,
      reviewer_last_name: userData?.last_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, entityData]);

  return (
    <Container fluid="lg" className="mt-5 pt-5">
      {loadingProgress ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="row py-3">
            <div className="text-center">
              <h3 className="theme-text-2 mt-2">Add Review</h3>
              <p>Write your opinion about this {entityData?.type}</p>
            </div>
            <form action="" method="post">
              <fieldset className="demosAddForm show mt-3">
                <Row className="py-3">
                  <Col lg={12}>
                    <Row className="mb-2">
                      <Col md={3}>
                        <p className="mb-0 fw-bold">
                          {entityData?.title
                            ? "Title"
                            : entityData?.first_name ||
                              entityData?.last_name ||
                              entityData?.name
                            ? "Name"
                            : null}
                        </p>
                      </Col>
                      <Col md={9}>
                        <p className="text-muted mb-0">
                          {entityData?.title !== "" ||
                          entityData?.first_name !== "" ||
                          entityData?.last_name !== "" ||
                          entityData?.name !== "" ? (
                            <>
                              {entityData?.title ||
                                entityData?.name ||
                                `${entityData?.first_name} ${entityData?.last_name}`}
                            </>
                          ) : (
                            "Not Available"
                          )}
                        </p>
                      </Col>
                    </Row>
                    {entityData?.description !== "" && (
                      <Row className="mb-2">
                        <Col md={3}>
                          <p className="mb-0 fw-bold">
                            {entityData?.title
                              ? "Description"
                              : entityData?.first_name || entityData?.last_name
                              ? "BIO"
                              : entityData?.brief_two_liner_bio
                              ? "Brief BIO"
                              : null}
                          </p>
                        </Col>
                        <Col md={9}>
                          <p className="text-muted mb-0 text-overflow-desc">
                            {entityData?.description !== "" ||
                            entityData?.brief_two_liner_bio !== "" ||
                            entityData?.last_name !== "" ? (
                              <>
                                {entityData?.description ||
                                  entityData?.bio ||
                                  entityData?.brief_two_liner_bio}
                              </>
                            ) : (
                              "Not Available"
                            )}
                          </p>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <hr />
                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <select
                        className="form-control form-select fromcontrol"
                        id="star_rating"
                        name="star_rating"
                        value={state?.star_rating}
                        onChange={(e) =>
                          handleFieldChange("star_rating", e.target.value)
                        }
                      >
                        <option value={0}>Rating</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <select
                        className="form-control form-select fromcontrol"
                        id="service_availed"
                        name="service_availed"
                        value={state?.service_availed}
                        onChange={(e) =>
                          handleFieldChange("service_availed", e.target.value)
                        }
                      >
                        <option value="">Service Availed</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        id="reviewer_first_name"
                        name="reviewer_first_name"
                        placeholder="Reviewer First name"
                        value={state?.reviewer_first_name}
                        onChange={(e) =>
                          handleFieldChange(
                            "reviewer_first_name",
                            e.target.value
                          )
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="reviewer_first_name">
                        Reviewer First name
                      </Form.Label>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        id="reviewer_last_name"
                        name="reviewer_last_name"
                        placeholder="Reviewer Last Name"
                        value={state?.reviewer_last_name}
                        onChange={(e) =>
                          handleFieldChange(
                            "reviewer_last_name",
                            e.target.value
                          )
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="reviewer_last_name">
                        Reviewer Last Name
                      </Form.Label>
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        id="review_title"
                        name="review_title"
                        placeholder="Review Title"
                        value={state?.review_title}
                        onChange={(e) =>
                          handleFieldChange("review_title", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="review_title">
                        Review Title
                      </Form.Label>
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="form-floating formfloating mb-3">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        className="max-h-auto"
                        id="review_comments"
                        name="review_comments"
                        placeholder="Review Comment"
                        defaultValue={state.review_comments}
                        onChange={(e) =>
                          handleFieldChange("review_comments", e.target.value)
                        }
                      ></Form.Control>
                      <Form.Label htmlFor="review_comments">
                        Review Comment
                      </Form.Label>
                    </div>
                  </Col>

                  <Col xs={12} className="mt-2">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      className="submit-button coursesAddBtn  next"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            </form>
          </div>
        </>
      )}
    </Container>
  );
};
export default withRouter(AddReview);
