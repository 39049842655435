import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";
import DynamicEntityBox from "../dynamicEntityBox/dynamicEntityBox";

const DynamicSections = ({ field, setFields, onRemove, setLoading, index }) => {


  const dynamicEntity = {
    title: "",
    path: "",
    listing_image_url: "",
    show: true,
    id: uuidv4(),
  };

  const [formData, setFormData] = useState(field);
  const [dynamicFields, setDynamicFields] = useState(field?.entityItems ?? [dynamicEntity]);

  useEffect(() => {
    setFormData(field);
  }, [field]);

  const handleInputChange = (fieldName, value) => {
    const updatedData = { ...formData, [fieldName]: value };
    setFormData(updatedData);
    setFields((prevFields) => {
      return prevFields.map((item) =>
        item.id === field.id ? updatedData : item
      );
    });
  };


  const removeItemById = (fields, setFields, itemId) => {
    const updatedItems = fields?.filter((item) => item.id !== itemId);
    setFields(updatedItems);
  };

  useEffect(() => {
    setFormData(field)
    setDynamicFields(field?.entityItems)
  }, [field])


  useEffect(() => {
    // Update parent state when dynamicFields change
    setFields((prevFields) => prevFields.map((item) => (item.id === field.id ? { ...item, entityItems: dynamicFields } : item)));
  }, [dynamicFields, field.id]);


  return (
    <div className="d-flex align-items-start flex-row gap-2 w-100 mb-3 card pt-2">
      <Row className="mx-0 flex-row flex-grow-1">
        {/* Space to add dynamicHomepage Sections */}
        <Col md={12}>
          <small className="text-end d-block text-dark">
            required<span className="text-danger">*</span>
          </small>
          <Form.Group className="form-floating mb-3">
            <Form.Control
              required
              type="text"
              id="section_heading1"
              value={field?.section_heading1}
              placeholder="Enter Event Title"
              name="section_heading1"
              onChange={(e) =>
                handleInputChange("section_heading1", e.target.value)
              }
            />
            <Form.Label
              className="formLabel"
              htmlFor="section_heading1"
            >
              Section {index + 1} Heading
            </Form.Label>
          </Form.Group>
        </Col>
        {/* Dynamic Entity Box */}
        <Col md={12}>
          {dynamicFields?.map((field, index) => (
            <DynamicEntityBox
              key={index}
              field={field}
              index={index}
              setLoading={setLoading}
              setFields={setDynamicFields}
              onRemove={() =>
                removeItemById(
                  dynamicFields,
                  setDynamicFields,
                  field.id
                )
              }
            />
          ))}
          <Button
            className="clickable add-button mb-3 ms-auto d-flex mt-3"
            onClick={() =>
              setDynamicFields([...dynamicFields, dynamicEntity])
            }
          >
            Add More Items
          </Button>
        </Col>
      </Row>
      <Button
        className="clickable actionDltBtn btn border-danger mt-4"
        onClick={() => onRemove(field.id)}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default DynamicSections;
