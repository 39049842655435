import React from "react";

const ConvertTo12HourFormat = ({ time24 }) => {
  if (time24) {
    const [hours, minutes] = time24?.split(":");
    const hoursInt = parseInt(hours, 10);
    const minutesInt = parseInt(minutes, 10);
    const period = hoursInt >= 12 ? "PM" : "AM";
    const hours12 = hoursInt % 12 || 12;
    const time12 = `${hours12}:${minutesInt
      .toString()
      .padStart(2, "0")} ${period}`;
    return <span>{time12}</span>;
  }
};

export default ConvertTo12HourFormat;
