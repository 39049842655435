import { ErrorRounded } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import { Toast } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./profileUploader.css";

const ProfileUploader = ({
  label,
  accept,
  imageType,
  state,
  setState,
  imageURL,
  imageContent,
  imageExtension,
  placehold,
  resolution,
}) => {
  const [showToast, setShowToast] = useState(false);
  const minWidth = resolution;
  const minHeight = resolution;
  const inputRef = useRef(null);

  const validateImageDimensions = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const base64Data = event.target.result.split(",")[1];

      const img = new Image();
      img.src = `data:image/${file.name.split(".").pop()};base64,${base64Data}`;

      img.onload = () => {
        if (img.width >= minWidth && img.height >= minHeight) {
          const fileExtension = file.name.split(".").pop();
          const newState = { ...state };

          newState.photo_image_content = base64Data;
          newState.photo_image_extension = fileExtension;

          setState(newState);
        } else {
          inputRef.current.value = "";
          setShowToast(true);
        }
      };
    };

    reader.readAsDataURL(file);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      validateImageDimensions(selectedFile);
    }
  };

  return (
    <Form.Group className="profile mb-3 rounded-circle position-relative d-flex gap-3 flex-wrap">
      <Form.Label
        className="cursor-pointer image-wrap position-relative overflow-hidden rounded-circle"
        htmlFor={imageType}
      >
        {imageExtension && imageContent ? (
          <img
            src={`data:image/${imageExtension};base64,${imageContent}`}
            loading="lazy"
            alt=""
            className="profile-upload object-fit-cover rounded-circle"
            width={resolution}
            height={resolution}
          />
        ) : imageURL ? (
          <img
            src={imageURL}
            loading="lazy"
            alt=""
            className="profile-upload object-fit-cover rounded-circle"
            width={resolution}
            height={resolution}
          />
        ) : (
          <img
            src={placehold}
            loading="lazy"
            alt=""
            className="profile-upload object-fit-cover rounded-circle"
            width={resolution}
            height={resolution}
          />
        )}
        <div className="placehold-text">
          <p>Upload New Image</p>
        </div>
      </Form.Label>
      <Form.Control
        ref={inputRef}
        type="file"
        accept={accept}
        className={`imgUploader w-100 mt-3 d-none`}
        name={imageType}
        id={imageType}
        onChange={handleFileChange}
      />
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        autohide
        delay={3500}
        style={{
          position: "absolute",
          top: "100%",
          right: 0,
          zIndex: "99",
        }}
      >
        <Toast.Header closeButton={false}>
          <strong className="mr-auto text-danger">
            <ErrorRounded />
            &nbsp;Error
          </strong>
        </Toast.Header>
        <Toast.Body>
          The {label} dimensions should be at least{" "}
          <b>
            {minWidth} x {minHeight} Pixels
          </b>
          .
        </Toast.Body>
      </Toast>
    </Form.Group>
  );
};

export default ProfileUploader;
