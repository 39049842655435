import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, InputGroup, Table } from "react-bootstrap";
import {
  PencilSquare,
  PlusCircle,
  Search,
  Trash3Fill,
} from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import CustomLoader from "../../../components/loader/loader";
import * as Actions from "../../../constants/baseUrl";
import useUserData from "../../../components/useUserDetails/useUserDetails";
import ConvertCreatedDateFormat from "../../../components/convertDateFormat/convertCreatedDateFormat";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { toast } from "react-toastify";

const SuperAdminUsers = () => {
  const userData = useUserData();
  const role = userData.role;

  const [loading, setLoading] = useState(true);
  const [users, setUser] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderBy, setOrderBy] = useState("first_name");
  const [order, setOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${Actions.BASE_URL}users/admin`);
      const sortedData = response?.data?.sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );
      setUser(sortedData);
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Something went wrong. Please Try again later.", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [role]);
  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this user?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios.delete(`${Actions.BASE_URL}users/${id}`).then((res) => {
              getUsers();
            });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };
  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredContacts = users.filter(
    (contact) =>
      contact?.first_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.last_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.phone?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.whatsapp?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.listName?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const sortedContacts = filteredContacts.sort((a, b) => {
    const isAsc = order === "asc";
    return isAsc
      ? a[orderBy]?.localeCompare(b[orderBy])
      : b[orderBy]?.localeCompare(a[orderBy]);
  });

  return (
    <div className="my-demos mb-4 all-contacts">
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="my-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <h4 className="theme-text mb-1">Users</h4>
              <Link
                to={`/super-admin-dashboard/users/add`}
                type="button"
                className="w-md-75 w-auto px-md-3 px-5 btn add-button d-flex align-items-center justify-content-center"
              >
                <PlusCircle /> &nbsp; Add
              </Link>
            </div>
            <InputGroup className="my-3 ms-auto w-100 tableSearch ms-auto rounded-pill position-relative">
              <Form.Control
                className="rounded-pill"
                onChange={handleSearch}
                placeholder="Search"
              />
              <InputGroup.Text
                id="basic-addon1"
                className="border-0 bg-transparent position-absolute top-0 h-100 end-0"
              >
                <Search />
              </InputGroup.Text>
            </InputGroup>

            {users.length > 0 ? (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 768 }}
                  aria-label="simple table"
                  className="striped rounded-0"
                >
                  <TableHead className="bg-theme">
                    <TableRow>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme">
                        <TableSortLabel
                          active={orderBy === "first_name"}
                          direction={orderBy === "first_name" ? order : "asc"}
                          onClick={() => handleSortRequest("first_name")}
                        >
                          User Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme">
                        <TableSortLabel
                          active={orderBy === "email"}
                          direction={orderBy === "email" ? order : "asc"}
                          onClick={() => handleSortRequest("email")}
                        >
                          Email
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme">
                        <TableSortLabel
                          active={orderBy === "host"}
                          direction={orderBy === "host" ? order : "asc"}
                          onClick={() => handleSortRequest("host")}
                        >
                          Host
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme">
                        <TableSortLabel
                          active={orderBy === "role"}
                          direction={orderBy === "role" ? order : "asc"}
                          onClick={() => handleSortRequest("role")}
                        >
                          Role
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white text-center fw-bold bg-theme">
                        <TableSortLabel
                          active={orderBy === "modifiedDate"}
                          direction={orderBy === "modifiedDate" ? order : "asc"}
                          onClick={() => handleSortRequest("modifiedDate")}
                        >
                          Joined
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white text-center fw-bold bg-theme">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedContacts
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((user) => (
                        <TableRow key={user?.id}>
                          <TableCell>
                            {user?.first_name}&nbsp;{user?.last_name}
                          </TableCell>
                          <TableCell>{user?.email}</TableCell>
                          <TableCell>{user?.host}</TableCell>
                          <TableCell>{user?.role}</TableCell>
                          <TableCell className="text-center">
                            {user?.modifiedDate ? (
                              <ConvertCreatedDateFormat
                                inputDate={user?.modifiedDate}
                              />
                            ) : (
                              "Not Available"
                            )}
                          </TableCell>
                          <TableCell>
                            <span className="d-flex align-items-center justify-content-center gap-2">
                              <Link
                                to={`/super-admin-dashboard/users/edit/${user?.id}`}
                                className="btn-link theme-text text-center me-1"
                              >
                                <PencilSquare />
                              </Link>
                              <Link
                                to={"#"}
                                className="btn-link text-danger text-center p-0"
                                onClick={() => deleteItem(user.id)}
                              >
                                <Trash3Fill />
                              </Link>
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[50, 75, 100]}
                  component="div"
                  className="d-flex align-items-center justify-content-end"
                  count={sortedContacts?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </TableContainer>
            ) : (
              <p>No Users Founds...</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SuperAdminUsers;
