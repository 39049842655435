import React, { useEffect, useState } from "react";

function FormattedDate({ createdDate }) {
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    // Parse the provided ISO date string
    const parsedDate = new Date(createdDate);

    // Format the date as "DD MMM, YYYY"
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formatted = parsedDate.toLocaleDateString("en-US", options);

    setFormattedDate(formatted);
  }, [createdDate]);

  return <>{formattedDate}</>;
}

export default FormattedDate;
