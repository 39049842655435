import React, { useEffect, useState } from "react";

function TimePeriod({ createdDate }) {
  const [timePeriod, setTimePeriod] = useState("");

  useEffect(() => {
    // Parse the provided ISO date string
    const parsedDate = new Date(createdDate);

    // Get the current date
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - parsedDate;

    // Calculate the number of milliseconds in a day, week, and month
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;
    const oneMonth = 30 * oneDay;

    if (timeDifference < oneDay) {
      // Less than a day ago
      setTimePeriod("today");
    } else if (timeDifference < oneWeek) {
      // Less than a week ago
      const daysAgo = Math.floor(timeDifference / oneDay);
      setTimePeriod(`${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`);
    } else if (timeDifference < oneMonth) {
      // Less than a month ago
      const weeksAgo = Math.floor(timeDifference / oneWeek);
      setTimePeriod(`${weeksAgo} week${weeksAgo === 1 ? "" : "s"} ago`);
    } else {
      // More than a month ago
      const monthsAgo = Math.floor(timeDifference / oneMonth);
      setTimePeriod(`${monthsAgo} month${monthsAgo === 1 ? "" : "s"} ago`);
    }
  }, [createdDate]);

  return <>{timePeriod}</>;
}

export default TimePeriod;
