// src/hooks/useUserData.js
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserData } from "../../states/userSlice/userSlice";

const useUserData = (props) => {
  const userData = useSelector(selectUserData);

  const [data, setData] = useState({});

  useEffect(() => {
    try {
      if (userData && Object.keys(userData).length > 0) {
        setData(userData);
      } else {
        setData({});
      }
    } catch (error) {
      console.error("Error retrieving user data:", error);
      setData({});
    } finally {
      props?.setLoading(false);
    }
  }, [userData]);

  return data;
};

export default useUserData;
