import React from "react";
import { Button, Modal } from "react-bootstrap";
import { PersonCheck } from "react-bootstrap-icons";

const SuccessModal = (props) => { 

  const handleModalClose = () => {
    window.location = "/profile/edit";
    props.setshow(false); 
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleModalClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="h5">
          Account Created
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-column">
        <PersonCheck className="display-1 theme-text" />
        <p className="mb-0 theme-text-2 fw-bold mt-2">Your Account Is Ready!</p>
        <p>Head back to login page to LEARN!</p>
        <Button
          className="btn add-button float-end mt-2 w-100"
          onClick={handleModalClose}
        >
          Done
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
