import { ErrorRounded } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Toast } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";

const VideoUploaderEdit = ({
  field,
  fileCategory,
  inputIdPrefix,
  onFieldChange,
  onRemove,
}) => {
  const [selectedFile, setSelectedFile] = useState({
    file_name: "",
    file_content: "",
    file_extension: "",
  });
  const [uploadOption, setUploadOption] = useState(field.url ? "url" : "file");
  const inputRef = useRef(null);
  const [showToast, setShowToast] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target?.files[0];
    if (file) {
      const fileExtension = getFileExtension(file.name);
      if (file.size <= 5 * 1024 * 1024) {
        getBase64(file).then((base64) => {
          setSelectedFile({
            file_name: file.name,
            file_content: base64,
            file_extension: fileExtension,
          });
        });
      } else {
        inputRef.current.value = "";
        setShowToast(true);
      }
    }
  };

  const getFileExtension = (fileName) => {
    return fileName.split(".").pop().toLowerCase();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64 = reader.result.split(",")[1];
        resolve(base64);
      };
    });
  };

  const updateField = () => {
    if (
      selectedFile.file_name &&
      selectedFile.file_content &&
      selectedFile.file_extension
    ) {
      const attachment = {
        id: uuidv4(),
        documentType: fileCategory,
        file_name: selectedFile?.file_name ? selectedFile?.file_name : "",
        file_content: selectedFile.file_content,
        file_extension: selectedFile.file_extension,
      };
      onFieldChange(attachment);
    } else {
      setShowToast(true);
    }
  };

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...selectedFile,
      [fieldName]: fieldValue,
    };
    setSelectedFile(newState);
  };

  useEffect(() => {
    const updatedField = {
      ...field,
      id: uuidv4(),
      documentType: fileCategory,
      file_name: selectedFile.file_name || field?.file_name,
      file_content: "",
      file_extension: "",
      url: selectedFile.url,
    };

    onFieldChange(updatedField);
  }, [selectedFile.url, selectedFile.file_name]);

  return (
    <>
      <div className="vidField d-flex gap-3 flex-sm-row flex-column-reverse align-items-sm-start align-items-end">
        <div className="d-flex w-100 gap-3 flex-column align-items-start">
          <Form.Group className="form-floating select w-md-fit">
            <select
              id="subcategory"
              className="form-control form-select pe-md-4"
              required
              name="subcategory"
              multiple=""
              onChange={(e) => setUploadOption(e.target.value)}
            >
              <option value="file">Upload Video File</option>
              <option value="url">Add Video URL</option>
            </select>
          </Form.Group>

          {selectedFile?.file_extension && selectedFile?.file_content ? (
            <video
              src={`data:video/${selectedFile?.file_extension};base64,${selectedFile?.file_content}`}
              className="uploaded-video-edit img-thumbnail rounded-2 w-100"
              width="100%"
              height="100%"
              controls
              loading="lazy"
            ></video>
          ) : field?.url ? (
            <video
              src={field?.url}
              loading="lazy"
              className="uploaded-video-edit img-thumbnail rounded-2 w-100"
              width="100%"
              height="100%"
              controls
            ></video>
          ) : null}
          <div className="d-flex flex-column flex-wrap gap-2 w-100 mb-3 flex-grow-1 position-relative">
            {uploadOption !== "file" ? null : (
              <Form.Group className="flex-grow-1">
                <Form.Label
                  className="form-label file-upload-label w-100 mb-0"
                  htmlFor={`${inputIdPrefix}-file`}
                >
                  <Form.Control
                    type="file"
                    ref={inputRef}
                    className="uploadFile docUploader w-100 mt-1 d-none"
                    name="document"
                    id={`${inputIdPrefix}-file`}
                    accept="video/*"
                    onChange={handleFileChange}
                  />
                  <span className="btn add-button text-capitalize">
                    Select {fileCategory}
                  </span>
                  <div className="docPreview">
                    <span className="theme-text-2 d-block">
                      *Upload Your Video File And Click Upload
                    </span>
                  </div>
                </Form.Label>
              </Form.Group>
            )}
            <Form.Group className="form-floating position-relative">
              <Form.Control
                type="text"
                className="uploadlabel docUploader w-100"
                name="document"
                placeholder="title"
                id={`${inputIdPrefix}-label`}
                value={selectedFile.file_name || field?.file_name || ""}
                onChange={(e) => handleFieldChange("file_name", e.target.value)}
              />
              <Form.Label
                className="form-label file-label"
                htmlFor={`${inputIdPrefix}-label`}
              >
                Add your {fileCategory} title
              </Form.Label>
            </Form.Group>
            {uploadOption !== "url" ? null : (
              <Form.Group className="form-floating position-relative">
                <Form.Control
                  type="text"
                  className="uploadlabel docUploader w-100"
                  name="document"
                  value={selectedFile.url || field?.url || ""}
                  placeholder="Link"
                  id={`${inputIdPrefix}-label-link`}
                  onChange={(e) => handleFieldChange("url", e.target.value)}
                />
                <Form.Label
                  className="form-label file-label text-capitalize"
                  htmlFor={`${inputIdPrefix}-label-link`}
                >
                  Add Reference {fileCategory} URL
                </Form.Label>
              </Form.Group>
            )}

            {uploadOption === "file" ? (
              <Button
                className="btn add-button text-capitalize w-fit ms-auto"
                onClick={updateField}
              >
                Upload
              </Button>
            ) : null}
          </div>
        </div>
        <Button
          className="clickable actionDltBtn btn border-danger"
          onClick={() => onRemove(field.id)}
        >
          <span className="addBttn d-block">
            <Trash />
          </span>
        </Button>
      </div>
      <hr />
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        autohide
        delay={3500}
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          zIndex: "99",
        }}
      >
        <Toast.Header closeButton={false}>
          <strong className="mr-auto text-danger">
            <ErrorRounded />
            &nbsp;Error
          </strong>
        </Toast.Header>
        <Toast.Body>
          {selectedFile.file_name &&
          selectedFile.file_content &&
          selectedFile.file_extension
            ? "File size exceeds 5MB limit. Please choose a smaller video."
            : "Please select a file."}
        </Toast.Body>
      </Toast>
    </>
  );
};

export default VideoUploaderEdit;
