import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import { Globe, Linkedin, Whatsapp } from "react-bootstrap-icons";

const ItemInfo = ({ itemData }) => {
  const founderAlias = SubDomainRouter("founderAlias");

  return (
    <Card className="border-top-0 rounded-top-0">
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          {founderAlias ? founderAlias : "demo"} Details:
        </h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Full Name
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.first_name + " " + itemData?.last_name ||
                "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              job title
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.job_title || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              email
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.email || "Not available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              industry
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.industry || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Address
            </Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {itemData?.city || "Not Available"},&nbsp;
              {itemData?.country || "Not Available"}
            </Card.Text>
          </Col>
        </Row>

        {itemData?.whatsapp || itemData?.linkedin || itemData?.website ? (
          <>
            <hr />
            <Row>
              <Col md={3}>
                <Card.Text className="mb-0 fw-bold text-capitalize">
                  Social Links
                </Card.Text>
              </Col>
              <Col
                md={9}
                className="social d-flex gap-md-3 gap-2 flex-wrap align-items-center"
              >
                {itemData?.linkedin ? (
                  <a
                    target="_blank"
                    href={`${itemData?.linkedin}`}
                    rel="noreferrer"
                  >
                    <Linkedin />
                  </a>
                ) : null}
                {itemData?.whatsapp ? (
                  <a href={`tel:${itemData?.whatsapp}`} rel="noreferrer">
                    <Whatsapp />
                  </a>
                ) : null}
                {itemData?.website ? (
                  <a
                    target="_blank"
                    href={`${itemData?.website}`}
                    rel="noreferrer"
                  >
                    <Globe />
                  </a>
                ) : null}
              </Col>
            </Row>
          </>
        ) : null}
      </Card.Body>
    </Card>
  );
};

export default ItemInfo;
