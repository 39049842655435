import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import UploadFile from "../../helper/UploadFile";
import BannerUploaderEdit from "../bannerUploaderEdit/bannerUploaderEdit";

const CustomerAddPopup = (props) => {
  const [customerData, setCustomerData] = useState(
    props.customerData || {
      listing_image_content: "",
      listing_image_extension: "",
      profile_image_url: "",
    }
  );
  // check values
  const [hasValue, setHasValue] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...customerData,
      [fieldName]: fieldValue,
    };
    setCustomerData(newState);
  };

  const modalFields = ["name"];

  const areFieldsFilled = (fieldNames, customerData) => {
    return fieldNames.every((fieldName) => customerData[fieldName]);
  };

  const isModalFieldsFilled = areFieldsFilled(modalFields, customerData);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isModalFieldsFilled) {
      setHasValue(false);
      setIsValid(true);

      if (props.customerData && props.customerData.id) {
        const customers = props.state[props.fieldsAlias].map((customer) => {
          if (customer.id === props.customerData.id) {
            return { ...customerData, id: customer.id, type: props.fieldAlias };
          }
          return customer;
        });

        props.setState({
          ...props.state,
          [props.fieldsAlias]: customers,
        });
      } else {
        props.setState({
          ...props.state,
          [props.fieldsAlias]: props.state[props.fieldsAlias]
            ? props.state[props.fieldsAlias].concat([
                { ...customerData, id: uuidv4(), type: props.fieldAlias },
              ])
            : [{ ...customerData, id: uuidv4(), type: props.fieldAlias }],
        });
      }
      onHide();
      props.onHide();
    } else {
      setHasValue(true);
      setIsValid(true);
    }
  };

  const onHide = () => {
    props.setCustomerData({});
    setCustomerData({});
    props.onHide();
  };

  useEffect(() => {
    setCustomerData({ ...props.customerData, type: props.fieldAlias });
  }, [props.customerData, props.fieldAlias]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-startup-popup"
      onHide={onHide}
      show={props.show}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-capitalize h5"
        >
          Add new {props.fieldAlias}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={6} className="list-img-wrap position-relative">
              <BannerUploaderEdit
                className="item-list-img position-static"
                key={customerData.profile_image_url}
                label="Customer Profile"
                accept="image/png, image/jpg, image/jpeg"
                imageType="listing_image"
                placehold={
                  customerData.profile_image_url ||
                  "https://placehold.co/280x180/FFFFFF/DDDDDD.png"
                }
                state={customerData}
                setState={(data) => {
                  UploadFile(
                    customerData.userId,
                    "profile_image",
                    data?.listing_image_content,
                    data?.listing_image_extension,
                    props.setReqLoading
                  ).then((url) => {
                    setCustomerData({
                      ...customerData,
                      profile_image_url: url,
                      listing_image_content: "",
                    });
                  });
                }}
                imageURL={customerData.profile_image_url}
                imageContent={customerData.listing_image_content}
                imageExtension={customerData.listing_image_extension}
              />
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={12} md={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      placeholder="NAME"
                      onChange={(e) => {
                        handleFieldChange("name", e.target.value);
                      }}
                      required
                      value={customerData.name || ""}
                      className={
                        isValid && hasValue && !customerData.name
                          ? "border-danger"
                          : ""
                      }
                    />
                    <Form.Label>Full Name</Form.Label>
                  </Form.Group>
                </Col>

                <Col lg={12} md={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="url"
                      placeholder="website"
                      onChange={(e) => {
                        handleFieldChange("website", e.target.value);
                      }}
                      required
                      value={customerData.website || ""}
                      className={
                        isValid && hasValue && !customerData.website
                          ? "border-danger"
                          : ""
                      }
                    />
                    <Form.Label>Enter Customer Website</Form.Label>
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col md={12}>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  as={"textarea"}
                  rows={4}
                  className={
                    isValid && hasValue && !customerData.description
                      ? "border-danger"
                      : ""
                  }
                  id="description"
                  required
                  value={customerData.description}
                  placeholder="description"
                  name="description"
                  onChange={(e) =>
                    handleFieldChange("description", e.target.value)
                  }
                />
                <Form.Label className="formLabel" htmlFor="description">
                  Briefly describe the customer and product/services rendered
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Button className="btn add-button float-end mt-3 px-4" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerAddPopup;
