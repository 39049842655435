import { configureStore } from "@reduxjs/toolkit";
import configReducer, {
  fetchData as fetchConfigData,
} from "./configSlice/configSlice";
import userReducer, { fetchUserData } from "./userSlice/userSlice";

export const store = configureStore({
  reducer: {
    config: configReducer,
    user: userReducer,
  },
});

// Dispatch the fetchData actions when the store is configured
store.dispatch(fetchUserData());
store.dispatch(fetchConfigData());
