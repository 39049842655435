export const HowItWorksData = [
  {
    question: "What is Ameerpet IT?",
    answer:
      "Ameerpet IT is an educational platform dedicated to providing comprehensive IT training courses designed to meet the evolving needs of the technology sector and its aspirants.",
  },
  {
    question: "Who can benefit from Ameerpet IT?",
    answer:
      "The platform serves a wide range of users including students, instructors, corporate accounts, super admins, and training coordinators, each with tailored functionalities and resources.",
  },
  {
    question: "How do I sign up or log in to Ameerpet IT?",
    answer:
      "Users can sign up by providing personal details such as name, email, and phone number, or use the single sign-on feature with services like Google and LinkedIn for a seamless login experience.",
  },
  {
    question: "What types of courses does Ameerpet IT offer?",
    answer:
      "Ameerpet IT offers a diverse array of IT-related courses ranging from foundational training for beginners to advanced modules for experienced professionals.",
  },
  {
    question: "How does the demo and course listing work?",
    answer:
      "The platform allows instructors to create and schedule demo sessions and courses, providing potential learners with the opportunity to explore and engage with the educational content before enrolling.",
  },
  {
    question: "Can I manage and track my learning progress?",
    answer:
      "Yes, the 'My Dashboard' feature enables users to manage their demos, courses, and groups, offering granular insights and streamlined addition options to enhance their educational journey.",
  },
  {
    question: "What is the purpose of the 'My Groups' feature?",
    answer:
      "'My Groups' fosters rich collaborative ecosystems for users to create, join, and engage in specific topics, projects, or collaborative learning, equipped with robust communication and management tools.",
  },
  {
    question: "How can I provide feedback on courses or demos?",
    answer:
      "The platform features a transparent review system where attendees can leave reviews for courses or demos, aiding in informed decision-making for future learners and allowing instructors to improve their offerings.",
  },
  {
    question: "What is the Code Generator feature?",
    answer:
      "The Code Generator is an innovative tool that converts plain language instructions into executable code, enhancing the user experience by facilitating learning and assisting in problem-solving across various programming languages.",
  },
  {
    question: "How can corporate entities collaborate with Ameerpet IT?",
    answer:
      "Corporates can express their interest through the 'Join As A Corporate' page, which facilitates a seamless connection between businesses seeking specialized training and the platform's network of trainers and mentors.",
  },
  {
    question: "How do I create a demo on Ameerpet IT?",
    answer:
      "Navigate to the 'Demos' section and use the intuitive interface to guide you through the demo creation steps, ensuring all essential details are captured.",
  },
  {
    question: "Can I schedule a demo for a different time zone?",
    answer:
      "Yes, the platform provides flexible scheduling tools that allow for easy setting of dates and times, accommodating a global audience across different time zones.",
  },
  {
    question: "How can I make my demo listing more engaging?",
    answer:
      "Enrich your demos with multimedia elements to make the listings more engaging and informative for prospective students.",
  },
  {
    question:
      "What features enhance the visibility and interaction with my demos?",
    answer:
      "Utilize advanced search and filter options, engaging demo listings, and social sharing capabilities to increase the visibility and interaction with your demos.",
  },
  {
    question: "Is it possible to get feedback on my demos?",
    answer:
      "Yes, the platform has a transparent review system where attendees can leave reviews, offering valuable insights for both future learners and instructors.",
  },
  {
    question: "How can I update my demo content?",
    answer:
      "Instructors can easily modify, clone, or add new demos, keeping their content fresh and engaging with immediate information refresh.",
  },
  {
    question: "What steps should I follow to create a course on Ameerpet IT?",
    answer:
      "Use the 'Add Course' feature and follow the guided information entry to input all necessary details about the new course, including the title, description, start date, and duration.",
  },
  {
    question: "Can I specify a geographical focus for my course?",
    answer:
      "Yes, you can specify whether the course is available to a global audience or targeted to specific regions, allowing you to tailor the content to meet diverse learner demographics.",
  },
  {
    question: "How do I enhance my course presentation?",
    answer:
      "Upload high-resolution banner images and provide links to promotional videos to enrich the course listing and entice potential students.",
  },
  {
    question: "What customization options are available for courses?",
    answer:
      "Select the appropriate learning level, teaching mode (online, in-person, or hybrid), and payment model to ensure the course is accurately represented.",
  },
  {
    question: "How can I efficiently manage my courses?",
    answer:
      "Leverage efficient course management tools for streamlined data management, clear submission controls, and robust data persistence features.",
  },
  {
    question: "How do I engage with course reviews and detailed information?",
    answer:
      "Encourage review submissions, utilize social proof elements like helpful votes, and ensure review authenticity with moderation tools to maintain high standards of quality and usefulness.",
  },
  {
    question: "Can I create and manage events on Ameerpet IT?",
    answer:
      "Yes, use the 'Add Demo' feature for efficient planning and scheduling of a diverse range of instructional events, including workshops, webinars, and course previews.",
  },
  {
    question: "How do I provide comprehensive event descriptions?",
    answer:
      "Offer detailed event descriptions including titles, dates, times, locations, and associated courses for clarity and transparency.",
  },
  {
    question: "Can I customize event content for targeted audience engagement?",
    answer:
      "Yes, customize event content to suit the expertise level and preferences of the intended audience to maximize engagement and relevance.",
  },
  {
    question: "Are there flexible payment options for events?",
    answer:
      "Provide flexible payment models, including free and paid options, to cater to varied preferences and financial capabilities of potential attendees.",
  },
  {
    question: "How can I increase engagement with my events?",
    answer:
      "Incorporate visually appealing elements such as banners, videos, and images to create compelling event presentations that captivate learners' interest.",
  },
  {
    question:
      "What tools are available for efficient event organization and oversight?",
    answer:
      "Implement streamlined data management processes and intuitive administration tools for efficient event organization.",
  },
  {
    question:
      "How do I ensure my demos and courses remain relevant and engaging?",
    answer:
      "Continually refine and update your demos and courses based on feedback and evolving educational needs to maintain relevance and engagement.",
  },
  {
    question:
      "What support does Ameerpet IT offer for managing my educational content?",
    answer:
      "Ameerpet IT provides robust backend support for updates, ensuring the system remains efficient and reliable, along with comprehensive insights and control options for your educational engagements within the platform.",
  },
];
