const ConvertMinutesToTime = ({ minutes }) => {
  let minutesInt = parseInt(minutes, 10);

  if (minutesInt <= 0) {
    return "Invalid time";
  }

  let formattedTime = "";

  if (minutesInt >= 40320) {
    const months = Math.floor(minutesInt / 40320);
    formattedTime += `${months} month${months === 1 ? "" : "s"}`;
    minutesInt %= 40320;
  }

  if (minutesInt >= 10080) {
    const weeks = Math.floor(minutesInt / 10080);
    if (formattedTime !== "") {
      formattedTime += ", ";
    }
    formattedTime += `${weeks} week${weeks === 1 ? "" : "s"}`;
    minutesInt %= 10080;
  }

  if (minutesInt >= 1440) {
    const days = Math.floor(minutesInt / 1440);
    if (formattedTime !== "") {
      formattedTime += ", ";
    }
    formattedTime += `${days} day${days === 1 ? "" : "s"}`;
    minutesInt %= 1440;
  }

  if (minutesInt >= 60) {
    const hours = Math.floor(minutesInt / 60);
    if (formattedTime !== "") {
      formattedTime += ", ";
    }
    formattedTime += `${hours} hour${hours === 1 ? "" : "s"}`;
    minutesInt %= 60;
  }

  if (minutesInt > 0) {
    if (formattedTime !== "") {
      formattedTime += ", ";
    }
    formattedTime += `${minutesInt} minute${minutesInt === 1 ? "" : "s"}`;
  }

  return formattedTime;
};

export default ConvertMinutesToTime;
