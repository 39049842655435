import UTCLocalTimeConverterEmail from "../UTCLocalTimeConverter/UTCLocalTimeConverterEmail";
import ConvertMinutesToTime from "../convertFromMinutes/convertFromMinutes  ";
 
const EmailBodyContent = (
  selectedItemDetails,
  customMessage,
  demosAlias,
  coursesAlias,
  eventsAlias,
) => {
  const myHost = window.location.hostname;

  const demos = selectedItemDetails?.filter((item) => item?.type === "demo");
  const courses = selectedItemDetails?.filter(
    (item) => item?.type === "course"
  );
  const events = selectedItemDetails?.filter(
    (item) => item?.type === "event"
  );
  const general = selectedItemDetails?.filter(
    (item) => item?.type !== "course" || item?.type !== "demo"
  );
  const generateHTML = (items) => {
    return items
      ?.map((detail, index) => {
        return `
        <table width="100%" border="0" cellspacing="0" cellpadding="0" key="${index}">
          <tr>
            <td height="16"></td>
          </tr>
          <tr>
            <td width="100%" align="center">
              <table width="560" border="0" cellspacing="0" cellpadding="0"
                style="border-radius: 12px;     box-shadow: 0 1px 20px 0 #455a6433;">
                <tr>
                  <td height="8"></td>
                </tr>
                <tr>
                  <td width="8"></td>
                  <td align="center" width="554">
                    <img src="${detail?.listing_image_url ??
          detail?.data?.listing_image_url ??
          detail?.data?.hero_image_url ??
          detail?.hero_image_url
          }"
                      alt="Load Error" width="100%" height="100%" loading="lazy"
                      style="object-fit: cover;  max-height: 18rem; border-radius: 10px; box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.06);">
                  </td>
                  <td width="8"></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td height="18"></td>
          </tr>
          <tr>
            <td width="100%">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td width="12"></td>
                  <td width="560" align="center">
                    <span
                      style="font-family: 'Unbounded', sans-serif; color: #43a39f; text-decoration: none; font-size: 1.25rem; font-weight: 500; line-height: normal;">
                      ${detail?.title ??
          detail?.data?.title ??
          detail?.data?.company_name ??
          detail?.data?.blogContent ??
          `${detail?.data?.first_name || ""} ${detail?.data?.last_name || ""
          }`
          }
                    </span>
                  </td>
                  <td width="12"></td>
                </tr>
              </table>
            </td>
          </tr>
          ${detail?.start_date ??
            detail?.data?.start_date ??
            detail?.duration ??
            detail?.data?.duration
            ? `
         ${(detail?.start_date || detail?.data?.start_date) &&
              (detail?.duration || detail?.data?.duration)
              ? ` <tr>
            <td height="8"></td>
          </tr>
          <tr>
            <td align="center">
              <span style="font-size: 12px;">
                ${detail?.start_date ?? detail?.data?.start_date} - 
                ${detail?.start_time
                ? UTCLocalTimeConverterEmail({
                  utcTime: detail?.start_time,
                  zone: true,
                })
                : UTCLocalTimeConverterEmail({
                  utcTime: detail?.data?.start_time,
                  zone: true,
                })
              }
               <br> 
                ${detail?.duration
                ? ConvertMinutesToTime({
                  minutes: detail?.duration,
                })
                : ConvertMinutesToTime({
                  minutes: detail?.data?.duration,
                })
              }
              </span>
              <span>
            </span>
            </td>
          </tr>`
              : ""
            }
          <tr>
            <td height="15"></td>
          </tr>
          `
            : ""
          } 

          ${detail?.author
            ? `
            <tr>
              <td height="8"></td>
            </tr>
           <tr>
            <td align="center">
                <span style="font-size: 12px;">
                 ~ By <b>${detail?.author}</b>
                </span>
                <span>
              </span>
            </td>
          </tr>
          <tr>
            <td height="15"></td>
          </tr>
          `
            : ""
          } 

          <tr>
            <td width="100%">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td width="18"></td>
                  <td width="560" align="center" style="line-height: 1.3;">
                    <span style="font-size: 12px; text-align:center; text-wrap: balance;
                    white-space: pre-line;
                    display: -webkit-box; 
             -webkit-box-orient: vertical; 
             overflow: hidden; 
             -webkit-line-clamp: 10; 
             line-height: 1.2; 
             max-height: 14.4em;">
                      ${detail?.description ??
          detail?.blogContent ??
          detail?.data?.description ??
          detail?.data?.bio ??
          detail?.data?.specialization ??
          detail?.data?.blogContent
          }
                    </span>
                  </td>
                  <td width="18"></td>
                </tr>
                <tr>
                  <td height="24"> </td>
                </tr>
                

 <tr>
            <td align="center" colSpan="3"> 
              <a href="https://${`${myHost}/${detail?.entity_type ?? detail?.type}s/details/${detail?.id}`}"
                style="display:inline-block; font-size: 14px; font-weight: 500; padding:6px 18px; text-decoration: none; border: 2px solid transparent; background-color: #43a39f; border-radius: 4px; color: #fff;">
                Read More
              </a>
            </td>
          </tr>
          <tr>
            <td height="18"></td>
          </tr> 

                
              </table>
              <hr width="90%">
            </td>
          </tr>
          <tr>
            <td height="24"> </td>
          </tr>
        </table>
      `;
      })
      .join("");
  };

  const demosHTML = generateHTML(demos);
  const coursesHTML = generateHTML(courses);
  const eventsHTML = generateHTML(events);
  const generalHTML = generateHTML(general);

  const htmlContent = `

  <!-- ***Body*** -->

  <table width="600px" style="max-width: 600px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
      align="center" style="text-wrap: balance;">
      <tr>
          <td height="24"></td>
      </tr>
      <!-- Main Message -->
      ${customMessage
      ? ` 
          <tr>
          <td width="100%" style="line-height: 1.3;">
              <span style="font-size: 12px; font-family: 'Poppins', sans-serif; white-space: pre-line;">
              ${customMessage}
              </span>
          </td>
      </tr>
      <tr>
          <td height="30"></td>
      </tr>
      `
      : ""
    }
  </table>
  
    <!-- Entity cards -->
    <table width="600px" style="max-width: 600px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
      align="center" style="text-wrap: balance;">
      <tr>
        <td>
        ${demosHTML &&
    `
              <!-- Demos -->
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr bgcolor="#43a39f" align="center">
                  <td align="center">
                    <span
                      style="color: #fff; line-height: 2.6; font-family: 'Unbounded', sans-serif; font-size: 14px;">
                      Upcoming ${demosAlias ? demosAlias : "demos"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    ${demosHTML}
                  </td>
                </tr>
              </table>
            `
    }
          
        ${coursesHTML &&
    `
              <!-- Courses -->
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr bgcolor="#43a39f" align="center">
                  <td align="center">
                    <span
                      style="color: #fff; line-height: 2.6; font-family: 'Unbounded', sans-serif; font-size: 14px;">
                      Upcoming  ${coursesAlias ? coursesAlias : "courses"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    ${coursesHTML}
                  </td>
                </tr>
              </table>
            `
    }
          
        ${eventsHTML &&
    `
              <!-- events -->
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr bgcolor="#43a39f" align="center">
                  <td align="center">
                    <span
                      style="color: #fff; line-height: 2.6; font-family: 'Unbounded', sans-serif; font-size: 14px;">
                      Upcoming  ${eventsAlias ? eventsAlias : "events"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    ${coursesHTML}
                  </td>
                </tr>
              </table>
            `
    }

        ${!demosHTML && !coursesHTML && generalHTML
      ? `
              <!-- startups -->
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td>
                    ${generalHTML}
                  </td>
                </tr>
              </table>
            `
      : ""
    }
        </td>
      </tr>
    </table>
  `;

  return htmlContent;
};

export default EmailBodyContent;
