import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Image, Row, Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import IMG2 from "../../img/SWIPERIMG3-min.jpg";
import COURSEIMG from "../../img/course.png";
import UploadedDocuments from "../documents/documents";
import ImageGallery from "../gallery/gallery";
import LinksContent from "../linksContent/linksContent";
import CustomLoader from "../loader/loader";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import StarRating from "../starRating/starRating";
import VideoGallery from "../videoGallery/videoGallery";
import contentData from "./config.json";
import "./details.css";
import ItemInfo from "./itemInfo";
import TokenDetails from "../useUserDetails/useTokenDetails";

function InstituteDetails(props) {
  const entityName = contentData.entity;
  const entities = contentData.entities;

  const myToken = localStorage.getItem("token");

  const [loading, setLoading] = useState(true);
  const [entityData, setDemoData] = useState({});
  const [reviewData, setReviewData] = useState([]);
  const [displayedCards, setDisplayedCards] = useState(3);

  const populateList = async (id) => {
    try {
      const data = await fetch(`${Actions.BASE_URL}${entities}/${id}`).then(
        (response) => response.json()
      );
      setLoading(false);
      setDemoData(data);
    } catch (error) {
      console.log("There was an error", error);
    }
  };
  const GetReviews = async (id) => {
    try {
      const response = await axios.get(
        `${Actions.BASE_URL}reviews/${entityName}/${id}`,
        {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setReviewData(response.data);
      ScrollToReviews();
    } catch (error) {
      console.error("There was an error", error);
    }
  };
  // Scroll to reviews
  const location = useLocation();
  const scrollTo = new URLSearchParams(location.search).get(entityName);

  // Create a ref for the target element
  const targetRef = useRef(null);

  const ScrollToReviews = () => {
    if (scrollTo) {
      setTimeout(() => {
        if (targetRef.current) {
          targetRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 800);
    }
  };

  useEffect(() => {
    populateList(props.params.id);
    GetReviews(props.params.id);
  }, [props.params.id]);

  const handleShowMore = () => {
    setDisplayedCards((prevDisplayedCards) =>
      prevDisplayedCards + 3 <= reviewData.length
        ? prevDisplayedCards + 3
        : reviewData.length
    );
  };

  const siteName = SubDomainRouter("title");

  return (
    <>
      <Helmet>
        <title>
          {entityData?.title ? `${entityData?.title}` : "Institute Details"} |
          &nbsp; {siteName ? siteName : window.location.host}
        </title>
      </Helmet>
      <Container fluid="lg" className="my-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <div className="demosViewMoreSection demoProfile overflow-hidden mx-auto">
            <div className="p-2">
              <Image
                className="coverImg"
                src={
                  entityData?.hero_image_url ||
                  entityData?.listing_image_url ||
                  IMG2
                }
                width="100%"
                alt=""
              />
            </div>

            <Row className="profileHeader align-items-center position-relative py-2 mx-4 justify-content-between">
              <Col lg={2} className="mr-3">
                <Image
                  src={
                    entityData?.listing_image_url ||
                    entityData?.hero_image_url ||
                    COURSEIMG
                  }
                  alt="..."
                  className="profileImg"
                />
              </Col>
              <Col md={6} className="mt-lg-0 mt-5 pt-lg-0 pt-2 ps-4">
                <h4 className="trainerName mb-2">{entityData?.title}</h4>
                {entityData?.star_rating_type && (
                  <StarRating rating={entityData?.star_rating_type} />
                )}
              </Col>
              <Col
                lg={4}
                md={6}
                className="d-flex align-items-center justify-content-center gap-3"
              >
                <Link
                  to={`/enquiries/${entityName}/${entityData?.id}/add`}
                  className="add-button btn w-fit px-3 d-block"
                >
                  Enquiry
                </Link>
              </Col>
            </Row>

            <Tabs
              defaultActiveKey="details"
              id="demo-details"
              className="mx-4 mt-2"
              fill
            >
              <Tab eventKey="details" title="Details" className="m-4 mt-0">
                <ItemInfo
                  entityData={entityData}
                  displayedCards={displayedCards}
                  reviewData={reviewData}
                  targetRef={targetRef}
                  handleShowMore={handleShowMore}
                />
              </Tab>
              <Tab eventKey="gallery" title="Gallery" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <ImageGallery imageData={entityData?.attachments?.images} />
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="videos" title="Videos" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <VideoGallery videoData={entityData?.attachments?.videos} />
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="docs" title="Docs" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <UploadedDocuments
                      docData={entityData?.attachments?.documents}
                    />
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="link" title="Links" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <LinksContent LinksData={entityData?.attachments?.links} />
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </div>
        )}
      </Container>
    </>
  );
}
export default withRouter(InstituteDetails);
