import React from "react";
import { Button, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";

const DynamicGeneralFieldset = ({ fields, index, onFieldChange }) => {
  const field = fields[index];

  const handleKeyChange = (key) => {
    const updatedField = { ...field, key: key };
    const updatedFields = [...fields];
    updatedFields[index] = updatedField;
    onFieldChange(updatedFields);
  };

  const handleValueChange = (value) => {
    const updatedField = { ...field, value: value };
    const updatedFields = [...fields];
    updatedFields[index] = updatedField;
    onFieldChange(updatedFields);
  };

  const onFieldRemove = () => {
    const updatedFields = fields.filter((_, i) => i !== index);
    onFieldChange(updatedFields);
  };

  return (
    <div className="d-flex gap-4 w-100 align-items-center mb-3">
      <div className="flex-grow-1 d-flex gap-3 flex-wrap">
        <div className="form-floating flex-grow-1">
          <Form.Control
            type="text"
            placeholder="Key"
            value={field?.key}
            onChange={(e) => handleKeyChange(e.target.value)}
            className="form-control italicInput text-capitalize"
            name={`key${index}`}
            id={`key${index}`}
          />
          <label className="form-label text-capitalize" htmlFor={`key${index}`}>
            Key
          </label>
        </div>
        <div className="form-floating flex-grow-1">
          <Form.Control
            type="text"
            placeholder="Value"
            value={field?.value}
            onChange={(e) => handleValueChange(e.target.value)}
            className="form-control italicInput text-capitalize"
            name={`value${index}`}
            id={`value${index}`}
          />
          <label
            className="form-label text-capitalize"
            htmlFor={`value${index}`}
          >
            Value
          </label>
        </div>
      </div>
      <Button
        className="clickable actionDltBtn btn border-danger"
        onClick={onFieldRemove}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default DynamicGeneralFieldset;
