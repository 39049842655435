import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Globe, Linkedin, Whatsapp } from "react-bootstrap-icons";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";

const ItemInfo = ({ itemData }) => {
  const NRIStartupsAlias = SubDomainRouter("NRIStartupsAlias");

  return (
    <Card className="border-top-0 rounded-top-0">
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          Personal Details:
        </h5>
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Full Name
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.first_name + " " + itemData?.last_name ||
                "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              job title
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.job_title || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              email
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0">
              {itemData?.email || "Not available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              industry
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.industry || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />

        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Address
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0">
              {itemData?.city || "Not Available"},&nbsp;
              {itemData?.country || "Not Available"}
            </Card.Text>
          </Col>
        </Row>

        {itemData?.whatsapp || itemData?.linkedin || itemData?.website ? (
          <>
            <hr />
            <Row>
              <Col md={4}>
                <Card.Text className="mb-0 fw-bold text-capitalize">
                  Social Links
                </Card.Text>
              </Col>
              <Col
                md={8}
                className="social d-flex gap-md-3 gap-2 flex-wrap align-items-center"
              >
                {itemData?.linkedin ? (
                  <a
                    target="_blank"
                    href={`${itemData?.linkedin}`}
                    rel="noreferrer"
                  >
                    <Linkedin />
                  </a>
                ) : null}
                {itemData?.whatsapp ? (
                  <a href={`tel:${itemData?.whatsapp}`} rel="noreferrer">
                    <Whatsapp />
                  </a>
                ) : null}
                {itemData?.website ? (
                  <a
                    target="_blank"
                    href={`${itemData?.website}`}
                    rel="noreferrer"
                  >
                    <Globe />
                  </a>
                ) : null}
              </Col>
            </Row>
          </>
        ) : null}
      </Card.Body>

      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          {NRIStartupsAlias} Details
        </h5>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              startup name
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.startup_name || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              founder name
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.founder_name || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              cofounder name
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.cofounder_name || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">BIO</Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.bio || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Startup Phase
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.phase || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              registered startup country
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.registered_location || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Product(s) offered
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.products || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Value Proposition (how this product solves customer problems or
              improves their situation)
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.proposition || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Current Team Size
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.team_size || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              patent(s)
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.patents || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              interest(s)
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.interest || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              Major Achievements
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.achievement || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Card.Text className="mb-0 fw-bold text-capitalize">
              startup's GST number
            </Card.Text>
          </Col>
          <Col md={8}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {itemData?.gst_no || "Not Available"}
            </Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ItemInfo;
