import React from "react";
import { Button, Image } from "react-bootstrap";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import LoginForm from "./formLogIn";
import "./login.css";

const RequestAuthenticate = ({
  mode,
  setMode,
  setLoading,
  onSubmit,
  formRef,
  signupErrorMsg,
}) => {
  const toggleMode = () => {
    setMode(!mode);
  };

  const logo = SubDomainRouter("logo");
  const name = SubDomainRouter("name");

  return (
    <>
      <div className={`loginformMain mx-0 ${mode ? "login" : "signup"}`}>
        <div
          className={`form-wrapper mx-md-0 mx-auto py-2 ${mode ? "login" : "signup"
            }`}
        >
          <header className="form-block__header mb-4">
            <Image
              src={`${logo}`}
              alt="AmeerpetIT"
              loading="lazy"
              className="logostyle mx-auto d-block object-fit-contain"
              width={160}
              height={70}
            />
          </header>

          <LoginForm
            formRef={formRef}
            mode={mode}
            setLoading={setLoading}
            signupErrorMsg={signupErrorMsg}
            onSubmit={onSubmit}
          />

          <div className="form-toggle form-toggle-sm text-center">
            <div className="d-flex align-items-center justify-content-center gap-2 my-3">
              <hr width="26%" />
              <span>{mode ? "Don't" : "Already"} have an Account?</span>
              <hr width="26%" />
            </div>

            <Button
              className="add-button rounded-pill d-block mx-auto my-2 px-4"
              onClick={toggleMode}
            >
              {mode ? "Sign Up" : "Log In"}
            </Button>
          </div>
        </div>

        <div className="form-toggle form-toggle-lg mt-3 text-center text-light">
          <h2>
            <span className="text-capitalize">{name}</span>
          </h2>
          <span>{mode ? "Don't" : "Already"} have an Account?</span>
          <Button
            className="rounded-pill toggle-btn d-block mx-auto mt-3 px-4"
            onClick={toggleMode}
          >
            {mode ? "Sign Up" : "Log In"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default RequestAuthenticate;
