import React from "react";
import { Form } from "react-bootstrap";

const DaysSelect = ({ setState, titleAlias }) => {
  return (
    <>
      <Form.Select
        className="form-control form-select"
        id="duration"
        required
        name="duration"
        onChange={(e) => setState(e.target.value)}
      >
        <option value="1">1 Day</option>
        <option value="2">2 Days</option>
        <option value="3">3 Days</option>
        <option value="4">4 Days</option>
        <option value="5">5 Days</option>
        <option value="6">6 Days</option>
        <option value="7">1 Week</option>
        <option value="8">8 Days</option>
        <option value="9">9 Days</option>
        <option value="10">10 Days</option>
        <option value="11">11 Days</option>
        <option value="12">12 Days</option>
        <option value="13">13 Days</option>
        <option value="14">2 Weeks</option>
        <option value="15">15 Days</option>
        <option value="16">16 Days</option>
        <option value="17">17 Days</option>
        <option value="18">18 Days</option>
        <option value="19">19 Days</option>
        <option value="20">20 Days</option>
        <option value="21">3 Weeks</option>
        <option value="22">22 Days</option>
        <option value="23">23 Days</option>
        <option value="24">24 Days</option>
        <option value="25">25 Days</option>
        <option value="26">26 Days</option>
        <option value="27">27 Days</option>
        <option value="28">4 Weeks</option>
        <option value="29">29 Days</option>
        <option value="30">Month</option>
        <option value="42">6 weeks</option>
        <option value="49">7 Weeks</option>
        <option value="60">2 Months</option>
      </Form.Select>
      <Form.Label htmlFor="select">Show {titleAlias} for Next</Form.Label>
    </>
  );
};

export default DaysSelect;
