import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Send } from "react-bootstrap-icons";
import * as Actions from "../../constants/baseUrl";
import GeneralMsgModal from "../generalMsgModal/generalMsgModal";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import TokenDetails from "../useUserDetails/useTokenDetails";
import useUserData from "../useUserDetails/useUserDetails";

function Subscriber({ className = "" }) {
  const userData = useUserData();

  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const subscriberLstId = SubDomainRouter("subscriptionListID");

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    userId: myUserId,
    lists: [subscriberLstId],
    type: "contact",
    approval_status: "",
    entityRelation: [{ emaillist: subscriberLstId }],
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    whatsapp: "",
    website: "",
    city: "",
    title: "",
    industry: "",
  });
  const [modalMessage, setModalMessage] = useState("success");
  const [modalShow, setModalShow] = useState(false);

  const handleFieldChange = (fieldName, fieldValue) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!subscriberLstId) {
      setLoading(false);
      return;
    }

    const data = {
      ...formData,
      lists: [subscriberLstId],
      entityRelation: [{ emaillist: subscriberLstId }],
    };

    axios
      .post(
        `${Actions.BASE_URL}contacts/${
          myUserId ? myUserId : "subscribe"
        }/${subscriberLstId}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setModalMessage("success");

        setFormData((prevState) => ({
          ...prevState,
          email: "",
        }));
      })
      .catch((error) => {
        setModalMessage("error");
        console.log(error.response);
      })
      .finally(() => {
        setModalShow(true);
        setLoading(false);
      });
  };

  //
  useEffect(() => {
    setFormData({
      ...formData,
      phone: userData?.phone,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      <Form
        className={`inputGroup subscriber rounded-pill d-flex align-items-center ${className}`}
        onSubmit={handleSubmit}
      >
        <Form.Control
          placeholder="Subscribe to our Newsletter"
          type="email"
          required
          value={formData?.email}
          onChange={(e) => handleFieldChange("email", e.target.value)}
        />
        <Button
          className="input-group-text position-relative d-flex align-items-center justify-content-center"
          id="basic-addon2 p-0"
          type="submit"
        >
          {loading ? (
            <span className="register-loader position-static d-block ai-loader"></span>
          ) : (
            <Send />
          )}
        </Button>
      </Form>

      <GeneralMsgModal
        modalmessage={modalMessage}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Subscriber;
