import jwtDecode from "jwt-decode";
import { useState } from "react";

const TokenDetails = () => {
  const token = localStorage.getItem("token");

  const [tokenDetails, setTokenDetails] = useState(
    token ? jwtDecode(token) : null
  );

  if (!token) {
    return;
  }

  if (
    localStorage.getItem("isValidUser") === "false" ||
    localStorage.getItem("isValidUser") === false
  ) {
    return null;
  }

  if (!tokenDetails) {
    console.error("error");
  }

  return tokenDetails;
};

export default TokenDetails;
