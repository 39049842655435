import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import * as Actions from "../../../constants/baseUrl";
import PlaceholdIMG from "../../../img/course.png";
import ConvertDateFormat from "../../../components/convertDateFormat/convertDateFormat";
import ConvertMinutesToTime from "../../../components/convertFromMinutes/convertFromMinutes  ";
import ConvertTo12HourFormat from "../../../components/convertTo12HourFormat/convertTo12HourFormat";
import CustomLoader from "../../../components/loader/loader";
import SubDomainRouter from "../../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../../components/useUserDetails/useTokenDetails";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const GroupAdd = () => {
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const navigate = useNavigate();

  const demosAlias = SubDomainRouter("demosAlias");
  const coursesAlias = SubDomainRouter("coursesAlias");

  const [loadingProgress, setLoadingProgress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [trainerDemos, setTrainerDemos] = useState([]);
  const [trainerCourses, setTrainerCourses] = useState([]);
  const [displayedDemoCards, setDisplayedDemoCards] = useState(6);
  const [displayedCourseCards, setDisplayedCourseCards] = useState(6);
  const [formData, setFormData] = useState({
    userId: myUserId,
    type: "group",
    relation: [],
    title: "My Group",
    description: "",
    uniqueUrlId: "",
  });
  const [checkedDemos, setCheckedDemos] = useState({});
  const [checkedCourses, setCheckedCourses] = useState({});

  const handleCheckboxChange = (id, type) => {
    if (type === "demo") {
      setCheckedDemos((prevCheckedDemos) => {
        if (prevCheckedDemos[id]) {
          const { [id]: removed, ...rest } = prevCheckedDemos;
          return rest;
        } else {
          return { ...prevCheckedDemos, [id]: type };
        }
      });
    } else if (type === "course") {
      setCheckedCourses((prevCheckedCourses) => {
        if (prevCheckedCourses[id]) {
          const { [id]: removed, ...rest } = prevCheckedCourses;
          return rest;
        } else {
          return { ...prevCheckedCourses, [id]: type };
        }
      });
    }
  };

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newState);
  };

  const getTrainerCourses = async () => {
    setLoading(true);
    const data = {
      userid: myUserId,
    };
    try {
      const response = await axios.post(
        `${Actions.BASE_URL}dashboard/MyCourses?userid=${myUserId}`,
        data
      );
      // Sort data  in ascending order
      const sortedData = response?.data?.sort((a, b) =>
        moment(a?.start_date).diff(moment(b?.start_date))
      );
      setTrainerCourses(sortedData);
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Something went wrong. Please Try again later.", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getTrainerDemos = async () => {
    setLoading(true);
    const data = {
      userid: myUserId,
    };
    try {
      const response = await axios.post(
        `${Actions.BASE_URL}dashboard/MyDemos?userid=${myUserId}`,
        data
      );
      // Sort data  in ascending order
      const sortedData = response?.data?.sort((a, b) =>
        moment(a?.start_date).diff(moment(b?.start_date))
      );
      setTrainerDemos(sortedData);
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Something went wrong. Please Try again later.", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTrainerDemos();
    getTrainerCourses();
  }, []);

  const handleShowMoreCourses = () => {
    setDisplayedCourseCards((prevDisplayedCards) =>
      prevDisplayedCards + 3 <= trainerCourses.length
        ? prevDisplayedCards + 3
        : trainerCourses.length
    );
  };
  const handleShowMoreDemos = () => {
    setDisplayedDemoCards((prevDisplayedCards) =>
      prevDisplayedCards + 3 <= trainerDemos.length
        ? prevDisplayedCards + 3
        : trainerDemos.length
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingProgress(true);
    const relation = { ...checkedDemos, ...checkedCourses };
    const updatedFormData = {
      ...formData,
      relation: relation,
    };
    try {
      await axios
        .post(`${Actions.BASE_URL}groups`, updatedFormData)
        .then((response) => {
          navigate(`/dashboard/my-groups`);
          setLoadingProgress(false);
        });
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Something went wrong. Please Try again later.", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
      setLoadingProgress(false);
    }
  };

  return (
    <Container fluid="lg" className="my-5 py-5 add-group">
      {loadingProgress ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="demosAddFormHeader text-center formMain">
            <h2 className="addFormHeading text-capitalize">Add a Group</h2>
            <div className="demosEditHeader text-center mb-2 mb-lg-4">
              <h5>Add Details Below:</h5>
            </div>
          </div>
          <div className="demosViewMoreSection p-3">
            <Form onSubmit={handleSubmit}>
              <Row className="mx-0 py-1">
                <Col md={12}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      className="italicInput"
                      id="title"
                      required
                      value={formData?.title}
                      placeholder="Enter Event Title"
                      name="title"
                      onChange={(e) =>
                        handleFieldChange("title", e.target.value)
                      }
                    />
                    <Form.Label className="formLabel" htmlFor="title">
                      Group Title
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      id="description"
                      value={formData?.description}
                      placeholder="Enter Event Title"
                      name="description"
                      onChange={(e) =>
                        handleFieldChange("description", e.target.value)
                      }
                    />
                    <Form.Label className="formLabel" htmlFor="description">
                      Group Description
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      id="uniqueUrlId"
                      value={formData?.uniqueUrlId}
                      placeholder="Enter Event Title"
                      name="uniqueUrlId"
                      onChange={(e) =>
                        handleFieldChange("uniqueUrlId", e.target.value)
                      }
                    />
                    <Form.Label className="formLabel" htmlFor="uniqueUrlId">
                      Unique Url Id
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <h6 className="theme-text-2">
                    Select your {demosAlias ? demosAlias : "demos"}
                  </h6>
                </Col>
                {trainerDemos?.length !== 0 ? (
                  trainerDemos.slice(0, displayedDemoCards)?.map((item) => (
                    <Col md={12} key={item.id}>
                      <Form.Check
                        type="checkbox"
                        className="ps-0 my-2 custom-checkbox"
                        name={item?.id}
                        id={item?.id}
                        label={
                          <Row className="demoCard flex-sm-row card p-2 ms-2">
                            <Col
                              md={2}
                              sm={3}
                              className="itemImg border-dark border-opacity-10 rounded-2 p-0 border"
                            >
                              <img
                                src={item.listing_image_url || PlaceholdIMG}
                                className="img-fluid"
                                alt="imageCourse"
                              />
                            </Col>
                            <Col md={10} sm={9} className="itemInfo px-2">
                              <div className="card-details">
                                <h3 className="itemName text-left pt-2">
                                  <span className="detailLink">
                                    {item.title}
                                  </span>
                                </h3>

                                <Card.Text className="card-text my-1 pb-0 text-start d-flex flex-wrap align-items-center">
                                  {
                                    <ConvertDateFormat
                                      inputDate={item.start_date}
                                    />
                                  }
                                  &nbsp;-&nbsp;
                                  {
                                    <ConvertTo12HourFormat
                                      time24={item.start_time}
                                    />
                                  }
                                  &nbsp;
                                  <span>
                                    (
                                    <ConvertMinutesToTime
                                      minutes={item.duration}
                                    />
                                    )
                                  </span>
                                </Card.Text>
                                <Card.Text className="card-text mb-0 pb-0 text-start text-overflow-desc-sm p-0">
                                  {item.description}
                                </Card.Text>
                              </div>
                            </Col>
                          </Row>
                        }
                        onChange={() => handleCheckboxChange(item.id, "demo")}
                        checked={checkedDemos[item.id] ? true : false}
                      />
                    </Col>
                  ))
                ) : (
                  <p className="opacity-50">
                    <small>
                      You don't have any {demosAlias ? demosAlias : "demos"}{" "}
                      yet!
                    </small>
                  </p>
                )}
                {!loading && displayedDemoCards < trainerDemos.length ? (
                  <div className="text-center mb-5">
                    <Button
                      type="button"
                      onClick={handleShowMoreDemos}
                      className="btn actionBtnMore action cursor-pointer w-auto add-button"
                    >
                      Show More
                    </Button>
                  </div>
                ) : null}

                <Col md={12} className="mt-4">
                  <h6 className="theme-text-2">
                    Select your {coursesAlias ? coursesAlias : "courses"}
                  </h6>
                </Col>
                {trainerCourses?.length !== 0 ? (
                  trainerCourses.slice(0, displayedCourseCards)?.map((item) => (
                    <Col md={12} key={item.id}>
                      <Form.Check
                        type="checkbox"
                        className="ps-0 my-2 custom-checkbox"
                        name={item?.id}
                        id={item?.id}
                        label={
                          <Row className="demoCard flex-sm-row card p-2 ms-2">
                            <Col
                              md={2}
                              sm={3}
                              className="itemImg border-dark border-opacity-10 rounded-2 p-0 border"
                            >
                              <img
                                src={item.listing_image_url || PlaceholdIMG}
                                className="img-fluid"
                                alt="imageCourse"
                              />
                            </Col>
                            <Col md={10} sm={9} className="itemInfo px-2">
                              <div className="card-details">
                                <h3 className="itemName text-left pt-2">
                                  <span className="detailLink">
                                    {item.title}
                                  </span>
                                </h3>

                                <Card.Text className="card-text my-1 pb-0 text-start d-flex flex-wrap align-items-center">
                                  {
                                    <ConvertDateFormat
                                      inputDate={item.start_date}
                                    />
                                  }
                                  &nbsp;-&nbsp;
                                  {
                                    <ConvertTo12HourFormat
                                      time24={item.start_time}
                                    />
                                  }
                                  &nbsp;
                                  <span>
                                    (
                                    <ConvertMinutesToTime
                                      minutes={item.duration}
                                    />
                                    )
                                  </span>
                                </Card.Text>
                                <Card.Text className="card-text mb-0 pb-0 text-start text-overflow-desc-sm p-0">
                                  {item.description}
                                </Card.Text>
                              </div>
                            </Col>
                          </Row>
                        }
                        onChange={() => handleCheckboxChange(item.id, "course")}
                        checked={checkedCourses[item.id] ? true : false}
                      />
                    </Col>
                  ))
                ) : (
                  <p className="opacity-50">
                    <small>
                      You don't have any{" "}
                      {coursesAlias ? coursesAlias : "courses"} yet!
                    </small>
                  </p>
                )}
                {!loading && displayedCourseCards < trainerCourses.length ? (
                  <div className="text-center mb-5">
                    <Button
                      type="button"
                      onClick={handleShowMoreCourses}
                      className="btn actionBtnMore action cursor-pointer w-auto add-button"
                    >
                      Show More
                    </Button>
                  </div>
                ) : null}

                <Col md={12} className="mt-3">
                  <Link
                    to="/dashboard/my-groups"
                    className="previous-button add-button btn demosEditBtn"
                  >
                    Cancel
                  </Link>
                  <Button
                    type="submit"
                    className="next add-button btn demosEditBtn"
                    disabled={!formData.title}
                  >
                    Create Group
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Container>
  );
};

export default GroupAdd;
