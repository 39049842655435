import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";

const DynamicNavItemFieldset = ({ fields, setFields, index }) => {
  const handleInputChange = (key, value) => {
    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index] = {
        ...updatedFields[index],
        [key]: value,
      };
      return updatedFields;
    });
  };

  const handleFieldRemove = () => {
    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields.splice(index, 1);
      return updatedFields;
    });
  };

  return (
    <div className="d-flex align-items-center mb-3 fieldset">
      <Row className="flex-grow-1">
        <Col md={2}>
          <Form.Group className="form-floating flex-grow-1 pe-2">
            <Form.Control
              type="text"
              id={`Index${index}`}
              placeholder="Enter index"
              value={fields[index]?.index ?? index + 1}
              onChange={(e) => handleInputChange("index", e.target.value)}
            />
            <Form.Label
              className="form-label text-capitalize"
              htmlFor={`Index${index}`}
            >
              Index
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group className="form-floating flex-grow-1 pe-2">
            <Form.Control
              type="text"
              id={`NavPath${index}`}
              placeholder="Enter path"
              value={fields[index]?.path || ""}
              onChange={(e) => handleInputChange("path", e.target.value)}
              className="form-control italicInput"
            />
            <Form.Label
              className="form-label text-capitalize"
              htmlFor={`NavPath${index}`}
            >
              Path
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group className="form-floating flex-grow-1 pe-2">
            <Form.Control
              type="text"
              id={`Label${index}`}
              placeholder="Enter label"
              value={fields[index]?.label || ""}
              onChange={(e) => handleInputChange("label", e.target.value)}
            />
            <Form.Label
              className="form-label text-capitalize"
              htmlFor={`Label${index}`}
            >
              Label
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Button
        className="clickable actionDltBtn btn border-danger"
        onClick={handleFieldRemove}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default DynamicNavItemFieldset;
