import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Image,
  Row
} from "react-bootstrap";
import { ShareFill } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import API from "../../components/API/API";
import CustomLoader from "../../components/loader/loader";
import SocialShare from "../../components/share/socialShare";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import withRouter from "../../constants/withRouter";
import IMG2 from "../../img/SWIPERIMG3-min.jpg";
import COURSEIMG from "../../img/course.png";
import ItemInfo from "./itemInfo";

function ItemsDetails(props) {

  const item_id = props.params.id;
  const item_type = props.params.item_type;

  const entities = "items";
  const currentDomain = window.location.hostname;

  const [loading, setLoading] = useState(true);
  const [itemData, setItemData] = useState({});

  const populateList = async (id) => {
    try {
      const response = await API("get", entities + `/${item_type}/` + id);
      setItemData(response);
    } catch (error) {
      console.log("There was an error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList(item_id);
  }, [item_id]);

  const siteName = SubDomainRouter("title");
  const startupAlias = SubDomainRouter("startupAlias");

  return (
    <>
      <Helmet>
        <title>
          {itemData?.data?.title
            ? `${itemData?.data?.title}`
            : `${startupAlias ? startupAlias : "Item"} Details`}{" "}
          | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <Container fluid="lg" className="my-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <div className="demosViewMoreSection demoProfile overflow-hidden mx-auto">
            <div className="coverImg p-2 position-relative w-100">
              <Image
                src={
                  itemData?.data?.hero_image_url ||
                  itemData?.data?.listing_image_url ||
                  IMG2
                }
                loading="lazy"
                width="100%"
                alt=""
              />
            </div>

            <Row className="profileHeader align-items-center position-relative py-2 mx-4">
              <Col lg={2} className="mr-3">
                <Image
                  src={
                    itemData?.data?.listing_image_url ||
                    itemData?.data?.hero_image_url ||
                    COURSEIMG
                  }
                  alt="..."
                  className="profileImg"
                />
              </Col>
              <Col md={6} className="mt-lg-0 mt-5 pt-2 ps-4">
                <h4 className="trainerName mb-2">
                  {itemData?.data?.title}
                </h4>
              </Col>
              <Col lg={4} md={6}>
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <SocialShare
                    url={`https://${currentDomain}/${item_type}/itemss/details/${itemData?.id}/`}
                    iconsSize={30}
                    title={itemData?.data?.title}
                    image={itemData?.data?.listing_image_url}
                    description={itemData?.data?.description}
                  />
                  <Link
                    to={`/share/items_/${item_type}/${itemData.id}`}
                    className="entity-share-btn d-flex align-items-center cursor-pointer"
                    title="Share"
                  >
                    <ShareFill />
                  </Link>
                </div>
              </Col>
            </Row>

            <div className="mx-4 mt-2" >
              <ItemInfo itemData={itemData?.data} item_type={item_type} />

            </div>
          </div>
        )}
      </Container>
    </>
  );
}
export default withRouter(ItemsDetails);
