import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";

const SpeakerFieldset = ({ field, onFieldChange, onRemove }) => {
  const handleFieldChange = (fieldName, fieldValue) => {
    const updatedField = {
      ...field,
      id: uuidv4(),
      [fieldName]: fieldValue,
    };

    onFieldChange(updatedField);
  };

  return (
    <div className="d-flex gap-3 mt-3 batch">
      <Row className="mx-0 flex-grow-1">
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="text"
              className="w-100"
              id="first_name"
              name="first_name"
              placeholder="Course Name"
              value={field?.first_name}
              onChange={(e) => handleFieldChange("first_name", e.target.value)}
            />
            <Form.Label className="form-label" htmlFor="first_name">
              First Name
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="text"
              className="w-100"
              id="last_name"
              name="last_name"
              placeholder="Course Name"
              value={field?.last_name}
              onChange={(e) => handleFieldChange("last_name", e.target.value)}
            />
            <Form.Label className="form-label" htmlFor="last_name">
              Last Name
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="tel"
              className="w-100"
              id="whatsapp"
              name="whatsapp"
              placeholder="Course Name"
              value={field?.whatsapp}
              onChange={(e) => handleFieldChange("whatsapp", e.target.value)}
            />
            <Form.Label className="form-label" htmlFor="whatsapp">
              WhatsApp
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="tel"
              className="w-100"
              id="Phone"
              name="Phone"
              placeholder="Course Name"
              value={field?.Phone}
              onChange={(e) => handleFieldChange("Phone", e.target.value)}
            />
            <Form.Label className="form-label" htmlFor="Phone">
              Phone
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="email"
              className="w-100"
              id="email"
              name="email"
              placeholder="Course Name"
              value={field?.email}
              onChange={(e) => handleFieldChange("email", e.target.value)}
            />
            <Form.Label className="form-label" htmlFor="email">
              Email
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="text"
              className="w-100"
              id="post"
              name="post"
              placeholder="Course Name"
              value={field?.post}
              onChange={(e) => handleFieldChange("post", e.target.value)}
            />
            <Form.Label className="form-label" htmlFor="post">
              Post
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="url"
              className="w-100"
              id="profile_link"
              name="profile_link"
              placeholder="Course Name"
              value={field?.profile_link}
              onChange={(e) =>
                handleFieldChange("profile_link", e.target.value)
              }
            />
            <Form.Label className="form-label" htmlFor="profile_link">
              Profile Photo Link
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <div className="form-floating formfloating mb-3">
            <select
              className="form-control form-select fromcontrol"
              id="star_rating"
              value={field.star_rating}
              name="star_rating"
              onChange={(e) => handleFieldChange("star_rating", e.target.value)}
            >
              <option value="">Star Rating?</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
        </Col>
        <Col md={12}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              as={"textarea"}
              rows={4}
              style={{ height: "auto" }}
              name="bio"
              placeholder="Course Name"
              value={field?.bio}
              onChange={(e) => handleFieldChange("bio", e.target.value)}
            />
            <Form.Label className="form-label file-label" htmlFor="bio">
              Brief BIO
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Button
        className="clickable actionDltBtn btn border-danger"
        onClick={() => onRemove(field.id)}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default SpeakerFieldset;
