import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ErrorImage from "../../img/404.png";
import { Helmet } from "react-helmet";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";

const NotFoundPage = () => {
  const siteName = SubDomainRouter("title");

  return (
    <>
      <Helmet>
        <title>
          Page Not Found | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <Container fluid="md" className="my-5 pt-5 page404">
        <Row className="justify-content-center align-items-center">
          <Col md="6" className="illustration">
            <img
              src={ErrorImage}
              loading="lazy"
              alt="Error 404"
              width="100%"
              style={{
                minHeight: "50vh",
              }}
            />
          </Col>
          <Col md="6">
            <h1 className="theme-text-2 display-5 fw-bold">Page Not Found</h1>
            <h3>Oops!</h3>
            <h5>The page you are looking for does not exist.</h5>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NotFoundPage;
