export const configDataStructure = {
  type: "config",
  host: window.location.host,
  appsettings: {
    title: window.location.host,
    name: window.location.host,
    logo: "",
    favicon:
      "",
    link: window.location.origin,
    heroSliderHeading: "SEARCH EVENTS",
    heroSliderText: "Helping out small businesses and individuals",
    userReqBtnText: "Get Quote",
    demoAlias: "demo",
    demosAlias: "demos",
    eventAlias: "event",
    eventsAlias: "events",
    courseAlias: "course",
    coursesAlias: "courses",
    trainerAlias: "trainer",
    trainersAlias: "trainers",
    codeAIAlias: "code(AI)",
    startupAlias: "startup",
    startupsAlias: "startups",
    meetupAlias: "meetup",
    meetupsAlias: "meetups",
    speakerAlias: "speaker",
    speakersAlias: "speakers",
    mentorAlias: "mentor",
    mentorsAlias: "mentors",
    founderAlias: "founder",
    foundersAlias: "founders",
    bloggerAlias: "blogger",
    bloggersAlias: "blogger",
    internAlias: "intern",
    internsAlias: "intern",
    freelancerAlias: "freelancer",
    freelancersAlias: "freelancer",
    serviceProviderAlias: "service provider",
    serviceProvidersAlias: "service provider",
    partnerAlias: "partner",
    partnersAlias: "partner",
    sponsorAlias: "sponsor",
    sponsorsAlias: "sponsor",
    corporateStartupAlias: "corporate startup",
    corporateStartupsAlias: "corporate startup",
    NRIStartupAlias: "NRI startup",
    NRIStartupsAlias: "NRI startup",
    betaTesterAlias: "beta tester",
    betaTestersAlias: "beta tester",
    propertyEventAlias: "property event",
    propertyEventsAlias: "property events",
    propertyAlias: "Property",
    propertiesAlias: "Properties",
    cohortAlias: "cohort",
    cohortsAlias: "cohorts",
    pageAlias: "page",
    pagesAlias: "pages",
    myPageAlias: "startups home",
    instructorAlias: "instructor",
    //
    trainersListID: "",
    subscriptionListID: "",
    fromEmail: "",
    countryFilter: "show",
    tagsFilter: "show",
    coursesFilter: "show",
    learningLevelFilter: "show",
    paymentFilter: "show",
    teachingModeFilter: "show",
    ratingsFilter: "show",
    homeNavItem: "show",
    demosNavItem: "",
    coursesNavItem: "",
    reviewsNavItem: "",
    corporatesNavItem: "show",
    blogNavItem: "",
    contactNavItem: "show",
    codeAINavItem: "",
    startupsNavItem: "",
    // -----
    meetupsItem: "hide",
    eventsItem: "show",
    startupsItem: "show",
    foundersItem: "hide",
    speakersItem: "hide",
    mentorsItem: "hide",
    trainersItem: "show",
    bloggersItem: "hide",
    internsItem: "hide",
    freelancersItem: "hide",
    serviceProvidersItem: "hide",
    partnersItem: "hide",
    sponsorsItem: "hide",
    corporateStartupsItem: "hide",
    NRIStartupsItem: "hide",
    betaTestersItem: "hide",
    basicSubscriptionsCharges: "$199",
    proSubscriptionsCharges: "$299",
    masterSubscriptionsCharges: "$399",
    countryTypeFilterAlias: "Country",
    coursesFilterAlias: "Category",
    learningLevelFilterAlias: "Level",
    paymentFilterAlias: "Payment",
    teachingModesFilterAlias: "Mode",
    tagsFilterAlias: "tags",
    ratingsFilterAlias: "Ratings",
    homePageRoute: "/",
    defaultRegisterForm: "registerAcceleratorForm",
    //
    codeGeneratorText: "Code Generator",
    codeGeneratorDesc:
      "Enter command to generate code in Any Programming Language  ",
    codeGeneratorPlaceholder:
      "To generate code: Type the functionality. For example: 'send email in java'",
  },
  appsettingsarray: {},
  hostsettings: {},
  customfields: [],
  appsettingsarrayobjects: {
    userRole: [],
  },
  categorysettings: {
    countryTypes: {},
    courseNames: {},
    subcategoryTypes: {},
    teachingModes: {},
    paymentTypes: {},
    starRatings: {},
    heroSliderHeading: {},
    basicPackage: {},
    proPackage: {},
    masterPackage: {},
    heroSliderImages: {},
  },
};
