import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { HeartPulse, PlayCircle } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ConvertMinutesToTime from "../../components/convertFromMinutes/convertFromMinutes  ";
import TextFormatter from "../../components/textFormat/textFormat";

const ItemInfo = ({ entityData }) => {
  const entityType = "cohort";
  return (
    <Card className="border-top-0 rounded-top-0">
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2 text-capitalize">
          Details:
        </h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Event Topics</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 d-flex gap-2">
              {entityData?.sessionTopics?.length > 0
                ? entityData?.sessionTopics.map((item, index) => (
                    <span
                      className="border-secondary-subtle px-2 py-1 fw-bold border rounded-3"
                      key={index}
                    >
                      {item.topic}
                    </span>
                  ))
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Subtitle</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.subtitle ? entityData?.subtitle : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />

        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Description</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.description ? (
                <TextFormatter text={entityData?.description} />
              ) : (
                "Not Available"
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Duration</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.duration && entityData?.duration ? (
                <ConvertMinutesToTime minutes={entityData?.duration} />
              ) : (
                ""
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Category</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {entityData?.category && entityData?.category
                ? entityData?.category
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Type</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0 text-capitalize">
              {entityData?.subcategorylevel1 && entityData?.subcategorylevel1
                ? entityData?.subcategorylevel1
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Mode</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.subcategorylevel2
                ? entityData?.subcategorylevel2
                : "Yet To Decide"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">RSVP Question Link</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.RSVP_question &&
              entityData?.RSVP_question.trim() !== "" ? (
                <Link
                  to={entityData?.RSVP_question}
                  target="_blank"
                  className="btn-link theme-text-2 btn d-flex align-items-center gap-1 p-0"
                  rel="noreferrer"
                >
                  Visit Link
                </Link>
              ) : (
                "Not Available"
              )}
            </Card.Text>
          </Col>
        </Row>
        {entityData?.video_url && (
          <>
            <hr />
            <Row>
              <Col md={3}>
                <Card.Text className="mb-0 fw-bold text-capitalize">
                  Intro Video
                </Card.Text>
              </Col>
              <Col md={9}>
                <Card.Text className="text-muted mb-0 d-flex align-items-center gap-2">
                  <Link
                    to={entityData?.video_url}
                    target="_blank"
                    className="btn-link theme-text-2 btn d-flex align-items-center gap-1 text-decoration-none p-0"
                  >
                    <PlayCircle /> Watch Video
                  </Link>
                </Card.Text>
              </Col>
            </Row>
            <hr />
          </>
        )}

        {entityData?.subcategorylevel1?.toLowerCase() === "paid" && (
          <Link
            to={`/request-sponsor/items_${entityType}/${entityData?.id}`}
            className="btn-link theme-text-2 text-decoration-none d-flex align-items-center mb-3"
          >
            <HeartPulse />
            &nbsp;
            <span className="text-decoration-underline">
              Request your employer or some one to sponsor / pay for this event
            </span>
          </Link>
        )}

        <Row>
          <Col md={12}>
            <div className="card-text text-muted mb-0 d-flex align-items-center gap-2">
              {entityData?.tags &&
                entityData?.tags?.map((tag, index) => (
                  <h3 className="tag-chip" key={index}>
                    #{tag}
                  </h3>
                ))}
            </div>
          </Col>
        </Row>
      </Card.Body>
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2">Organizer Details:</h5>
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Organizer Name</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.organizer_first_name ||
              entityData?.organizer_last_name
                ? `${entityData?.organizer_first_name} ${entityData?.organizer_last_name}`.trim()
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Company Name</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.organizer_company_name &&
              entityData?.organizer_company_name
                ? entityData?.organizer_company_name
                : "Not Available"}
            </Card.Text>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card.Text className="mb-0 fw-bold">Website</Card.Text>
          </Col>
          <Col md={9}>
            <Card.Text className="text-muted mb-0">
              {entityData?.organizer_website &&
              entityData?.organizer_website.trim() !== "" ? (
                <a
                  href={entityData?.organizer_website}
                  target="_blank"
                  rel="noreferrer"
                >
                  {entityData?.organizer_website}
                </a>
              ) : (
                "Not Available"
              )}
            </Card.Text>
          </Col>
        </Row>
        <hr />
      </Card.Body>
      {entityData.certificate ? (
        <Card.Body>
          <h5 className="theme-text-2 mt-2 m-0">Certificate:</h5>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Image
                className="h-100 w-auto mx-auto"
                src={entityData.certificate}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://i.seadn.io/gae/OGpebYaykwlc8Tbk-oGxtxuv8HysLYKqw-FurtYql2UBd_q_-ENAwDY82PkbNB68aTkCINn6tOhpA8pF5SAewC2auZ_44Q77PcOo870?auto=format&dpr=1&w=3840";
                }}
                alt="image error"
                style={{ maxHeight: "50vh" }}
              />
            </Col>
          </Row>
        </Card.Body>
      ) : null}
      <Card.Body>
        <h5 className="theme-text-2 mt-2 pb-lg-3 pb-2">Sponsors:</h5>
        <Row className="justify-content-center">
          {entityData?.sponsors.length > 0 &&
          (entityData?.sponsors[0]?.sponsor_logo_title ||
            entityData?.sponsors[0]?.sponsor_logo_url) ? (
            entityData.sponsors.map((item, index) => (
              <Col
                lg={2}
                md={3}
                xs={4}
                title={item?.sponsor_logo_title}
                onClick={() =>
                  item?.sponsor_website_url
                    ? window.open(item?.sponsor_website_url, "_blank")
                    : null
                }
                className={`pb-1 cursor-pointer`}
                key={index}
              >
                {item.sponsor_logo_url && (
                  <Image
                    src={item.sponsor_logo_url}
                    width={"100%"}
                    alt="logo"
                  />
                )}
              </Col>
            ))
          ) : (
            <p>No Sponsors Yet</p>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ItemInfo;
