import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import CustomLoader from "../../components/loader/loader";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import * as Actions from "../../constants/baseUrl";

const AllContacts = () => {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [loading, setLoading] = useState(true);
  const [contactsData, setContactsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderBy, setOrderBy] = useState("first_name");
  const [order, setOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [listNameColors, setListNameColors] = useState({});
  const [checkedShow, setCheckedShow] = useState(false);

  const getEmailContacts = () => {
    setLoading(true);

    axios
      .get(`${Actions.BASE_URL}contacts/users/${myUserId}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
        const contactsWithListNames = await Promise.all(
          res.data?.map(async (contact) => {
            const telegramlistId = contact?.entityRelation[0]?.telegramlist;
            const whatsapplistId = contact?.entityRelation[0]?.whatsapplist;
            const smslistId = contact?.entityRelation[0]?.smslist;
            const emaillistId = contact?.entityRelation[0]?.emaillist;

            const currentListID = emaillistId
              ? emaillistId
              : smslistId
              ? smslistId
              : whatsapplistId
              ? whatsapplistId
              : telegramlistId
              ? telegramlistId
              : null;
            const currentListName = emaillistId
              ? "emaillists"
              : smslistId
              ? "smslists"
              : whatsapplistId
              ? "whatsapplists"
              : telegramlistId
              ? "telegramlists"
              : null;
            const listName = await getListName(currentListName, currentListID);
            return { ...contact, listName };
          })
        );

        // Generate unique colors for each list name
        const uniqueListNames = Array.from(
          new Set(contactsWithListNames?.map((contact) => contact?.listName))
        );
        const newColors = {};
        uniqueListNames?.forEach((listName, index) => {
          newColors[listName] = getRandomColor(index);
        });

        setListNameColors(newColors);
        setContactsData(contactsWithListNames);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
        toast.error("Something went wrong. Please Try again later.", {
          autoClose: 3000,
          hideProgressBar: true,
          draggable: true,
        });
        toast.error("Something went wrong. Please Try again later.", {
          autoClose: 3000,
          hideProgressBar: true,
          draggable: true,
        });
      });
  };

  // Helper function to generate random colors
  const getRandomColor = (index) => {
    const colors = ["#ffcccb", "#ccffcc", "#ccccff", "#ffebcd", "#98fb98"]; // Add more colors as needed
    return colors[index % colors.length];
  };
  const getListName = async (currentListName, currentListID) => {
    try {
      const response = await axios.get(
        `${Actions.BASE_URL}${currentListName}/${currentListID}`,
        {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.list_name;
    } catch (error) {
      console.log(error.response);
      return "Unknown";
    }
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getEmailContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredContacts = contactsData.filter(
    (contact) =>
      contact?.first_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.last_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.phone?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.whatsapp?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      contact?.listName?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const sortedContacts = filteredContacts.sort((a, b) => {
    const isAsc = order === "asc";
    return isAsc
      ? a[orderBy]?.localeCompare(b[orderBy])
      : b[orderBy]?.localeCompare(a[orderBy]);
  });

  return (
    <div className="all-contacts">
      <div className="d-flex justify-content-between align-items-start px-3 pt-3 flex-wrap">
        <div className="mb-3">
          <h4 className="theme-text mb-1">My Contacts</h4>
          <Form.Group>
            <Form.Check
              label="Show All Contacts"
              name="showContacts"
              type="checkbox"
              id="showContacts"
              className="ps-0 custom-checkbox fw-medium"
              checked={checkedShow}
              onClick={() => setCheckedShow(!checkedShow)}
            />
          </Form.Group>
        </div>

        <InputGroup className="mb-3 w-100 tableSearch ms-auto rounded-pill position-relative">
          <Form.Control
            className="rounded-pill"
            onChange={handleSearch}
            placeholder="Search"
          />
          <InputGroup.Text
            id="basic-addon1"
            className="border-0 bg-transparent position-absolute top-0 h-100 end-0"
          >
            <Search />
          </InputGroup.Text>
        </InputGroup>
      </div>
      {loading ? (
        <CustomLoader />
      ) : sortedContacts.length > 0 ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 768 }}
            aria-label="simple table"
            className="striped rounded-0"
          >
            <TableHead className="bg-theme">
              <TableRow>
                <TableCell className="text-nowrap text-white fw-bold">
                  <TableSortLabel
                    active={orderBy === "first_name"}
                    direction={orderBy === "first_name" ? order : "asc"}
                    onClick={() => handleSortRequest("first_name")}
                  >
                    First Name
                  </TableSortLabel>
                </TableCell>
                <TableCell className="text-nowrap text-white fw-bold">
                  <TableSortLabel
                    active={orderBy === "last_name"}
                    direction={orderBy === "last_name" ? order : "asc"}
                    onClick={() => handleSortRequest("last_name")}
                  >
                    Last Name
                  </TableSortLabel>
                </TableCell>{" "}
                <TableCell className="text-nowrap text-white fw-bold">
                  <TableSortLabel
                    active={orderBy === "listName"}
                    direction={orderBy === "listName" ? order : "asc"}
                    onClick={() => handleSortRequest("listName")}
                  >
                    List Name
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedContacts
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((contact) => (
                  <TableRow key={contact?.id}>
                    <TableCell>{contact?.first_name}</TableCell>
                    <TableCell>{contact?.last_name}</TableCell>
                    {/* <TableCell>
                      {contact?.email
                        ? checkedShow
                          ? contact?.email
                          : "Available"
                        : "Not Available"}
                    </TableCell>
                    <TableCell>
                      {contact?.phone
                        ? checkedShow
                          ? contact?.phone
                          : "Available"
                        : "Not Available"}
                    </TableCell>
                    <TableCell>
                      {contact?.whatsapp
                        ? checkedShow
                          ? contact?.whatsapp
                          : "Available"
                        : "Not Available"}
                    </TableCell>
                    <TableCell>
                      {contact?.telegram
                        ? checkedShow
                          ? contact?.telegram
                          : "Available"
                        : "Not Available"}
                    </TableCell> */}
                    <TableCell>
                      <span
                        className="p-1 rounded-1 text-capitalize"
                        style={{
                          backgroundColor: listNameColors[contact?.listName],
                        }}
                      >
                        {contact?.listName}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[50, 75, 100]}
            component="div"
            className="d-flex align-items-center justify-content-end"
            count={sortedContacts?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableContainer>
      ) : (
        <p className="text-center">No Data Found...</p>
      )}
    </div>
  );
};

export default AllContacts;
