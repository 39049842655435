import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, InputGroup, Table } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import CustomLoader from "../../../components/loader/loader";
import useUserData from "../../../components/useUserDetails/useUserDetails";
import * as Actions from "../../../constants/baseUrl";
import { toast } from "react-toastify";

const Analytics = () => {
  const userData = useUserData();
  const myToken = localStorage.getItem("token");
  const role = userData.role;

  const [loading, setLoading] = useState(true);
  const [analytics, setAnalytics] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderBy, setOrderBy] = useState("date");
  const [order, setOrder] = useState("des");
  const [searchTerm, setSearchTerm] = useState("");

  const getAnalytics = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${Actions.BASE_URL}items/sendgrid-webhook`,
        {
          headers: {
            Authorization: "Bearer " + myToken,
            Host: window.location.host,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const sortedData = response?.data?.sort((a, b) =>
        a.createdDate.localeCompare(b.createdDate)
      );
      setAnalytics(sortedData);
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Something went wrong. Please Try again later.", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAnalytics();
  }, [role]);

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    // Sort the data based on property and order
    const sortedData = filteredData.map((item) => ({
      ...item,
      data: item.data.slice().sort((a, b) => {
        const aValue = (a ? a[property] : "")?.toString();
        const bValue = (b ? b[property] : "")?.toString();

        // Handle case where value is undefined
        if (!aValue && !bValue) return 0;
        if (!aValue) return isAsc ? 1 : -1;
        if (!bValue) return isAsc ? -1 : 1;

        return isAsc
          ? aValue?.localeCompare(bValue)
          : bValue?.localeCompare(aValue);
      }),
    }));

    setAnalytics(sortedData);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  // Inside handlePageChange function
  const handlePageChange = (event, newPage) => {
    setPage(Number(newPage)); // Convert newPage to a number
  };

  // Inside handleRowsPerPageChange function
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = analytics?.filter((item) =>
    item.data.some(
      (dataItem) =>
        dataItem?.campaign_name
          ?.toLowerCase()
          ?.includes(searchTerm?.toLowerCase()) ||
        dataItem?.date?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        dataItem?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        dataItem?.event?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        dataItem?.ip?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        dataItem?.subject?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    )
  );

  const sortedData = filteredData?.sort((a, b) => {
    const aValue = a?.data[0][orderBy];
    const bValue = b?.data[0][orderBy];
    const isAsc = order === "asc";
    return isAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });

  // Flatten the sortedData array
  const flattenedData = sortedData.reduce((acc, item) => {
    return acc.concat(item.data);
  }, []);
  console.log(flattenedData);
  return (
    <div className="my-demos mb-4 all-contacts">
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="my-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <h4 className="theme-text mb-1">Analytics</h4>

              <InputGroup className="mb-3 w-100 tableSearch ms-auto rounded-pill position-relative">
                <Form.Control
                  className="rounded-pill"
                  onChange={handleSearch}
                  placeholder="Search"
                />
                <InputGroup.Text
                  id="basic-addon1"
                  className="border-0 bg-transparent position-absolute top-0 h-100 end-0"
                >
                  <Search />
                </InputGroup.Text>
              </InputGroup>
            </div>

            {analytics?.length > 0 ? (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 768 }}
                  aria-label="simple table"
                  className="striped rounded-0"
                >
                  <TableHead className="bg-theme">
                    <TableRow>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme text-capitalize text-center">
                        <TableSortLabel
                          active={orderBy === "date"}
                          direction={orderBy === "date" ? order : "asc"}
                          onClick={() => handleSortRequest("date")}
                        >
                          date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme text-capitalize">
                        <TableSortLabel
                          style={{ width: 172 }}
                          active={orderBy === "campaignId"}
                          direction={orderBy === "campaignId" ? order : "asc"}
                          onClick={() => handleSortRequest("campaignId")}
                        >
                          campaignId
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme text-capitalize">
                        <TableSortLabel
                          active={orderBy === "subject"}
                          direction={orderBy === "subject" ? order : "asc"}
                          onClick={() => handleSortRequest("subject")}
                        >
                          subject
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme text-capitalize">
                        <TableSortLabel
                          style={{ width: 172 }}
                          active={orderBy === "campaign_name"}
                          direction={
                            orderBy === "campaign_name" ? order : "asc"
                          }
                          onClick={() => handleSortRequest("campaign_name")}
                        >
                          campaign name
                        </TableSortLabel>
                      </TableCell>

                      <TableCell className="text-nowrap text-white fw-bold bg-theme text-capitalize">
                        <TableSortLabel
                          active={orderBy === "email"}
                          direction={orderBy === "email" ? order : "asc"}
                          onClick={() => handleSortRequest("email")}
                        >
                          email
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme text-capitalize text-center">
                        <TableSortLabel
                          active={orderBy === "event"}
                          direction={orderBy === "event" ? order : "asc"}
                          onClick={() => handleSortRequest("event")}
                        >
                          event
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme text-capitalize text-center">
                        IP
                      </TableCell>

                      <TableCell className="text-nowrap text-white fw-bold bg-theme text-capitalize">
                        <TableSortLabel
                          active={orderBy === "response"}
                          direction={orderBy === "response" ? order : "asc"}
                          onClick={() => handleSortRequest("response")}
                        >
                          response
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme text-capitalize">
                        <TableSortLabel
                          active={orderBy === "user"}
                          direction={orderBy === "user" ? order : "asc"}
                          onClick={() => handleSortRequest("user")}
                        >
                          user
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme text-capitalize">
                        <TableSortLabel
                          active={orderBy === "sg_event_id"}
                          direction={orderBy === "sg_event_id" ? order : "asc"}
                          onClick={() => handleSortRequest("sg_event_id")}
                        >
                          sg_event_id
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme text-capitalize">
                        <TableSortLabel
                          active={orderBy === "sg_message_id"}
                          direction={
                            orderBy === "sg_message_id" ? order : "asc"
                          }
                          onClick={() => handleSortRequest("sg_message_id")}
                        >
                          sg_message_id
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className="text-nowrap text-white fw-bold bg-theme text-capitalize">
                        <TableSortLabel
                          active={orderBy === "smtp-id"}
                          direction={orderBy === "smtp-id" ? order : "asc"}
                          onClick={() => handleSortRequest("smtp-id")}
                        >
                          smtp-id
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {flattenedData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((dataItem, index) => (
                        <TableRow key={index}>
                          <TableCell className="text-truncate text-center">
                            {dataItem?.date || "-"}
                          </TableCell>
                          <TableCell>
                            <span
                              className="d-block text-truncate"
                              style={{ width: 172 }}
                            >
                              {dataItem?.campaignId || "-"}
                            </span>
                          </TableCell>
                          <TableCell className="text-truncate">
                            <span
                              className="d-block text-truncate"
                              style={{ width: 172 }}
                            >
                              {dataItem?.subject || "-"}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span
                              className="d-block text-truncate"
                              style={{ width: 172 }}
                            >
                              {dataItem?.campaign_name || "-"}
                            </span>
                          </TableCell>
                          <TableCell className="text-truncate">
                            {dataItem?.email || "-"}
                          </TableCell>
                          <TableCell className={`text-truncate text-center`}>
                            <span
                              className={`text-white rounded d-inline-block px-1 w-100 ${
                                dataItem?.event?.toLowerCase() === "processed"
                                  ? "bg-warning"
                                  : dataItem?.event?.toLowerCase() ===
                                    "delivered"
                                  ? "bg-theme"
                                  : "bg-success"
                              }`}
                            >
                              {dataItem?.event || "-"}
                            </span>
                          </TableCell>
                          <TableCell className="text-truncate text-center">
                            {dataItem?.ip || "-"}
                          </TableCell>

                          <TableCell className="text-truncate">
                            <span
                              className="d-block text-truncate"
                              style={{ width: 172 }}
                            >
                              {dataItem?.response || "-"}
                            </span>
                          </TableCell>
                          <TableCell className="text-truncate">
                            <span
                              className="d-block text-truncate"
                              style={{ width: 172 }}
                            >
                              {dataItem?.user || "-"}
                            </span>
                          </TableCell>
                          <TableCell className="text-truncate">
                            <span
                              className="d-block text-truncate"
                              style={{ width: 172 }}
                            >
                              {dataItem?.sg_event_id || "-"}
                            </span>
                          </TableCell>
                          <TableCell className="text-truncate">
                            <span
                              className="d-block text-truncate"
                              style={{ width: 172 }}
                            >
                              {dataItem?.sg_message_id || "-"}
                            </span>
                          </TableCell>
                          <TableCell className="text-truncate">
                            <span
                              className="d-block text-truncate"
                              style={{ width: 172 }}
                            >
                              {dataItem["smtp-id"] || "-"}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 50, 75, 100, 200, 300, 500, 1000]}
                  component="div"
                  className="d-flex align-items-center justify-content-end"
                  count={flattenedData?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </TableContainer>
            ) : (
              <p>No Analytics Founds...</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Analytics;
