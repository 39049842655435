import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import meetups from "../../img/home/meeetup.jpg";

const Meetups = () => {
  const meetupsAlias = SubDomainRouter("meetupsAlias");

  const meetupsData = [
    {
      image: meetups,
      title: "bangalore ",
      link: "https://www.meetup.com/IndianStartupsBangalore/",
    },
    {
      image: meetups,
      title: "hyderabad ",
      link: "https://www.meetup.com/IndianStartups/",
    },
    {
      image: meetups,
      title: "New Delhi ",
      link: "https://www.meetup.com/IndianStartupsDelhi/",
    },
    {
      image: meetups,
      title: "Mumbai ",
      link: "https://www.meetup.com/IndianStartupsMumbai/",
    },
    {
      image: meetups,
      title: "pune ",
      link: "https://www.meetup.com/IndianStartupsPune/",
    },
    {
      image: meetups,
      title: "ahmadabad ",
      link: "https://www.meetup.com/IndianStartupsAhmedabad/",
    },
    {
      image: meetups,
      title: "chennai ",
      link: "https://www.meetup.com/IndianStartupsChennai/",
    },
    {
      image: meetups,
      title: "kolkata ",
      link: "https://www.meetup.com/IndianStartupsKolkata/",
    },
    {
      image: meetups,
      title: "jaipur ",
      link: "https://www.meetup.com/IndianStartupsJaipur/",
    },
    {
      image: meetups,
      title: "kochi ",
      link: "https://www.meetup.com/IndianStartupsKochi/",
    },
    {
      image: meetups,
      title: "indian startups - america ",
      link: "https://www.meetup.com/IndianStartupsAmerica/",
    },
    {
      image: meetups,
      title: "silicon valley ",
      link: "https://www.meetup.com/IndianStartupsSiliconValley/",
    },
    {
      image: meetups,
      title: "indian startups - canada ",
      link: "https://www.meetup.com/IndianStartupsCanada/",
    },
    {
      image: meetups,
      title: "investors ",
      link: "https://www.meetup.com/IndiaInvestors/",
    },
    {
      image: meetups,
      title: "telangana startups ",
      link: "https://www.meetup.com/Telangana/",
    },
    {
      image: meetups,
      title: "indian startups tech ",
      link: "https://www.meetup.com/TrainingOnline/",
    },
  ];

  const siteName = SubDomainRouter("title");
  let uppercaseTitle = meetupsAlias.toLocaleUpperCase();

  return (
    <>
      <Helmet>
        <title>
          {uppercaseTitle} | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      <Container fluid="lg" className="startupsHomepage my-5 py-5">
        <p>
          Indian Startups, a 100,000+ member community, has meetup chapters
          across the globe. Some of the most active chapters being Hyderabad,
          Bangalore, Mumbai, Chennai, Delhi, San Diego, Los Angeles, San
          Francisco, Dallas, New York, Houston, Texas, Edison, Chicago, and many
          more coming up.
        </p>
        <p>
          Indian Startups community members get the opportunity to connect with
          co-founders, startup team members, mentors, advisers, incubation
          facilities, co-working spaces, agencies in seed funding, crowdfunding,
          high networth individuals and other essential startup partners.
        </p>
        <Row className="mt-3 align-items-stretch">
          {meetupsData.map((item, index) => (
            <Col key={index} xl={3} lg={4} md={6} className="py-2 min-h-full">
              <Card className="directory-card h-100 d-flex flex-column justify-content-center align-items-center w-100">
                <Link
                  target="_blank"
                  to={item.link}
                  className="text-decoration-none d-flex flex-column justify-content-center align-items-center w-100"
                >
                  <div className="image-wrapper d-flex align-items-center justify-content-center position-relative">
                    <Image
                      src={item.image}
                      //   width={"100%"}
                      height={"100%"}
                      alt="img"
                    />
                  </div>
                  <h5 className="theme-text-2 text-capitalize">{item.title}</h5>
                </Link>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Meetups;
