import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { ArrowLeftCircleFill, Share } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import * as Actions from "../../../constants/baseUrl";
import withRouter from "../../../constants/withRouter";
import COURSEIMG from "../../../img/course.png";
import ConvertDateFormat from "../../../components/convertDateFormat/convertDateFormat";
import ConvertMinutesToTime from "../../../components/convertFromMinutes/convertFromMinutes  ";
import ConvertTo12HourFormat from "../../../components/convertTo12HourFormat/convertTo12HourFormat";
import CustomLoader from "../../../components/loader/loader";

const UniqueItems = (props) => {
  let uniqueId = props.params?.id;
  const myToken = localStorage.getItem("token");

  const [loading, setLoading] = useState(true);
  const [groupData, setGroupData] = useState(null);
  const [items, setItems] = useState([]);

  // Get data

  const getGroups = () => {
    axios
      .get(`${Actions.BASE_URL}groups/unique/${uniqueId}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setGroupData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchItems = async () => {
    if (!groupData || !groupData.relation) {
      return;
    }

    const itemIds = Object.keys(groupData.relation);
    const itemTypes = Object.values(groupData.relation);

    const itemsData = [];

    for (let i = 0; i < itemIds.length; i++) {
      const itemId = itemIds[i];
      const itemType = itemTypes[i];

      try {
        const response = await axios.get(
          `${Actions.BASE_URL}${itemType}s/${itemId}`
        );
        itemsData.push(response.data);
      } catch (error) {
        setLoading(false);
        console.error(`Error fetching ${itemType} with ID ${itemId}:`, error);
      }
    }
    setItems(itemsData);
    setLoading(false);
  };

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (groupData && groupData.relation) {
      fetchItems();
    }
  }, [groupData]);

  return (
    <Container fluid="lg" className="my-5 pt-5">
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <h4 className="theme-text-2 mb-3 d-flex align-items-center gap-2">
            <Link
              to="/dashboard/my-groups"
              className="btn-link theme-text text-center"
            >
              <ArrowLeftCircleFill />
            </Link>
            Group
          </h4>{" "}
          <Row>
            {items?.length > 0 ? (
              <>
                {items?.map((item) => (
                  <Col
                    xxl={3}
                    xl={4}
                    md={6}
                    sm={10}
                    className="mt-2 mb-3"
                    key={item.id}
                  >
                    <Card className="demoCard">
                      <div className="itemImg">
                        <Link
                          to={`/demos/details/${item.id}`}
                          className="linkImg"
                        >
                          <img
                            src={item.listing_image_url || COURSEIMG}
                            className="img-fluid"
                            alt="imageDemo"
                          />
                        </Link>
                      </div>
                      <div className="alert alert-theme-2 p-1 ps-2 w-fit position-absolute top-0 end-0 z-1 bg-theme-2 text-white rounded-end-0 border-end-0 text-capitalize">
                        {item.type}
                      </div>
                      <Card.Body className="itemInfo px-2 pb-3 d-flex flex-column justify-content-between py-1">
                        <div className="card-details text-center">
                          <h3 className="itemName text-left pt-2">
                            <Link
                              to={`/demos/details/${item.id}`}
                              className="detailLink text-left"
                            >
                              {item.title}
                            </Link>
                          </h3>

                          <Link
                            to={`/share/demo/${item.id}`}
                            className="entity-share-btn d-flex align-items-center cursor-pointer float-end"
                            title="review"
                          >
                            <Share />
                          </Link>
                          <Card.Text className="card-text my-1 pb-0 text-left d-flex flex-wrap align-items-center justify-content-center">
                            {<ConvertDateFormat inputDate={item.start_date} />}
                            &nbsp;-&nbsp;
                            {<ConvertTo12HourFormat time24={item.start_time} />}
                            &nbsp;
                            <span>
                              (
                              <ConvertMinutesToTime minutes={item.duration} />)
                            </span>
                          </Card.Text>
                          <hr className="mt-2 mb-1" />
                          <Card.Text className="card-text mb-0 pb-0 text-left text-overflow-desc p-0">
                            {item.description}
                          </Card.Text>
                        </div>

                        <div>
                          <div className="itemEdit d-flex flex-wrap justify-content-center mt-3 gap-2">
                            <Link
                              to={`/demos/details/${item.id}`}
                              className="actionBtn d-flex align-items-center justify-content-center text-center btn action cursor-pointer actionBtnMain"
                            >
                              View More
                            </Link>
                            <Link
                              to={`/demos/details/${item.id}?demo=reviews`}
                              className="actionBtn d-flex align-items-center justify-content-center text-center btn action cursor-pointer actionBtnMain"
                            >
                              Reviews
                            </Link>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </>
            ) : (
              <p>No Items Found!</p>
            )}
          </Row>
        </>
      )}
    </Container>
  );
};

export default withRouter(UniqueItems);
