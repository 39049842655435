import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Actions from "../../../constants/baseUrl";
import { Button, Form } from "react-bootstrap"; 
import "./style.css";
import TokenDetails from "../../../components/useUserDetails/useTokenDetails";

const CreatePassword = () => {
  const navigate = useNavigate();

  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      event.currentTarget["createpassword"].value ===
      event.currentTarget["repeatpassword"].value
    ) {
      axios
        .post(`${Actions.AUTH_URL}Users/ResetNewPassword`, {
          Email: myUserId,
          Password: event.currentTarget["createpassword"].value,
        })
        .then((res) => {
          alert(res.data.Data);
          if (res.data.Data == "SUCCESS") {
            navigate("/");
          }
        });
    } else {
      alert("Password and repeat password must be same");
    }
  };
  return (
    <div className="my-5 pt-5 demosAddFormSection">
      <div className="formWrap row mx-0 mt-5">
        <Form
          onSubmit={handleSubmit}
          className="col-lg-6 col-md-10 demosAddForm"
        >
          <div className="col-12 demosAddFormHeasder text-center py-3">
            <h3 className="addFormHeading" id="tabelLabel">
              Change Password
            </h3>
            <h6 className="theme-text-2">
              You can change your password from here.
            </h6>
          </div>
          <Form.Group className="form-floating formfloating my-3 mx-2">
            <Form.Control
              className="form-control fromcontrol"
              type="password"
              id="createpassword"
              label="Create Password"
              placeholder="Create Password"
              required
            ></Form.Control>
            <Form.Label htmlFor="createpassword">Create Password</Form.Label>
          </Form.Group>
          <Form.Group className="form-floating formfloating mb-3 mx-2">
            <Form.Control
              className="form-control fromcontrol"
              type="password"
              id="repeatpassword"
              label="Repeat Password"
              placeholder="Repeat Password"
              required
            ></Form.Control>
            <Form.Label htmlFor="repeatpassword">Repeat Password</Form.Label>
          </Form.Group>

          <Button className="btn add-button px-4 float-end mb-3" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

const Input = ({ id, type, label, disabled }) => (
  <input
    className="form-group__input"
    type={type}
    id={id}
    placeholder={label}
    disabled={disabled}
    required
  />
);

export default CreatePassword;
