import Box from "@mui/material/Box";
import * as React from "react";
import { FileEarmark } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const UploadedDocuments = ({ docData }) => {
  const hasDocuments = docData?.some(
    (item) => item.url !== null && item.url !== ""
  );

  return (
    <div>
      <Box sx={{ minHeight: 400 }}>
        {docData && hasDocuments ? (
          <Box columns={3} spacing={2}>
            {docData?.map((item, index) => (
              <div key={index}>
                <a
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-none d-flex align-items-baseline gap-2 p-2 border rounded-1 mb-2"
                >
                  <span className="btn btn-outline-danger p-2">
                    <FileEarmark className="h5 my-0 mx-1" />
                  </span>
                  <h6 className="text-dark mb-0">
                    {item.field_name ? item.field_name : `Document${index + 1}`}
                    .{item.file_extension}
                  </h6>
                </a>
              </div>
            ))}
          </Box>
        ) : (
          <p>No Documents Found</p>
        )}
      </Box>
    </div>
  );
};

export default UploadedDocuments;
